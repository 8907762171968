var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.partners.length > 0
    ? _c("v-autocomplete", {
        attrs: {
          items: _vm.partners,
          "item-text": "companyName",
          "item-value": "_id",
          "return-object": "",
          dense: "",
          solo: "",
          outlined: "",
          flat: "",
          "auto-select-first": "",
          "hide-details": "",
          autofocus: ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item",
              fn: function fn(_ref) {
                var item = _ref.item
                return [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s(item.companyName) + " ")
                      ]),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          _vm._s(item.companyUsername) +
                            " (" +
                            _vm._s(item.id) +
                            ")"
                        )
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          false,
          2828766483
        ),
        model: {
          value: _vm.partner,
          callback: function callback($$v) {
            _vm.partner = $$v
          },
          expression: "partner"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }