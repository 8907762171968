import { render, staticRenderFns } from "./TemplateDetail.vue?vue&type=template&id=55df65e0&scoped=true&"
import script from "./TemplateDetail.vue?vue&type=script&lang=ts&"
export * from "./TemplateDetail.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateDetail.vue?vue&type=style&index=0&id=55df65e0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55df65e0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55df65e0')) {
      api.createRecord('55df65e0', component.options)
    } else {
      api.reload('55df65e0', component.options)
    }
    module.hot.accept("./TemplateDetail.vue?vue&type=template&id=55df65e0&scoped=true&", function () {
      api.rerender('55df65e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/template/TemplateDetail.vue"
export default component.exports