var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        margin: 0,
        title: _vm.$t(
          "components.partner.PartnerReportInitializeCard.sendMail.title"
        ),
        subtitle: _vm.$t(
          "components.partner.PartnerReportInitializeCard.sendMail.description"
        )
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  },
                  on: {
                    click: function click($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(" mdi-close ")])],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-row",
        {
          staticClass: "mb-5"
        },
        [
          _c(
            "v-col",
            {
              staticStyle: {
                height: "500px"
              },
              attrs: {
                cols: "12"
              }
            },
            [
              _c("preselect-template-card", {
                ref: "templateCard",
                staticClass: "ma-2",
                attrs: {
                  templateKey: "request_report_mail",
                  partnerId: _vm.partnerId,
                  from: [_vm.partner],
                  context: _vm.context,
                  to: _vm.to
                },
                on: {
                  sent: _vm.sent
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: {
                text: ""
              },
              on: {
                click: function click($event) {
                  return _vm.setNextStep(_vm.PagesEnum.MAIL)
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerReportInitializeCard.back"
                    )
                  ) +
                  " "
              )
            ]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                outlined: "",
                color: "info"
              },
              on: {
                click: _vm.send
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerReportInitializeCard.sendMail.button"
                    )
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }