var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        app: "",
        width: "300px"
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function fn() {
            return [_c("the-settings-list")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.drawer,
        callback: function callback($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _vm.isAuthenticated && _vm.navigationSettings.showMmmmintLogo
        ? _c(
            "v-list-item",
            {
              staticClass: "pt-3 pb-2",
              attrs: {
                to: "/"
              }
            },
            [
              _c("v-img", {
                attrs: {
                  src: _vm.logo,
                  alt: "logo",
                  contain: ""
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.partnerId
        ? _c(
            "v-list",
            {
              staticClass: "pr-3",
              attrs: {
                dense: "",
                expand: "",
                shaped: ""
              }
            },
            [
              _vm._l(_vm.items, function(nav, i) {
                return [
                  _c(
                    "v-subheader",
                    {
                      key: i,
                      staticClass: "pl-4"
                    },
                    [_vm._v(_vm._s(_vm.$t("navCategory." + nav.category)))]
                  ),
                  _vm._l(nav.items, function(item, idx) {
                    return _c(
                      "v-list-item",
                      {
                        key: nav.category + idx,
                        attrs: {
                          to: _vm.to(item),
                          color: "primary"
                        }
                      },
                      [
                        item.navigation
                          ? _c(
                              "v-list-item-icon",
                              {
                                staticClass: "mr-4"
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(_vm._s(item.navigation.icon))
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              {
                                staticClass: "font-weight-regular text-body-2"
                              },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            )
                          ],
                          1
                        ),
                        _vm.isDebug && item.navigation
                          ? _c(
                              "v-list-item-action",
                              [
                                _c(
                                  "v-chip",
                                  {
                                    attrs: {
                                      "x-small": ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.navigation.priority) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  })
                ]
              })
            ],
            2
          )
        : _vm._e(),
      _c(
        "debug",
        [
          _c(
            "v-list",
            {
              attrs: {
                dense: ""
              }
            },
            _vm._l(_vm.navigationRoutes, function(item, idx) {
              return _c(
                "v-list-item",
                {
                  key: "debug" + idx
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v(_vm._s(item.name))]),
                      item.navigation
                        ? _c("v-list-item-subtitle", [
                            _vm._v(_vm._s(item.navigation.priority))
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }