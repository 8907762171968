

















import UserPermissionForm from "@/components/partner/UserPermissionForm.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { handleError } from "@/lib/utility/handleError";
import { IAdminUser } from "@/models/user.entity";
import { AuthRolesEnum } from "@/store/enum/authRolesEnum";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    ConfirmActionDialog,

    UserPermissionForm
  }
})
export default class UsersRoleManageDialog extends Vue {
  @Prop()
  users: IAdminUser[] = [];

  counter = 0;

  roles: AuthRolesEnum[] = [];
  authRoles = Object.keys(AuthRolesEnum).map(k => AuthRolesEnum[k as any]);

  isDialogActive = false;

  isLoading = false;

  async updateRoles() {
    this.isLoading = true;
    this.counter = 0;

    for (const user of this.users) {
      try {
        user.roles = this.roles;
        await user.updateRoles();
        this.counter = this.counter + 1;
      } catch (error) {
        handleError(error);
      }
    }
    this.isDialogActive = false;
    this.isLoading = false;
    this.roles = [];
    this.counter = 0;
  }

  open() {
    this.isDialogActive = true;
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.isDialogActive = v;
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }
}
