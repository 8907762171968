



















































































































































































import { emailRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Vue } from "vue-property-decorator";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { handleError } from "@/lib/utility/handleError";
import { MrfiktivCreateUserDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { UpdateUserDtoFactory } from "@/lib/utility/updateUserDtoFactory";
import { AdminUser } from "@/models/user.entity";

@Component({
  components: {}
})
export default class UserAdministrationCreateDialog extends DarkModeHighlightMixin {
  dialog = false;
  isLoading = false;
  isValid = false;

  newUser: MrfiktivCreateUserDtoGen = {
    firstName: "",
    lastName: "",
    userName: "",
    address: {
      street: "",
      zip: "",
      city: "",
      state: "",
      countryCode: CountryCodeEnum.germany
    },
    contact: {
      email: "",
      phone: ""
    },
    countryCode: CountryCodeEnum.germany,
    isCompany: false,
    company: "",
    isTaxDeductible: false,
    taxnumber: ""
  };

  countryCodes = Object.keys(CountryCodeEnum).map(k => CountryCodeEnum[k as any]);

  get rules() {
    return [requiredRule()];
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  get loading() {
    return this.isLoading;
  }

  get isDisabled() {
    return !this.isValid;
  }

  resetDialog() {
    this.newUser = {
      firstName: "",
      lastName: "",
      userName: "",
      address: {
        street: "",
        zip: "",
        city: "",
        state: "",
        countryCode: CountryCodeEnum.germany
      },
      contact: {
        email: "",
        phone: ""
      },
      countryCode: CountryCodeEnum.germany,
      isCompany: false,
      company: "",
      isTaxDeductible: false,
      taxnumber: ""
    };
  }

  close() {
    this.resetDialog();
    this.dialog = false;
  }

  async inviteUser() {
    this.isLoading = true;
    if (!this.newUser?.contact?.email) {
      return;
    }

    const dto: MrfiktivCreateUserDtoGen = {
      ...new UpdateUserDtoFactory().createFromIUser(this.newUser),
      userName: this.newUser.contact.email,
      firstName: this.newUser.firstName,
      lastName: this.newUser.lastName
    };

    try {
      const invitedUser = await new AdminUser(dto).invite();
      this.resetDialog();
      this.dialog = false;
      if (invitedUser.id) {
        Vue.$toast.success(this.$t("components.partner.UserAdministrationCreateDialog.success"));
        await this.$router.push({ name: "UserAdministrationDetail", params: { userId: invitedUser._id } });
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.dialog = false;
      this.isLoading = false;
    }
  }
}
