











































import { CreateReportAsPartnerDto, getCreateReportDto } from "@/lib/dto/create-report-as-partner-dto";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ReportRoleEnum } from "@/lib/enum/ReportRole.enum";
import { handleError } from "@/lib/utility/handleError";
import { ActivityLog } from "@/models/activity-log.entity";
import { PreExistingDamageDetails } from "@/models/pre-existing-damage.entity";
import { IReport } from "@/models/report.entity";
import {
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivCreateLeasingDetailsDtoGen,
  MrfiktivCreatePoliceDetailsDtoGen,
  MrfiktivCreateReportAsPartnerDtoGen,
  MrfiktivCreateReportWittnesDtoGen,
  MrfiktivReferenceGen,
  MrfiktivUpdateReportDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { DamageTypeEnum } from "@/store/enum/damageType.enum";
import { ReportType } from "@/store/enum/reportType";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import CreateReportForm from "../fleet/CreateReportForm.vue";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import DateTimePicker from "../utility/DateTimePicker.vue";
import Tooltip from "../utility/tooltip.vue";
import PartnerReportCardAccidentDetails from "./PartnerReportCardAccidentDetails.vue";
import PartnerReportCardAddress from "./PartnerReportCardAddress.vue";
import PartnerReportCardContact from "./PartnerReportCardContact.vue";
import PartnerReportCardDamage from "./PartnerReportCardDamage.vue";
import PartnerReportCardDatePreference from "./PartnerReportCardDatePreference.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";

export enum ReportUpdateTabEnum {
  /**
   * Show only position selector in report tab
   */
  position = "position",

  /**
   * Show entire report tab (position info, report type, title, external id)
   */
  report = "report",
  accident = "accident",
  witness = "witness",
  police = "police",
  preExisting = "preExisting",
  body = "body",
  message = "message",
  contact = "contact",
  leasing = "leasing",
  images = "images",
  insurance = "insurance",
  broker = "broker"
}

@Component({
  components: {
    ConfirmActionDialog,
    DateTimePicker,
    Card,
    Tooltip,
    LatestEntriesCardEmpty,
    PartnerReportCardGeneral,
    PartnerReportCardAddress,
    PartnerReportCardContact,
    PartnerReportCardDatePreference,
    PartnerReportCardDamage,
    PartnerReportCardAccidentDetails,
    CreateReportForm
  }
})
export default class PartnerReportUpdateReportDialog extends Vue {
  @Prop()
  config!: ReportUpdateTabEnum[];

  @Prop({ default: "report" })
  activityTarget?: string;

  @Prop({ default: false })
  fullscreen!: boolean;

  @Prop({ default: 1000 })
  width!: number;

  @Prop({ default: false })
  hideButton?: boolean;

  isDialogActive = false;

  updateLoading = false;

  updateReportDto: MrfiktivUpdateReportDtoGen = {};

  isValid = true;

  get isImages() {
    return this.config.includes(ReportUpdateTabEnum.images);
  }

  get isPreExisting() {
    return this.config.includes(ReportUpdateTabEnum.preExisting);
  }

  get isInsurance() {
    return this.config.includes(ReportUpdateTabEnum.insurance);
  }

  get isBroker() {
    return this.config.includes(ReportUpdateTabEnum.broker);
  }

  get isPosition() {
    if (this.$route.query.forceTabsForTesting) {
      return true;
    }

    if (!this.config.includes(ReportUpdateTabEnum.position)) {
      return false;
    }

    return true;
  }

  get isReport() {
    if (this.$route.query.forceTabsForTesting) {
      return true;
    }

    if (!this.config.includes(ReportUpdateTabEnum.report)) {
      return false;
    }

    return true;
  }
  get isAccidentDetails() {
    if (this.$route.query.forceTabsForTesting) {
      return true;
    }

    if (!this.config.includes(ReportUpdateTabEnum.accident)) {
      return false;
    }

    return true;
  }
  get isWitness() {
    if (this.$route.query.forceTabsForTesting) {
      return true;
    }

    if (!this.config.includes(ReportUpdateTabEnum.witness)) {
      return false;
    }

    return true;
  }
  get isPoliceDetails() {
    if (!this.config.includes(ReportUpdateTabEnum.police)) {
      return false;
    }

    return true;
  }

  get isLeasingDetails() {
    if (this.$route.query.forceTabsForTesting) {
      return true;
    }

    if (!this.config.includes(ReportUpdateTabEnum.leasing)) {
      return false;
    }

    return true;
  }

  get isBody() {
    if (this.$route.query.forceTabsForTesting) {
      return true;
    }

    if (!this.config.includes(ReportUpdateTabEnum.body)) {
      return false;
    }

    if (this.report.body) {
      return true;
    }

    return true;
  }
  get isMessage() {
    if (!this.config.includes(ReportUpdateTabEnum.message)) {
      return false;
    }

    return true;
  }
  get isContact() {
    if (this.$route.query.forceTabsForTesting) {
      return true;
    }

    if (!this.config.includes(ReportUpdateTabEnum.contact)) {
      return false;
    }

    return true;
  }

  get isExternalId() {
    if (this.$route.query.forceTabsForTesting) {
      return true;
    }

    if (this.report.externalId) {
      return true;
    }

    return false;
  }

  get report() {
    return PartnerModule.report;
  }

  get accidentDetailsDate() {
    if (!this.updateReportDto.accidentDetails) {
      return new Date();
    }
    return new Date(this.updateReportDto.accidentDetails.date);
  }

  set accidentDetailsDate(date: Date) {
    if (!this.updateReportDto.accidentDetails) {
      return;
    }
    this.updateReportDto.accidentDetails.date = date.toISOString();
  }

  mapReportToUpdateDto(report: IReport): MrfiktivUpdateReportDtoGen {
    let mapReportToUpdateDto: MrfiktivUpdateReportDtoGen = {};

    mapReportToUpdateDto.vehicleId = report.vehicleId;

    if (report.message) {
      mapReportToUpdateDto = {
        ...mapReportToUpdateDto,
        message: report.message
      };
    }
    mapReportToUpdateDto = {
      ...mapReportToUpdateDto,
      customerName: report.customerName,
      firstName: report.firstName,
      lastName: report.lastName,
      isCompany: report.isCompany,
      companyName: report.companyName,
      isTaxDeductible: report.isTaxDeductible,
      taxnumber: report.taxnumber,
      externalId: report.externalId,
      numberplate: report.numberplate ?? "",
      message: report.message || "",
      title: report.title || report.numberplate || "",
      damage: (report.damage as any) || [],
      reportType: report.reportType,
      datePreference: report.datePreference,
      body: report.body
    };

    if ((this.isReport || this.isPosition) && report.position) {
      mapReportToUpdateDto.position = {
        blueprintId: report.position.blueprint.id,
        coordinates: report.position.coordinates,
        meta: report.position.meta
      };
    }

    if (this.isImages) {
      mapReportToUpdateDto.images = {
        registrations: report.images.registrations.map(i => i.id).filter(i => i) as string[],
        cockpits: report.images.cockpits.map(i => i.id).filter(i => i) as string[],
        damages: report.images.damages.map(i => i.id).filter(i => i) as string[],
        damagesDetail: report.images.damagesDetail.map(i => i.id).filter(i => i) as string[],
        overviews: report.images.overviews.map(i => i.id).filter(i => i) as string[]
      };
    }

    if (this.isContact && report.customerContact) {
      mapReportToUpdateDto.customerContact = report.customerContact;
    }
    if (this.isContact && report.customerAddress) {
      mapReportToUpdateDto.customerAddress = report.customerAddress;
    }

    if (this.isAccidentDetails && report.accidentDetails) {
      const accidentDetails = {
        address: {
          city: report.accidentDetails.address.city || "",
          geo: { lat: report.accidentDetails.address.geo.lat || 0, lng: report.accidentDetails.address.geo.lng || 0 },
          state: report.accidentDetails.address.state || "",
          street: report.accidentDetails.address.street || "",
          zip: report.accidentDetails.address.zip || "",
          countryCode: report.accidentDetails.address.countryCode || CountryCodeEnum.germany
        },
        comment: report.accidentDetails.comment || "",
        date: report.accidentDetails.date,
        role: report.accidentDetails.role || ReportRoleEnum.UNCLEAR
      };

      mapReportToUpdateDto = { ...mapReportToUpdateDto, accidentDetails };
    }
    if (this.isPoliceDetails && report.policeDetails) {
      const policeDetails: MrfiktivCreatePoliceDetailsDtoGen = {
        isRecorded: report.policeDetails.isRecorded,
        department: report.policeDetails.department || "",
        fileReference: report.policeDetails.fileReference || ""
      };

      mapReportToUpdateDto = { ...mapReportToUpdateDto, policeDetails };
    }

    if (this.isWitness) {
      const witness: MrfiktivCreateReportWittnesDtoGen[] = report.witness || [];

      mapReportToUpdateDto = { ...mapReportToUpdateDto, witness };
    }

    if (this.isLeasingDetails && report.leasing) {
      const leasing: MrfiktivCreateLeasingDetailsDtoGen = {
        isLeasing: report.leasing?.isLeasing || false,
        company: report.leasing?.company || "",
        claimNumber: report.leasing?.claimNumber || ""
      };

      mapReportToUpdateDto = { ...mapReportToUpdateDto, leasing };
    }

    if (this.isInsurance && report.insurances) {
      const insurances = report.insurances.map(i => ({
        name: i.name || "",
        number: i.number || "",
        type: i.type || undefined
      }));

      mapReportToUpdateDto = {
        ...mapReportToUpdateDto,
        insurances
      };
    }

    if (this.isBroker && report.broker) {
      const broker = {
        name: report.broker.name || "",
        number: report.broker.number || ""
      };

      mapReportToUpdateDto = {
        ...mapReportToUpdateDto,
        broker
      };
    }

    mapReportToUpdateDto.preExistingDamageDetails = report.preExistingDamageDetails || new PreExistingDamageDetails();

    return mapReportToUpdateDto;
  }

  startDialog() {
    this.updateReportDto = this.mapReportToUpdateDto(this.report);

    this.isDialogActive = true;
  }

  show() {
    this.startDialog();
  }

  async update() {
    try {
      const partnerId = this.report.partnerId;
      const reportId = this.report._id;
      this.updateLoading = true;
      const createDto: MrfiktivCreateReportAsPartnerDtoGen = getCreateReportDto({
        ...this.updateReportDto,
        damage: this.updateReportDto.damage as DamageTypeEnum[],
        reportType: this.updateReportDto.reportType as ReportType
      } as CreateReportAsPartnerDto);

      // Fix for issue where create DTO expects wittness, update dto expects witness.
      const witness = createDto.wittness;
      createDto.wittness = undefined;
      const updateDto = {
        ...createDto,
        witness
      };

      await PartnerModule.report.updatePartial(updateDto);
      this.isDialogActive = false;

      const source: MrfiktivReferenceGen = {
        refType: BackendResourceEnum.REPORT,
        refId: reportId
      };
      const data: MrfiktivCreateActivityLogDtoGen = {
        source,
        actionType: ActionEnum.UPDATE,
        comment: this.activityTarget,
        isDeletable: true,
        activity: ActivityTypeEnum.UPDATE_REPORT
      };

      await new ActivityLog({
        partnerId,
        ...data
      })
        .create()
        .catch(handleError);
    } catch (e) {
      handleError(e);
    } finally {
      this.updateLoading = false;
    }
  }
}
