























































import Card from "@/components/utility/Card.vue";
import TableWrapper, { ITableWrapperHeader, IControlElements } from "@/components/utility/TableWrapper.vue";
import { handleError } from "@/lib/utility/handleError";
import DocumentTemplateMixin from "@/mixins/DocumentTemplateMixin.vue";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { DocumentModule } from "@/store/modules/document.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import { SignatureQRTokenConfig } from "./DocumentTemplateDetailTokenList.vue";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import PdfViewer from "@/components/files/PdfViewer.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { convertPdfToImg } from "@/lib/utility/convertPdfToImage";

export function isSignatureTokenSet(signDocument: ISignDocument) {
  for (const tokenPage of signDocument?.tokens ?? []) {
    for (const token of tokenPage) {
      if (token.token === SignatureQRTokenConfig.token) {
        return true;
      }
    }
  }

  return false;
}

@Component({
  components: {
    Card,
    TableWrapper,
    PdfViewer,
    ConfirmActionDialog,
    PaginatedTable
  }
})
export default class ReportDocumentSignRequestStepperFileSelection
  extends mixins(ReportDocumentSignRequestStepperMixin, DocumentTemplateMixin)
  implements IStepper {
  readonly store = DocumentModule;

  isTemplateFilter = true;

  private selectedItem: ISignDocument[] = [];

  isLoadingDetails = false;
  preview: string[] | null = null;

  get partner() {
    return PartnerModule.partner;
  }

  get report() {
    return PartnerModule.report;
  }

  get partnerId() {
    return this.partner.id;
  }

  get headers(): ITableWrapperHeader[] {
    return [
      { text: String(this.$t("sign.DocumentTable.type")), align: "start", value: "isTemplate", width: 150 },
      { text: String(this.$t("sign.SignDocumentForm.title")), value: "title" },
      { text: String(this.$t("sign.SignDocumentForm.name")), value: "name" },
      { text: String(this.$t("sign.SignDocumentForm.folder")), value: "folder" },
      {
        text: "",
        value: "timestamp.created",
        type: "date",
        align: "end",
        width: "100px"
      },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get selectedItems() {
    this.isValid = this.selectedItem.length > 0;
    return this.selectedItem;
  }

  set selectedItems(items: ISignDocument[]) {
    const selectedItem = items[items.length - 1];
    if (selectedItem) {
      this.selectedItem.splice(0, this.selectedItem.length, selectedItem);
    } else {
      this.selectedItem.splice(0, this.selectedItem.length);
    }
    this.isValid = this.selectedItem.length > 0;
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [];

    controlElements.push({
      icon: "mdi-eye",
      text: this.$t("sign.SignDocumentForm.back").toString(),
      action: (item: ISignDocument) => this.showPreview(item)
    });
    return controlElements;
  }

  getObjectUrl(file: File) {
    return URL.createObjectURL(file);
  }

  @Watch("isTemplateFilter")
  setIsTemplateFilter(value: boolean) {
    DocumentModule.setFilters([
      new PageFilterElement({
        key: "isTemplate",
        operation: PageFilterOperationEnum.EQUAL,
        value: value.toString()
      })
    ]);
  }

  selectItem(item: ISignDocument) {
    const selectedItem = this.selectedItems[0];
    if (selectedItem?.id === item.id) {
      this.selectedItems = [];
    } else {
      this.selectedItems = [item];
    }
  }

  selectItemAndNext(item: ISignDocument) {
    this.selectItem(item);
    this.next();
  }

  filter(item: ISignDocument, searchString: string) {
    return JSON.stringify(item)
      .toLowerCase()
      .includes(searchString.toLowerCase());
  }

  previous() {
    this.currentStep = SignRequestStepperPageEnum.CHOICE;
  }

  get mapToImage() {
    return this.preview?.map(i => {
      return {
        src: i,
        thumbnail: i,
        w: 0,
        h: 0
      };
    });
  }

  async showPreview(item: ISignDocument) {
    this.isLoadingDetails = true;
    try {
      await item.fetch();
      this.preview = await convertPdfToImg(item.url);
      this.selectItem(item);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingDetails = false;
    }
  }

  closePreview() {
    this.selectedItems = [];
    this.preview = null;
  }
  confirmPreview() {
    this.preview = null;
    this.next();
  }

  beforeMount() {
    DocumentModule.setFilters([
      new PageFilterElement({
        key: "isTemplate",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.isTemplateFilter as any
      })
    ]);
  }

  async next() {
    try {
      this.isLoading = true;
      const selectedItem = this.selectedItem[0];
      if (!selectedItem) {
        return;
      }

      const signDocument = await new SignDocument({ partnerId: this.partnerId, id: selectedItem.id }).fetch();

      this.$emit("setSignDocument", signDocument);

      if (isSignatureTokenSet(signDocument)) {
        this.currentStep = SignRequestStepperPageEnum.SIGNATURE_SELECTION;
        // this.currentStep = SignRequestStepperPageEnum.FORM;
      } else {
        this.currentStep = SignRequestStepperPageEnum.SIGNATURE_SELECTION;
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
