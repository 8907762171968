

















import TemplateTable from "@/components/template/TemplateTable.vue";
import Debug from "@/components/utility/Debug.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { TemplateGoToHelper } from "@/lib/utility/template.go-to-helper";
import { IPartnerTemplate, PartnerTemplate } from "@/models/partner-template.entity";

@Component({
  components: {
    TemplateTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerTemplateTableView extends mixins(PartnerFallbackMixin) {
  @Ref("templateTable")
  templateTable!: TemplateTable;

  readonly AMOUNT = "amountTemplate";

  readonly store = PartnerTemplateModule;

  isLoading = false;

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get actions(): IAction[] {
    return [];
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(TemplateGoToHelper.breadCrumbs.TemplateTable({ partnerId: this.partnerId }));

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      text: `${this.store.entities.length} ${this.$t("template.templates")}`,
      key: this.AMOUNT,
      disabled: true
    });

    return chips;
  }

  beforeMount() {
    this.store.setFilters([]);
  }

  async mounted() {
    this.isLoading = true;

    await this.trySetByRouteOrDefault();

    const templateId = this.$route.query.templateId as string;
    if (templateId) {
      const template = new PartnerTemplate({ partnerId: this.partnerId, id: templateId });
      this.templateTable?.setSelectedItem(template);
    }

    this.isLoading = false;
  }

  onClickRow(template: IPartnerTemplate) {
    new TemplateGoToHelper(this.$router).setUrl(
      TemplateGoToHelper.locations.templateTable({
        partnerId: this.partnerId,
        query: template ? { templateId: template.id } : {}
      })
    );
  }
}
