


































































































import { KsrSearchOptions } from "@/components/partner/PartnerReportDetailSendToKsrDialogExisting.vue";
import Debug from "@/components/utility/Debug.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { IKsrJobDetailViewmodel } from "@/lib/interfaces/ksr/ksr-job-detail-viewmodel.interface";
import { IReport, Report } from "@/models/report.entity";
import { ExportModule } from "@/store/modules/export.store";
import { KsrModule } from "@/store/modules/ksr.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";

@Component({
  components: {
    Tooltip,
    Debug
  }
})
export default class ReportDocumentSignRequestStepperKsr extends mixins(ReportDocumentSignRequestStepperMixin)
  implements IStepper {
  @Prop()
  report?: IReport;

  page = SignRequestStepperPageEnum.KSR_IMPORT;

  /**
   * Nach Kennzeichen oder Auftragsnummer suchen
   */
  searchOption = KsrSearchOptions.NUMBERPLATE;

  search = this.report?.numberplate ?? "";

  isSearchEditActive = false;

  /**
   * are existing jobs loading
   */
  isExistingJobsLoading = false;

  /**
   * is the loading of existing jobs finished
   */
  isExistingJobsLoaded = false;

  itemsPerPage = 5;

  /**
   * the amount of ksr documents to get details for from backend
   */
  amount = this.itemsPerPage;

  /**
   * list of existing jobs for numberplate
   */
  existingJobs: IKsrJobDetailViewmodel[] = [];

  click(job: IKsrJobDetailViewmodel) {
    this.setReportAndNext(job);
  }

  previous() {
    this.currentStep = SignRequestStepperPageEnum.CHOICE;
  }

  mounted() {
    if (this.report?.numberplate) {
      this.search = this.report?.numberplate ?? "";
      this.loadJobs();
    }
  }

  async next() {
    this.currentStep = this.page;
  }

  ksrTypeMap(type: number) {
    return KsrModule.ksrPartyTypeMap[type];
  }

  /**
   * look for a job id or a numberplate
   */
  get SearchOptions() {
    return KsrSearchOptions;
  }

  get headers() {
    return [
      {
        text: "#",
        value: "jobId"
      },
      {
        text: "Nummernschild",
        value: "numberPlate"
      },
      {
        text: "Angestellter",
        value: "employee"
      },
      { text: "Fahrzeug", value: "vehicle" },
      { text: "Beteiligte", value: "involvedParties" }
    ];
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get jobs() {
    return ExportModule.ksrDocuments;
  }

  async loadJobs() {
    try {
      this.isSearchEditActive = false;
      this.isExistingJobsLoaded = false;
      this.isExistingJobsLoading = true;

      if (!this.search) {
        this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.noNumberPlateGiven"));
        return;
      }

      if (this.searchOption === KsrSearchOptions.NUMBERPLATE) {
        await this.loadJobsByNumberPlate();
      } else {
        await this.loadJobsByJobId();
      }
    } catch (error) {
      this.existingJobs.splice(0);
      this.$log.error(error);
      this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.byNumberPlateFailed"));
    } finally {
      this.isExistingJobsLoading = false;
      this.isExistingJobsLoaded = true;
    }
  }

  /**
   * Loads jobs by jobId from ksr
   */
  async loadJobsByJobId() {
    const document = await ExportModule.getJobDetailById({
      partnerId: this.partnerId,
      jobId: Number(this.search)
    });
    this.existingJobs.splice(0, this.existingJobs.length, document);
  }

  /**
   * loads jobs by formatted numberplate
   */
  async loadJobsByNumberPlate() {
    const documents = await KsrModule.getKsrDocuments({
      partnerId: this.partnerId,
      numberPlate: this.search,
      amount: this.amount
    });
    this.existingJobs.splice(0, this.existingJobs.length, ...documents);
  }

  setReportAndNext(job: IKsrJobDetailViewmodel) {
    if (!job) {
      return;
    }

    this.$emit("setKsrJob", job);

    if (!this.report) {
      this.$emit("setReport", Report.createFromKsrJob(job, this.partnerId));
    }
    this.currentStep = SignRequestStepperPageEnum.DOCUMENT_TYPE_CHOICE;
  }
}
