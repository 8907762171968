/**
 * Different formatters for template.
 *
 * Dear developer, if you add something here make sure you also change @renderTemplate in the frontend and the AbstractTemplateFactory.build method in the backend. U got this!
 */
export enum TemplateFormatterEnum {
  /**
   * Formats a date string into a date representation
   */
  DDMMYYYY = "ddmmyyyy",

  /**
   * Formats a date string into time representation
   */
  HHMM = "hhmm",

  /**
   * Formats the input string with the escape function
   */
  ESCAPE = "escape"
}
