
























































import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import PartnerMessageDetailAttachmentCard from "./PartnerMessageDetailAttachmentCard.vue";
import { handleError } from "@/lib/utility/handleError";
import { IPartnerMessage } from "@/models/partner-message.entity";
import { downloadUrlFromRemoteOrigin } from "@/lib/utility/downloadFileFunc";
import { IPartnerMessageAttachment } from "@/models/partner-message-attachment.entity";

@Component({
  components: { ConfirmActionDialog, PartnerMessageDetailAttachmentCard }
})
export default class PartnerMessageDetailAttachmentRow extends PermissionMixin {
  @Prop({ default: false })
  value!: IPartnerMessage;

  /**
   * Overwrite the attachments from the message, if only a subset of attachments should be displayed for example.
   */
  @Prop()
  attachments?: IPartnerMessageAttachment[];

  get _attachments() {
    return this.attachments || this.value.attachments;
  }

  isOverflowing = false;

  isExpanded = false;

  isDownloading = false;

  selectedAttachments: number[] = [];

  isSelecting = false;

  @Watch("_attachments.length")
  checkOverflow() {
    const container = this.$refs.container as HTMLElement;
    this.isOverflowing = container.scrollHeight > container.clientHeight;
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  async downloadSelected() {
    let isDownloaded = false;
    this.isDownloading = true;

    if (this.selectedAttachments.length === this.value.attachments.length) {
      if (!this.value.attachmentZip) await this.value.populateAttachmentZip();
      if (this.value.attachmentZip) {
        await downloadUrlFromRemoteOrigin(
          URL.createObjectURL(new Blob([this.value.attachmentZip], { type: "application/zip" })),
          `${this.value.from}-${this.value.content.subject
            .replace(/[^a-zA-Z0-9]+/g, "-")
            .replace(/-+/g, "-")
            .replace(/^-|-$/g, "")}.zip`
        )
          .then(() => (isDownloaded = true))
          .catch(handleError);
      }
    }

    if (!isDownloaded) {
      await Promise.all(
        this.selectedAttachments.map(async index => {
          const attachment = this._attachments[index];
          await attachment?.download(this.value);
        })
      )
        .then(() => (isDownloaded = true))
        .catch(handleError);
    }

    this.isDownloading = false;
  }

  abortReportAttachment() {
    this.isSelecting = false;
    this.selectedAttachments.splice(0);
    this.isExpanded = false;
  }

  startSelecting() {
    this.selectedAttachments.splice(0);
    this.selectedAttachments.push(...new Array(this._attachments.length).fill(0).map((_, i) => i));
    this.isExpanded = true;
    this.isSelecting = true;
  }
}
