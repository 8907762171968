




















































import { Component, Prop, Vue } from "vue-property-decorator";
import { IReference } from "@/models/reference.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import RefsSelect from "./RefsSelect.vue";
import RefsSelected from "./RefsSelected.vue";
import RefsSelection from "./RefsSelection.vue";

/**
 * Component that can handle the selection of entities for a given refType
 * Note: The ref type MUST BE registered in the RefTypeMap (@see RefTypeMap @ refs.store.ts)
 */
@Component({
  components: {
    RefsSelect,
    RefsSelected,
    RefsSelection
  },
  filters: {}
})
export default class RefsSelectForm extends Vue {
  @Prop()
  private value!: IReference[];

  @Prop()
  partnerId!: string;

  @Prop()
  label!: string;

  @Prop({
    default: () => [
      BackendResourceEnum.VEHICLE,
      BackendResourceEnum.EVENT,
      BackendResourceEnum.REPORT,
      BackendResourceEnum.TICKET,
      BackendResourceEnum.DOCUMENT,
      BackendResourceEnum.SIGN,
      BackendResourceEnum.COST
    ]
  })
  categories!: BackendResourceEnum[];

  @Prop()
  small!: boolean;

  @Prop()
  required!: boolean;

  @Prop()
  rules!: () => (boolean | string)[];

  isMenu = false;

  get localValue(): IReference[] {
    return this.value;
  }

  set localValue(v: IReference[]) {
    this.$emit("input", v);
  }

  onRemoved(item: IReference) {
    const values = this.value.filter(i => i.refId !== item.refId);
    this.localValue = values;
  }
}
