import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { MrfiktivTemplateContentViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFilterable
class TemplateContentBase implements MrfiktivTemplateContentViewModelGen {
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.template.content.subject" })
  subject: string;

  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.template.content.body" })
  body: string;

  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.template.content.tokens" })
  tokens: string[];

  constructor(content?: Partial<ITemplateContent | MrfiktivTemplateContentViewModelGen>) {
    this.subject = content?.subject || "";
    this.body = content?.body || "";
    this.tokens = content?.tokens || [];
  }

  get dto() {
    return {
      subject: this.subject,
      body: this.body
    };
  }
}

type ITemplateContent = TemplateContentBase;
const TemplateContent = Filter.createForClass(TemplateContentBase);

export { ITemplateContent, TemplateContent };
