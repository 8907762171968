


































import { Permission } from "@/components/partner/UserPermissionAddDialog.vue";
import UserPermissionForm from "@/components/partner/UserPermissionForm.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import { IAdminUser } from "@/models/user.entity";
import { MrfiktivPermissionDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    ConfirmActionDialog,

    UserPermissionForm
  }
})
export default class UsersPermissionAddDialog extends Vue {
  @Prop()
  users: IAdminUser[] = [];

  isDialogActive = false;

  isLoading = false;

  partnerId = "";

  counter = 0;

  /**
   * A list of all resources (based on @see BackendResourceEnum) with all configured actions
   */
  permissionForResources: Permission[] = [];

  get resources() {
    return Object.keys(BackendResourceEnum).map(k => BackendResourceEnum[k as any]);
  }

  get rules() {
    return [requiredRule()];
  }

  addsPermissions() {
    this.permissionForResources = this.resources.map(r => new Permission(r, this.partnerId));
  }

  get partners() {
    return PartnerModule.partners;
  }

  async updatepermissions() {
    const permission: MrfiktivPermissionDtoGen[] = [];
    this.isLoading = true;
    this.counter = 0;

    for (const resource of this.permissionForResources) {
      if (resource.hasAction()) {
        permission.push(resource.toDto());
      }
    }

    if (!permission.length) {
      this.isDialogActive = false;
      return;
    }

    for (const user of this.users) {
      try {
        await user?.addPermissions(permission);
        this.counter = this.counter + 1;
      } catch (error) {
        handleError(error);
      }
    }
    this.isDialogActive = false;
    this.isLoading = false;
    this.partnerId = "";
    this.counter = 0;
  }

  open() {
    this.isDialogActive = true;
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.isDialogActive = v;
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }
}
