



































































import UsersPermissionAddDialog from "@/components/partner/UsersPermissionAddDialog.vue";
import UsersRoleManageDialog from "@/components/partner/UsersRoleManageDialog.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TableWrapper, { IControlElements } from "@/components/utility/TableWrapper.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IAdminUser } from "@/models/user.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { Component } from "vue-property-decorator";
import UserImportDialog from "./UserImportDialog.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    TheLayoutPortal,
    TableWrapper,
    ConfirmActionDialog,
    PaginatedTable,
    MHeader,
    UsersPermissionAddDialog,
    UsersRoleManageDialog,
    UserImportDialog
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class UserTable extends PermissionMixin {
  readonly store = AdminUserPaginationModule;

  isLoadingAll = false;
  isLoading = false;

  /**
   * Opening delete confirmation
   */
  isDeleteDialogActive = false;
  isDeleteDialogLoading = false;

  openPermissionDialog() {
    (this.$refs.permissions as UsersPermissionAddDialog)?.open();
  }

  openRolesDialog() {
    (this.$refs.roles as UsersRoleManageDialog)?.open();
  }

  search = "";

  itemsPerPage = 25;

  snack = false;
  snackColor = "";
  snackText = "";

  expanded = [];
  selected: IAdminUser[] = [];

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [
      {
        text: this.$t("components.partner.UserTable.title"),
        exact: true,
        to: { name: "UserAdministration" }
      },
      {
        text: this.$t("common.nouns.documents"),
        disabled: true,
        exact: false,
        to: undefined
      }
    ];

    return breadCrumbs;
  }

  headers = [
    { text: this.$t("components.partner.UserTable.date"), align: "start", value: "timestamps.created", width: 150 },
    { text: this.$t("components.partner.UserTable.id"), align: "start", value: "_id" },
    { text: this.$t("components.partner.UserTable.firstName"), align: "start", value: "firstName" },
    { text: this.$t("components.partner.UserTable.lastName"), align: "start", value: "lastName" },
    { text: this.$t("components.partner.UserTable.userName"), align: "start", value: "userName" },
    { text: this.$t("components.partner.UserTable.roles"), align: "center", value: "roles", width: 150 },
    { text: this.$t("components.partner.UserTable.permission"), align: "center", value: "permission", width: 50 },
    { text: "", align: "end", value: "controls", width: 200, sortable: false }
  ];

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        text: this.$t("components.partner.UserTable.open").toString(),
        action: this.open
      },
      {
        icon: "mdi-delete-outline",
        text: this.$t("components.partner.UserTable.delete").toString(),
        action: (item: IAdminUser) => {
          this.selected = [item];
          this.isDeleteDialogActive = true;
        }
      }
    ];
  }

  async open(user: IAdminUser) {
    await new GoToHelper(this.$router).goToUserDetail(user._id);
  }

  async deleteDocument() {
    this.isDeleteDialogLoading = true;

    const request = this.selected[0];

    if (!request) {
      this.$toast.error("No Document selected");
    }

    try {
      await request.delete();
      this.$toast.success("👍");
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("sign.Sign.error.network");
    } finally {
      this.isDeleteDialogLoading = false;
      this.isDeleteDialogActive = false;
    }
  }
}
