var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.isLoading
    ? _c("v-skeleton-loader", {
        attrs: {
          type:
            "card-heading, card-heading, list-item-two-line@4, card-heading, list-item-two-line@4"
        }
      })
    : _c("m-detail-form", {
        staticClass: "mx-2",
        attrs: {
          extraPanelsCount: 1,
          readonly: !_vm.model.isUpdateable,
          config: _vm.config,
          item: _vm.model,
          abortChanges: _vm.abortChanges,
          syncChanges: _vm.syncChanges,
          customConfigKey: _vm.customConfigKey,
          loadCustomConfig: _vm.loadCustomConfig,
          customCategoryNamePath: _vm.customCategoryNamePath,
          clearableCategories: _vm.clearables.clearableCategories,
          clearableLeaves: _vm.clearables.clearableLeaves,
          loading: _vm.isLoading,
          partnerId: _vm.partnerId,
          containButtons: _vm.containButtons
        },
        scopedSlots: _vm._u(
          [
            {
              key: "extraPanels",
              fn: function fn() {
                return [
                  _vm._t("extraPanels", null, null, {
                    item: _vm.model
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }