






















































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { handleError } from "@/lib/utility/handleError";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportType } from "@/store/enum/reportType";
import { AttachmentResponseModule, AttachmentResponseViewModel } from "@/store/modules/attachment-response.store";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportDataResponse extends Vue {
  loading = false;

  isExpired = false;

  isFailed = false;

  public header: IReportHeaderData = {
    title: "report.request.title",
    description: "report.request.description",
    image: AssetEnum.confirmData
  };

  get attachmentResponse() {
    return AttachmentResponseModule.attachmentResponse;
  }

  get attachmentId() {
    return this.$route.params.attachmentId;
  }

  get accessToken() {
    return this.$route.params.accessToken;
  }

  get attachmentStartUrl() {
    return `r/${this.accessToken}`;
  }

  start() {
    ReportModule.setStep(this.attachmentStartUrl as ReportScreenEnum);
    const next = ReportModule.nextScreen;
    ReportModule.setStep(next);
    this.$router.push({ path: `/${next}` });
  }

  async mounted() {
    try {
      this.loading = true;
      const partnerId = ReportModule.partner.id;
      const attachmentId = this.attachmentId;
      const accessToken = this.accessToken;

      const response = await AttachmentResponseModule.find({ partnerId, accessToken });

      const report = new AttachmentResponseViewModel(response).getReport();
      if (!report) {
        this.$toast.error("No report shared");
      }

      AttachmentResponseModule.setAttachmentRequestId(attachmentId);
      AttachmentResponseModule.setAttachmentRequestToken(accessToken);

      // TODO: Move init to report module! (fine for now)
      if (report.accidentDetails) {
        if ((report.accidentDetails as any)._id) {
          (report.accidentDetails as any)._id = undefined;
        }
        if ((report.accidentDetails?.address as any)?._id) {
          (report.accidentDetails?.address as any)._id = undefined;
        }

        ReportModule.setAccidentDetails(report.accidentDetails);
      }
      if (report.customerAddress) {
        if ((report.customerAddress as any)._id) {
          (report.customerAddress as any)._id = undefined;
        }
        ReportModule.setAddress(report.customerAddress);
      }
      if (report.companyName) {
        ReportModule.setCompanyName(report.companyName);
      }
      if (report.customerContact) {
        if ((report.customerContact as any)._id) {
          (report.customerContact as any)._id = undefined;
        }
        ReportModule.setContact(report.customerContact);
      }

      if (report.firstName) {
        ReportModule.setUser({ firstName: report.firstName });
      }
      if (report.lastName) {
        ReportModule.setUser({ lastName: report.lastName });
      }
      if (
        report.customerAddress?.city ||
        report.customerAddress?.countryCode ||
        report.customerAddress?.state ||
        report.customerAddress?.street ||
        report.customerAddress?.zip
      ) {
        ReportModule.setAddress({
          city: report.customerAddress.city,
          countryCode: report.customerAddress.countryCode,
          state: report.customerAddress.state,
          street: report.customerAddress.street,
          zip: report.customerAddress.zip
        });
      }
      if (report.datePreference) {
        ReportModule.setDatePreference(report.datePreference);
      }
      if (report.externalId) {
        ReportModule.setExternalId(report.externalId);
      }
      if (report.accidentDetails?.address.city || report.accidentDetails?.address.street) {
        ReportModule.setIsAtAccidentPlace(true);
      }
      if (report.leasing) {
        ReportModule.setLeasing(report.leasing);
      }
      if (report.message) {
        ReportModule.setMessage(report.message);
      }
      if (report.damage) {
        report.damage.forEach(d => {
          ReportModule.pushDamageLocation(d);
        });
      }
      if (report.numberplate) {
        ReportModule.setNumberplate(report.numberplate);
      }
      if (report.policeDetails) {
        if ((report.policeDetails as any)._id) {
          (report.policeDetails as any)._id = undefined;
        }
        ReportModule.setPoliceDetails(report.policeDetails);
      }
      if (report.registrationResults) {
        if ((report.registrationResults as any)._id) {
          (report.registrationResults as any)._id = undefined;
        }
        ReportModule.setRegistrationResults(report.registrationResults);
      }
      if (report.reportType) {
        ReportModule.setReportType(report.reportType as ReportType);
      }
      if (report.witness) {
        for (const witness of report.witness) {
          if ((witness as any)._id) {
            (witness as any)._id = undefined;
          }
        }
        ReportModule.setWitnessList(report.witness);
      }

      if (report.insurances) {
        ReportModule.setInsuranceList(report.insurances);
      }

      const screenOrder = [
        `r/${accessToken}`,
        ...(this.attachmentResponse?.screenOrder ?? []),
        "attach"
      ] as ReportScreenEnum[];

      ReportModule.partner.settings?.reportSettings.screenOrder?.splice(
        0,
        ReportModule.partner.settings?.reportSettings.screenOrder.length,
        ...screenOrder
      );
    } catch (e) {
      if ((e as any).message === "Sharing document expired") {
        this.isExpired = true;
      } else {
        this.isFailed = true;
        handleError(e);
      }
    } finally {
      this.loading = false;
    }
  }
}
