




























import Card from "@/components/utility/Card.vue";
import Debug from "@/components/utility/Debug.vue";
import { IReport } from "@/models/report.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";

@Component({
  components: {
    Card,
    Debug
  }
})
export default class ReportDocumentSignRequestStepperDocumentTypeChoice
  extends mixins(ReportDocumentSignRequestStepperMixin)
  implements IStepper {
  @Prop()
  report?: IReport;

  page = SignRequestStepperPageEnum.DOCUMENT_TYPE_CHOICE;

  get pages() {
    return [
      {
        icon: "mdi-upload",
        title: this.$t("sign.ReportDocumentSignRequestStepperChoice.upload"),
        subtitle: this.$t("sign.ReportDocumentSignRequestStepperChoice.uploadSubtitle"),
        buttonText: this.$t("sign.ReportDocumentSignRequestStepperChoice.upload"),
        nextPage: SignRequestStepperPageEnum.UPLOAD,
        isEnabled: true
      },
      {
        icon: "mdi-file-document-multiple",
        title: this.$t("sign.ReportDocumentSignRequestStepperChoice.choose"),
        subtitle: this.$t("sign.ReportDocumentSignRequestStepperChoice.chooseSubtitle"),
        buttonText: this.$t("sign.ReportDocumentSignRequestStepperChoice.chooseButton"),
        nextPage: SignRequestStepperPageEnum.FILE_SELECTION,
        isEnabled: true
      }
    ].filter(p => p.isEnabled);
  }

  next() {
    this.currentStep = this.page;
  }

  previous() {
    this.currentStep = SignRequestStepperPageEnum.CHOICE;
  }
}
