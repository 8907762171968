var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    [
      !_vm.isMobile && _vm.pages.length > 1
        ? _c(
            "v-col",
            {
              attrs: {
                cols: "1"
              }
            },
            [
              _c("document-template-detail-page-list", {
                staticStyle: {
                  "overflow-y": "auto"
                },
                attrs: {
                  outlined: false,
                  pages: _vm.pages,
                  currentPageNumber: _vm.currentPageNumber
                },
                on: {
                  setCurrentPage: _vm.setCurrentPage
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isMobile
        ? _c(
            "v-col",
            {
              style: "height: ".concat(_vm.height, "; overflow: hidden"),
              attrs: {
                cols: "5"
              }
            },
            [
              _vm.isLoading
                ? _c("v-skeleton-loader", {
                    staticClass: "ma-auto",
                    attrs: {
                      type: "image"
                    }
                  })
                : _c("v-img", {
                    attrs: {
                      src: _vm.images[_vm.currentPageNumber],
                      contain: ""
                    }
                  })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        {
          attrs: {
            cols: _vm.isMobile ? 12 : _vm.pages.length > 1 ? 6 : 7
          }
        },
        [
          _vm.createSignRequestDto
            ? _c(
                "v-form",
                {
                  ref: "signDocumentForm",
                  model: {
                    value: _vm.isFormValid,
                    callback: function callback($$v) {
                      _vm.isFormValid = $$v
                    },
                    expression: "isFormValid"
                  }
                },
                [
                  _c("v-card-subtitle", [
                    _vm._v(_vm._s(_vm.$t("sign.SignRequestForm.hint")))
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            dense: ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [
                              _c("template-card-subject-body", {
                                attrs: {
                                  partnerId: _vm.partnerId,
                                  context: {
                                    report: _vm.report,
                                    partner: _vm.partner
                                  },
                                  isPlain: true,
                                  subjectLabel:
                                    _vm.$t("sign.SignRequestForm.title") + " *",
                                  subjectHint: _vm.$t(
                                    "sign.SignRequestForm.titleHint"
                                  ),
                                  bodyLabel: _vm.$t(
                                    "sign.SignRequestForm.description"
                                  ),
                                  bodyHint: _vm.$t(
                                    "sign.SignRequestForm.descriptionHint"
                                  )
                                },
                                on: {
                                  "update:subject": _vm.updateSubject,
                                  "update:body": _vm.updateBody
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-subtitle", [
                    _vm._v(_vm._s(_vm.$t("sign.SignRequestForm.sender")))
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            dense: ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "components.template.dialog.preview.sender"
                                  ),
                                  disabled: "",
                                  "hide-details": "",
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.partner.companyName,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.partner, "companyName", $$v)
                                  },
                                  expression: "partner.companyName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "sign.SignRequestForm.externalId"
                                  ),
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.createSignRequestDto.externalId,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.createSignRequestDto,
                                      "externalId",
                                      $$v
                                    )
                                  },
                                  expression: "createSignRequestDto.externalId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-subtitle", [
                    _vm._v(_vm._s(_vm.$t("sign.SignRequestForm.receiver")))
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            dense: ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.requiredRule,
                                  label:
                                    _vm.$t("sign.SignRequestForm.email") + " *",
                                  hint: _vm.$t(
                                    "sign.SignRequestForm.emailHint"
                                  ),
                                  "persistent-hint": "",
                                  outlined: ""
                                },
                                model: {
                                  value:
                                    _vm.createSignRequestDto.recipient.email,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.createSignRequestDto.recipient,
                                      "email",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "createSignRequestDto.recipient.email"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "6"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "sign.SignRequestForm.firstName"
                                  ),
                                  outlined: ""
                                },
                                model: {
                                  value:
                                    _vm.createSignRequestDto.recipient
                                      .firstName,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.createSignRequestDto.recipient,
                                      "firstName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "createSignRequestDto.recipient.firstName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "6"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "sign.SignRequestForm.lastName"
                                  ),
                                  outlined: ""
                                },
                                model: {
                                  value:
                                    _vm.createSignRequestDto.recipient.lastName,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.createSignRequestDto.recipient,
                                      "lastName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "createSignRequestDto.recipient.lastName"
                                }
                              })
                            ],
                            1
                          ),
                          false
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "mb-n6",
                                  attrs: {
                                    cols: "12"
                                  }
                                },
                                [
                                  _vm.createDocumentDto
                                    ? _c("v-file-input", {
                                        staticClass: "pt-4 pb-4",
                                        attrs: {
                                          "prepend-icon": "",
                                          accept: "application/pdf, .pdf",
                                          label: _vm.$t(
                                            "sign.SignDocumentForm.file"
                                          ),
                                          "persistent-hint": "",
                                          outlined: "",
                                          "show-size": "",
                                          disabled: ""
                                        },
                                        model: {
                                          value: _vm.createDocumentDto.file,
                                          callback: function callback($$v) {
                                            _vm.$set(
                                              _vm.createDocumentDto,
                                              "file",
                                              $$v
                                            )
                                          },
                                          expression: "createDocumentDto.file"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "Can",
                            {
                              attrs: {
                                I: _vm.ActionEnum.UPDATE,
                                a: _vm.BackendResourceEnum.SHARED_CONTENT,
                                field: _vm.partnerId
                              }
                            },
                            [
                              _vm.isLoadingSharedContent
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12"
                                      }
                                    },
                                    [
                                      _c("v-skeleton-loader", {
                                        staticClass: "ma-auto",
                                        attrs: {
                                          type: "list-item-two-line"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm.sharedContent.length > 0
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12"
                                      }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: _vm.$t(
                                            "sign.ReportDocumentSignRequestStepperInputs.shareAll",
                                            {
                                              length: this.sharedContent.length
                                            }
                                          )
                                        },
                                        model: {
                                          value: _vm.isShareWithAll,
                                          callback: function callback($$v) {
                                            _vm.isShareWithAll = $$v
                                          },
                                          expression: "isShareWithAll"
                                        }
                                      }),
                                      !_vm.isShareWithAll
                                        ? _c(
                                            "v-row",
                                            {
                                              staticStyle: {
                                                height: "250px",
                                                "overflow-y": "auto"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-list",
                                                    [
                                                      _c(
                                                        "v-list-item-group",
                                                        {
                                                          attrs: {
                                                            multiple: "",
                                                            "active-class": ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.toBeSharedWithIndex,
                                                            callback: function callback(
                                                              $$v
                                                            ) {
                                                              _vm.toBeSharedWithIndex = $$v
                                                            },
                                                            expression:
                                                              "toBeSharedWithIndex"
                                                          }
                                                        },
                                                        _vm._l(
                                                          _vm.sharedContent,
                                                          function(content) {
                                                            return _c(
                                                              "v-list-item",
                                                              {
                                                                key: content.id,
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function fn(
                                                                        _ref
                                                                      ) {
                                                                        var active =
                                                                          _ref.active
                                                                        return [
                                                                          _c(
                                                                            "v-list-item-action",
                                                                            [
                                                                              _c(
                                                                                "v-checkbox",
                                                                                {
                                                                                  attrs: {
                                                                                    "input-value": active
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-list-item-icon"
                                                                          ),
                                                                          _c(
                                                                            "v-list-item-content",
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  content.isExpired
                                                                                    ? _c(
                                                                                        "v-chip",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "error",
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "common.states.expired"
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _c(
                                                                                        "v-chip",
                                                                                        {
                                                                                          attrs: {
                                                                                            color:
                                                                                              "success",
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "common.states.active"
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                  content.isSystemGenerated
                                                                                    ? _c(
                                                                                        "v-chip",
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-1",
                                                                                          attrs: {
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "timeLine.ActivityTimeLineItemDocument.systemgenerated"
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : content.userId &&
                                                                                      _vm.getUserNameForId(
                                                                                        content.userId
                                                                                      )
                                                                                    ? _c(
                                                                                        "v-chip",
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-1",
                                                                                          attrs: {
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.getUserNameForId(
                                                                                                  content.userId
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e()
                                                                                ],
                                                                                1
                                                                              ),
                                                                              content.title
                                                                                ? _c(
                                                                                    "v-list-item-title",
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            content.title
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              content.description
                                                                                ? _c(
                                                                                    "v-list-item-subtitle",
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            content.description
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              content.validBy
                                                                                ? _c(
                                                                                    "v-list-item-subtitle",
                                                                                    {
                                                                                      attrs: {
                                                                                        small:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "timeLine.ActivityTimeLineItemShare.validBy",
                                                                                              {
                                                                                                date: _vm.simpleDate(
                                                                                                  content.validBy
                                                                                                )
                                                                                              }
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "v-list-item-subtitle",
                                                                                    {
                                                                                      attrs: {
                                                                                        small:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "timeLine.ActivityTimeLineItemShare.validIndefinitely"
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }