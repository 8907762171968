var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("paginated-table", {
        attrs: {
          store: _vm.store,
          headers: _vm.headers,
          baseQuery: {
            partnerId: _vm.partnerId
          },
          controlElements: _vm.controlElements,
          predefinedFilter: _vm.predefinedFilter
        },
        scopedSlots: _vm._u([
          {
            key: "bodyActions",
            fn: function fn() {
              return [
                _c(
                  "Can",
                  {
                    attrs: {
                      I: _vm.ActionEnum.CREATE,
                      a: _vm.ResourceEnum.CREDENTIAL,
                      field: _vm.partnerId
                    }
                  },
                  [_c("credential-create-dialog")],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.isActive",
            fn: function fn(_ref) {
              var _vm$getIsActiveMeta, _vm$getIsActiveMeta2

              var item = _ref.item
              return [
                _c(
                  "tooltip",
                  {
                    attrs: {
                      text:
                        (_vm$getIsActiveMeta = _vm.getIsActiveMeta(item)) ===
                          null || _vm$getIsActiveMeta === void 0
                          ? void 0
                          : _vm$getIsActiveMeta.text
                    }
                  },
                  [
                    _c("v-avatar", {
                      attrs: {
                        color:
                          (_vm$getIsActiveMeta2 = _vm.getIsActiveMeta(item)) ===
                            null || _vm$getIsActiveMeta2 === void 0
                            ? void 0
                            : _vm$getIsActiveMeta2.color,
                        size: "15px"
                      }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item.timestamp.created",
            fn: function fn(_ref2) {
              var item = _ref2.item
              return [
                _vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")
              ]
            }
          },
          {
            key: "item.name",
            fn: function fn(_ref3) {
              var item = _ref3.item
              return [
                _vm._v(" " + _vm._s(item.name) + " "),
                _c("br"),
                _vm._l(item.permissions, function(pem, idx) {
                  return _c(
                    "v-chip",
                    {
                      key: idx,
                      staticClass: "mr-2",
                      attrs: {
                        label: "",
                        "x-small": "",
                        color: "primary"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("BackendResourceEnum.".concat(pem.type))
                          )
                      )
                    ]
                  )
                })
              ]
            }
          },
          {
            key: "item.userId",
            fn: function fn(_ref4) {
              var item = _ref4.item
              return [
                _vm.loadingPartnerUsers
                  ? _c(
                      "div",
                      [
                        _c("v-skeleton-loader", {
                          attrs: {
                            type: "chip"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.getUserForId(item.userId)
                  ? _c(
                      "tooltip",
                      {
                        attrs: {
                          text: _vm.getUserNameForId(item.userId)
                        }
                      },
                      [
                        _c(
                          "v-avatar",
                          {
                            staticClass: "white--text",
                            attrs: {
                              color: "primary",
                              small: "",
                              size: "35px"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getUserForId(item.userId).firstName[0]
                                ) +
                                _vm._s(
                                  _vm.getUserForId(item.userId).lastName[0]
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("credential-show-dialog", {
        attrs: {
          isDialogActive: _vm.isShowDialogActive,
          item: _vm.selectedItem
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isShowDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isShowDialogActive = $event
          }
        }
      }),
      _c("credential-update-dialog", {
        attrs: {
          isDialogActive: _vm.isUpdateDialogActive
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isUpdateDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isUpdateDialogActive = $event
          }
        },
        model: {
          value: _vm.selectedItem,
          callback: function callback($$v) {
            _vm.selectedItem = $$v
          },
          expression: "selectedItem"
        }
      }),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isDeleteDialogActive,
            rightText: _vm.$t("delete"),
            rightLoading: _vm.isDeleteLoading,
            leftDisabled: _vm.isDeleteLoading,
            rightColor: "error"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDeleteDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDeleteDialogActive = $event
            },
            leftClick: function leftClick($event) {
              _vm.isDeleteDialogActive = false
            },
            rightClick: _vm.onDelete
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("credential.deleteWarning")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }