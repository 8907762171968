























































































































































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IReference } from "@/models/reference.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import AssigneePreview from "../utility/AssigneePreview.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { Cost, ICost } from "@/models/cost.entity";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IRefSuggestion } from "@/store/modules/fleet-aggregation.store";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import RefsSelect from "../utility/RefsSelect.vue";
import { handleError } from "@/lib/utility/handleError";
import SelectAssignees from "../utility/SelectAssignees.vue";
import { CostModule } from "@/store/modules/cost.store";
import CostCardDocuments from "@/components/cost/CostCardDocuments.vue";
import { CostGroupModule } from "@/store/modules/cost-group.store";
import RefsCostGroup from "../utility/RefsCostGroup.vue";
import { ICostGroup, CostGroup } from "@/models/cost-group.entity";
import MoneyInput from "@/components/utility/MoneyInput.vue";
import Debug from "@/components/utility/Debug.vue";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { CostTypeEnum } from "@/lib/enum/cost-type.enum";
import { ISignDocument } from "@/models/sign-document.entity";
import FileInputMixin from "@/components/utility/FileInputMixin.vue";
import { SignDocument } from "@/models/sign-document.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { MrfiktivCreateActivityLogDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";
import aiService from "@/services/mrfiktiv/services/aiService";
import { convertPdfToImg } from "@/lib/utility/convertPdfToImage";
import { UserModule } from "@/store/modules/me-user.store";
import { convertDataURItoFile } from "@/lib/files/imageResizer";
import { ActivityLog } from "@/models/activity-log.entity";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    AssigneePreview,
    RefsSelect,
    SelectAssignees,
    CostCardDocuments,
    RefsCostGroup,
    CustomFieldListForm,
    LatestEntriesCardEmpty,
    Debug,
    MoneyInput
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CostCreateDialog extends mixins(PartnerFallbackMixin, FileInputMixin) {
  readonly CostTypeEnum = CostTypeEnum;

  @Ref("costCardDocuments")
  costCardDocuments!: CostCardDocuments;

  @Prop()
  partnerId!: string;

  @Prop()
  refs!: IReference[];

  @Prop()
  isDialogActive!: boolean;

  @Prop()
  hideButton!: boolean;

  /**
   * ID of cost group to preselect
   */
  @Prop()
  group?: string;

  @Prop({ default: false })
  fullscreen?: boolean;

  isLoading = false;

  cost: ICost = new Cost({
    group: this.group || "",
    date: new Date().toISOString().substr(0, 10)
  });

  costGroup: ICostGroup | null = null;

  isLoadingCustomFieldValues = false;

  expenseOrIncome = CostTypeEnum.EXPENSE;

  isLoadingCostGroups = false;

  get groups() {
    return CostGroupModule.entities;
  }

  get categories(): BackendResourceEnum[] {
    return [BackendResourceEnum.VEHICLE, BackendResourceEnum.REPORT];
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get isDialogActiveLocal() {
    return this.isDialogActive;
  }

  set isDialogActiveLocal(isDialogActive: boolean) {
    this.$emit("update:isDialogActive", isDialogActive);
  }

  get suggested(): IRefSuggestion[] {
    return [];
  }

  get tags() {
    const tags = new Set<string>();
    CostModule.entities.forEach(t => t.tags?.forEach(tag => tags.add(tag)));

    return Array.from(tags).sort();
  }

  @Watch("expenseOrIncome")
  @Watch("cost.total")
  ensureExpenseOrIncome() {
    this.cost.expenseOrIncome = this.expenseOrIncome;
  }

  show() {
    this.cost = new Cost({
      partnerId: this.partnerId,
      refs: this.refs,
      group: this.group || "",
      date: new Date().toISOString().substr(0, 10)
    });
    this.setCostGroup();
    this.isDialogActiveLocal = true;
  }

  startDrag() {
    this.isDrag = true;
  }

  endDrag() {
    this.isDrag = false;
  }

  async onRightClick() {
    try {
      this.isDialogActiveLocal = true;
      await this.cost.create();
      await this.createReportActivities();
      this.$emit("created", this.cost);
      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDialogActiveLocal = false;
    }
  }

  @Watch("cost.group")
  async setCostGroup() {
    this.costGroup = null;

    const costGroupId = this.cost.group;
    if (costGroupId) {
      this.isLoadingCustomFieldValues = true;
      const costGroup =
        CostGroupModule.maps.id.get(costGroupId)[0] ||
        (await new CostGroup({ partnerId: this.cost.partnerId, id: costGroupId }).fetch().catch(handleError));
      this.isLoadingCustomFieldValues = false;

      this.$nextTick(() => {
        this.costGroup = costGroup;
      });
    }
  }

  async handleFileHook(file: File) {
    const signDocument = new SignDocument({
      name: file.name,
      title: file.name,
      isTemplate: false,
      folder: "/cost/",
      refs: [],
      file: file,
      partnerId: this.partnerId
    });

    this.costCardDocuments.isLoadingCreation = true;
    await signDocument.create().catch(handleError);
    this.costCardDocuments.isLoadingCreation = false;
    this.costCardDocuments.onCreated(signDocument);

    (this.$refs.radioExpense as any)?.focus();
  }

  async onCreatedDocument(...documents: ISignDocument[]) {
    this.$log.debug(documents);

    if (!this.cost.title && documents.length) {
      const title = documents
        .map(d => {
          if (d.title?.endsWith(".pdf")) {
            return d.title.substring(0, d.title.length - 4);
          }
          return d.title;
        })
        .join("; ") as string;

      if (this.cost.date === new Date().toISOString().slice(0, 10)) {
        this.cost.date = documents[0].timestamp.created.slice(0, 10);
      }
      this.cost.title = title;
    }

    if (!UserModule.abilities.can(ActionEnum.CREATE, BackendResourceEnum.AI, this.partnerId)) {
      return;
    }

    try {
      this.isLoading = true;

      const images = (await Promise.all(documents.map(d => convertPdfToImg(d.url)))).flat();

      const files = await Promise.all(images.map((image, index) => convertDataURItoFile(image, "image" + index)));

      this.$log.debug(files);

      const cost = await aiService.analyzeCost(this.partnerId, { images: files });
      this.cost.title = cost.title ?? "";
      this.cost.description = cost.description ?? "";
      this.cost.absoluteTotal = cost.total ?? 0;
      this.cost.date = cost.date?.slice(0, 10) ?? "";
      this.cost.tags = cost.tags ?? [];
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  onRefsChange() {
    this.$log.debug("onRefsChange");
  }

  async createReportActivities() {
    for (const ref of this.cost.refs) {
      try {
        const data: MrfiktivCreateActivityLogDtoGen = {
          source: ref,
          target: [
            {
              refType: BackendResourceEnum.COST,
              refId: this.cost.id
            }
          ],
          actionType: ActionEnum.CREATE,
          activity: ActivityTypeEnum.CREATE_COST
        };

        const activity = await new ActivityLog({
          partnerId: this.partnerId,
          ...data
        }).create();

        this.$emit("createdActivity", activity);
      } catch (error) {
        this.$log.error(error);
      }
    }

    for (const fileId of this.cost.files) {
      try {
        const data: MrfiktivCreateActivityLogDtoGen = {
          source: {
            refType: BackendResourceEnum.DOCUMENT,
            refId: fileId
          },
          target: [
            {
              refType: BackendResourceEnum.COST,
              refId: this.cost.id
            }
          ],
          actionType: ActionEnum.CREATE,
          activity: ActivityTypeEnum.CREATE_COST
        };
        const activity = await new ActivityLog({ partnerId: this.partnerId, ...data }).create();

        this.$emit("createdActivity", activity);
      } catch (error) {
        this.$log.error(error);
      }
    }
  }

  goToCostGroupCustomView() {
    if (!this.cost.group) return;

    new CostGoToHelper(this.$router).goToCostGroupCustomView({
      partnerId: this.partnerId,
      costGroupId: this.cost.group,
      viewId: "0",
      newTab: true
    });
  }

  async refreshCostGroups() {
    this.isLoadingCostGroups = true;
    try {
      CostGroupModule.setFilter([]);
      CostGroupModule.setHiddenFilter([]);
      await CostGroupModule.fetchAll({ partnerId: this.partnerId });
    } catch (e) {
      handleError(e);
    }
    this.isLoadingCostGroups = false;
  }
}
