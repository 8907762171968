


















import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import TemplateDetailForm from "@/components/template/TemplateDetailForm.vue";
import { IPartnerTemplate, PartnerTemplate } from "@/models/partner-template.entity";

@Component({
  components: {
    TheLayoutPortal,
    TemplateDetailForm,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerTemplateDetailFormView extends mixins(PartnerFallbackMixin) {
  readonly store = PartnerTemplateModule;

  isLoading = false;

  template: IPartnerTemplate | null = null;

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get templateId(): string {
    return this.$route.params.templateId;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const template =
      this.store.maps.id.get(this.templateId)[0] ??
      new PartnerTemplate({
        id: this.templateId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await template.fetch();
      this.template = template;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
