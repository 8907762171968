
























import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { Credential } from "@/models/credential.entity";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import CredentialForm from "./CredentialForm.vue";

@Component({
  components: {
    ConfirmActionDialog,
    CredentialForm
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CredentialCreateDialog extends mixins(PermissionMixin, RulesMixin) {
  dialogActive = false;

  isLoading = false;

  isValid = false;

  credential = new Credential({ partnerId: this.partnerId });

  get partnerId() {
    return PartnerModule.partner._id;
  }

  /**
   *
   */
  async create() {
    try {
      this.isLoading = true;

      if (!this.credential) {
        return;
      }

      await this.credential.create();

      this.$toast.success("👍");

      this.dialogActive = false;

      this.$emit("created", this.credential);

      // Reset
      this.credential = new Credential({ partnerId: this.partnerId });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
