var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.dialog,
        title: _vm.title,
        supressKeyboardActions: true,
        leftText: _vm.$t("back"),
        hideLeft: !_vm.step,
        hideRight: !_vm.step,
        rightDisabled: _vm.isTranslationLoading,
        fullscreen: _vm.step
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.dialog = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.dialog = $event
        },
        leftClick: function leftClick() {
          _vm.step--
          _vm.newLanguage = ""
        },
        rightClick: _vm.createNewTemplate
      }
    },
    [
      _c("debug", [_vm._v(" " + _vm._s(_vm.partnerId) + " ")]),
      _c(
        "v-window",
        {
          attrs: {
            touchless: ""
          },
          model: {
            value: _vm.step,
            callback: function callback($$v) {
              _vm.step = $$v
            },
            expression: "step"
          }
        },
        [
          _c(
            "v-window-item",
            {
              attrs: {
                value: 0
              }
            },
            _vm._l(_vm.availableLanguages, function(language) {
              return _c(
                "v-list-item",
                {
                  key: "language" + language
                },
                [
                  _c(
                    "v-list-item-icon",
                    {
                      staticClass: "mb-n5 mt-5"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("getFlagEmojiByLanguage")(language)) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _vm.loading
                        ? _c("v-skeleton-loader", {
                            attrs: {
                              type: "list-item"
                            }
                          })
                        : _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.templates.get(language) ? "✅" : "❌"
                                ) +
                                " "
                            )
                          ])
                    ],
                    1
                  ),
                  !_vm.loading
                    ? _c(
                        "v-list-item-action",
                        [
                          !_vm.templates.get(language) && !_vm.canCreateAI
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    loading: _vm.loadingCopy,
                                    elevation: 0
                                  },
                                  on: {
                                    click: function click() {
                                      _vm.newLanguage = language

                                      _vm.copyTemplate()
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]
                              )
                            : !_vm.templates.get(language) && _vm.canCreateAI
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    loading: _vm.loadingCopy,
                                    elevation: 0
                                  },
                                  on: {
                                    click: function click() {
                                      _vm.newLanguage = language

                                      _vm.translate()
                                    }
                                  }
                                },
                                [
                                  _vm._v(" " + _vm._s(_vm.$t("create")) + " "),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-2",
                                      attrs: {
                                        small: ""
                                      }
                                    },
                                    [_vm._v(" mdi-creation ")]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    elevation: 0
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.goToTemplateDetail(language)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")]
                              )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-window-item",
            {
              attrs: {
                value: 1
              }
            },
            [
              _vm.isTranslationLoading
                ? _c("v-progress-linear", {
                    attrs: {
                      indeterminate: ""
                    }
                  })
                : _vm._e(),
              _c("template-editor-subject", {
                attrs: {
                  partnerId: _vm.partnerId,
                  readonly: _vm.isTranslationLoading,
                  subject: _vm.newSubject,
                  addPlaceholderInSubject: true
                },
                on: {
                  "update:subject": function updateSubject($event) {
                    _vm.newSubject = $event
                  }
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "calc(100vh - 240px)"
                  }
                },
                [
                  _vm.debug
                    ? _c("v-textarea", {
                        attrs: {
                          readOnly: _vm.isTranslationLoading,
                          outlined: ""
                        },
                        model: {
                          value: _vm.newBody,
                          callback: function callback($$v) {
                            _vm.newBody = $$v
                          },
                          expression: "newBody"
                        }
                      })
                    : _c("template-editor", {
                        key: _vm.isTranslationLoading + "isTranslationLoading",
                        attrs: {
                          isPlain:
                            _vm.template.meta.contentFormat ===
                            _vm.MessageContentFormatEnum.PLAIN,
                          height: "100%",
                          readOnly: _vm.isTranslationLoading,
                          editConfiguration: _vm.editConfiguration,
                          partnerId: _vm.partnerId,
                          label: _vm.$t("components.template.detail.body.body")
                        },
                        model: {
                          value: _vm.newBody,
                          callback: function callback($$v) {
                            _vm.newBody = $$v
                          },
                          expression: "newBody"
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }