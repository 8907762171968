

















































import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ConfigModule } from "@/store/modules/config";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { Permission } from "./UserPermissionAddDialog.vue";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { $t } from "@/lib/utility/t";

/**
 * A list of all licenses
 */
enum LicensesEnum {
  FREE = "free",
  STARTER = "starter",
  BASIC = "basic",
  PREMIUM = "premium"
}

@Component({
  components: { Debug }
})
export default class UserPermissionForm extends mixins(PermissionMixin) {
  @Prop()
  permissions!: Permission[];

  search = "";

  /**
   * A map of all permissions for each resource and license
   */
  permissionMap = new Map([
    [
      BackendResourceEnum.PARTNER,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.READ]],
        [LicensesEnum.STARTER, [ActionEnum.READ]],
        [LicensesEnum.BASIC, [ActionEnum.READ]],
        [LicensesEnum.PREMIUM, [ActionEnum.READ]]
      ])
    ],
    [
      BackendResourceEnum.FORM,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, []],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.REPORT,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.USER,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.READ]],
        [LicensesEnum.STARTER, [ActionEnum.READ]],
        [LicensesEnum.BASIC, [ActionEnum.READ]],
        [LicensesEnum.PREMIUM, [ActionEnum.READ]]
      ])
    ],
    [
      BackendResourceEnum.MESSAGE,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.RATING,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.READ]],
        [LicensesEnum.BASIC, [ActionEnum.READ]],
        [LicensesEnum.PREMIUM, [ActionEnum.READ]]
      ])
    ],
    [
      BackendResourceEnum.RESOURCE,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.SERVICE,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.BOOKING,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.OPERATION,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.READ]],
        [LicensesEnum.BASIC, [ActionEnum.READ]],
        [LicensesEnum.PREMIUM, [ActionEnum.READ]]
      ])
    ],
    [
      BackendResourceEnum.SETTING_PARTNER_INTERNAL,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.ANALYTICS,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.READ]],
        [LicensesEnum.BASIC, [ActionEnum.READ]],
        [LicensesEnum.PREMIUM, [ActionEnum.READ]]
      ])
    ],
    [
      BackendResourceEnum.TEMPLATE,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.POOL,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.DRIVER,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.VEHICLE,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.CONTRACT,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.SIGN,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.DOCUMENT,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.SHARED_CONTENT,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.FAQ,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.ACTIVITY,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.CUSTOM_FIELD,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.EVENT,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.PROJECT,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.TICKET,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.ATTACHMENT_REQUEST,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.MILEAGE,
      new Map([
        [LicensesEnum.FREE, [ActionEnum.MANAGE]],
        [LicensesEnum.STARTER, [ActionEnum.MANAGE]],
        [LicensesEnum.BASIC, [ActionEnum.MANAGE]],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.COST,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, []],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ],
    [
      BackendResourceEnum.COST_GROUP,
      new Map([
        [LicensesEnum.FREE, []],
        [LicensesEnum.STARTER, []],
        [LicensesEnum.BASIC, []],
        [LicensesEnum.PREMIUM, [ActionEnum.MANAGE]]
      ])
    ]
  ]);

  get filteredPermissions() {
    return this.permissions.filter(p => {
      if (!this.isAdmin && !this.can(p.actions, p.resource)) {
        return false;
      }

      return `
        ${JSON.stringify(p)}
        ${$t(`BackendResourceEnum.${p.resource}`)}
        ${(p.actions as ActionEnum[]).map(a => this.$t(`ActionEnum.${a}`)).join(", ")}
      `.includes(this.search);
    });
  }

  get isReportPortalAppContext() {
    return ConfigModule.appContext === AppContextEnum.REPORT_PORTAL;
  }

  get licenses(): LicensesEnum[] {
    return Object.values(LicensesEnum);
  }

  get actions() {
    return Object.values(ActionEnum);
  }

  get resources() {
    return Object.values(BackendResourceEnum);
  }

  applyLicense(license: LicensesEnum) {
    for (const resource of this.permissions) {
      const actions = this.permissionMap.get(resource.resource)?.get(license);

      if (actions) {
        resource.actions = actions;
      }
    }
  }
}
