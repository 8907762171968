var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "background-color": "#f5f5f5",
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "none",
        "-ms-user-select": "none",
        "user-select": "none"
      },
      style: _vm.isZooming
        ? _vm.followMouse
          ? "cursor: grabbing;"
          : "cursor: grab;"
        : "",
      on: {
        mousemove: _vm.mousemove,
        dblclick: _vm.doubleClick,
        contextmenu: function contextmenu($event) {
          $event.preventDefault()
          return _vm.resetZoom.apply(null, arguments)
        },
        wheel: function wheel($event) {
          $event.preventDefault()
          return _vm.handleZoomEvent.apply(null, arguments)
        },
        mousedown: _vm.onMouseDown,
        mouseup: _vm.onMouseUp,
        mouseleave: _vm.onMouseLeave
      }
    },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            staticClass: "ma-auto",
            attrs: {
              indeterminate: "",
              color: "primary"
            }
          })
        : _vm._e(),
      _c(
        "debug",
        [
          _c(
            "v-snackbar",
            {
              attrs: {
                bottom: "",
                right: "",
                text: "",
                value: true,
                timeout: 10000000
              }
            },
            [
              _vm._v(" x1 " + _vm._s(_vm.dragStartX)),
              _c("br"),
              _vm._v(" y1 " + _vm._s(_vm.dragStartY)),
              _c("br"),
              _vm._v(" x2 " + _vm._s(_vm.dragLeaveX)),
              _c("br"),
              _vm._v(" y2 " + _vm._s(_vm.dragLeaveY)),
              _c("br"),
              _vm._v(" x2-x1 " + _vm._s(_vm.dragLeaveX - _vm.dragStartX)),
              _c("br"),
              _vm._v(" y2-y1" + _vm._s(_vm.dragLeaveY - _vm.dragStartY)),
              _c("br"),
              _vm._v(
                " left " + _vm._s(_vm.left) + " - width: " + _vm._s(_vm.width)
              ),
              _c("br"),
              _vm._v(
                " top " + _vm._s(_vm.top) + " - height: " + _vm._s(_vm.height)
              ),
              _c("br"),
              _vm._v(" left " + _vm._s(_vm.leftDelta)),
              _c("br"),
              _vm._v(" top " + _vm._s(_vm.topDelta)),
              _c("br"),
              _vm._v(
                " scrollSpeedMultiplier " + _vm._s(_vm.scrollSpeedMultiplier)
              ),
              _c("br"),
              _vm._v(" zoomLevel " + _vm._s(_vm.zoom)),
              _c("br"),
              _vm._v(" zoomFactor " + _vm._s((_vm.zoom / 100) * 4) + " ")
            ]
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            top: "",
            timeout: 10000
          },
          model: {
            value: _vm.isSnackBarReset,
            callback: function callback($$v) {
              _vm.isSnackBarReset = $$v
            },
            expression: "isSnackBarReset"
          }
        },
        [
          _c(
            "v-row",
            {
              attrs: {
                align: "center"
              }
            },
            [
              _c("v-icon", [_vm._v(" mdi-information-variant ")]),
              _c("v-spacer"),
              _c("div", [
                _vm._v(
                  " " + _vm._s(_vm.$t("components.template.canvas.reset")) + " "
                )
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  },
                  on: {
                    click: function click($event) {
                      _vm.isSnackBarReset = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v(" mdi-close ")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$vuetify.breakpoint.smAndDown && !_vm.isSigning
        ? _c(
            "div",
            [
              _c("image-position-selector-menu", {
                attrs: {
                  documentTokens: _vm.documentTokens,
                  editValues: _vm.editValues,
                  token: _vm.highlightedToken,
                  page: _vm.page
                },
                on: {
                  close: _vm.resetHighlightedToken
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          ref: "imageContainerContainer",
          style: "\n        width:100%;\n        height: calc(100% - ".concat(
            _vm.$vuetify.breakpoint.smAndDown && !_vm.isSigning
              ? "37px"
              : "0px",
            ");\n      "
          ),
          attrs: {
            flat: ""
          }
        },
        [
          !_vm.isMobile
            ? _c(
                "div",
                {
                  style: "width: ".concat(
                    _vm.width,
                    "; display:block; margin:auto;"
                  )
                },
                [
                  _c(
                    "div",
                    {
                      style: "width: ".concat(
                        _vm.width,
                        "; position: absolute; z-index: 5; "
                      )
                    },
                    [
                      _c(
                        "div",
                        {
                          style: "display: flex;"
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "div",
                            {
                              style: "max-width: ".concat(
                                _vm.isZooming ? "240" : "40",
                                "px;"
                              )
                            },
                            [
                              !_vm.isZooming
                                ? _c(
                                    "v-btn",
                                    {
                                      staticStyle: {
                                        width: "40px"
                                      },
                                      attrs: {
                                        icon: ""
                                      },
                                      on: {
                                        click: function click(e) {
                                          e.stopPropagation()

                                          _vm.startZoom()
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-magnify")])],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "max-width": "240px"
                                  },
                                  attrs: {
                                    hidden: !_vm.isZooming
                                  }
                                },
                                [
                                  _vm.isZooming
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: ""
                                          },
                                          on: {
                                            click: function click(e) {
                                              e.stopPropagation()

                                              _vm.resetZoom()
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-magnify-close")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-slide-x-transition",
                                    {
                                      attrs: {
                                        "hide-on-leave": ""
                                      }
                                    },
                                    [
                                      _vm.isZooming
                                        ? _c(
                                            "v-sheet",
                                            {
                                              staticStyle: {
                                                width: "200px",
                                                height: "34px"
                                              },
                                              attrs: {
                                                outlined: "",
                                                rounded: ""
                                              }
                                            },
                                            [
                                              _c("v-slider", {
                                                attrs: {
                                                  min: "15",
                                                  max: "100"
                                                },
                                                on: {
                                                  click: function click(e) {
                                                    return e.stopPropagation()
                                                  },
                                                  mousedown: function mousedown(
                                                    e
                                                  ) {
                                                    return e.stopPropagation()
                                                  }
                                                },
                                                model: {
                                                  value: _vm.zoom,
                                                  callback: function callback(
                                                    $$v
                                                  ) {
                                                    _vm.zoom = $$v
                                                  },
                                                  expression: "zoom"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-spacer")
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          !_vm.isMobile
            ? _c(
                "div",
                {
                  staticClass: "ml-auto mr-auto",
                  staticStyle: {
                    display: "flex",
                    align: "center",
                    justify: "center",
                    height: "0px"
                  },
                  style: _vm.isZooming
                    ? "max-width: 300px;"
                    : _vm.pageCount && _vm.pageCount > 1
                    ? "max-width: 106px;"
                    : "max-width: 36px;"
                },
                [
                  _c(
                    "v-sheet",
                    {
                      staticStyle: {
                        "z-index": "5",
                        display: "flex",
                        height: "36px"
                      },
                      style: "width: ".concat(_vm.width, ";"),
                      attrs: {
                        rounded: "bl-lg br-lg",
                        elevation: 1
                      }
                    },
                    [
                      _vm.pageCount && _vm.pageCount > 1
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.$emit("previousPage")
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v(" mdi-chevron-left ")])],
                            1
                          )
                        : _vm._e(),
                      _vm.pageCount && _vm.pageCount > 1
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.$emit("nextPage")
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v(" mdi-chevron-right ")])],
                            1
                          )
                        : _vm._e(),
                      !_vm.isZooming
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: ""
                              },
                              on: {
                                click: function click(e) {
                                  e.stopPropagation()

                                  _vm.startZoom()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-magnify")])],
                            1
                          )
                        : _vm._e(),
                      _vm.isZooming
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: ""
                              },
                              on: {
                                click: function click(e) {
                                  e.stopPropagation()

                                  _vm.resetZoom()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-magnify-close")])],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-slide-x-transition",
                        {
                          attrs: {
                            "hide-on-leave": ""
                          }
                        },
                        [
                          _vm.isZooming
                            ? _c("v-slider", {
                                staticStyle: {
                                  height: "37px"
                                },
                                attrs: {
                                  min: "15",
                                  max: "100"
                                },
                                on: {
                                  click: function click(e) {
                                    return e.stopPropagation()
                                  },
                                  mousedown: function mousedown(e) {
                                    return e.stopPropagation()
                                  }
                                },
                                model: {
                                  value: _vm.zoom,
                                  callback: function callback($$v) {
                                    _vm.zoom = $$v
                                  },
                                  expression: "zoom"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.debounceResize,
                  expression: "debounceResize"
                }
              ],
              ref: "imageContainer",
              style: "\n          width: "
                .concat(
                  _vm.width,
                  ";\n          display: block;\n          margin: auto;\n          overflow: hidden;\n          transform: scale("
                )
                .concat((_vm.zoom / 100) * 4, ") translateY(")
                .concat(_vm.top + _vm.topDelta, "px) translateX(")
                .concat(_vm.left + _vm.leftDelta, "px);\n        ")
            },
            [
              !_vm.hideTokenFields
                ? _c(
                    "div",
                    _vm._l(_vm.documentTokens, function(e, index) {
                      return _c(
                        "div",
                        {
                          key: "element" + _vm.key + e.token,
                          style: ""
                            .concat(_vm.getDocumentTokenStyle(e), " ")
                            .concat(_vm.getCursorStyle(e))
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                disabled: _vm.isSigning,
                                "nudge-top": 40,
                                "close-on-content-click": false,
                                transition: "slide-y-reverse-transition",
                                elevation: "0"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function fn(_ref) {
                                      var on = _ref.on,
                                        attrs = _ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              height: "100%",
                                              width: "100%"
                                            }
                                          },
                                          [
                                            !_vm.getIsSignature(e.token)
                                              ? _c(
                                                  "div",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        style: "height: 100%; width: 100%; ".concat(
                                                          _vm.getCursorStyle(e)
                                                        ),
                                                        on: {
                                                          click: function click() {
                                                            return _vm.onTokenFieldClick(
                                                              index,
                                                              e
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "div",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    !e.token && !_vm.editValues
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticStyle: {
                                                              position:
                                                                "absolute",
                                                              bottom: "2px",
                                                              left: "0px"
                                                            },
                                                            attrs: {
                                                              color: "error",
                                                              small: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-alert "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    e.value
                                                      ? _c(
                                                          "small",
                                                          {
                                                            style:
                                                              "word-break: break-word; position: absolute; top: 2px; left: 2px; white-space: pre-line;"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  e.value
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : !_vm.isSigning
                                                      ? _c(
                                                          "small",
                                                          {
                                                            key: e.token,
                                                            style:
                                                              "word-break: break-word; position: absolute; top: 2px; left: 2px; white-space: pre-line;"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getTokenText(
                                                                    e.token
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticStyle: {
                                                          position: "absolute",
                                                          top: "0px",
                                                          right: "0px"
                                                        },
                                                        attrs: {
                                                          small: "",
                                                          color: _vm.getTokenColor(
                                                            e,
                                                            _vm.isTokenHighlighted(
                                                              e
                                                            )
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.isSigning
                                                                ? !e.isEditable ||
                                                                  _vm.getIsSignature(
                                                                    e.token
                                                                  )
                                                                  ? ""
                                                                  : _vm.$vuetify
                                                                      .breakpoint
                                                                      .smAndDown
                                                                  ? "mdi-cursor-pointer"
                                                                  : "mdi-cursor-default"
                                                                : "mdi-pencil"
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "ma-auto",
                                                        style: "\n                      font-family:'Brush Script MT'; height: 100%; width: 100%; font-size: 150%; display: flex;\n                      ".concat(
                                                          _vm.getCursorStyle(e),
                                                          "\n                    "
                                                        ),
                                                        on: {
                                                          click: function click() {
                                                            return _vm.onTokenFieldClick(
                                                              index,
                                                              e
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "div",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass: "ma-auto"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "sign.Sign.signature"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              !_vm.$vuetify.breakpoint.smAndDown &&
                              _vm.highlightedToken
                                ? _c("image-position-selector-menu", {
                                    attrs: {
                                      documentTokens: _vm.documentTokens,
                                      token:
                                        _vm.documentTokens[
                                          _vm.hightlightedTokenIndex
                                        ],
                                      editValues: _vm.editValues,
                                      page: _vm.page
                                    },
                                    on: {
                                      close: _vm.resetHighlightedToken
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              (_vm.isHideSelect && _vm.imageContainer) || _vm.isSigning
                ? _c("div", {
                    style: "\n          height: "
                      .concat(_vm.height, ";\n          width: ")
                      .concat(
                        _vm.width,
                        ";\n          position: absolute;\n          z-index: 1;\n          background-color: "
                      )
                      .concat(
                        _vm.isDebug ? "rgba(255, 0,0,0.5)" : "",
                        "\n        "
                      ),
                    on: {
                      mousemove: _vm.mousemove
                    }
                  })
                : _vm._e(),
              _c("clipper-basic", {
                ref: "clippy",
                attrs: {
                  grid: false,
                  corner: true,
                  shadow: _vm.isHideSelect
                    ? "rgba(0,0,0,0)"
                    : "rgba(250, 250, 250, 0.3)",
                  "touch-create": true,
                  src: _vm.src
                },
                on: {
                  load: _vm.onLoad
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }