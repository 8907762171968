import { render, staticRenderFns } from "./RefsAttachmentRequest.vue?vue&type=template&id=6d9c16ae&scoped=true&"
import script from "./RefsAttachmentRequest.vue?vue&type=script&lang=ts&"
export * from "./RefsAttachmentRequest.vue?vue&type=script&lang=ts&"
import style0 from "./RefsAttachmentRequest.vue?vue&type=style&index=0&id=6d9c16ae&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d9c16ae",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d9c16ae')) {
      api.createRecord('6d9c16ae', component.options)
    } else {
      api.reload('6d9c16ae', component.options)
    }
    module.hot.accept("./RefsAttachmentRequest.vue?vue&type=template&id=6d9c16ae&scoped=true&", function () {
      api.rerender('6d9c16ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/utility/RefsAttachmentRequest.vue"
export default component.exports