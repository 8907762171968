






























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IActivityLog } from "@/models/activity-log.entity";
import { Component, Prop } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import TimeLineItemText from "../utility/TimeLineItemText.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { activityLogName } from "@/lib/utility/activityLogName";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import { ActionEnum } from "@/store/enum/authActionEnum";

@Component({
  components: {
    TimeLineItemText,
    ActivityTimeLineItemDelete,
    ContextMenu
  }
})
export default class ActivityTimeLineItemManagePropertiesOnSource extends DarkModeHighlightMixin {
  @Prop({})
  activityLogEntry!: IActivityLog;

  get icon() {
    switch (this.activityLogEntry.actionType) {
      case ActionEnum.CREATE:
        return "mdi-plus";
      case ActionEnum.DELETE:
        return "mdi-delete";
      default:
        return "mdi-pencil";
    }
  }

  get iconColor() {
    switch (this.activityLogEntry.actionType) {
      case ActionEnum.CREATE:
        return "success";
      case ActionEnum.DELETE:
        return "error";
      default:
        return "info";
    }
  }

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get description() {
    const date = simpleDoubleDigitDate(this.activityLogEntry.timestamp.created);
    let description = $t("common.verbs.edit");
    if (this.activityLogEntry.comment) {
      description = $t(this.activityLogEntry.comment);
    }

    return $t("timeLine.ActivityTimeLineItemManageTargetsOnSource.title", {
      date,
      description,
      action: $t(`ActionEnum.verb.past.${this.activityLogEntry.actionType}`)
    });
  }
}
