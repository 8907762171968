





































import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { ICompany } from "@/models/company.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { ICompanyGroup, CompanyGroup } from "@/models/company-group.entity";

@Component({
  components: {},
  filters: {}
})
export default class RefsCompany extends mixins(Vue) implements IRefDetail<ICompany> {
  @Prop()
  item!: ICompany;

  @Prop()
  small!: boolean;

  @Prop({ default: true })
  showCompany!: boolean;

  @Prop()
  confirmable!: boolean;

  group: ICompanyGroup | null = null;

  loadingProject = false;

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  async mounted() {
    await this.setGroup();
  }

  async goToDetail() {
    const partnerId = this.item.partnerId;
    const companyId = this.item.id;
    const newTab = false;

    new CompanyGoToHelper(this.$router).goToCompanyDetail({ companyId, partnerId, newTab });
  }

  @Watch("item.groupId")
  async setGroup() {
    try {
      if (this.item.groupId) {
        this.loadingProject = true;
        let group = CompanyGroupModule.maps.id.get(this.item.groupId)[0];
        if (!group) {
          group = await new CompanyGroup({ partnerId: this.item.partnerId, id: this.item.groupId }).fetch();
        }
        this.group = group;
      }
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loadingProject = false;
    }
  }

  goToCompanyGroupCustomView() {
    if (!this.item.groupId) return;

    new CompanyGoToHelper(this.$router).goToCompanyGroupCustomView({
      partnerId: this.item.partnerId,
      companyGroupId: this.item.groupId,
      viewId: "0",
      newTab: true
    });
  }
}
