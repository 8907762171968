
















































































































import { formatYearsMonthDay, simpleDate, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { debounce } from "debounce";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsSelected from "../utility/RefsSelected.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import Tooltip from "../utility/tooltip.vue";
import { IAttachmentRequest } from "@/models/attachment-request.entity";
import ReportActivityBoxShareComponentScreenOrder from "./ReportActivityBoxShareComponentScreenOrder.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    LatestEntriesCardEmpty,
    Card,
    ConfirmActionDialog,
    MHeader,
    Tooltip,
    RefsSelected,
    ReportActivityBoxShareComponentScreenOrder
  }
})
export default class AttachmentRequestDetail extends DarkModeHighlightMixin {
  @Prop()
  value!: IAttachmentRequest;

  @Prop({ default: false })
  hideBreadCrumbs!: boolean;

  readonly EDIT_TITLE = "editTitle";
  readonly DELETE = "delete";
  readonly COPY = "copy";
  readonly DEACTIVATE = "deactivate";

  isDeleteDialogActive = false;

  isEditTitle = false;

  isEditDescription = false;

  editTitle = "";

  editDescription = "";

  debounceUpdate = debounce(() => {
    this.value.update();
  }, 500);

  get datePickerMinimum() {
    return formatYearsMonthDay(new Date());
  }

  get validByDatePickerValue() {
    if (!this.value.validBy) {
      return "";
    }

    return formatYearsMonthDay(new Date(this.value.validBy));
  }

  set validByDatePickerValue(value: string) {
    this.value.validBy = new Date(value).toISOString();
  }

  get validBy() {
    if (this.value.isExpired) {
      return $t("views.PartnerSharedReport.expired");
    } else if (this.value.validBy) {
      return $t("timeLine.ActivityTimeLineItemShare.validBy", { date: simpleDate(this.value.validBy) });
    } else {
      return $t("timeLine.ActivityTimeLineItemShare.validIndefinitely");
    }
  }

  get referenceCategories() {
    return [BackendResourceEnum.REPORT, BackendResourceEnum.DOCUMENT];
  }

  get isEntityLoading() {
    return this.value.isLoading;
  }

  get breadCrumbs(): IBreadcrumb[] | undefined {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadCrumbs: IBreadcrumb[] = [];
    if (this.value.partnerId) {
      breadCrumbs.push({
        text: $t("components.value.OperationTable.title"),
        to: { name: "PartnerOperations", params: { partnerId: this.value.partnerId } },
        exact: true
      });
    } else {
      breadCrumbs.push({
        text: $t("components.value.OperationTable.title"),
        to: { name: "ThgOperations" },
        exact: true
      });
    }

    return breadCrumbs;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (!this.value.isExpired) {
      actions.push({
        text: $t("timeLine.ActivityTimeLineItemShare.btnText"),
        key: this.COPY
      });
    }

    if (!this.value.isExpired) {
      actions.push({
        text: $t("timeLine.ActivityTimeLineItemShare.btnTextAlt"),
        key: this.DEACTIVATE
      });
    }

    actions.push({
      text: $t("common.verbs.delete"),
      key: this.DELETE
    });

    if (!this.isEditTitle) {
      actions.push({
        text: $t("project.ticket.editTitle"),
        key: this.EDIT_TITLE
      });
    }

    return actions;
  }

  startEdit() {
    this.isEditTitle = true;
    this.editTitle = this.value.title;
  }

  cancelUpdateTitle() {
    this.isEditTitle = false;
  }

  updateTitle() {
    this.value.title = this.editTitle;
    this.isEditTitle = false;
    this.value.update();
  }

  startEditDescription() {
    this.isEditDescription = true;
    this.editDescription = this.value.description;
  }

  cancelUpdateDescription() {
    this.isEditDescription = false;
  }

  updateDescription() {
    this.value.description = this.editDescription;
    this.isEditDescription = false;
    this.value.update();
  }

  get subtitle() {
    if (this.value.userId && this.getUserNameForId(this.value.userId)) {
      return $t("createdOnBy", {
        date: simpleDoubleDigitDate(this.value.timestamp.created),
        name: this.getUserNameForId(this.value.userId)
      });
    }

    if (this.value.isSystemGenerated) {
      return $t("createdOnBy", {
        date: simpleDoubleDigitDate(this.value.timestamp.created),
        name: $t("timeLine.ActivityTimeLineItemDocument.systemgenerated")
      });
    }

    return $t("createdOn", { date: simpleDoubleDigitDate(this.value.timestamp.created) });
  }

  processAction(action: IAction) {
    switch (action.key) {
      case this.DELETE:
        this.startDeleteDialog();
        break;

      case this.COPY:
        this.value.copyLinkToClipboard();
        break;

      case this.DEACTIVATE:
        this.value.validBy = new Date().toISOString();
        this.debounceUpdate();
        break;

      case this.EDIT_TITLE:
        this.startEdit();
        break;
    }
  }

  startDeleteDialog() {
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteDialogActive = false;
    await this.value.delete();
    this.$emit("onDelete");
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }
}
