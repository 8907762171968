import { OperationStatusEnum } from "@/lib/enum/OperationStatus.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { handleError } from "@/lib/utility/handleError";
import operationService from "@/services/mrfiktiv/services/operationService";
import { ThgPartnerOperationViewModelGen, ThgTimestampDocumentGen } from "@/services/thg/v1/data-contracts";
import { Timestamp } from "./timestamp.entity";
import { CronJobEnum } from "@/lib/enum/CronJob.enum";
import { IEntity } from "@/lib/utility/data/entity.interface";
import {
  MrfiktivOperationViewModelGen,
  MrfiktivPartnerOperationViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { IHasPartnerId } from "@/lib/interfaces/has-partner-id.interface";
import { OperationDataAccessLayer } from "@/store/modules/access-layers/operation.access-layer";
import { IReference, Reference } from "./reference.entity";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { GoToHelper } from "@/lib/utility/goToHelper";
import VueRouter from "vue-router";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

@IsFilterable
class OperationBase implements MrfiktivOperationViewModelGen, Partial<IHasPartnerId>, Partial<IEntity<IOperation>> {
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.operation.partnerId"
  })
  partnerId?: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.operation.creatorId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  creatorId?: string;

  @FilterConfig({
    type: FilterTypes.ENUM,
    config: { items: Object.values(OperationStatusEnum) },
    displayName: "objects.operation.status"
  })
  status: OperationStatusEnum;

  @FilterConfig({
    type: FilterTypes.ENUM,
    config: { items: Object.values(CronJobEnum) },
    displayName: "objects.operation.type"
  })
  type: CronJobEnum;

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.operation.isCanceled"
  })
  isCanceled: boolean;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.operation.message"
  })
  message: string;

  @FilterConfig({
    type: Timestamp
  })
  timestamp: ThgTimestampDocumentGen;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.operation.id"
  })
  _id: string;

  id: string;

  body?: object | undefined;

  error: object;

  @FilterConfig({
    type: Reference
  })
  refs: IReference[];

  get operationRefs() {
    const refs = [];

    for (const ref of this.refs) {
      if (ref.refType === BackendResourceEnum.OPERATION) {
        refs.push(ref);
      }
    }

    return refs;
  }

  constructor(operation?: Partial<MrfiktivPartnerOperationViewModelGen>) {
    this.partnerId = operation?.partnerId;
    this.creatorId = operation?.creatorId;
    this.status = operation?.status as OperationStatusEnum;
    this.type = (operation?.type ?? CronJobEnum.DEFAULT) as CronJobEnum;
    this.isCanceled = !!operation?.isCanceled;
    this.message = operation?.message || "";
    this.timestamp = (operation?.timestamp ?? {}) as ThgTimestampDocumentGen;
    this._id = operation?.id ?? "";
    this.id = operation?.id ?? "";
    this.error = operation?.error || {};
    this.body = operation?.body || {};
    this.refs = operation?.refs?.map(r => new Reference(r)) || [];
  }

  private async map(operation: MrfiktivOperationViewModelGen) {
    this.partnerId = (operation as ThgPartnerOperationViewModelGen).partnerId;
    this.creatorId = operation?.creatorId;
    this.status = operation.status as OperationStatusEnum;
    this.type = (operation?.type ?? CronJobEnum.DEFAULT) as CronJobEnum;
    this.isCanceled = !!operation.isCanceled;
    this.message = operation.message || "";
    this.timestamp = operation.timestamp as ThgTimestampDocumentGen;
    this._id = operation?.id ?? "";
    this.id = operation.id;
    this.error = operation.error || {};
    this.body = operation.body || {};
    this.refs.splice(0);
    this.refs.push(...(operation?.refs?.map(r => new Reference(r)) || []));
  }

  async fetch() {
    if (this.partnerId) {
      await operationService
        .getOperationByPartner(this.partnerId, this.id)
        .then(b => this.map(b))
        .catch(handleError);
    } else {
      await operationService
        .getOperation(this.id)
        .then(b => this.map(b))
        .catch(handleError);
    }

    OperationDataAccessLayer.set(this);

    return this;
  }

  async cancel() {
    if (this.partnerId) {
      await operationService
        .cancelOperationByPartnerId(this.id, this.partnerId)
        .then(b => this.map(b))
        .catch(handleError);
    } else {
      await operationService
        .cancelOperation(this.id)
        .then(b => this.map(b))
        .catch(handleError);
    }

    OperationDataAccessLayer.set(this);

    return this;
  }

  /**
   * Restarts the operation
   * @returns the new operation
   */
  async restartPartnerOperation() {
    if (!this.partnerId) throw new Error("PartnerId is required to restart operation");

    const res = await operationService.restartOperation(this.partnerId, this.id);

    const newOperation = new Operation(res);
    OperationDataAccessLayer.set(newOperation);

    return newOperation;
  }

  async goToDetail(router: VueRouter, newTab = false) {
    new GoToHelper(router).goToOperationDetail(this.id, this.partnerId, newTab);
  }
}
type IOperation = OperationBase;
const Operation = Filter.createForClass(OperationBase);

export { IOperation, Operation };
