var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("debug", [_vm._v(" " + _vm._s(_vm.value) + " ")]),
      _c("m-header", {
        staticClass: "mb-2",
        attrs: {
          title: ""
            .concat(_vm.getFlagEmojiByLanguage(_vm.value.meta.language), " ")
            .concat(_vm.value.meta.title),
          alerts: _vm.alerts,
          subtitle: _vm.value.meta.description,
          breadCrumbs: _vm.breadCrumbs,
          chips: _vm.chips,
          actions: _vm.actions
        }
      }),
      _c("v-text-field", {
        attrs: {
          outlined: "",
          "auto-grow": "",
          readonly: true,
          label: _vm.$t("components.template.editor.subject")
        },
        model: {
          value: _vm.value.content.subject,
          callback: function callback($$v) {
            _vm.$set(_vm.value.content, "subject", $$v)
          },
          expression: "value.content.subject"
        }
      }),
      _vm.value.meta.contentFormat === _vm.MessageContentFormatEnum.PLAIN
        ? _c("v-textarea", {
            attrs: {
              outlined: "",
              "auto-grow": "",
              readonly: "",
              label: _vm.$t("components.template.detail.body.body")
            },
            model: {
              value: _vm.value.content.body,
              callback: function callback($$v) {
                _vm.$set(_vm.value.content, "body", $$v)
              },
              expression: "value.content.body"
            }
          })
        : _c("template-editor", {
            attrs: {
              isPlain: false,
              readOnly: true,
              partnerId: _vm.value.partnerId,
              label: _vm.$t("components.template.detail.body.body")
            },
            model: {
              value: _vm.value.content.body,
              callback: function callback($$v) {
                _vm.$set(_vm.value.content, "body", $$v)
              },
              expression: "value.content.body"
            }
          }),
      _vm.value
        ? _c("template-detail-language-dialog", {
            ref: "languageOptions",
            attrs: {
              template: _vm.value,
              partnerId: _vm.value.partnerId
            }
          })
        : _vm._e(),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isDeleteDialogActive,
          title: _vm.$t("components.template.detail.delete.title"),
          leftText: _vm.$t("components.template.detail.delete.abort"),
          rightText: _vm.$t("components.template.detail.delete.delete"),
          rightColor: "error",
          loading: _vm.isLoadingDelete,
          rightLoading: _vm.isLoadingDelete
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          close: function close($event) {
            _vm.isDeleteDialogActive = false
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteTemplate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }