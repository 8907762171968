









































































import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import TableWrapper, { IControlElements } from "@/components/utility/TableWrapper.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { SignDocument } from "@/models/sign-document.entity";
import { ISignRequest } from "@/models/sign-request.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { SignRequestModule } from "@/store/modules/sign-request.store";
import { mixins } from "vue-class-component";
import { Component, Ref, Prop } from "vue-property-decorator";
import { PredefinedFilterType } from "../event/filter/event.filter";
import DragMixin from "./DragMixin.vue";
import ReportDocumentSignRequestDialog from "./ReportDocumentSignRequestDialog.vue";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";

// A map of states and colors
export const SIGN_COLOR_BY_STATUS = new Map([
  [DigitalSignatureRequestStatusEnum.CREATED, "rgb(224,224,224)"],
  [DigitalSignatureRequestStatusEnum.SENT, "#F9E79F"],
  [DigitalSignatureRequestStatusEnum.DELIVERED, "#D6F0CE"],
  [DigitalSignatureRequestStatusEnum.PROCESSED, "#C7DDBA"],
  [DigitalSignatureRequestStatusEnum.ACCEPTED, "#CCEFD4"],
  [DigitalSignatureRequestStatusEnum.SIGNED, "#9EE09E"],
  [DigitalSignatureRequestStatusEnum.DECLINED, "#FF6B6B"],
  [DigitalSignatureRequestStatusEnum.VOIDED, "#FFADAD"],
  [DigitalSignatureRequestStatusEnum.DELETED, "grey"]
]);

@Component({
  components: {
    AssigneePreview,
    TheLayoutPortal,
    TableWrapper,
    ConfirmActionDialog,
    ReportDocumentSignRequestDialog,
    ReportDocumentCardStatusMenu: () => import("./ReportDocumentCardStatusMenu.vue"),
    PaginatedTable
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class SignRequestTable extends mixins(DragMixin) {
  @Ref("signRequestDialog")
  signRequestDialog!: ReportDocumentSignRequestDialog;

  readonly store = SignRequestModule;

  @Prop()
  partnerId!: string;

  /**
   * Opening delete confirmation
   */
  isDeleteDialogActive = false;
  isDeleteDialogLoading = false;

  acceptImage = false;

  search = "";

  snack = false;
  snackColor = "";
  snackText = "";

  expanded = [];
  selected: ISignRequest[] = [];

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  headers = [
    { text: this.$t("sign.SignRequestTable.created"), align: "start", width: "120px", value: "timestamp.created" },
    { text: this.$t("sign.SignRequestTable.status"), align: "start", value: "status", width: "100px" },
    { text: this.$t("sign.SignRequestTable.assignees"), align: "start", value: "assignees", width: "150px" },
    { text: this.$t("sign.SignRequestTable.requestTitle"), align: "start", value: "title", width: "250px" },
    { text: this.$t("sign.SignRequestTable.email"), align: "start", value: "recipient.email" },
    { text: this.$t("sign.SignRequestForm.externalId"), value: "externalId" },
    { text: this.$t("sign.SignRequestTable.modified"), value: "timestamp.lastModified" },
    { text: "", align: "end", value: "controls", width: 120, sortable: false }
  ];

  get predefinedFilter() {
    const predefinedFilter: PredefinedFilterType[] = [];
    predefinedFilter.push(
      {
        name: "project.ticket.mine",
        filter: [
          new PageFilterElement({
            key: "assignees",
            value: this.userId,
            operation: PageFilterOperationEnum.EQUAL
          })
        ]
      },
      {
        name: "enums.TicketStatusEnum.open",
        filter: [
          new PageFilterElement({
            key: "status",
            operation: PageFilterOperationEnum.NOT_EQUAL,
            value: DigitalSignatureRequestStatusEnum.SIGNED
          })
        ]
      },
      {
        name: "objects.document.isSigned",
        filter: [
          new PageFilterElement({
            key: "status",
            operation: PageFilterOperationEnum.EQUAL,
            value: DigitalSignatureRequestStatusEnum.SIGNED
          })
        ]
      }
    );

    return predefinedFilter;
  }

  get userId() {
    return UserModule.userId;
  }

  get isUserLoaded() {
    return PartnerUserModule.entities.length > 0;
  }

  get isDeleteDisabled() {
    const selected = this.selected[0];
    if (!selected) {
      return true;
    }

    return (
      selected.logs.find(l => l.action === DigitalSignatureRequestStatusEnum.ACCEPTED) ??
      (selected.status === DigitalSignatureRequestStatusEnum.ACCEPTED ||
        selected.status === DigitalSignatureRequestStatusEnum.SIGNED)
    );
  }

  onDrop(file: File) {
    const sign = new SignDocument({
      url: URL.createObjectURL(file)
    });

    this.signRequestDialog.initializeForLocalSignDocument(sign);
  }

  get colorByStatus() {
    return SIGN_COLOR_BY_STATUS;
  }

  get documents(): ISignRequest[] {
    return SignRequestModule.requests;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        text: this.$t("sign.SignRequestTable.open").toString(),
        action: this.open
      },
      {
        icon: "mdi-delete-outline",
        text: this.$t("sign.SignRequestTable.delete").toString(),
        action: (item: ISignRequest) => {
          this.selected = [item];
          this.isDeleteDialogActive = true;
        }
      }
    ];
  }

  async open(request: ISignRequest) {
    await new GoToHelper(this.$router).goToSignatureRequestDetail(request.id, request.partnerId);
  }

  showDialog() {
    this.signRequestDialog.show();
  }

  async deleteSignRequest() {
    this.isDeleteDialogLoading = true;

    const request = this.selected[0];

    if (!request) {
      this.$toast.error("No Document selected");
    }

    try {
      await request.delete();

      this.$toast.success("👍");
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("sign.Sign.error.network");
    } finally {
      this.isDeleteDialogLoading = false;
      this.isDeleteDialogActive = false;
    }
  }
}
