










































import { convertPdfToImg } from "@/lib/utility/convertPdfToImage";
import { detailedDateWithDay, formatHoursAndMinutes, simpleDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { ISignDocument } from "@/models/sign-document.entity";
import { Component, Prop } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import RefsVehicle from "./RefsVehicle.vue";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: { RefsVehicle },
  filters: {
    simpleDate,
    detailedDateWithDay,
    formatHoursAndMinutes
  }
})
export default class RefsDocument extends mixins(PermissionMixin) implements IRefDetail<ISignDocument> {
  @Prop()
  item!: ISignDocument;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  isLoading = false;

  images: {
    src: string;
    thumbnail: string;
    w: number;
    h: number;
    title: string;
  }[] = [];

  async mounted() {
    if (this.small) return;

    try {
      this.isLoading = true;

      const pages = await convertPdfToImg(this.item.url);
      this.images.slice(0);

      pages.forEach((i, index) => {
        this.images.push({
          src: i,
          thumbnail: i,
          w: 0,
          h: 0,
          title: `${document?.title} ${index + 1}/${pages.length}`
        });
      });
    } catch (e) {
      this.$log.error(e);
      await this.fetchAndConvert();
    } finally {
      this.isLoading = false;
    }
  }

  async fetchAndConvert() {
    try {
      this.isLoading = true;
      this.can(ActionEnum.READ, ResourceEnum.SIGN);
      await this.item.fetch();
      const pages = await convertPdfToImg(this.item.url);
      this.images.slice(0);

      pages.forEach((i, index) => {
        this.images.push({
          src: i,
          thumbnail: i,
          w: 0,
          h: 0,
          title: `${document?.title} ${index + 1}/${pages.length}`
        });
      });
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  goToDetail() {
    new GoToHelper(this.$router).goToDocumentDetail(this.item.id, this.item.partnerId, true);
  }
}
