var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v(" Gebuchte Services ")])]
          ),
          _c(
            "v-card-text",
            [
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.registrationService.title"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.registrationService.hint"
                  ),
                  "persistent-hint": "",
                  disabled: _vm.disabled,
                  inset: "",
                  dense: ""
                },
                model: {
                  value: _vm.bookedServices.registrationService,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "registrationService", $$v)
                  },
                  expression: "bookedServices.registrationService"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.form.title"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.form.hint"
                  ),
                  "persistent-hint": "",
                  disabled: _vm.disabled,
                  inset: "",
                  dense: ""
                },
                model: {
                  value: _vm.bookedServices.form,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "form", $$v)
                  },
                  expression: "bookedServices.form"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.report.title"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.report.hint"
                  ),
                  "persistent-hint": "",
                  disabled: _vm.disabled,
                  inset: "",
                  dense: ""
                },
                model: {
                  value: _vm.bookedServices.report,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "report", $$v)
                  },
                  expression: "bookedServices.report"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.payment.title"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.payment.hint"
                  ),
                  "persistent-hint": "",
                  disabled: _vm.disabled,
                  inset: "",
                  dense: ""
                },
                model: {
                  value: _vm.bookedServices.payment,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "payment", $$v)
                  },
                  expression: "bookedServices.payment"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.marketplace.title"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.marketplace.hint"
                  ),
                  "persistent-hint": "",
                  disabled: _vm.disabled,
                  inset: "",
                  dense: ""
                },
                model: {
                  value: _vm.bookedServices.marketplace,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "marketplace", $$v)
                  },
                  expression: "bookedServices.marketplace"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.onlineBooking.title"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.onlineBooking.hint"
                  ),
                  "persistent-hint": "",
                  disabled: _vm.disabled,
                  inset: "",
                  dense: ""
                },
                model: {
                  value: _vm.bookedServices.onlineBooking,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "onlineBooking", $$v)
                  },
                  expression: "bookedServices.onlineBooking"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.sign.title"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.sign.hint"
                  ),
                  "persistent-hint": "",
                  disabled: _vm.disabled,
                  inset: "",
                  dense: ""
                },
                model: {
                  value: _vm.bookedServices.sign,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "sign", $$v)
                  },
                  expression: "bookedServices.sign"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.chargingStation.title"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.chargingStation.hint"
                  ),
                  "persistent-hint": "",
                  disabled: _vm.disabled,
                  inset: "",
                  dense: ""
                },
                model: {
                  value: _vm.bookedServices.chargingStation,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "chargingStation", $$v)
                  },
                  expression: "bookedServices.chargingStation"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.faq.title"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.faq.hint"
                  ),
                  "persistent-hint": "",
                  disabled: _vm.disabled,
                  inset: "",
                  dense: ""
                },
                model: {
                  value: _vm.bookedServices.faq,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "faq", $$v)
                  },
                  expression: "bookedServices.faq"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.customer.title"
                  ),
                  hint: _vm.$t(
                    "components.partner.PartnerCreateOrUpdateLicense.bookedServices.customer.hint"
                  ),
                  "persistent-hint": "",
                  disabled: _vm.disabled,
                  inset: "",
                  dense: ""
                },
                model: {
                  value: _vm.bookedServices.customer,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "customer", $$v)
                  },
                  expression: "bookedServices.customer"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label:
                    "Weitergabe der Daten an dritte (individuelle Datenschutz)",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.settings.isDatasharingActive,
                  callback: function callback($$v) {
                    _vm.$set(_vm.settings, "isDatasharingActive", $$v)
                  },
                  expression: "settings.isDatasharingActive"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: "Sprachauswahl anzeigen",
                  hint:
                    "Nutzer der Anwendungen werden beim Laden der Anwendung zur Auswahl der bevorzugten Sprache gefragt",
                  disabled: _vm.disabled,
                  "persistent-hint": ""
                },
                model: {
                  value: _vm.settings.isLanguageSelectionActive,
                  callback: function callback($$v) {
                    _vm.$set(_vm.settings, "isLanguageSelectionActive", $$v)
                  },
                  expression: "settings.isLanguageSelectionActive"
                }
              }),
              _c("v-text-field", {
                staticClass: "pt-4",
                attrs: {
                  number: "",
                  outlined: "",
                  disabled: _vm.disabled,
                  label: "Max Vehicle Count"
                },
                model: {
                  value: _vm.bookedServices.maxVehicleCount,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "maxVehicleCount", $$v)
                  },
                  expression: "bookedServices.maxVehicleCount"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  number: "",
                  outlined: "",
                  disabled: _vm.disabled,
                  label: "Max User Count"
                },
                model: {
                  value: _vm.bookedServices.maxUserCount,
                  callback: function callback($$v) {
                    _vm.$set(_vm.bookedServices, "maxUserCount", $$v)
                  },
                  expression: "bookedServices.maxUserCount"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("Emaileinstellungen")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c("v-switch", {
                attrs: {
                  label: "Fahrzeugscheinergebnisse in Email anzeigen",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.emailSettings.registrationShowResults,
                  callback: function callback($$v) {
                    _vm.$set(_vm.emailSettings, "registrationShowResults", $$v)
                  },
                  expression: "emailSettings.registrationShowResults"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: "Ksr Button in E-Mail anzeigen",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.emailSettings.registrationShowKsrButton,
                  callback: function callback($$v) {
                    _vm.$set(
                      _vm.emailSettings,
                      "registrationShowKsrButton",
                      $$v
                    )
                  },
                  expression: "emailSettings.registrationShowKsrButton"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 mb-n7"
            },
            [_c("h4", [_vm._v("Domains und DNS Einträge")])]
          ),
          _c(
            "v-card-text",
            {
              staticClass: "mt-8"
            },
            [
              _c("v-combobox", {
                attrs: {
                  disabled: _vm.disabled,
                  rules: _vm.domainRules,
                  label: "Domains",
                  hint:
                    "Darf nicht mit https:// oder http starten. Einfach mmmint.schadensmeldung.digital oder restemeier.schadensmeldung.digital",
                  multiple: "",
                  chips: "",
                  required: ""
                },
                model: {
                  value: _vm.settings.urls,
                  callback: function callback($$v) {
                    _vm.$set(_vm.settings, "urls", $$v)
                  },
                  expression: "settings.urls"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }