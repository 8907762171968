var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    {
      staticClass: "wrapper"
    },
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            attrs: {
              type:
                "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
            }
          })
        : _vm.template
        ? _c(
            "v-card",
            {
              staticClass: "mt-n3 mb-n6",
              attrs: {
                flat: ""
              }
            },
            [
              _c("v-card-title", [
                _c(
                  "h3",
                  {
                    staticClass: "text-truncate"
                  },
                  [_vm._v(_vm._s(_vm.template.meta.title))]
                )
              ]),
              _c(
                "v-card-subtitle",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "subtitle",
                      attrs: {
                        align: "center"
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "mr-2"
                        },
                        [_vm._v(" " + _vm._s(_vm.createdDate) + " ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            "close-on-click": false,
                            "close-on-content-click": false
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function fn(_ref) {
                                var on = _ref.on,
                                  attrs = _ref.attrs
                                return [
                                  _vm.canEditTemplate
                                    ? _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                icon: ""
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v(" mdi-pen ")])],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("template-detail-meta-update-dialog", {
                                attrs: {
                                  meta: _vm.template.meta,
                                  partnerId: _vm.partnerId,
                                  isPublic: _vm.template.isPublic,
                                  templateKey: _vm.template.key
                                }
                              }),
                              _c("template-detail-content-update-dialog", {
                                attrs: {
                                  content: _vm.template.content,
                                  partnerId: _vm.partnerId,
                                  templateKey: _vm.template.key
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("template-detail-delete-dialog", {
                        attrs: {
                          partnerId: _vm.partnerId
                        },
                        on: {
                          onDelete: _vm.refresh
                        }
                      }),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            "nudge-right": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function fn(_ref2) {
                                var on = _ref2.on
                                return [
                                  _vm.displayToDetailView
                                    ? _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: ""
                                            },
                                            on: {
                                              click: _vm.toDetailView
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-open-in-new")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.template.detail.toDetail")
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "subtitle",
                      attrs: {
                        align: "center"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-width": "75%",
                            overflow: "scroll",
                            "max-height": "40px"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.template.meta.description) + " "
                          )
                        ]
                      ),
                      _c("v-spacer"),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-width": "25%",
                            overflow: "scroll",
                            "max-height": "40px"
                          }
                        },
                        _vm._l(_vm.template.meta.categories, function(kitten) {
                          return _c(
                            "v-chip",
                            {
                              key: kitten,
                              staticClass: "ma-1",
                              attrs: {
                                small: ""
                              }
                            },
                            [_vm._v(" " + _vm._s(kitten) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                {
                  staticClass: "scrollable virtual-scroller",
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c("template-detail-body-card", {
                    attrs: {
                      partnerId: _vm.partnerId,
                      template: _vm.template
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-card",
            {
              staticClass: "fill-height",
              attrs: {
                fluid: "",
                flat: ""
              }
            },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "justify-center": "",
                    "align-center": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        shrink: ""
                      }
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
                          "max-height": "350px",
                          contain: ""
                        }
                      }),
                      _c(
                        "v-row",
                        {
                          attrs: {
                            align: "center pt-8"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center"
                            },
                            [
                              _c("template-detail-create-dialog", {
                                attrs: {
                                  partnerId: _vm.partnerId
                                },
                                on: {
                                  onCreated: _vm.refresh
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }