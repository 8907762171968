export function toArray<T>(value: T | T[]): T[] {
  if (!value) {
    return [];
  }

  if (!Array.isArray(value)) {
    return [value];
  }

  return value;
}
