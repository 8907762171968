var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.UPDATE,
            a: _vm.ResourceEnum.TEMPLATE
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                loading: _vm.loading
              },
              on: {
                click: _vm.initialize
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("components.template.detail.body.update.title")
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _vm.dialog
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.dialog,
                supressKeyboardActions: true,
                fullscreen: true,
                title: _vm.$t("components.template.detail.body.update.title"),
                rightLoading: _vm.loading,
                rightText: _vm.$t(
                  "components.template.detail.body.update.update"
                )
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.dialog = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.dialog = $event
                },
                leftClick: function leftClick($event) {
                  _vm.dialog = false
                },
                rightClick: _vm.update
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "overflow-y": "hidden",
                    height: "calc(100vh - 100px - 100px)"
                  }
                },
                [
                  _c("template-editor-subject", {
                    attrs: {
                      partnerId: _vm.partnerId,
                      subject: _vm.updateTemplateBody.subject,
                      addPlaceholderInSubject: true
                    },
                    on: {
                      "update:subject": function updateSubject($event) {
                        return _vm.$set(
                          _vm.updateTemplateBody,
                          "subject",
                          $event
                        )
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "calc(100% - 140px)"
                      }
                    },
                    [
                      _vm.debug
                        ? _c("v-textarea", {
                            attrs: {
                              outlined: ""
                            },
                            model: {
                              value: _vm.updateTemplateBody.body,
                              callback: function callback($$v) {
                                _vm.$set(_vm.updateTemplateBody, "body", $$v)
                              },
                              expression: "updateTemplateBody.body"
                            }
                          })
                        : _c("template-editor", {
                            attrs: {
                              readOnly: false,
                              editConfiguration: _vm.editConfiguration,
                              partnerId: _vm.partnerId,
                              label: _vm.$t(
                                "components.template.detail.body.body"
                              )
                            },
                            model: {
                              value: _vm.updateTemplateBody.body,
                              callback: function callback($$v) {
                                _vm.$set(_vm.updateTemplateBody, "body", $$v)
                              },
                              expression: "updateTemplateBody.body"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }