



































































































































































































































































































































































































































































































import ActivityTimeLine from "@/components/partner/ActivityTimeLine.vue";
import GoToReferenceButton from "@/components/partner/GoToReferenceButton.vue";
import ReportActivityBoxCommentComponent from "@/components/partner/ReportActivityBoxCommentComponent.vue";
import ReportActivityBoxMailComponent from "@/components/partner/ReportActivityBoxMailComponent.vue";
import TemplateDialog from "@/components/template/TemplateDialog.vue";
import Card from "@/components/utility/Card.vue";
import ContextMenu from "@/components/utility/ContextMenu.vue";
import SideCard from "@/components/utility/SideCard.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { getLastChanged, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IReport } from "@/models/report.entity";
import { ISignDocument } from "@/models/sign-document.entity";
import { ISignRequest } from "@/models/sign-request.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import {
  SignDigitalSignatureViewModelGen,
  SignDocumentTokenDtoGen,
  SignUpdateSignRequestDtoGen
} from "@/services/sign/v1/data-contracts";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { PaginationFilterOperationEnum } from "@/store/modules/base-pagination.store";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop, Ref } from "vue-property-decorator";
import SelectAssignees from "../../components/utility/SelectAssignees.vue";
import DocumentDetailCardEditor, { DocumentDetailSideBarElements } from "./DocumentDetailCardEditor.vue";
import DocumentDetailEditorMixin from "./DocumentDetailEditorMixin.vue";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";
import DocumentTemplateDetailCost from "./DocumentTemplateDetailCost.vue";
import DocumentTemplateDetailDeleteDialog from "./DocumentTemplateDetailDeleteDialog.vue";
import DocumentTemplateDetailDownload from "./DocumentTemplateDetailDownload.vue";
import DocumentTemplateDetailInformation from "./DocumentTemplateDetailInformation.vue";
import DocumentTemplateDetailLink from "./DocumentTemplateDetailLink.vue";
import DocumentTemplateDetailSave from "./DocumentTemplateDetailSave.vue";
import DocumentTemplateDetailSaveMobile from "./DocumentTemplateDetailSaveMobile.vue";
import DocumentTemplateDetailSignature from "./DocumentTemplateDetailSignature.vue";
import DocumentTemplateDetailTokenListOptions from "./DocumentTemplateDetailTokenListOptions.vue";
import DocumentTemplateDetailTokenListOptionsMobile from "./DocumentTemplateDetailTokenListOptionsMobile.vue";
import DocumentTemplateDetailTokenListSideCard from "./DocumentTemplateDetailTokenListSideCard.vue";
import ReportDocumentCardStatusMenu from "./ReportDocumentCardStatusMenu.vue";
import ReportDocumentSignRequestDialog from "./ReportDocumentSignRequestDialog.vue";

@Component({
  components: {
    SelectAssignees,
    TheLayoutPortal,
    Card,
    Tooltip,
    ContextMenu,
    GoToReferenceButton,
    DocumentTemplateDetailTokenListSideCard,
    DocumentTemplateDetailTokenListOptions,
    DocumentTemplateDetailTokenListOptionsMobile,
    DocumentTemplateDetailInformation,
    DocumentTemplateDetailDeleteDialog,
    DocumentTemplateDetailDownload,
    DocumentTemplateDetailSave,
    DocumentTemplateDetailSaveMobile,
    DocumentTemplateDetailSignature,
    DocumentTemplateDetailCost,
    DocumentTemplateDetailLink,
    DocumentDetailCardEditor,
    ReportDocumentSignRequestDialog,
    ReportDocumentCardStatusMenu,
    SideCard,
    ActivityTimeLine,
    ReportActivityBoxCommentComponent,
    ReportActivityBoxMailComponent
  },
  filters: { simpleDoubleDigitDate }
})
export default class DocumentDetailCard extends mixins(
  DocumentDetailMixin,
  DocumentDetailEditorMixin,
  PermissionMixin
) {
  @Prop()
  document!: ISignDocument;

  @Prop()
  signature?: SignDigitalSignatureViewModelGen;

  @Prop()
  signatureRequest?: ISignRequest;

  @Prop()
  report?: IReport;

  /**
   * Regulates the navigate to(goToReferences, goToDocumentsDetail)
   * functions. If true, navigation buttons emit events(back, goToReference) instead of doing
   * the navigation in the component.
   */
  @Prop({ default: false })
  overrideNavigation!: boolean;

  @Ref("detailCardEditor")
  detailCardEditor!: DocumentDetailCardEditor;

  @Ref("signRequestDialog")
  signRequestDialog!: ReportDocumentSignRequestDialog;

  @Ref("deleteDialog")
  deleteDialog!: DocumentTemplateDetailDeleteDialog;

  @Ref("templateDialog")
  templateDialog!: TemplateDialog;

  isLoading = false;

  isLoadingSave = false;

  isLoadingDelete = false;

  isLoadingSignature = false;

  isLoadingActivities = false;

  sideBarElement = this.isMobile
    ? DocumentDetailSideBarElements.CLOSED
    : this.signatureRequest
    ? DocumentDetailSideBarElements.SIGNATURE
    : DocumentDetailSideBarElements.DETAIL;

  lastChanged = "";

  timer: NodeJS.Timeout = setInterval(this.setLastChanged, 30000);

  /**
   * Activity card tab
   */
  tab = 0;

  get activityLog() {
    return ActivityLogModule.filteredAndSorted;
  }

  get source(): MrfiktivReferenceGen {
    if (this.signatureRequest) {
      return {
        refType: ResourceEnum.SIGN,
        refId: this.signatureRequest.id
      };
    }

    return {
      refType: ResourceEnum.DOCUMENT,
      refId: this.document.id
    };
  }

  get partner() {
    return PartnerModule.partner;
  }

  get mail() {
    return this.signatureRequest?.recipient.email;
  }

  get canDeleteSignRequest() {
    const isSigned =
      this.signatureRequest?.logs?.find(l => l.action === DigitalSignatureRequestStatusEnum.ACCEPTED) ??
      this.document.isSigned;

    return !isSigned;
  }

  get canEditSignRequest() {
    if (this.signatureRequest) {
      return this.signatureRequest.isEditable;
    }

    return !this.document.isSigned;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get DocumentDetailSideBarElements() {
    return DocumentDetailSideBarElements;
  }

  get hasRefs() {
    return Boolean(this.signatureRequest?.refs && this.signatureRequest?.refs.length > 0);
  }

  async mounted() {
    const partnerId = this.partnerId;
    const documentId = this.document.id;

    DocumentTemplateModule.setHighlightedToken(-1);

    if (partnerId && documentId) {
      this.isLoading = true;
      try {
        await this.initialize(this.document);

        this.isLoadingActivities = true;

        ActivityLogModule.setFilters([
          new PageFilterElement({
            key: "source.refId",
            operation: "$eq",
            value: this.source.refId
          }),
          new PageFilterElement({
            key: "source.refType",
            operation: PaginationFilterOperationEnum.EQUAL,
            value: this.source.refType
          })
        ]);
        await ActivityLogModule.fetchFirstPage({
          partnerId: this.partnerId
        }).finally(() => (this.isLoadingActivities = false));

        this.setLastChanged();
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  deleteDocument() {
    this.deleteDialog.startDelete();
  }

  startTemplateDialog() {
    this.templateDialog.isDialogActive = true;
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }

  // used in combination with timer and beforeDestroy to update the 'lastChanged'-text every minute
  setLastChanged() {
    this.$log.debug("setLastChanged");
    const lastModified = this.document.timestamp.lastModified;

    this.lastChanged = getLastChanged(lastModified, (v: string, p?: Record<string, string>) =>
      this.$t(v, p).toString()
    );
  }

  async goToDocumentOverview() {
    if (this.overrideNavigation) {
      this.$emit("back");
      return;
    }

    let tab = ResourceEnum.DOCUMENT;

    if (this.signatureRequest) {
      tab = ResourceEnum.SIGN;
    }

    await new GoToHelper(this.$router).goToDocumentOverview(tab);
  }

  async goToReference() {
    if (!this.hasRefs) {
      return;
    }

    if (this.overrideNavigation) {
      this.$emit("goToReference");
      return;
    }

    const reference = this.signatureRequest?.refs ? this.signatureRequest?.refs[0] : undefined;
    if (!reference) {
      return;
    }

    const goToHelper = new GoToHelper(this.$router);
    if (reference.refType === ResourceEnum.REPORT) {
      await goToHelper.goToReportDetail(reference.refId, this.partnerId);
      return;
    }

    if (reference.refType === ResourceEnum.DOCUMENT) {
      await goToHelper.goToDocumentDetail(reference.refId, this.partnerId);
      return;
    }

    if (reference.refType === ResourceEnum.VEHICLE) {
      await goToHelper.goToVehicleDetail(reference.refId, this.partnerId, VehicleTabs.DOCUMENT);
      return;
    }
  }

  async initializeSignatureRequestDialog() {
    this.$log.debug(`initializeSignatureRequestDialog for partnerID ${this.partnerId}, documentID ${this.document.id}`);
    const partnerId = this.partnerId;
    const documentId = this.document.id;
    this.$log.debug(`signRequestDialog ${this.signRequestDialog}`);
    await this.signRequestDialog.initializeForSignDocument(partnerId, documentId);
  }

  toggleSideBar(sideBarElement: DocumentDetailSideBarElements) {
    if (this.sideBarElement !== sideBarElement) {
      this.openSideBar(sideBarElement);
    } else {
      this.closeSideBar();
    }
  }

  openSideBar(sideBarElement: DocumentDetailSideBarElements) {
    this.detailCardEditor?.onResize();
    this.sideBarElement = sideBarElement;
  }

  closeSideBar() {
    this.detailCardEditor?.onResize();
    this.sideBarElement = DocumentDetailSideBarElements.CLOSED;
  }

  setCurrentPage(currentPageNumber: number) {
    this.currentPageNumber = currentPageNumber;
    DocumentTemplateModule.setCurrentPage(this.currentPageNumber);
  }

  /**
   * Checks if every token in the list is configured well
   * @param tokens
   */
  isPageComplete(tokens: SignDocumentTokenDtoGen[]) {
    for (const token of tokens) {
      if (!token.token) {
        return false;
      }
    }
    return true;
  }

  findPagesWithMissingTokens(tokens: SignDocumentTokenDtoGen[][]) {
    const incompletePages: number[] = [];
    let pageNumber = 1;
    for (const tokenPage of tokens) {
      if (!this.isPageComplete(tokenPage)) {
        incompletePages.push(pageNumber);
      }

      pageNumber++;
    }

    return incompletePages;
  }

  async updateMeta() {
    await this.document.update();
  }

  async updateTokens() {
    const tokens = DocumentTemplateModule.documentTokens;
    const incompletePages = this.findPagesWithMissingTokens(tokens);
    if (incompletePages.length) {
      throw new Error(String(this.$t("sign.DocumentDetail.tokenError", { page: incompletePages.join(", ") })));
    }

    this.document.tokens = tokens;

    await this.document.updateTokens();
  }

  async onCloseSignRequestDialog() {
    this.$log.debug("onCloseSignRequestDialog");
    await this.initialize(this.document);
  }

  async saveMeta() {
    try {
      this.isLoadingSave = true;
      await Promise.all([this.updateMeta()]);
      this.$toast.success("👍");
      this.setLastChanged();
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.isLoadingSave = false;
    }
  }

  async saveTokens() {
    try {
      this.isLoadingSave = true;
      await Promise.all([this.updateTokens()]);
      this.$toast.success("👍");
      this.setLastChanged();
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.isLoadingSave = false;
    }
  }

  async save() {
    try {
      this.isLoadingSave = true;
      await Promise.all([this.updateMeta(), this.updateTokens()]);
      this.$toast.success("👍");
      this.setLastChanged();
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.isLoadingSave = false;
    }
  }

  async saveSignatureRequest() {
    try {
      this.isLoadingSignature = true;

      if (!this.signatureRequest) {
        return;
      }

      const data: SignUpdateSignRequestDtoGen = {
        recipient: this.signatureRequest.recipient,
        title: this.signatureRequest.title,
        description: this.signatureRequest.description,
        validBy: this.signatureRequest.validBy,
        externalId: this.signatureRequest.externalId
      };

      await this.signatureRequest.updatePartial(data);

      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingSignature = false;
    }
  }

  onSelect() {
    this.sideBarElement = DocumentDetailSideBarElements.EDIT;
  }

  async onDelete() {
    try {
      this.isLoadingDelete = true;

      await this.document.delete();

      if (this.signatureRequest) {
        await this.signatureRequest.delete();
      }

      this.$toast.success("🗑️ 👍");
      this.goToDocumentOverview();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingDelete = false;
    }
  }

  async onAssigneesUpdate(assignees: []) {
    if (!this.signatureRequest) return;

    await this.signatureRequest.updatePartial({ assignees });
  }

  async onAssigneesAdded(assignees: string[]) {
    if (!this.signatureRequest) return;

    await this.signatureRequest.createAssigneeActivity(ActivityTypeEnum.CREATE_ASSIGNEE, assignees);
  }

  async onAssigneesRemoved(assignees: string[]) {
    if (!this.signatureRequest) return;

    await this.signatureRequest.createAssigneeActivity(ActivityTypeEnum.DELETE_ASSIGNEE, assignees);
  }
}
