




















































































import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivPartnerTemplateViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import TemplateDetailBodyCard from "./TemplateDetailBodyCard.vue";
import TemplateDetailDeleteDialog from "./TemplateDetailDeleteDialog.vue";
import TemplateDetailMetaUpdateDialog from "./TemplateDetailMetaUpdateDialog.vue";
import TemplateDetailContentUpdateDialog from "./TemplateDetailContentUpdateDialog.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import TemplateDetailCreateDialog from "./TemplateDetailCreateDialog.vue";

@Component({
  components: {
    LatestEntriesCardEmpty,
    Card,
    TemplateDetailDeleteDialog,
    TemplateDetailBodyCard,
    TemplateDetailMetaUpdateDialog,
    TemplateDetailContentUpdateDialog,
    TemplateDetailCreateDialog
  }
})
export default class TemplateDetail extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop()
  loading!: boolean;

  @Prop()
  partnerId!: string;

  @Prop()
  displayToDetailView?: boolean;

  get template(): MrfiktivAdminTemplateViewModelGen | MrfiktivPartnerTemplateViewModelGen | undefined {
    if (this.partnerId) {
      return PartnerTemplateModule.partnerTemplate;
    } else {
      return AdminTemplateModule.adminTemplate;
    }
  }

  get createdDate() {
    if (!this.template) {
      return "";
    }
    return detailedDate(this.template.timestamp.created);
  }

  toDetailView() {
    if (this.partnerId && this.template) {
      this.$router.push({
        name: "TemplateDetailPartnerView",
        params: { key: this.template.key, partnerId: this.partnerId }
      });
    } else if (this.template) {
      this.$router.push({
        name: "TemplateDetailView",
        params: { key: this.template.key }
      });
    }
  }

  refresh() {
    this.$emit("refreshList");
  }
}
