






























































import Card from "@/components/utility/Card.vue";
import { CreateSignatureUrlFactory } from "@/lib/utility/createSignatureUrlFactory";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { ISignRequest } from "@/models/sign-request.entity";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";
import { ExportModule } from "@/store/modules/export.store";

@Component({
  components: {
    Card
  }
})
export default class ReportDocumentSignRequestStepperSuccess extends mixins(ReportDocumentSignRequestStepperMixin)
  implements IStepper {
  @Prop()
  signRequest!: ISignRequest;

  url = this.signUrl;

  get baseUrls() {
    return PartnerModule.partner.settings?.urls;
  }

  get signUrl() {
    return PartnerModule.partner.settings?.defaultUrl;
  }

  get isDaSetup() {
    return ExportModule.isDaSetup;
  }

  previous() {
    this.currentStep = SignRequestStepperPageEnum.CHOICE;
  }

  goToDaExport() {
    this.currentStep = SignRequestStepperPageEnum.DA_EXPORT;
  }

  next() {
    this.goToSendMail();
  }

  goToSendMail() {
    const url = this.copyLinkToClipboard();
    this.$emit("setUrl", url);
    this.currentStep = SignRequestStepperPageEnum.MAIL;
  }

  goToSignRequest() {
    if (!this.signRequest) {
      this.$toast.error(this.$t("sign.DocumentTemplateDetailEdit.error"));
      return;
    }

    new GoToHelper(this.$router).goToSignatureRequestDetail(this.signRequest.id, PartnerModule.partner.id, true);
  }

  goToSignPage() {
    const url = this.copyLinkToClipboard();
    window.open(url, "_blank");
  }

  copyLinkToClipboard() {
    if (!this.signRequest) {
      this.$toast.error(this.$t("sign.DocumentTemplateDetailEdit.error"));
      return;
    }

    const url = new CreateSignatureUrlFactory(
      this.url ?? "",
      this.signRequest.id,
      this.signRequest.recipient.firstName,
      this.signRequest.recipient.lastName
    ).build();

    navigator.clipboard.writeText(url);
    this.$toast.success(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));

    return url;
  }

  get link() {
    if (!this.signRequest || !this.signRequest.id) {
      return "";
    }

    const url = new CreateSignatureUrlFactory(
      this.url ?? "",
      this.signRequest.id,
      this.signRequest.recipient.firstName,
      this.signRequest.recipient.lastName
    ).build();

    return url;
  }
}
