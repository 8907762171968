



































import SideCard from "@/components/utility/SideCard.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { IDocumentPageMeta } from "./DocumentDetailEditorMixin.vue";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";
import DocumentTemplateDetailTokenList from "./DocumentTemplateDetailTokenList.vue";
import DocumentTemplateDetailTokenForm from "./DocumentTemplateDetailTokenForm.vue";
import DocumentTemplateDetailTokenListKeyboardInstructions from "./DocumentTemplateDetailTokenListKeyboardInstructions.vue";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";

@Component({
  components: {
    SideCard,
    Tooltip,
    DocumentTemplateDetailTokenList,
    DocumentTemplateDetailTokenForm,
    DocumentTemplateDetailTokenListKeyboardInstructions
  }
})
export default class DocumentTemplateDetailTokenListSideCard extends mixins(DocumentDetailMixin) {
  @Prop()
  currentPageNumber!: number;

  @Prop()
  isLoadingSave!: boolean;

  /**
   * Suppress editing of values
   */
  @Prop({ default: false })
  disabled!: boolean;

  @Prop()
  page!: IDocumentPageMeta;

  setCurrentPage(page: number) {
    this.$emit("setCurrentPage", page);
  }

  get selectedPage() {
    return DocumentTemplateModule.currentPage;
  }

  get selectedItem() {
    return DocumentTemplateModule.highlightedToken;
  }

  get isSelectedItem() {
    return DocumentTemplateModule.highlightedToken >= 0;
  }
}
