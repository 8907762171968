










































































import { Component, Vue } from "vue-property-decorator";
import { ISharedContent, SharedContent } from "@/models/shared-content.entity";
import { CreateSharedContentDto } from "@/models/create-shared-content.dto";
import { $t } from "@/lib/utility/t";
import { ITableWrapperHeader, IControlElements } from "../utility/TableWrapper.vue";
import { SharedContentModule } from "@/store/modules/shared-content.store";
import PaginatedTable from "../utility/v2/PaginatedTable.vue";
import SharedContentSideCard from "./SharedContentSideCard.vue";
import { calculateDayDifference } from "@/lib/utility/date-helper";
import CreateDialog from "../utility/CreateDialog.vue";
import SharedContentCardRefTypes from "./SharedContentCardRefTypes.vue";
import Tooltip from "../utility/tooltip.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { dottedSubString } from "@/lib/utility/string-helper";
import { simpleDate } from "@/lib/utility/date-helper";

@Component({
  components: {
    SharedContentCardRefTypes,
    PaginatedTable,
    SharedContentSideCard,
    CreateDialog,
    Tooltip
  },
  filters: {
    simpleDate
  }
})
export default class SharedContentTable extends Vue {
  readonly SharedContentModule = SharedContentModule;
  readonly CreateSharedContentDto = CreateSharedContentDto;

  sideCardItem: ISharedContent | null = null;

  isCreateDialog = false;

  get isEntityLoading() {
    return this.sideCardItem?.isLoading;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get headers(): ITableWrapperHeader[] {
    return [
      {
        text: String($t("views.SharedContentView.created")),
        value: "timestamp.created",
        type: "date",
        width: "120px",
        align: "start"
      },
      {
        text: String($t("views.SharedContentView.creator")),
        value: "isSystemGenerated",
        width: "150px",
        align: "start"
      },
      {
        text: String($t("views.SharedContentView.active")),
        value: "validBy",
        width: "120px",
        align: "start"
      },
      {
        text: String($t("views.SharedContentView.documents")),
        value: "refs",
        align: "start"
      },
      {
        text: String($t("views.SharedContentView.title")),
        value: "title",
        align: "start"
      },

      { text: "", value: "controls", sortable: false, align: "end", width: "30px" }
    ];
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-keyboard-tab",
        text: "Detail",
        action: this.openDetail
      }
    ];
  }

  async mounted() {
    const sharedContentId = this.$route.params.sharedContentId;
    if (sharedContentId) {
      this.openDetail(new SharedContent({ id: sharedContentId, partnerId: this.partnerId }));
    }
  }

  dottedSubString(text: string, length: number) {
    return dottedSubString(text, length);
  }

  calculateDayDifference(date: string) {
    if (!date) {
      return "";
    }
    return calculateDayDifference(new Date(date)) - 1;
  }

  openDetail(item: ISharedContent) {
    this.sideCardItem = null;
    this.$nextTick(() => (this.sideCardItem = item));
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }
}
