




















































import RulesMixin from "@/mixins/RulesMixin.vue";
import { ICredential } from "@/models/credential.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Prop } from "vue-property-decorator";
import CustomFieldConfigurationForm from "../report/CustomFieldConfigurationForm.vue";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";

@Component({
  components: { CustomFieldConfigurationForm }
})
export default class CredentialForm extends RulesMixin {
  @Prop()
  private value!: ICredential;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop()
  private valid!: boolean;

  get credential() {
    return this.value;
  }

  set credential(credential: ICredential) {
    this.$emit("input", credential);
  }

  get isValid() {
    return this.valid;
  }

  set isValid(v: boolean) {
    this.$emit("update:valid", v);
  }

  get availableRessources() {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return [ResourceEnum.THG];
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      return [ResourceEnum.ANONYMIZATION];
    }

    return [];
  }

  actions = Object.values(ActionEnum);

  addPem() {
    this.credential.permissions.push({
      type: this.availableRessources[0],
      action: [ActionEnum.MANAGE],
      id: ""
    });
  }

  removePermission(index: number) {
    this.credential.permissions.splice(index, 1);
  }

  get addPemBtnTxt() {
    if (this.credential.permissions.length) {
      return "credential.addFurther";
    } else {
      return "credential.add";
    }
  }
}
