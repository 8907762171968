
































































































































































































































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import TemplateEditorPlaceholderDropdown from "@/components/template/TemplateEditorPlaceholderDropdown.vue";
import Debug from "@/components/utility/Debug.vue";
import { isMobile } from "@/lib/utility/isMobile";
import { SignDocumentTokenDtoGen } from "@/services/sign/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import debounce from "debounce";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { clipperBasic } from "vuejs-clipper";
import { IDocumentPageMeta } from "./DocumentDetailEditorMixin.vue";
import { isSignatureToken, TextFieldConfig } from "./DocumentTemplateDetailTokenList.vue";
import ImagePositionSelectorMenu from "./ImagePositionSelectorMenu.vue";

export function getDocumentEditorPositionOptions(e?: SignDocumentTokenDtoGen, page?: IDocumentPageMeta) {
  function getValueBetweenMinMax(newValue: number, min: number, max?: number) {
    newValue = Math.round(newValue);
    if (newValue < min) {
      newValue = min;
    }
    if (max && newValue > max) {
      newValue = max;
    }

    return newValue;
  }

  return {
    y: {
      decrease: {
        icon: "mdi-arrow-up",
        click: () => {
          if (!e) return;

          e.coordinates.y = getValueBetweenMinMax(e.coordinates.y - 1, 0, !page ? 0 : page.pdfHeight - e.coordinates.h);
        },
        buttonCombinations: [
          {
            alt: true,
            shift: false,
            key: "ArrowUp"
          }
        ]
      },
      increase: {
        icon: "mdi-arrow-down",
        click: () => {
          if (!e) return;

          e.coordinates.y = getValueBetweenMinMax(e.coordinates.y + 1, 0, !page ? 0 : page.pdfHeight - e.coordinates.h);
        },
        buttonCombinations: [
          {
            alt: true,
            shift: false,
            key: "ArrowDown"
          }
        ]
      }
    },

    x: {
      decrease: {
        icon: "mdi-arrow-left",
        click: () => {
          if (!e) return;

          e.coordinates.x = getValueBetweenMinMax(e.coordinates.x - 1, 0, !page ? 0 : page.pdfWidth - e.coordinates.w);
        },
        buttonCombinations: [
          {
            alt: true,
            shift: false,
            key: "ArrowLeft"
          }
        ]
      },
      increase: {
        icon: "mdi-arrow-right",
        click: () => {
          if (!e) return;
          e.coordinates.x = getValueBetweenMinMax(e.coordinates.x + 1, 0, !page ? 0 : page.pdfWidth - e.coordinates.w);
        },
        buttonCombinations: [
          {
            alt: true,
            shift: false,
            key: "ArrowRight"
          }
        ]
      }
    },

    h: {
      decrease: {
        icon: "mdi-unfold-less-horizontal",
        click: () => {
          if (!e) return;
          e.coordinates.h = getValueBetweenMinMax(e.coordinates.h - 1, 0, !page ? 0 : page.pdfHeight - e.coordinates.y);
        },
        buttonCombinations: [
          {
            alt: true,
            shift: true,
            key: "ArrowUp"
          },
          {
            alt: true,
            shift: true,
            key: "¿"
          }
        ]
      },
      increase: {
        icon: "mdi-unfold-more-horizontal",
        click: () => {
          if (!e) return;
          e.coordinates.h = getValueBetweenMinMax(e.coordinates.h + 1, 0, !page ? 0 : page.pdfHeight - e.coordinates.y);
        },
        buttonCombinations: [
          {
            alt: true,
            shift: true,
            key: "ArrowDown"
          }
        ]
      }
    },

    w: {
      decrease: {
        icon: "mdi-unfold-less-vertical",
        click: () => {
          if (!e) return;
          e.coordinates.w = getValueBetweenMinMax(e.coordinates.w - 1, 0, !page ? 0 : page.pdfWidth - e.coordinates.x);
        },
        buttonCombinations: [
          {
            alt: true,
            shift: true,
            key: "ArrowLeft"
          }
        ]
      },
      increase: {
        icon: "mdi-unfold-more-vertical",
        click: () => {
          if (!e) return;
          e.coordinates.w = getValueBetweenMinMax(e.coordinates.w + 1, 0, !page ? 0 : page.pdfWidth - e.coordinates.x);
        },
        buttonCombinations: [
          {
            alt: true,
            shift: true,
            key: "ArrowRight"
          }
        ]
      }
    },

    f: {
      decrease: {
        icon: "mdi-format-font-size-decrease",
        click: () => {
          if (!e) return;
          e.coordinates.fontSize = getValueBetweenMinMax(e.coordinates.fontSize - 1, 1, 40);
        },
        buttonCombinations: [
          {
            alt: true,
            shift: false,
            key: "-"
          },
          {
            alt: true,
            shift: true,
            key: "-"
          },
          {
            alt: true,
            shift: false,
            key: "–"
          },
          {
            alt: true,
            shift: true,
            key: "–"
          }
        ]
      },
      increase: {
        icon: "mdi-format-font-size-increase",
        click: () => {
          if (!e) return;
          e.coordinates.fontSize = getValueBetweenMinMax(e.coordinates.fontSize + 1, 1, 40);
        },
        buttonCombinations: [
          {
            alt: true,
            shift: true,
            key: "+"
          },
          {
            alt: true,
            shift: true,
            key: "="
          },
          {
            alt: true,
            shift: true,
            key: "±"
          },
          {
            alt: true,
            shift: false,
            key: "+"
          },
          {
            alt: true,
            shift: false,
            key: "="
          },
          {
            alt: true,
            shift: false,
            key: "≠"
          }
        ]
      }
    }
  };
}

@Component({
  components: {
    LatestEntriesCardEmpty,
    TemplateEditorPlaceholderDropdown,
    ImagePositionSelectorMenu,
    Debug
  }
})
export default class ImagePositionSelector extends Vue {
  @Ref("imageContainer")
  imageContainer!: HTMLDivElement;

  @Ref("clippy")
  clippy!: clipperBasic;

  @Prop()
  pageCount?: number;

  @Prop()
  scaleX!: number;

  @Prop()
  scaleY!: number;

  @Prop()
  currentPageNumber!: number;

  @Prop()
  src!: string;

  @Prop()
  documentTokens!: SignDocumentTokenDtoGen[];

  @Prop({ default: false })
  editValues!: boolean;

  @Prop({ default: false })
  isSigning!: boolean;

  @Prop({ default: false })
  supressKeyboardActions!: boolean;

  @Prop({ default: false })
  hideTokenFields!: boolean;

  @Prop({ default: false })
  enableMagnify!: boolean;

  @Prop()
  page!: IDocumentPageMeta;

  @Prop({ default: 20 })
  resizeTimeout!: number;

  /**
   * if zooming happens for first time, we offer the user an informative snack explaining how to stop zooming
   * After the snackbar was shown for the first time, this will be set to false, preventing all further snacks
   */
  firstSnackBarReset = true;

  isSnackBarReset = false;

  debounceResize = debounce(() => this.resize(), this.resizeTimeout, false);

  debounceKeyUpdate = debounce(() => this.keyUpdate(), 50, false);

  debounceSave = debounce(() => this.$emit("save"), 200, false);

  width = "100%";

  height = "";

  image = new Image();

  // to force repositions on dimension change
  key = 0;

  isHideSelect = true;

  zoom = 25;
  top = 0;
  left = 0;

  topDelta = 0;
  leftDelta = 0;

  centerX = 0;
  centerY = 0;

  scrollSpeedMultiplier = 1;

  isZooming = false;

  dragStartX = 0;
  dragStartY = 0;
  dragLeaveX = 0;
  dragLeaveY = 0;

  imageContainerRect!: DOMRect;
  imageContainerContainerRect!: DOMRect;

  followMouse = false;

  loading = false;

  get isDebug() {
    return ConfigModule.debug;
  }

  get hightlightedTokenIndex() {
    return DocumentTemplateModule.highlightedToken;
  }

  get isMobile() {
    return isMobile() || this.$vuetify.breakpoint.smAndDown;
  }

  get textFieldToken() {
    return TextFieldConfig.token;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get border() {
    return `
    border-style: solid;
    border-radius: 5px;
    border-color: ${this.$vuetify.theme.currentTheme.info};
    border-width: 1px;
  `;
  }

  get highlightedToken() {
    const index = DocumentTemplateModule.highlightedToken;
    if (index === -1) {
      return undefined;
    }

    return DocumentTemplateModule.documentTokens[this.currentPageNumber][index];
  }

  get menuBorder() {
    return `
      border-style: solid;
      border-radius: 5px;
      border-color: ${this.$vuetify.theme.currentTheme.info};
      border-width: 1px;
    `;
  }

  mounted() {
    this.setImage(this.src);
    if (!this.supressKeyboardActions) {
      document.addEventListener("keydown", this.onKeyDown);
    }

    this.loading = true;

    setTimeout(() => {
      this.onLoad();
    }, 200);
    setTimeout(() => {
      this.onLoad();
    }, 500);
  }

  keyUpdate() {
    this.key++;
  }

  onMouseDown(e: MouseEvent) {
    if (!this.isHideSelect) return;
    if (!this.isZooming) return;
    this.followMouse = true;

    this.dragStartX = e.clientX;
    this.dragStartY = e.clientY;
  }

  onMouseUp() {
    if (!this.isHideSelect) return;
    this.followMouse = false;

    this.top += this.topDelta;
    this.left += this.leftDelta;

    this.imageContainerRect = (this.$refs.imageContainer as HTMLDivElement).getBoundingClientRect();
    this.imageContainerContainerRect = (this.$refs.imageContainerContainer as HTMLDivElement).getBoundingClientRect();

    if (this.imageContainerRect.top > this.imageContainerContainerRect.bottom) {
      this.top -= this.topDelta;
    }
    if (this.imageContainerRect.bottom < this.imageContainerContainerRect.top) {
      this.top -= this.topDelta;
    }
    if (this.imageContainerRect.left > this.imageContainerContainerRect.right) {
      this.left -= this.leftDelta;
    }
    if (this.imageContainerRect.right < this.imageContainerContainerRect.left) {
      this.left -= this.leftDelta;
    }

    this.topDelta = 0;
    this.leftDelta = 0;
  }

  onMouseLeave() {
    if (!this.isHideSelect) return;
    if (!this.isZooming) return;

    this.onMouseUp();
  }

  doubleClick(e: MouseEvent) {
    if (!this.isHideSelect) return;
    this.zoom = 50;
    this.top = (this.centerY - e.clientY) / ((this.zoom / 100) * 4);
    this.left = (this.centerX - e.clientX) / ((this.zoom / 100) * 4);

    this.startZoom();
  }

  startZoom() {
    if (this.isMobile && !this.enableMagnify) {
      this.isZooming = false;
      return;
    }
    this.isZooming = true;
    this.handleFirstSnackBarReset();
  }

  handleFirstSnackBarReset() {
    if (!this.firstSnackBarReset) {
      return;
    }
    this.firstSnackBarReset = false;
    this.isSnackBarReset = true;
  }

  freezeZoom() {
    this.isZooming = false;
  }

  resetZoom() {
    this.freezeZoom();
    this.zoom = 25;
    this.top = 0;
    this.left = 0;
  }

  mousemove(e: MouseEvent) {
    if (!this.isHideSelect) return;
    if (!this.followMouse || !this.isZooming) return;

    this.dragLeaveX = e.clientX;
    this.dragLeaveY = e.clientY;
    this.scrollSpeedMultiplier = 1 / ((this.zoom / 100) * 4);
    this.topDelta = (this.dragLeaveY - this.dragStartY) * this.scrollSpeedMultiplier;
    this.leftDelta = (this.dragLeaveX - this.dragStartX) * this.scrollSpeedMultiplier;
  }

  handleZoomEvent(event: WheelEvent) {
    if (!this.isHideSelect) return;
    if (!this.isZooming) return;

    event.preventDefault();
    this.zoom = Math.min(Math.max(15, this.zoom + event.deltaY), 100);
  }

  zoomIn() {
    this.zoom += 0.2;
  }

  zoomOut() {
    this.zoom -= 0.2;
  }

  getCursorStyle(token: SignDocumentTokenDtoGen) {
    if (this.isSigning && (!token.isEditable || isSignatureToken(token.token))) {
      return "cursor: default !important;";
    }

    return "cursor: pointer !important;";
  }

  isButtonDisabled(icon: string, token: string) {
    if (
      [
        "mdi-unfold-less-horizontal",
        "mdi-unfold-more-horizontal",
        "mdi-unfold-less-vertical",
        "mdi-unfold-more-vertical",
        "mdi-format-font-size-decrease",
        "mdi-format-font-size-increase"
      ].includes(icon) &&
      isSignatureToken(token)
    ) {
      return true;
    }

    return false;
  }

  onLoad() {
    this.hideSelect();
    this.debounceKeyUpdate();
    this.loading = false;
  }

  @Watch("src")
  setImage(newSrc: string) {
    const img = new Image();
    img.src = newSrc;
    this.image = img;

    setTimeout(this.hideSelect, 10);
  }

  resetHighlightedToken() {
    this.select(-1);
  }

  onTokenFieldClick(index: number, token: SignDocumentTokenDtoGen) {
    if (this.isSigning && (!token.isEditable || isSignatureToken(token.token))) {
      this.resetHighlightedToken();
      return;
    }

    if (DocumentTemplateModule.highlightedToken === index) {
      this.select(-1);
    } else {
      this.select(index);
    }
  }

  select(index: number) {
    DocumentTemplateModule.setHighlightedToken(index);
    this.$emit("onSelect", index);
  }

  unselect() {
    this.resetHighlightedToken();
  }

  getDrawPosition() {
    const clippy = this.$refs.clippy as any;
    return clippy?.getDrawPos();
  }

  hideSelect() {
    const width = 0;
    const height = 0;
    const left = 0;
    const top = 0;

    (this.$refs.clippy as any)?.setTL$.next({ left, top });
    (this.$refs.clippy as any)?.setWH$.next({ width, height });

    this.isHideSelect = true;
  }

  showSelect() {
    const width = 20;
    const height = 5;
    const left = (100 - width) / 2;
    const top = (100 - height) / 2;

    (this.$refs.clippy as any)?.setTL$.next({ left, top });
    (this.$refs.clippy as any)?.setWH$.next({ width, height });

    this.isHideSelect = false;
  }

  // Image breaks out vertically sometimes
  resize() {
    const imageContainerContainer = this.$refs.imageContainerContainer as HTMLDivElement;
    const imageContainer = this.$refs.imageContainer as HTMLDivElement;

    const imageWidth = imageContainer.clientWidth;
    const imageHeight = imageContainer.clientHeight;

    const containerWidth = imageContainerContainer.clientWidth;
    const containerHeight = imageContainerContainer.clientHeight;
    const increaseFactorWidth = containerWidth / imageWidth;
    const increaseFactorHeight = containerHeight / imageHeight;
    let scalingFactor = increaseFactorHeight;
    if (increaseFactorWidth < scalingFactor) {
      scalingFactor = increaseFactorWidth;
    }

    this.width = `${scalingFactor * imageWidth}px`;
    this.height = `${scalingFactor * imageHeight}px`;

    this.debounceKeyUpdate();

    const rect = (this.$refs.imageContainerContainer as HTMLDivElement).getBoundingClientRect();
    this.centerX = rect.left + rect.width / 2;
    this.centerY = rect.top + rect.height / 2;
  }

  getTokenText(token: string) {
    if (!token) {
      return this.$t("sign.DocumentTemplateDetailEdit.selectToken");
    }

    let t = token.replaceAll("{", "");
    t = t.replaceAll("}", "");
    t = t?.trim();

    if (t.includes("#") && t.includes(" ") && t.includes("/")) {
      const bits = t.split(" ");
      let text = this.$t(`components.template.editor.placeholder.path.${bits[1]}`);
      t = bits[0];
      t = t.replaceAll("#", "");
      text += " " + this.$t(`components.template.editor.placeholder.formatters.${t}`);

      return text;
    }

    return this.$t(`components.template.editor.placeholder.path.${t}`);
  }

  getPlotDimensions() {
    const imageContainer = this.imageContainer as HTMLDivElement;

    return {
      h: imageContainer?.clientHeight,
      w: imageContainer?.clientWidth
    };
  }

  scaleWidth(w: number) {
    if (this.image.width) {
      const scale = this.getPlotDimensions().w / this.image.width / this.scaleX;

      return scale * w;
    }
  }

  /**
   * from mm to pixels
   * @param h
   */
  scaleHeight(h: number) {
    if (this.image.height) {
      const scale = this.getPlotDimensions().h / this.image.height / this.scaleY;

      return scale * h;
    }
  }

  translateWidth(w: number) {
    const left = (this.$refs.clippy as any)?.$el.offsetLeft;

    return left + (this.scaleWidth(w) ?? w);
  }

  translateHeight(h: number) {
    const top = (this.$refs.clippy as any)?.$el.offsetTop;

    return top + (this.scaleHeight(h) ?? h);
  }

  getButtonStyle(e: SignDocumentTokenDtoGen) {
    let width = 304;
    if (!e.token) {
      width = 272;
    }
    const height = 32;
    const tokenWidth = this.scaleWidth(e.coordinates.w) ?? 0;
    const left = tokenWidth - width + this.translateWidth(e.coordinates.x);

    return `
      ${this.border}
      border-width: 2px;
      background-color: #f5f5f5;
      position: absolute;
      left: ${left}px;
      top: ${this.translateHeight(e.coordinates.y - 9) + 2}px;
      width: ${width}px;
      height: ${height}px;
      z-index: 3;
      overflow:hidden;`;
  }

  isTokenHighlighted(documentToken: SignDocumentTokenDtoGen) {
    const index = this.documentTokens.findIndex(
      t =>
        t.token === documentToken.token &&
        t.coordinates.h === documentToken.coordinates.h &&
        t.coordinates.w === documentToken.coordinates.w &&
        t.coordinates.x === documentToken.coordinates.x &&
        t.coordinates.y === documentToken.coordinates.y
    );

    return DocumentTemplateModule.highlightedToken === index;
  }

  getTokenColor(documentToken: SignDocumentTokenDtoGen, marked = false) {
    if (marked) {
      return this.$vuetify.theme.currentTheme.info;
    }

    if (
      this.isSigning &&
      !(!documentToken.isEditable || isSignatureToken(documentToken.token)) &&
      documentToken.isEditable &&
      documentToken.isRequired &&
      !documentToken.value
    ) {
      return this.$vuetify.theme.currentTheme.error;
    }

    return "#d3d3d3";
  }

  getIsSignature(token: string) {
    return isSignatureToken(token);
  }

  getDocumentTokenStyle(documentToken: SignDocumentTokenDtoGen) {
    const marked = this.isTokenHighlighted(documentToken);

    let border = `border-style: solid; border-radius: 5px; border-color: ${this.getTokenColor(documentToken, marked)}`;

    if (marked || JSON.stringify(documentToken) === JSON.stringify(this.highlightedToken)) {
      border = `
        ${border}
        border-width: 2px;
        background-color: #f5f5f5;
        overflow:hidden;
      `;
    } else {
      border = `
        ${border}
        border-width: 1px;
        height: ${this.scaleHeight(documentToken.coordinates.h)}px;
      `;
    }

    /**
     * font size of pdf me is in pt.
     * using font-size: ${documentToken.coordinates.fontSize}pt ends up too big tho
     *
     * so i translate fontSizeInPt to fontSizeInPixels
     * using font-size: ${fontSizeInPixels}px ends up good enough (although a tiny bit too small)
     */
    const fontSizeInPt = documentToken.coordinates.fontSize;
    const fontSizeInInch = fontSizeInPt / 72;
    const fontSizeInMm = fontSizeInInch * 25.4;
    const fontSizeInPixels = this.scaleHeight(fontSizeInMm + 1);

    return `
      ${border}
      position: absolute;
      font-size: ${fontSizeInPixels}px;
      line-height: ${fontSizeInPixels}px;
      left: ${this.translateWidth(documentToken.coordinates.x) - 2}px;
      top: ${this.translateHeight(documentToken.coordinates.y) - 1}px;
      width: ${this.scaleWidth(documentToken.coordinates.w)}px;
      height: ${this.scaleHeight(documentToken.coordinates.h)}px;
      z-index: 2;
    `;
  }

  getFlattenedOptions(token: SignDocumentTokenDtoGen) {
    const options: {
      icon: string;
      click: () => void;
      buttonCombinations: {
        alt: boolean;
        shift: boolean;
        key: string;
      }[];
    }[] = [];

    Object.values(getDocumentEditorPositionOptions(token, this.page)).forEach(inOrDecrease =>
      Object.values(inOrDecrease).forEach(option => options.push(option))
    );

    return options;
  }

  onKeyDown(e: KeyboardEvent) {
    const token = this.highlightedToken;

    if (token) {
      this.$log.debug(e.altKey, e.shiftKey, e.key);
      const options = this.getFlattenedOptions(token);
      const option = options.find(option => {
        return option.buttonCombinations.find(
          buttonCombination =>
            buttonCombination.alt === e.altKey &&
            buttonCombination.shift === e.shiftKey &&
            buttonCombination.key === e.key
        );
      });

      if (
        option &&
        !(
          [
            "mdi-unfold-less-horizontal",
            "mdi-unfold-more-horizontal",
            "mdi-unfold-less-vertical",
            "mdi-unfold-more-vertical",
            "mdi-format-font-size-decrease",
            "mdi-format-font-size-increase"
          ].includes(option.icon) && isSignatureToken(token.token)
        )
      ) {
        option.click();

        return;
      }
    }

    if (e.altKey && e.key === "Enter") {
      this.$log.debug("ENTER");
      this.debounceSave();

      return;
    }

    // if no token is selected and left key is pressed emit event to go to previous page
    if (DocumentTemplateModule.highlightedToken === -1) {
      if (e.key === "ArrowLeft") {
        this.$emit("previousPage");

        return;
      }
      if (e.key === "ArrowRight") {
        this.$emit("nextPage");

        return;
      }
    }
  }

  destroyed() {
    document.removeEventListener("keydown", this.onKeyDown);
  }
}
