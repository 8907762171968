import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IPartnerTemplate, PartnerTemplate } from "@/models/partner-template.entity";
import partnerTemplateService from "@/services/shared/partnerTemplateService";
import { IPaginationParams } from "../base-pagination.store";
import { IHasPartnerId } from "@/lib/interfaces/has-partner-id.interface";

export const PartnerTemplatePageDataProvider = new (class extends AbstractPageDataProvider<
  IPartnerTemplate,
  IHasPartnerId & IPaginationParams
> {
  itemsPerPage = 25;

  async getPage({ partnerId }: IHasPartnerId & IPaginationParams): Promise<IPageViewModel<IPartnerTemplate>> {
    const data =
      (await partnerTemplateService.getAll(partnerId))?.map(v => new PartnerTemplate({ ...v, partnerId })) ?? [];
    const meta = { currentPage: 1, itemsPerPage: data?.length ?? 0, totalItems: data?.length ?? 0, totalPages: 1 };

    return { meta, data };
  }
})();
