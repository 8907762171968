













import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPartnerMessage, PartnerMessage } from "@/models/partner-message.entity";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { AllMessagesModule } from "@/store/modules/all-messages.store";
import PartnerMessageDetail from "@/components/partner/PartnerMessageDetail.vue";

@Component({
  components: {
    TheLayoutPortal,
    PartnerMessageDetail,
    LatestEntriesCardEmpty
  }
})
export default class PartnerMessagesDetailView extends mixins(PartnerFallbackMixin, PermissionMixin) {
  loading = true;

  isGoHomeOnBack = false;

  message: IPartnerMessage | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();
    await this.load();
  }

  async refresh() {
    await this.load();
  }

  get partner() {
    return PartnerModule.partner;
  }

  get to(): string[] {
    if (!this.message?.to) {
      return [];
    }
    return [this.message?.to];
  }

  get newMessageTo() {
    if (this.message?.from) {
      return [this.message.from];
    }

    return this.to;
  }

  async load() {
    this.loading = true;
    try {
      this.message =
        AllMessagesModule.maps.id?.get(this.$route.params.messageId)[0] ??
        new PartnerMessage({
          partnerId: this.$route.params.partnerId,
          id: this.$route.params.messageId
        });

      await this.message.fetch();
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Error loading Message");
    }
    this.loading = false;
  }
}
