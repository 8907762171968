






























import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { DocumentModule } from "@/store/modules/document.store";
import { PartnerModule } from "@/store/modules/partner";
import { SignRequestModule } from "@/store/modules/sign-request.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Watch } from "vue-property-decorator";
import SignRequestTable from "./SignRequestTable.vue";
import DocumentTable from "./DocumentTable.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import { GoToHelper, BaseGoToHelper } from "@/lib/utility/goToHelper";
import { $t } from "@/lib/utility/t";
import { DocumentTabs } from "@/lib/enum/document-tabs.type";

@Component({
  components: {
    TheLayoutPortal,
    MHeader,
    DocumentTable,
    SignRequestTable
  }
})
export default class DigitalSignatureHomeView extends PartnerFallbackMixin {
  loading = false;
  tab = 0;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.DOCUMENT, PartnerModule.partner.id)) {
      actions.push({
        text: $t("sign.DigitalSignatureHomeView.goToDocumentsTable"),
        key: "goToDocumentsTable",
        exec: () => new GoToHelper(this.$router).goToDocumentTableView(this.partnerId, true)
      });
    }

    return actions;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];
    return breadCrumbs;
  }

  get chips() {
    const chips: IAction[] = [];

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.SIGN, PartnerModule.partner.id)) {
      chips.push({
        text: `${SignRequestModule.totalItems} ${$t("sign.DigitalSignatureHomeView.showAllSignatures")}`,
        key: "numberOfSignatures"
      });
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.DOCUMENT, PartnerModule.partner.id)) {
      chips.push({
        text: `${DocumentModule.totalItems} ${$t("sign.DigitalSignatureHomeView.showAllDocuments")}`,
        key: "numberOfDocuments"
      });
    }

    return chips;
  }

  get items() {
    const items = [];

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.SIGN, PartnerModule.partner.id))
      items.push("sign.DigitalSignatureHomeView.showAllSignatures");

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.DOCUMENT, PartnerModule.partner.id))
      items.push("sign.DigitalSignatureHomeView.showAllDocuments");

    return items;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get allDocuments() {
    return DocumentModule.entities;
  }

  get allSignatureRequests() {
    return SignRequestModule.requests;
  }

  get tabs() {
    const tabOptions: string[] = [];
    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.SIGN, PartnerModule.partner.id)) {
      tabOptions.push(ResourceEnum.SIGN);
    }
    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.DOCUMENT, PartnerModule.partner.id)) {
      tabOptions.push(ResourceEnum.DOCUMENT);
    }

    return tabOptions;
  }

  @Watch("tab")
  syncRoute() {
    const location = this.$router.resolve({
      name: "DigitalSignatureHomeViewTab",
      params: {
        partnerId: this.$route.params.partnerId,
        tab: this.tabs[this.tab] ?? ""
      }
    });
    if (location) {
      new BaseGoToHelper(this.$router).setUrl(location.location);
    }
  }

  async mounted() {
    const tab = this.$route.params.tab;
    this.setTab(tab as DocumentTabs);
    await this.trySetByRouteOrDefault();
  }

  setTab(tab: DocumentTabs) {
    this.tab = 0;
    if (tab) {
      const index = this.tabs.indexOf(tab);

      if (index > -1) {
        this.tab = index;
      }
    }
  }
}
