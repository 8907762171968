






































import { requiredRule } from "@/lib/rules/requiredRule";
import { IAdminUser, IPartnerUser } from "@/models/user.entity";
import { MrfiktivPermissionDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import UserPermissionForm from "./UserPermissionForm.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";

export class Permission {
  resource: BackendResourceEnum;
  partnerId: string;

  actions = [] as any;

  existingActions: ActionEnum[] = [];

  constructor(resource: BackendResourceEnum, partnerId: string, user?: IAdminUser | IPartnerUser) {
    this.resource = resource;
    this.partnerId = partnerId;

    if (user) {
      const partnerPermission: MrfiktivPermissionDtoGen[] =
        user.permission?.filter(value => value.id === this.partnerId) || [];

      const partnerPermissionForResource = partnerPermission.find(value => value.type === resource);

      if (partnerPermissionForResource) {
        this.existingActions = (partnerPermissionForResource.action as ActionEnum[]) || [];
        this.actions = (partnerPermissionForResource.action as ActionEnum[]) || [];
      }
    }
  }

  print() {
    Vue.$log.debug(this);
  }

  toDto(): MrfiktivPermissionDtoGen {
    return {
      id: this.partnerId,
      type: this.resource,
      action: this.actions.map((a: string) => a as ActionEnum)
    };
  }

  hasAction(action?: ActionEnum) {
    if (action) {
      return this.existingActions.find((a: ActionEnum) => a === action);
    }

    return this.actions.length > 0;
  }
}

@Component({
  components: { ConfirmActionDialog, Debug, UserPermissionForm }
})
export default class UserPermissionAddDialog extends mixins(PermissionMixin) {
  @Prop()
  selectedUser!: IAdminUser | IPartnerUser;

  @Prop()
  preselectedPartnerId?: string;

  isLoading = false;
  dialog = false;

  /**
   * Selected partner id
   */
  partnerId = "";

  /**
   * A list of all resources (based on @see BackendResourceEnum) with all configured actions
   */
  permissionForResources: Permission[] = [];

  get resources() {
    return Object.values(BackendResourceEnum);
  }

  get rules() {
    return [requiredRule()];
  }

  get partners() {
    return PartnerModule.partners;
  }

  mounted() {
    if (this.preselectedPartnerId) {
      this.partnerId = this.preselectedPartnerId ?? "";
      this.mapPermissions();
    }
  }

  @Watch("partnerId")
  mapPermissions() {
    const user = this.selectedUser;
    if (!user) return;

    this.permissionForResources = this.resources.map(r => new Permission(r, this.partnerId, user));
  }

  async updatePermissions() {
    this.isLoading = true;

    const permission: MrfiktivPermissionDtoGen[] = [];

    for (const resource of this.permissionForResources) {
      if (resource.hasAction()) {
        permission.push(resource.toDto());
      }
    }

    await this.selectedUser?.addPermissions(permission);

    this.mapPermissions();
    this.dialog = false;
    this.isLoading = false;
  }
}
