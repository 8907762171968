var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.activityLogEntry.target
    ? _c("time-line-item-text", {
        attrs: {
          icon: _vm.icon,
          "fill-dot": "",
          color: _vm.iconColor,
          small: "",
          text: "<b>".concat(_vm.name, "</b> ").concat(_vm.description)
        },
        scopedSlots: _vm._u(
          [
            {
              key: "menu",
              fn: function fn() {
                return [
                  _vm.$vuetify.breakpoint.smAndDown
                    ? _c(
                        "div",
                        {
                          staticClass: "mr-4"
                        },
                        [
                          _c(
                            "context-menu",
                            [
                              _c("activity-time-line-item-delete", {
                                attrs: {
                                  text: true,
                                  partnerId: _vm.activityLogEntry.partnerId,
                                  activityId: _vm.activityLogEntry.id
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c("activity-time-line-item-delete", {
                        staticClass: "mr-4",
                        attrs: {
                          partnerId: _vm.activityLogEntry.partnerId,
                          activityId: _vm.activityLogEntry.id
                        }
                      })
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          2978824791
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }