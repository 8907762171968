
























































import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import CopyClipboardText from "../utility/CopyClipboardText.vue";
import PartnerReportUpdateReportDialog, { ReportUpdateTabEnum } from "./PartnerReportUpdateReportDialog.vue";
import { IAddress } from "@/models/address.entity";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { CompanyServiceEnum } from "@/lib/enum/company-service.enum";

@Component({
  components: { Card, CopyClipboardText, PartnerReportUpdateReportDialog }
})
export default class CustomerContactTimeLineElement extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop()
  customerName!: string;

  @Prop({ default: false })
  isCompany!: boolean;

  @Prop({ default: "" })
  companyName!: string;

  @Prop({ default: false })
  isTaxDeductible!: boolean;

  @Prop({ default: "" })
  taxnumber!: string;

  @Prop()
  address!: IAddress;

  @Prop()
  email!: string;

  @Prop()
  phone!: string;

  @Prop({ default: "" })
  userId!: string;

  get formatedAdress() {
    let address = "";

    if (this.address?.street) {
      address = address + this.address.street;
      if (this.address?.city || this.address?.zip) {
        address = address + ", ";
      }
    }
    if (this.address?.zip) {
      address = address + this.address.zip + " ";
    }
    if (this.address?.city) {
      address = address + this.address.city;
    }

    return address;
  }

  CountryCodeEnum = CountryCodeEnum;

  get ReportUpdateTabEnum() {
    return ReportUpdateTabEnum;
  }

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  openMap() {
    if (this.formatedAdress) {
      new CompanyGoToHelper(this.$router).goToCompanyMap({
        partnerId: this.partnerId,
        query: { s: this.formatedAdress, f: CompanyServiceEnum.WORKSHOP }
      });
    }
  }
}
