












































































import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IForm } from "@/lib/interfaces/form.interface";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";

@Component({
  components: {
    Card
  }
})
export default class PartnerReportCardAddress extends PartnerReportCardGeneral {
  showRemove = true;

  get countryCodeItems() {
    return Object.values(CountryCodeEnum);
  }

  add() {
    this.reportDto.customerAddress = { city: "", countryCode: CountryCodeEnum.germany, state: "", street: "", zip: "" };

    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  remove() {
    this.reportDto.customerAddress = undefined;
    this.showRemove = false;
    this.key++;
    this.isValid = true;
  }
}
