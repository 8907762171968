import { render, staticRenderFns } from "./TheLayoutPortalSplit.vue?vue&type=template&id=dcfcac5c&"
import script from "./TheLayoutPortalSplit.vue?vue&type=script&lang=ts&"
export * from "./TheLayoutPortalSplit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VDivider,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dcfcac5c')) {
      api.createRecord('dcfcac5c', component.options)
    } else {
      api.reload('dcfcac5c', component.options)
    }
    module.hot.accept("./TheLayoutPortalSplit.vue?vue&type=template&id=dcfcac5c&", function () {
      api.rerender('dcfcac5c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/TheLayoutPortalSplit.vue"
export default component.exports