var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      class: [
        "drop-area",
        {
          "is-loading": _vm.loading
        }
      ],
      attrs: {
        id: "droparea"
      },
      on: {
        dragover: function dragover($event) {
          $event.preventDefault()
          return _vm.dragOver.apply(null, arguments)
        },
        dragleave: function dragleave($event) {
          $event.preventDefault()
          return _vm.dragLeave.apply(null, arguments)
        },
        drop: function drop($event) {
          $event.preventDefault()
          !_vm.loading && _vm.onFileDrop($event)
        }
      }
    },
    [
      !_vm.loading
        ? _c("div", [
            _vm.isDragging
              ? _c(
                  "div",
                  {
                    staticClass: "drag-overlay"
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          "x-large": "",
                          color: "primary"
                        }
                      },
                      [_vm._v("mdi-upload")]
                    ),
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.dragoverText)
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "content"
                  },
                  [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.text)
                      }
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.$t("or")))]),
                    _c(
                      "a",
                      {
                        staticClass: "upload-link",
                        attrs: {
                          href: "#"
                        },
                        on: {
                          click: function click($event) {
                            $event.preventDefault()
                            return _vm.triggerFileInput.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("selectFiles")) + " ")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "caption"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.DropAreaMulti.maxFiles", {
                              value: _vm.maxFiles
                            })
                          )
                        )
                      ]
                    ),
                    _c("input", {
                      ref: "fileInput",
                      staticClass: "hidden-file-input",
                      attrs: {
                        type: "file",
                        multiple: "",
                        accept: _vm.accept
                      },
                      on: {
                        change: _vm.onFileSelect
                      }
                    })
                  ]
                )
          ])
        : _c(
            "div",
            [
              _c(
                "v-card",
                {
                  staticClass: "px-4 mt-16 mb-4"
                },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      value: _vm.loadingProgress,
                      color: "primary",
                      height: "4",
                      absolute: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
      !_vm.isDragging
        ? _c(
            "div",
            {
              staticClass: "content"
            },
            [_c("span", [_vm._v(_vm._s(_vm.fileNamePreviews))])]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }