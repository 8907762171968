





















































































































































import { CreateReportUrlFactory } from "@/lib/utility/createReportUrlFactory";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import QrcodeVue from "qrcode.vue";
import { Component, Prop } from "vue-property-decorator";
import TemplateCard from "../template/TemplateCard.vue";
import Card from "../utility/Card.vue";
import { emailRule, phoneRule } from "@/lib/rules/contactRule";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { ReportType } from "@/store/enum/reportType";
import { PartnerModule } from "@/store/modules/partner";
import { ExportModule } from "@/store/modules/export.store";
import { requiredRule } from "@/lib/rules/requiredRule";
import { vinRuleLength } from "@/lib/rules/vinRule";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    QrcodeVue,
    TemplateCard,
    Card
  }
})
export default class PartnerReportInitializeCard extends DarkModeHighlightMixin {
  @Prop()
  initReport!: CreateReportUrlFactory;

  /**
   * The Url of the report, e.g. app.schadensmeldung.digital
   */
  baseReportUrl = "";

  /**
   * Rules for the Email Input
   */
  get emailRules() {
    const rules = [];

    rules.push(emailRule());

    return rules;
  }

  get vinRuleLength() {
    return [vinRuleLength()];
  }

  get ActionEnum() {
    return ActionEnum;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  /**
   * Rules for the numberplate and lastname input
   */
  get numberPlateRules() {
    const rules = [];
    rules.push(numberplateRule());

    if (this.initReport.jobId || this.initReport.serviceEventId) {
      rules.push(requiredRule());
    }

    return rules;
  }

  /**
   * Rules for the phonenumber and lastname input
   */
  get phoneNumberRules() {
    const rules = [];

    rules.push(phoneRule());

    return rules;
  }

  /**
   * Get the base report URl of the Partner from the Partnermodulke
   */
  get baseReportUrls() {
    return PartnerModule.partner.settings?.urls;
  }

  /**
   * Possible report types
   */
  get reportTypeItems() {
    return Object.values(ReportType);
  }

  /**
   * Check if the partner is a KSR partner
   */
  get isKsrSetup() {
    return ExportModule.isKsrSetup;
  }

  /**
   * Check if the partner is a DA partner
   */
  get isDaSetup() {
    return ExportModule.isDaSetup;
  }

  /**
   * Creates the url to the report and appends the specified query params
   */
  get createUrlWithQuery() {
    const url = this.initReport.url(this.baseReportUrl);
    this.$emit("update", url);

    return url;
  }

  mounted() {
    const defaultUrl = PartnerModule.partner.settings?.defaultUrl;
    if (defaultUrl) {
      this.baseReportUrl = defaultUrl;
    } else if (this.baseReportUrls?.length) {
      this.baseReportUrl = this.baseReportUrls[0];
    }
  }
}
