import { IHasPartnerIdOptional } from "@/lib/interfaces/has-partner-id.interface";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { IPartnerTemplate, PartnerTemplate } from "@/models/partner-template.entity";
import store from "@/store/VuexPlugin";
import { getModule, Module } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { PartnerTemplateDataAccessLayer } from "./access-layers/partner-template.access-layer";
import { IPaginationParams, PaginationFilterListElement } from "./base-pagination.store";
import { PartnerTemplatePageDataProvider } from "./page-data-provider/partner-template.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "partner-template",
  store
})
export class PartnerTemplateStore extends PaginatedBaseStore<
  IPartnerTemplate,
  IHasPartnerIdOptional & IPaginationParams
> {
  protected _data = PartnerTemplateDataAccessLayer;
  protected _pageProvider = PartnerTemplatePageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = PartnerTemplate.filterables;
}

export const PartnerTemplateModule = getModule(PartnerTemplateStore);
