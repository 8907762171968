





























































































































































































































































































import FileDownload from "@/components/utility/FileDownload.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import { downloadAsXlsx } from "@/lib/download-as-xlsx";
import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";
import { ImageSwipeImage } from "@/lib/interfaces/vue-image-swipe.interface";
import { convertPdfToImg } from "@/lib/utility/convertPdfToImage";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { downloadFile, downloadUrlFromRemoteOrigin } from "@/lib/utility/downloadFileFunc";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { dottedSubString } from "@/lib/utility/string-helper";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IReport } from "@/models/report.entity";
import { ISnapshot } from "@/models/snapshot.entity";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import ShareDialog from "../partner/ShareDialog.vue";
import TemplateEditor from "../template/TemplateEditor.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import { IDownloadFile } from "../utility/interface/download-file.interface";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "../utility/mmmint/MDetailTable.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import MImageMarker, { IMarker } from "../utility/mmmint/MImageMarker.vue";
import SideCard from "../utility/SideCard.vue";
import TableWrapper, { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import CreateHandoverSnapshotDialog from "./CreateHandoverSnapshotDialog.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    TableWrapper,
    Debug,
    MHeader,
    MDetailViewGrid,
    MDetailTable,
    LatestEntriesCardEmpty,
    CreateHandoverSnapshotDialog,
    SideCard,
    TemplateEditor,
    MImageMarker,
    ConfirmActionDialog,
    ShareDialog,
    FileDownload
  },
  filters: { simpleDoubleDigitDate }
})
export default class SnapshotDetail extends mixins(Vue, PermissionMixin) {
  @Prop()
  value!: ISnapshot;

  @Prop({ default: false })
  hideBreadCrumbs!: boolean;

  @Prop({ default: false })
  hideToDetailButton!: boolean;

  @Prop({ default: false })
  hideSignButton!: boolean;

  @Prop({ default: false })
  hideShareButton!: boolean;

  @Prop({ default: false })
  outline!: boolean;

  markers = [];

  isLoadingImages = false;

  documentPages: ImageSwipeImage[] = [];

  report: IReport | null = null;

  markedReportNumber = "";

  get ResourceEnum() {
    return ResourceEnum;
  }

  get images(): ImageSwipeImage[] {
    const images: ImageSwipeImage[] = [];

    for (const vehicle of this.value.handover.vehicles ?? []) {
      for (const blueprint of vehicle?.blueprints ?? []) {
        const image = blueprint.image;

        images.push({
          src: image.url,
          thumbnail: image.url,
          w: 0,
          h: 0,
          title: `${vehicle.displayName ?? vehicle.id}/${$t("common.nouns.blueprint")}/${blueprint.name}`
        });
      }

      for (const report of this.getReportsForVehicleId(vehicle.id) ?? []) {
        images.push(
          ...this.getReportImages(report).map(i => ({
            ...i,
            title: `${vehicle.displayName ?? vehicle.id}/${report.handover?.number ?? report.id}/${i.title}`
          }))
        );
      }
    }

    return images;
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadcrumbs: IBreadcrumb[] = [];

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.value.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.value.handover.title, 30);
    } else {
      handoverTitle = `#${this.value.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.value.handover.partnerId,
          handoverId: this.value.handoverId
        }
      }
    });

    breadcrumbs.push({
      text: this.$t("snapshot.title"),
      exact: true,
      disabled: false,
      to: {
        name: "SnapshotTableView",
        params: {
          partnerId: this.value.handover.partnerId,
          handoverId: this.value.handoverId
        }
      }
    });

    breadcrumbs.push({
      text: `#${this.value.number}`,
      exact: true,
      disabled: true,
      to: this.toDetailViewDestination
    });

    return breadcrumbs;
  }

  get chips() {
    const breadyumm = [];

    if (this.value.status === DigitalSignatureRequestStatusEnum.SIGNED) {
      breadyumm.push({
        text: $t(`enums.DigitalSignatureRequestStatusEnum.${this.value.status}`),
        color: "info"
      });
    }
    return breadyumm;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (!this.hideToDetailButton) {
      actions.push({
        text: String(this.$t("components.template.detail.toDetail")),
        key: "detail",
        icon: "mdi-open-in-new",
        exec: () => new GoToHelper(this.$router).go(this.toDetailViewDestination, false)
      });
    }

    if (!this.hideShareButton) {
      actions.push({
        text: String(this.$t("common.verbs.share")),
        key: "detail",
        icon: "mdi-share",
        exec: this.openShareDialog
      });
    }

    if (!this.hideSignButton && this.value.status !== DigitalSignatureRequestStatusEnum.SIGNED) {
      actions.push({
        text: String(this.$t("common.verbs.sign")),
        key: "sign",
        icon: "mdi-signature",
        exec: () => (this.$refs.createHandoverSnapshotDialog as CreateHandoverSnapshotDialog).openToSign(this.value)
      });
    }

    actions.push({
      text: String(this.$t("common.nouns.download") + " (PDF)"),
      key: "downloadPdf",
      icon: "mdi-download",
      exec: this.downloadPdf
    });

    actions.push({
      text: String(this.$t("common.nouns.download") + ` (${$t("common.nouns.images")})`),
      key: "downloadImages",
      icon: "mdi-download",
      exec: this.downloadImages
    });

    actions.push({
      text: String(this.$t("common.nouns.download") + " (XLSX)"),
      key: "downloadCsv",
      icon: "mdi-download",
      exec: this.downlodXlsx
    });

    actions.push({
      text: String(this.$t("common.nouns.download") + " (JSON)"),
      key: "downloadJson",
      icon: "mdi-download",
      exec: this.downloadJson
    });

    return actions;
  }

  get toDetailViewDestination() {
    return {
      name: "SnapshotDetailView",
      params: {
        partnerId: this.value.handover.partnerId,
        handoverId: this.value.handoverId,
        snapshotId: this.value.id
      }
    };
  }

  get user() {
    return PartnerUserModule.maps.id.get(this.value.userId)[0];
  }

  get createdByOn() {
    if (this.user) {
      return $t("createdOnBy", {
        name: `${this.user.firstName} ${this.user.lastName}`,
        date: simpleDoubleDigitDate(this.value?.timestamp?.created)
      });
    } else {
      return $t("createdOn", {
        date: simpleDoubleDigitDate(this.value?.timestamp?.created)
      });
    }
  }

  async downloadPdf() {
    const file = this.value.documents[this.value.documents.length - 1];
    await downloadUrlFromRemoteOrigin(file.url, file.name);
    // downloadFile(`${$t(file.name)}.pdf`.toLowerCase(), file.url);
  }

  getReportImages(report: IReport) {
    const images: ImageSwipeImage[] = [];

    for (const image of report.attachment) {
      images.push({
        src: image.url,
        thumbnail: image.url,
        w: 0,
        h: 0,
        title: image.name
      });
    }

    return images;
  }

  downloadJson() {
    downloadFile(`${this.prefix}_${new Date().toISOString()}.json`, JSON.stringify(this.value));
  }

  downlodXlsx() {
    downloadAsXlsx(
      this.value.handover.reports
        .map(report => this.mapReportToDamageInfo(report))
        .sort((a, b) => (a.number ?? 0) - (b.number ?? 0)),
      new Map([
        ["number", $t("objects.handover.number")],
        ["vehicle", $t("common.nouns.vehicle")],
        ["blueprintName", $t("common.nouns.blueprint")],
        ["inspectionTitle", $t("objects.handover.title")],
        ["summary", $t("objects.handover.description")],
        ["date", $t("objects.handover.timestamp.created")],
        ["progressStatus", $t("objects.report.progressStatus")],
        ["isFixed", $t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFixed")],
        ["companyName", $t("finding.contact")],
        ["images", $t("common.nouns.images")],
        ["urls", $t("common.nouns.link")]
      ]),
      false
    );
  }

  get headers(): ITableWrapperHeader[] {
    const header: ITableWrapperHeader[] = [
      {
        text: this.$t("common.nouns.number").toString(),
        value: "number",
        width: "100px",
        align: "center"
      },
      {
        text: $t("objects.thgMeterReading.status"),
        value: "progressStatus",
        width: "100px"
      },
      {
        text: $t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFixed"),
        value: "isFixed",
        width: "120px"
      },
      {
        text: $t("objects.handover.description"),
        value: "title"
      },
      {
        text: $t("finding.contact"),
        value: "companyName"
      },
      {
        text: $t("objects.handover.timestamp.created"),
        value: "date"
      }
    ];

    return header;
  }

  get detailTableConfig(): IMDetailTableConfig[] {
    return [
      {
        key: "progressStatus",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [
          { value: ProgressStatusEnum.NEW, displayValue: $t("enums.ProgressStatusEnum.new"), color: "info" },
          {
            value: ProgressStatusEnum.IN_PROGRESS,
            displayValue: $t("enums.ProgressStatusEnum.inProgress"),
            color: "warning"
          },
          {
            value: ProgressStatusEnum.FINISHED,
            displayValue: $t("enums.ProgressStatusEnum.finished"),
            color: "success"
          },
          { value: ProgressStatusEnum.DELETED, displayValue: $t("enums.ProgressStatusEnum.deleted"), color: "error" }
        ]
      },
      {
        key: "isFixed",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [
          {
            value: true,
            displayValue: $t("yes"),
            color: "success"
          },
          {
            value: false,
            displayValue: "no",
            color: "warning"
          }
        ]
      },
      {
        key: "timestamp.created",
        visualization: DetailTableVisualizationEnum.DATE,
        type: DetailTableTypeEnum.PROPERTY
      },
      {
        key: "companyName",
        visualization: DetailTableVisualizationEnum.TEXT,
        type: DetailTableTypeEnum.PROPERTY
      }
    ];
  }

  mapReportToDamageInfo(report: IReport) {
    const inspection = this.value.handover.inspections.find(i => i.id === report.handover?.foundInInspection);
    return {
      number: report.handover?.number,
      vehicle: this.getVehicleForVehicleId(report.vehicleId)?.displayName ?? report.vehicleId,
      blueprintName: report.position?.blueprint.name,
      title: report.title,
      message: report.message,
      companyName: report.companyName,
      inspectionTitle: inspection?.title,
      progressStatus: report.progressStatus ? $t(`enums.ProgressStatusEnum.${report.progressStatus}`) : "",
      isFixed: report.isFixed,
      images: report.attachment.map(image => image.name).join("\n"),
      urls: report.attachment.map(r => r.url).join("\n"),
      date: simpleDoubleDigitDate(report.timestamp.created)
    };
  }

  getVehicleForVehicleId(vehicleId?: string) {
    return this.value.handover.vehicles?.find(v => v.id === vehicleId);
  }

  async downloadImages() {
    await (this.$refs.zipper as FileDownload).download();
  }

  get prefix() {
    return `${$t("snapshot.snapshot")}_${this.value.number}`;
  }

  get downloadableFiles(): IDownloadFile[] {
    return this.images.map(image => ({
      url: image.src,
      filename: image.title
    }));
  }

  async loadDocumentImages() {
    this.isLoadingImages = true;
    if (this.value.documents.length) {
      const url = this.value.documents[this.value.documents.length - 1].url;
      const srcs = await convertPdfToImg(url);
      const images: ImageSwipeImage[] = srcs.map((src, index) => ({
        src: src,
        thumbnail: src,
        w: 0,
        h: 0,
        title: `${this.$t("page")}: ${index + 1}`
      }));

      this.documentPages.splice(0, this.documentPages.length, ...images);
    }
    this.isLoadingImages = false;
  }

  closeReportSideCard() {
    this.report = null;
    this.markedReportNumber = "";
  }

  openReportSideCard({ number }: { number: number }) {
    this.report = null;
    this.$nextTick(() => {
      this.report = this.value.handover.reports.find(r => r.handover?.number === number) ?? null;
      this.markedReportNumber = number.toString();
    });
  }

  getReportsForVehicleId(vehicleId: string) {
    const reports = this.value.handover.reports.filter(r => r.vehicleId === vehicleId);

    return reports;
  }

  getReportsWithoutBlueprint(vehicleId: string) {
    const reports = this.value.handover.reports.filter(r => !r.position?.blueprint && r.vehicleId === vehicleId);

    return reports;
  }

  getReportsForBlueprint(blueprintId: string, vehicleId: string) {
    const reports = this.value.handover.reports.filter(
      r => r.position?.blueprint.id === blueprintId && r.vehicleId === vehicleId
    );

    reports.sort((a, b) => (a.position?.coordinates?.x ?? 0) - (b.position?.coordinates?.x ?? 0));

    return reports;
  }

  onMarkerClicked(marker: IMarker) {
    const reportNumber = marker.text?.replace("#", "") ?? "";

    this.openReportSideCard({ number: Number(reportNumber) });
  }

  onMouseoverMarker(marker: IMarker) {
    this.markedReportNumber = marker.text?.replace("#", "") ?? "";
  }

  onMouseleaveMarker() {
    this.markedReportNumber = "";
  }

  openShareDialog() {
    (this.$refs.shareDialog as ShareDialog)?.open();
  }

  openAttachment(url: string) {
    window.open(url, "_blank");
  }
}
