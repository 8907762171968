import { Filter, FilterConfig, IsFilterable } from "@/lib/filterable";
import { IHasPartnerIdOptional } from "@/lib/interfaces/has-partner-id.interface";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { $t } from "@/lib/utility/t";
import {
  MrfiktivCreatePartnerTemplateDtoGen,
  MrfiktivPartnerTemplateViewModelGen,
  MrfiktivUpdatePartnerTemplateDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import partnerTemplateService from "@/services/shared/partnerTemplateService";
import { ThgUpdatePartnerTemplateDtoGen } from "@/services/thg/v1/data-contracts";
import { PartnerTemplateDataAccessLayer } from "@/store/modules/access-layers/partner-template.access-layer";
import { AdminTemplateBase } from "./admin-template.entity";
import VueRouter from "vue-router";
import { PartnerTemplateGoToHelper } from "@/lib/utility/partner-template.go-to-helper";
import { PartnerModule } from "@/store/modules/partner";

@IsFilterable
class PartnerTemplateBase extends AdminTemplateBase
  implements IEntity<MrfiktivPartnerTemplateViewModelGen, MrfiktivUpdatePartnerTemplateDtoGen> {
  /** A flag to determine if a template is public */
  @FilterConfig({
    type: PageFilterTypes.ENUM,
    displayName: "objects.template.isAdminTemplate",
    config: {
      items: [
        {
          text: $t("yes"),
          value: true
        },
        {
          text: $t("no"),
          value: false
        }
      ]
    }
  })
  isAdminTemplate: boolean;

  partnerId: string;

  get updateDto(): MrfiktivUpdatePartnerTemplateDtoGen {
    return {
      content: this.content.dto,
      meta: this.meta.updateDto
    };
  }

  constructor(
    partnerTemplate?: Partial<PartnerTemplateBase | MrfiktivPartnerTemplateViewModelGen> & IHasPartnerIdOptional
  ) {
    super(partnerTemplate);
    this.isAdminTemplate = partnerTemplate?.isAdminTemplate || false;
    this.partnerId = partnerTemplate?.partnerId || PartnerModule.partner.id;
  }

  map(partnerTemplate?: Partial<PartnerTemplateBase | MrfiktivPartnerTemplateViewModelGen>) {
    if (!partnerTemplate) return;
    super.map(partnerTemplate);
    this.isAdminTemplate = partnerTemplate?.isAdminTemplate || false;
  }

  async fetch(): Promise<this> {
    try {
      this.loading = true;
      const fetched = await partnerTemplateService.getByKey(this.partnerId, this.key, this.meta.language);
      this.map(fetched);
      PartnerTemplateDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }

    return this;
  }

  async create() {
    try {
      this.loading = true;
      const dto: MrfiktivCreatePartnerTemplateDtoGen = {
        key: this.key || undefined,
        content: this.content.dto,
        meta: this.meta
      };
      const res = await partnerTemplateService.create(this.partnerId, dto);
      this.map(res);
      PartnerTemplateDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }

    return this;
  }

  async delete() {
    try {
      this.loading = true;
      await partnerTemplateService.delete(this.partnerId, this.key, this.meta.language);
      PartnerTemplateDataAccessLayer.delete(this);
    } catch (e) {
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }
  }

  async update() {
    const dto: MrfiktivUpdatePartnerTemplateDtoGen | ThgUpdatePartnerTemplateDtoGen = {
      content: this.content.dto,
      meta: this.meta.updateDto
    };

    return this.updatePartial(dto);
  }

  async updatePartial(dto: MrfiktivUpdatePartnerTemplateDtoGen) {
    try {
      this.loading = true;
      const res = await partnerTemplateService.update(this.partnerId, this.key, this.meta.language, dto);
      this.map(res);
      PartnerTemplateDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }

    return this;
  }

  goTo(router: VueRouter) {
    return {
      table: () => new PartnerTemplateGoToHelper(router).goToPartnerTemplateTable({ partnerId: this.partnerId }),
      detail: () =>
        new PartnerTemplateGoToHelper(router).goToPartnerTemplateDetail({
          partnerId: this.partnerId,
          templateId: this.id
        }),
      form: () =>
        new PartnerTemplateGoToHelper(router).goToPartnerTemplateDetailForm({
          partnerId: this.partnerId,
          templateId: this.id
        })
    };
  }
}

type IPartnerTemplate = PartnerTemplateBase;
const PartnerTemplate = Filter.createForClass(PartnerTemplateBase);

export { IPartnerTemplate, PartnerTemplate };
