var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ma-2",
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        margin: 0,
        title: "Berechtigungen"
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.UPDATE,
                    a: _vm.ResourceEnum.USER,
                    field: _vm.selectedUser.partnerId
                  }
                },
                [
                  _vm.isAdmin
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: {
                            bottom: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function fn(_ref) {
                                  var on = _ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            to: {
                                              name: "PermissionTable",
                                              params: {
                                                userId: _vm.selectedUser.id
                                              }
                                            },
                                            icon: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "grey lighten-1"
                                            }
                                          },
                                          [_vm._v("mdi-table")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            971557626
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.partner.PermissionTable.title"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.isAdmin
                    ? _c("user-administration-detail-permissions-add-dialog", {
                        attrs: {
                          selectedUser: _vm.selectedUser
                        }
                      })
                    : _vm._e(),
                  _c("user-permission-add-dialog", {
                    attrs: {
                      selectedUser: _vm.selectedUser,
                      preselectedPartnerId: _vm.partnerId
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.permissions.length === 0
        ? _c("v-card-text", [_vm._v(" Keine Berechtigungen ")])
        : _c(
            "v-list",
            _vm._l(_vm.permissions, function(permission, i) {
              return _c(
                "v-list-item",
                {
                  key: i
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getRessourceName(permission)) +
                            " - " +
                            _vm._s(
                              _vm.$t(
                                "BackendResourceEnum.".concat(permission.type)
                              )
                            ) +
                            " (" +
                            _vm._s(permission.type) +
                            ") "
                        )
                      ]),
                      permission.action
                        ? _c(
                            "v-list-item-subtitle",
                            _vm._l(permission.action, function(action) {
                              return _c(
                                "v-chip",
                                {
                                  key: action,
                                  staticClass: "ma-1",
                                  attrs: {
                                    small: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("ActionEnum.".concat(action))
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "Can",
                        {
                          attrs: {
                            I: _vm.ActionEnum.UPDATE,
                            a: _vm.ResourceEnum.USER,
                            field: _vm.selectedUser.partnerId
                          }
                        },
                        [
                          _c(
                            "user-administration-detail-permissions-remove-dialog",
                            {
                              attrs: {
                                selectedUser: _vm.selectedUser,
                                permissions: _vm.permissions,
                                permissionToDelete: permission
                              }
                            }
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }