
































































import { Component, Prop } from "vue-property-decorator";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import Card from "../utility/Card.vue";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ContactEntity } from "@/models/contactEntity";
import { IAddress } from "@/models/address.entity";

@Component({
  components: { Card }
})
export default class CustomerContactCard extends DarkModeHighlightMixin {
  /** Change the user/customer detail page */
  @Prop({ default: "UserAdministrationDetail" })
  detailsComponentName!: string;

  /**
   * Show go to details button
   */
  @Prop({ default: true })
  navigateToDetails!: boolean;

  @Prop()
  customerName!: string;

  @Prop({ default: "" })
  companyName!: string;

  @Prop({ default: "" })
  isCompany!: boolean;

  @Prop({ default: "" })
  taxnumber!: string;

  @Prop({ default: "" })
  isTaxDeductible!: boolean;

  @Prop()
  address!: IAddress;

  @Prop()
  customerContact!: ContactEntity;

  @Prop({ default: "" })
  email!: string;

  @Prop({ default: "" })
  phone!: string;

  @Prop({ default: "" })
  userId!: string;

  CountryCodeEnum = CountryCodeEnum;
}
