var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm._t(
        "default",
        function() {
          return [
            _c(
              "v-btn",
              {
                attrs: {
                  icon: "",
                  disabled:
                    !_vm.isAdmin &&
                    _vm.permissionToDelete.type ===
                      _vm.BackendResourceEnum.PARTNER
                },
                on: {
                  click: _vm.open
                }
              },
              [
                _c(
                  "v-icon",
                  {
                    attrs: {
                      color: "grey lighten-1"
                    }
                  },
                  [_vm._v("mdi-delete-outline")]
                )
              ],
              1
            )
          ]
        },
        null,
        {
          on: _vm.open
        }
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isDialogActive,
            title: "Wollen Sie die Berechtigung wirklich löschen?",
            rightLoading: _vm.isLoading
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            close: _vm.close,
            leftClick: _vm.close,
            rightClick: function rightClick($event) {
              return _vm.removePermission(_vm.permissionToDelete)
            }
          }
        },
        [
          _c(
            "v-card-text",
            {
              staticClass: "mt-4"
            },
            [
              _vm.permissionToDelete.type
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("credential.resourceType")) +
                        ": " +
                        _vm._s(
                          ""
                            .concat(
                              _vm.$t(
                                "BackendResourceEnum.".concat(
                                  _vm.permissionToDelete.type
                                )
                              ),
                              " ("
                            )
                            .concat(_vm.permissionToDelete.type, ")")
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.permissionToDelete.id
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("credential.resourceId")) +
                        ": " +
                        _vm._s(
                          _vm.getPartnerNameForId(_vm.permissionToDelete.id)
                            ? ""
                                .concat(
                                  _vm.getPartnerNameForId(
                                    _vm.permissionToDelete.id
                                  ),
                                  " ("
                                )
                                .concat(_vm.permissionToDelete.id, ")")
                            : _vm.permissionToDelete.id
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.permissionToDelete.action
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("credential.actions")) +
                        ": " +
                        _vm._s(
                          _vm.permissionToDelete.action
                            .map(function(action) {
                              return ""
                                .concat(
                                  _vm.$t("ActionEnum.".concat(action)),
                                  " ("
                                )
                                .concat(action, ")")
                            })
                            .join(", ")
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }