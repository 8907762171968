import { MrfiktivHttpClientProvider } from "../mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreatePartnerTemplateDtoGen,
  MrfiktivPartnerTemplateViewModelGen,
  MrfiktivRenderedTemplateViewModelGen,
  MrfiktivRenderTemplateDtoGen,
  MrfiktivUpdatePartnerTemplateDtoGen
} from "../mrfiktiv/v1/data-contracts";
import { PartnerTemplate as MrfiktivPartnerTemplate } from "../mrfiktiv/v1/PartnerTemplate";
import { PartnerTemplate as ThgPartnerTemplate } from "../thg/v1/PartnerTemplate";
import { ThgHttpClientProvider } from "../thg/thg-http-client.provider";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import {
  ThgCreatePartnerTemplateDtoGen,
  ThgPartnerTemplateViewModelGen,
  ThgRenderedTemplateViewModelGen,
  ThgRenderTemplateDtoGen,
  ThgUpdatePartnerTemplateDtoGen
} from "../thg/v1/data-contracts";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";

class PartnerTemplateService {
  /**
   *
   */
  mrfiktivPartnerTemplate: MrfiktivPartnerTemplate;

  /**
   *
   */
  thgPartnerTemplate: ThgPartnerTemplate;

  /**
   *
   * @param mrfiktivHttpClientProvider
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivPartnerTemplate = new MrfiktivPartnerTemplate(mrfiktivHttpClientProvider.client());
    this.thgPartnerTemplate = new ThgPartnerTemplate(thgHttpClientProvider.client());
  }

  async create(
    partnerId: string,
    data: MrfiktivCreatePartnerTemplateDtoGen | ThgCreatePartnerTemplateDtoGen
  ): Promise<MrfiktivPartnerTemplateViewModelGen | ThgPartnerTemplateViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivPartnerTemplate.partnerTemplateControllerCreate(partnerId, data)).data;
    }
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgPartnerTemplate.partnerTemplateControllerCreate(partnerId, data)).data;
    }
  }

  async delete(
    partnerId: string,
    key: string,
    language: LanguageCodeEnum
  ): Promise<MrfiktivPartnerTemplateViewModelGen | ThgPartnerTemplateViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivPartnerTemplate.partnerTemplateControllerDelete({ partnerId, key, language })).data;
    }
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgPartnerTemplate.partnerTemplateControllerDelete({ partnerId, key, language })).data;
    }
  }

  async getAll(partnerId: string): Promise<MrfiktivPartnerTemplateViewModelGen[] | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivPartnerTemplate.partnerTemplateControllerGetAll(partnerId)).data;
    }
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgPartnerTemplate.partnerTemplateControllerGetAll(partnerId))
        .data as MrfiktivPartnerTemplateViewModelGen[];
    }
  }

  async getByKey(
    partnerId: string,
    key: string,
    language: LanguageCodeEnum
  ): Promise<MrfiktivPartnerTemplateViewModelGen | ThgPartnerTemplateViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivPartnerTemplate.partnerTemplateControllerGetByKey({ partnerId, key, language })).data;
    }
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgPartnerTemplate.partnerTemplateControllerGetByKey({ partnerId, key, language })).data;
    }
  }

  async render(
    partnerId: string,
    key: string,
    language: LanguageCodeEnum,
    data: MrfiktivRenderTemplateDtoGen | ThgRenderTemplateDtoGen
  ): Promise<MrfiktivRenderedTemplateViewModelGen | ThgRenderedTemplateViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivPartnerTemplate.partnerTemplateControllerRender({ partnerId, key, language }, data))
        .data;
    }
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgPartnerTemplate.partnerTemplateControllerRender({ partnerId, key, language }, data)).data;
    }
  }

  async update(
    partnerId: string,
    key: string,
    language: LanguageCodeEnum,
    data: MrfiktivUpdatePartnerTemplateDtoGen | ThgUpdatePartnerTemplateDtoGen
  ): Promise<MrfiktivPartnerTemplateViewModelGen | ThgPartnerTemplateViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivPartnerTemplate.partnerTemplateControllerUpdate({ partnerId, key, language }, data))
        .data;
    }
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgPartnerTemplate.partnerTemplateControllerUpdate({ partnerId, key, language }, data)).data;
    }
  }
}

export default new PartnerTemplateService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
