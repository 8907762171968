


















import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ITemplate } from "@/models/template.entity";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import TemplateDetail from "@/components/template/TemplateDetail.vue";
import Debug from "@/components/utility/Debug.vue";
import { AdminTemplate } from "@/models/admin-template.entity";

@Component({
  components: {
    TheLayoutPortal,
    TemplateDetail,
    LatestEntriesCardEmpty,
    Debug
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class AdminTemplateDetailView extends mixins(PartnerFallbackMixin) {
  readonly store = AdminTemplateModule;

  isLoading = false;

  template: ITemplate | null = null;

  get templateId(): string {
    return this.$route.params.templateId;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const template =
      this.store.maps.id.get(this.templateId)[0] ??
      new AdminTemplate({
        id: this.templateId
      });

    try {
      this.isLoading = true;
      await template.fetch();
      this.template = template;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
