var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("table-wrapper", {
    attrs: {
      title: _vm.title,
      hideSearch: true,
      hideTableFooter: true,
      headers: _vm.headers,
      page: _vm.currentPage,
      itemsPerPage: _vm.initialItemsPerPage,
      loading: _vm.store.isLoading,
      allItems: _vm.store.filteredAndSorted,
      selectedItems: _vm.selected,
      singleSelect: _vm.singleSelect,
      showSelect: _vm.showSelect,
      controlElements: _vm.controlElements,
      sortBy: _vm.sortBy,
      groupBy: _vm.groupBy,
      groupDesc: _vm.groupDesc
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event
      },
      "click:row": function clickRow($event) {
        _vm.hasClickRowListener ? _vm.$emit("click:row", $event) : undefined
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "group.header",
          fn: function fn(_ref) {
            var groupBy = _ref.groupBy,
              group = _ref.group,
              toggle = _ref.toggle,
              isOpen = _ref.isOpen,
              items = _ref.items,
              headers = _ref.headers,
              remove = _ref.remove,
              isMobile = _ref.isMobile,
              hover = _ref.hover
            return [
              _vm._t("group.header", null, {
                group: group,
                groupBy: groupBy,
                toggle: toggle,
                isOpen: isOpen,
                items: items,
                headers: headers,
                remove: remove,
                isMobile: isMobile,
                hover: hover
              })
            ]
          }
        },
        {
          key: "selectActions",
          fn: function fn() {
            return [_vm._t("selectActions")]
          },
          proxy: true
        },
        {
          key: "bodyActions",
          fn: function fn() {
            return [_vm._t("bodyActions")]
          },
          proxy: true
        },
        {
          key: "headerActions",
          fn: function fn() {
            return [_vm._t("headerActions")]
          },
          proxy: true
        },
        _vm._l(_vm.headers, function(header) {
          return {
            key: "item.".concat(header.value),
            fn: function fn(_ref2) {
              var item = _ref2.item,
                value = _ref2.value
              return [
                header.value
                  ? _vm._t("item.".concat(header.value), null, {
                      item: item,
                      value: value
                    })
                  : _vm._e()
              ]
            }
          }
        }),
        {
          key: "no-data",
          fn: function fn() {
            return [
              !_vm.store.filters.length && !_vm.store.search
                ? _vm._t("onboarding", function() {
                    return [
                      _vm._t("no-data", function() {
                        return [
                          _c("latest-entries-card-empty", {
                            staticClass: "mt-2 mb-4",
                            attrs: {
                              small: false
                            }
                          })
                        ]
                      })
                    ]
                  })
                : _vm._t("no-data", function() {
                    return [
                      _c("latest-entries-card-empty", {
                        staticClass: "mt-2 mb-4",
                        attrs: {
                          small: false
                        }
                      })
                    ]
                  })
            ]
          },
          proxy: true
        },
        {
          key: "search",
          fn: function fn() {
            return [
              _c("filter-card-pagination", {
                staticClass: "ma-n4",
                staticStyle: {
                  "min-width": "50%"
                },
                attrs: {
                  filterList: _vm.store.filterOptions,
                  filter: _vm.store.filters,
                  search: _vm.store.search,
                  hideDivider: true,
                  predefinedFilter: _vm.predefinedFilter
                },
                on: {
                  "update:filter": [
                    function($event) {
                      return _vm.$set(_vm.store, "filters", $event)
                    },
                    _vm.setFilter
                  ],
                  "update:search": _vm.debounceSetSearch,
                  confirmSearch: function confirmSearch($event) {
                    return _vm.debounceFetchFirstPage(
                      Object.assign({}, _vm.baseQuery)
                    )
                  },
                  confirmFilter: function confirmFilter($event) {
                    return _vm.debounceFetchFirstPage(
                      Object.assign({}, _vm.baseQuery)
                    )
                  }
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function fn() {
            return [
              _c("v-progress-linear", {
                attrs: {
                  color: "grey",
                  height: 2,
                  value:
                    ((_vm.initialItemsPerPage * _vm.currentPage) /
                      _vm.totalElements) *
                    100,
                  "buffer-value":
                    (_vm.store.filtered.length / _vm.totalElements) * 100
                }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center"
                  }
                },
                [
                  _vm.isCsvExport
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            icon: "",
                            elevation: 0
                          },
                          on: {
                            click: _vm.download
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-download ")])],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdmin
                    ? _c(
                        "div",
                        [
                          !_vm.isLoadingAll
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: ""
                                  },
                                  on: {
                                    click: _vm.startLoadAll
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.partner.PartnerReportList.loadAll"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("v-icon", [_vm._v("mdi-play")])
                                ],
                                1
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      _vm.isInterrupted = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " # " +
                                      _vm._s(_vm.store.filtered.length) +
                                      " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.partner.PartnerReportList.loadAllInterrupt"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("v-icon", [_vm._v("mdi-pause")])
                                ],
                                1
                              ),
                          _vm.isLoadingAll
                            ? _c("v-progress-linear", {
                                attrs: {
                                  indeterminate: "",
                                  color: "#".concat(
                                    Math.floor(
                                      Math.random() * 16777215
                                    ).toString(16)
                                  )
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c("v-select", {
                    staticClass: "mr-2 mt-2 mb-n2",
                    staticStyle: {
                      "max-width": "60px"
                    },
                    attrs: {
                      dense: "",
                      items: _vm.itemsPerPageOptions,
                      "data-test-items-per-page": ""
                    },
                    on: {
                      change: _vm.onItemsPerPageChanged
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function fn(_ref3) {
                          var item = _ref3.item
                          return [_c("small", [_vm._v(_vm._s(item))])]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.initialItemsPerPage,
                      callback: function callback($$v) {
                        _vm.initialItemsPerPage = $$v
                      },
                      expression: "initialItemsPerPage"
                    }
                  }),
                  _c("small", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.initialItemsPerPage * (_vm.currentPage - 1) + 1
                        ) +
                        " - " +
                        _vm._s(
                          _vm.initialItemsPerPage * _vm.currentPage >
                            _vm.totalElements
                            ? _vm.totalElements
                            : _vm.initialItemsPerPage * _vm.currentPage
                        ) +
                        " of " +
                        _vm._s(_vm.totalElements) +
                        " "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        disabled: _vm.currentPage === 1,
                        loading: _vm.isLoadingMore,
                        "data-test-page-back": ""
                      },
                      on: {
                        click: _vm.setPreviousPage
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        disabled:
                          _vm.totalElements / _vm.initialItemsPerPage <=
                          _vm.currentPage,
                        loading: _vm.isLoadingMore,
                        "data-test-page-next": ""
                      },
                      on: {
                        click: _vm.fetchNextPage
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }