import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";
import { AdminTemplateGoToHelper } from "./admin-template.go-to-helper";
import { PartnerTemplateGoToHelper } from "./partner-template.go-to-helper";

type Tab = { newTab?: boolean };
type Partner = { partnerId?: string };
type Template = { templateId: string };
type Query = { query?: Record<string, string> };

export class TemplateGoToHelper extends BaseGoToHelper {
  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  PartnerTemplateGoToHelper = new PartnerTemplateGoToHelper(this.router);

  AdminTemplateGoToHelper = new AdminTemplateGoToHelper(this.router);

  static get breadCrumbs() {
    return {
      TemplateTable: (d: Query & Partner) => {
        const partnerId = d.partnerId;
        if (partnerId) return PartnerTemplateGoToHelper.breadCrumbs.TemplateTable({ ...d, partnerId });
        return AdminTemplateGoToHelper.breadCrumbs.TemplateTable(d);
      },
      TemplateDetail: (d: Template & Query & Partner) => {
        const partnerId = d.partnerId;
        if (partnerId) return PartnerTemplateGoToHelper.breadCrumbs.TemplateDetail({ ...d, partnerId });
        return AdminTemplateGoToHelper.breadCrumbs.TemplateDetail(d);
      },
      TemplateDetailForm: (d: Template & Query & Partner) => {
        const partnerId = d.partnerId;
        if (partnerId) return PartnerTemplateGoToHelper.breadCrumbs.TemplateDetailForm({ ...d, partnerId });
        return AdminTemplateGoToHelper.breadCrumbs.TemplateDetailForm(d);
      }
    };
  }

  static locations = {
    templateTable: (d: Query & Partner) => {
      const partnerId = d.partnerId;
      if (partnerId) return PartnerTemplateGoToHelper.locations.templateTable({ ...d, partnerId });
      return AdminTemplateGoToHelper.locations.templateTable(d);
    },
    templateDetail: (d: Template & Partner) => {
      const partnerId = d.partnerId;
      if (partnerId) return PartnerTemplateGoToHelper.locations.templateDetail({ ...d, partnerId });
      return AdminTemplateGoToHelper.locations.templateDetail(d);
    },
    templateDetailForm: (d: Template & Partner) => {
      const partnerId = d.partnerId;
      if (partnerId) return PartnerTemplateGoToHelper.locations.templateDetailForm({ ...d, partnerId });
      return AdminTemplateGoToHelper.locations.templateDetailForm(d);
    }
  };

  goToTemplateTable(d: Tab & Partner) {
    const partnerId = d.partnerId;
    if (partnerId) return this.PartnerTemplateGoToHelper.goToPartnerTemplateTable({ ...d, partnerId });
    return this.AdminTemplateGoToHelper.goToAdminTemplateTable({ ...d });
  }
  goToTemplateDetail(d: Tab & Template & Partner) {
    const partnerId = d.partnerId;
    if (partnerId) return this.PartnerTemplateGoToHelper.goToPartnerTemplateDetail({ ...d, partnerId });
    return this.AdminTemplateGoToHelper.goToAdminTemplateDetail({ ...d });
  }
  goToTemplateDetailForm(d: Tab & Template & Partner) {
    const partnerId = d.partnerId;
    if (partnerId) return this.PartnerTemplateGoToHelper.goToPartnerTemplateDetailForm({ ...d, partnerId });
    return this.AdminTemplateGoToHelper.goToAdminTemplateDetailForm({ ...d });
  }
}
