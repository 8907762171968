import { AdminTemplateRouteNames } from "@/lib/utility/admin-template.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import AdminTemplateDetailView from "@/views/template/AdminTemplateDetailView.vue";
import AdminTemplateDetailFormView from "@/views/template/AdminTemplateDetailFormView.vue";
import AdminTemplateTableView from "@/views/template/AdminTemplateTableView.vue";

/**
 * Routes for templates and tickets
 */
export const AdminTemplateRoutes: ICustomRouteConfig[] = [
  {
    path: "/template",
    component: AdminTemplateTableView,
    name: AdminTemplateRouteNames.TEMPLATE_TABLE,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-format-float-left",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 45
    }
  },
  {
    path: "/template/:templateId",
    component: AdminTemplateDetailView,
    name: AdminTemplateRouteNames.TEMPLATE_DETAIL,
    permission: {
      resource: ResourceEnum.ADMIN,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/template/:templateId/form",
    component: AdminTemplateDetailFormView,
    name: AdminTemplateRouteNames.TEMPLATE_DETAIL_FORM,
    permission: {
      resource: ResourceEnum.TEMPLATE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
