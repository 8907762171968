import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { AdminTemplate, IAdminTemplate } from "@/models/admin-template.entity";
import adminTemplateService from "@/services/shared/adminTemplateService";
import { IPaginationParams } from "../base-pagination.store";

export const AdminTemplatePageDataProvider = new (class extends AbstractPageDataProvider<
  IAdminTemplate,
  IPaginationParams
> {
  itemsPerPage = 25;

  /* eslint-disable @typescript-eslint/no-unused-vars */
  async getPage(_: IPaginationParams): Promise<IPageViewModel<IAdminTemplate>> {
    const data = (await adminTemplateService.getAll())?.map(v => new AdminTemplate(v)) ?? [];
    const meta = { currentPage: 1, itemsPerPage: data?.length ?? 0, totalItems: data?.length ?? 0, totalPages: 1 };

    return { meta, data };
  }
})();
