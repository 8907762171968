
























import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { LeasingContract } from "@/models/leasingContract";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { IContract } from "@/models/contract.entity";

@Component({
  components: {},
  filters: { simpleDoubleDigitDate }
})
export default class RefsContract extends Vue implements IRefDetail<LeasingContract | IContract> {
  @Prop()
  item!: LeasingContract | IContract;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  async goToDetail() {
    const goTo = (this.item as IContract).goTo;
    if (goTo) {
      goTo(this.$router).detail();
    }
  }
}
