var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          attrs: {
            "no-gutters": ""
          }
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  rules: _vm.textRules,
                  outlined: "",
                  label:
                    _vm.subjectLabel ||
                    _vm.$t("components.template.dialog.preview.subject"),
                  hint: _vm.subjectHint,
                  "persistent-hint": !!_vm.subjectHint
                },
                model: {
                  value: _vm.renderedSubject,
                  callback: function callback($$v) {
                    _vm.renderedSubject = $$v
                  },
                  expression: "renderedSubject"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mt-n2",
              attrs: {
                cols: "12"
              }
            },
            [
              _c(
                "template-editor",
                {
                  key: _vm.k,
                  staticStyle: {
                    height: "250px"
                  },
                  attrs: {
                    isPlain: _vm.isPlainLocal,
                    readOnly: false,
                    partnerId: _vm.partnerId,
                    isMobile: _vm.isMobile,
                    label:
                      _vm.bodyLabel ||
                      _vm.$t("components.template.detail.body.body"),
                    hint: _vm.bodyHint,
                    persistentHint: !!_vm.bodyHint,
                    hideCounter: true
                  },
                  model: {
                    value: _vm.renderedBody,
                    callback: function callback($$v) {
                      _vm.renderedBody = $$v
                    },
                    expression: "renderedBody"
                  }
                },
                [
                  _vm.hasTokens
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            {
                              class: _vm.isMobile ? "mr-n1 ml-n1" : "pr-0 pl-0",
                              attrs: {
                                text: "",
                                small: "",
                                icon: _vm.isMobile
                              },
                              on: {
                                click: function click($event) {
                                  _vm.customTokenDialog = true
                                }
                              }
                            },
                            [
                              !_vm.isMobile
                                ? _c(
                                    "small",
                                    {
                                      staticClass: "mr-1 ml-1"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.template.dialog.preview.customTokens"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.isMobile
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: _vm.isTokenWithoutValue
                                          ? "error"
                                          : ""
                                      }
                                    },
                                    [_vm._v(" mdi-list-status ")]
                                  )
                                : _c(
                                    "tooltip",
                                    {
                                      staticClass: "mr-n1 ml-n1",
                                      attrs: {
                                        text: _vm.$t(
                                          "components.template.dialog.preview.missingTokens"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: _vm.isTokenWithoutValue
                                              ? "error"
                                              : ""
                                          }
                                        },
                                        [_vm._v(" mdi-list-status ")]
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          ),
                          !_vm.isPlain && !_vm.canReadTemplate
                            ? _c("template-editor-action-divider")
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canReadTemplate
                    ? _c(
                        "span",
                        [
                          _c("template-selection-dialog", {
                            attrs: {
                              partnerId: _vm.partnerId,
                              isPlain: _vm.isPlainLocal,
                              context: _vm.extendedContext,
                              color: ""
                            },
                            on: {
                              select: _vm.setTemplate
                            }
                          }),
                          !_vm.isPlain
                            ? _c("template-editor-action-divider")
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.customTokenDialog
        ? _c(
            "div",
            [
              _c(
                "confirm-action-dialog",
                {
                  attrs: {
                    title: _vm.$t(
                      "components.template.dialog.preview.customTokens"
                    ),
                    isDialogActive: _vm.customTokenDialog,
                    hideLeft: true
                  },
                  on: {
                    "update:isDialogActive": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.customTokenDialog = $event
                    },
                    "update:is-dialog-active": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.customTokenDialog = $event
                    },
                    rightClick: function rightClick($event) {
                      _vm.customTokenDialog = false
                    }
                  }
                },
                _vm._l(_vm.template.content.tokens, function(token) {
                  return _c(
                    "div",
                    {
                      key: token
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.getValue(token),
                          label: _vm.getLabel(token),
                          outlined: ""
                        },
                        on: {
                          input: function input(v) {
                            return _vm.debounceInput(v, token)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }