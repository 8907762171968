















































import { Component, Prop } from "vue-property-decorator";
import CustomFieldText from "./CustomFieldText.vue";

@Component({
  components: {}
})
export default class CustomFieldBoolean extends CustomFieldText {
  @Prop()
  dense!: boolean;

  get input() {
    return this.value === "true" || this.value === "false" ? JSON.parse(this.value) : this.value;
  }

  set input(v: string) {
    this.$emit("input", v);
  }
}
