














import { ICompanyAddress } from "@/models/company-address.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";

@Component({
  components: {},
  filters: { getFlagEmoji }
})
export default class RefsCompanyAddress extends mixins(Vue) implements IRefDetail<ICompanyAddress> {
  @Prop()
  item!: ICompanyAddress;

  @Prop()
  small!: boolean;

  @Prop()
  confirmable!: boolean;

  async goToDetail() {
    throw new Error("Method not implemented.");
  }

  copy() {
    this.item.copyToClipboard();
  }
}
