














































































































































































import { isMobile } from "@/lib/utility/isMobile";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPartnerMessageAttachment } from "@/models/partner-message-attachment.entity";
import { Component, Prop, Ref } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { IPartnerMessage } from "@/models/partner-message.entity";
import PdfViewer from "@/components/files/v2/PdfViewer.vue";
import PartnerMessageDetailAttachDialog from "@/components/partner/PartnerMessageDetailAttachDialog.vue";
import ReportDocumentSignRequestDialog from "@/views/sign/ReportDocumentSignRequestDialog.vue";
import { ISignDocument } from "@/models/sign-document.entity";
import CostCreateDialog from "@/components/cost/CostCreateDialog.vue";
import TicketCreateDialog from "@/components/project/TicketCreateDialog.vue";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { IReference } from "@/models/reference.entity";
import { ICost } from "@/models/cost.entity";
import { ITicket } from "@/models/ticket.entity";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    ConfirmActionDialog,
    PdfViewer,
    ReportDocumentSignRequestDialog,
    PartnerMessageDetailAttachDialog: () => import("@/components/partner/PartnerMessageDetailAttachDialog.vue"),
    CostCreateDialog,
    TicketCreateDialog
  }
})
export default class PartnerMessageDetailAttachmentCard extends PermissionMixin {
  @Prop()
  value!: IPartnerMessageAttachment;

  @Prop()
  message!: IPartnerMessage;

  @Prop({ default: false })
  hideActions?: boolean;

  @Ref("attachDialog")
  attachDialog!: PartnerMessageDetailAttachDialog;

  @Ref("signRequestDialog")
  signRequestDialog!: ReportDocumentSignRequestDialog;

  isCostCreateDialogActive = false;

  isTicketCreateDialogActive = false;

  isDialog = false;

  isCostDialogLoading = false;

  isSignDialogLoading = false;

  get ticketCreateRefs(): IReference[] {
    const refs: IReference[] = [];

    if (!this.value) {
      return refs;
    }

    if (this.value.ref?.refType === ResourceEnum.DOCUMENT) {
      refs.push(this.value.ref);
    }

    refs.push({ refType: ResourceEnum.MESSAGE, refId: this.message?.id });
    if (this.message?.refs) {
      for (const ref of this.message.refs) {
        if (ref.refType === ResourceEnum.REPORT) {
          refs.push(ref);
        }
      }
    }

    return refs;
  }

  get isTouch() {
    return isMobile();
  }

  get reportRefs() {
    return this.message.refs?.filter(ref => ref.refType === ResourceEnum.REPORT) ?? [];
  }

  async openAttachment() {
    this.isDialog = true;
    if (!this.value.populatedReference) {
      await this.value.populateReference(this.message);
    }
  }

  exportSelectedAttachment() {
    this.attachDialog.openForAttachment(this.value);
  }

  async initializeSignatureRequestDialog() {
    if (!this.value.populatedReference) {
      return;
    }
    this.isSignDialogLoading = true;
    const partnerId = this.message.partnerId;
    const documentId = (this.value.populatedReference as ISignDocument).id;
    await this.signRequestDialog.initializeForSignDocument(partnerId, documentId);
    this.isSignDialogLoading = false;
  }

  async openCreateCostDialog() {
    const message = this.message;
    if (!message || !this.value.populatedReference) {
      return;
    }

    this.isCostDialogLoading = true;
    const costCreateDialog = this.$refs.costCreateDialog as CostCreateDialog;
    if (!costCreateDialog) {
      throw new Error("No CostCreateDialog");
    }
    costCreateDialog.isLoading = true;
    const document = this.value.populatedReference as ISignDocument;
    costCreateDialog.show();
    costCreateDialog.cost.files.push(document.id);
    await costCreateDialog.onCreatedDocument(document).catch(handleError);
    costCreateDialog.isLoading = false;
    this.isCostDialogLoading = false;
  }

  openCreateTicketDialog() {
    (this.$refs.ticketCreateDialog as TicketCreateDialog).startDialog();
  }

  onCostCreated(cost: ICost) {
    this.$emit("onCostCreated", cost);
    this.isDialog = false;
  }

  onTicketCreated(ticket: ITicket) {
    this.$emit("onTicketCreated", ticket);
    this.isDialog = false;
  }
}
