

























import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import { MessageContentFormatEnum } from "@/lib/enum/templateEnums/messageContentFormat.enum";
import { TemplateEditorEnum } from "@/lib/enum/templateEnums/tempalteEditor.enum";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivUpdateTemplateMetaDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgTemplateMetaViewModelGen } from "@/services/thg/v1/data-contracts";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ComboBox from "../utility/ComboBox.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TemplateDetailMetaFields from "./TemplateDetailMetaFields.vue";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard,
    TemplateDetailMetaFields,
    ConfirmActionDialog,
    Card,
    ComboBox
  }
})
export default class TemplateDetailMetaUpdateDialog extends mixins(PermissionMixin, DarkModeHighlightMixin) {
  @Prop()
  partnerId!: string;

  @Prop()
  meta!: ThgTemplateMetaViewModelGen;

  @Prop()
  isPublic!: boolean;

  @Prop()
  templateKey!: string;

  loading = false;
  dialog = false;

  updateIsPublic = true;
  updateTemplateMeta: MrfiktivUpdateTemplateMetaDtoGen = {
    title: "",
    categories: [],
    contentFormat: MessageContentFormatEnum.HTML,
    description: "",
    editor: TemplateEditorEnum.TIPTAP
  };

  async initialize() {
    this.updateTemplateMeta.title = this.meta.title;
    this.updateTemplateMeta.categories = this.meta.categories;
    this.updateTemplateMeta.contentFormat = this.meta.contentFormat;
    this.updateTemplateMeta.description = this.meta.description;
    this.updateTemplateMeta.editor = this.meta.editor;
    this.updateIsPublic = this.isPublic;

    this.dialog = true;
  }

  close() {
    this.dialog = false;
  }

  async update() {
    try {
      this.updateTemplateMeta.contentFormat = MessageContentFormatEnum.HTML;
      this.updateTemplateMeta.editor = TemplateEditorEnum.TIPTAP;

      this.loading = true;
      if (this.partnerId) {
        const template = await PartnerTemplateModule.updateTemplate({
          partnerId: this.partnerId,
          key: this.templateKey,
          data: { meta: this.updateTemplateMeta }
        });
        if (template) {
          PartnerTemplateModule.replaceInList(template);
        }
      } else {
        const template = await AdminTemplateModule.updateTemplate({
          key: this.templateKey,
          data: { meta: this.updateTemplateMeta, isPublic: this.updateIsPublic }
        });
        if (template) {
          AdminTemplateModule.replaceInList(template);
        }
      }
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.loading = false;
      this.close();
    }
  }
}
