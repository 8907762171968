var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4 "
            },
            [_vm._v(" Generelle Informationen ")]
          ),
          _c(
            "v-card-subtitle",
            {
              staticClass: "mb-n7"
            },
            [
              _vm._v(
                " Erstellt am: " +
                  _vm._s(
                    new Date(_vm.partner.timestamps.created).toLocaleDateString(
                      "de-DE"
                    )
                  ) +
                  " "
              )
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "my-2",
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _vm._v(" " + _vm._s(_vm.partner.address.street) + " "),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.partner.address.zip) +
                      " " +
                      _vm._s(_vm.partner.address.city) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.partner.address.state || "") + " "),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "CountryCodeEnum.".concat(
                            _vm.partner.address.countryCode ||
                              _vm.CountryCodeEnum.germany
                          )
                        )
                      )
                  ),
                  _c("br"),
                  _vm._v(
                    " Geo-Lat: " +
                      _vm._s(_vm.partner.address.geo.lat || "0") +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    " Geo-Lng: " +
                      _vm._s(_vm.partner.address.geo.lng || "0") +
                      " "
                  ),
                  _c("br"),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        small: ""
                      }
                    },
                    [_vm._v("mdi-email-outline")]
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "mailto:".concat(_vm.partner.contact.email)
                      }
                    },
                    [_vm._v(_vm._s(_vm.partner.contact.email))]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        small: ""
                      }
                    },
                    [_vm._v("mdi-phone-outline")]
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "tel:".concat(_vm.partner.contact.phone)
                      }
                    },
                    [_vm._v(_vm._s(_vm.partner.contact.phone))]
                  ),
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip: "Username (Für die Route get by Name)",
                      displayName: _vm.partner.companyUsername,
                      icon: "mdi-account-box-outline"
                    }
                  }),
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip: "PartnerId",
                      displayName: _vm.partner._id,
                      icon: "mdi-account-box-outline"
                    }
                  }),
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip:
                        "Typ des Partners: Werkstatt, Flotte, Versicherung, ...",
                      displayName: _vm.partner.partnerType,
                      icon: "mdi-comment-account-outline"
                    }
                  }),
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip: "Betrieb des Partners: CountryCode",
                      displayName: _vm.getFlag(_vm.partner.countryCode),
                      icon: "mdi-comment-account-outline"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-title",
                {
                  staticClass: "title pt-4 mb-n7"
                },
                [_c("h4", [_vm._v("Kontaktdaten zum anzeigen im Footer")])]
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "mt-8"
                },
                [
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip: "Email, welche im Footer angezeigt wird",
                      displayName: _vm.partner.settings.contact.email,
                      icon: "mdi-email-outline"
                    }
                  }),
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip: "Telefonnummer, welche im Footer angezeigt wird",
                      displayName: _vm.partner.settings.contact.phone,
                      icon: "mdi-phone-outline"
                    }
                  }),
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip: "Website Text, der im Footer angezeigt wird",
                      displayName: _vm.partner.settings.websites[0].text,
                      icon: "mdi-web-refresh"
                    }
                  }),
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip: "Website URL, die im Footer genutzt wird",
                      displayName: _vm.partner.settings.websites[0].link,
                      icon: "mdi-web-check"
                    }
                  }),
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip: "Impressum Text, der im Footer angezeigt wird",
                      displayName: _vm.agbUrl.text,
                      icon: "mdi-web-refresh"
                    }
                  }),
                  _c("PartnerDetailAttributeWithToolTip", {
                    attrs: {
                      tooltip: "Impressum Link, der im Footer genutzt wird",
                      displayName: _vm.agbUrl.link,
                      icon: "mdi-web-check"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }