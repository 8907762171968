var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-btn",
    {
      attrs: {
        elevation: "0",
        plain: "",
        text: ""
      },
      on: {
        click: function click($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("v-icon", [_vm._v(" mdi-magnify ")]),
      !_vm.isMobile
        ? _c(
            "small",
            {
              staticClass: "text-caption ml-2"
            },
            [
              _vm._v(" Suche "),
              _c(
                "strong",
                {
                  staticClass: "grey lighten-3 pa-1 rounded"
                },
                [_vm._v("⌘ + k")]
              ),
              _vm._v(" oder "),
              _c(
                "strong",
                {
                  staticClass: "grey lighten-3 pa-1 rounded"
                },
                [_vm._v("strg + k")]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }