var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.PROJECT,
            field: _vm.project.partnerId
          }
        },
        [
          _vm._t(
            "activator",
            function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "info",
                      elevation: "0"
                    },
                    on: {
                      click: function click($event) {
                        _vm.dialogActive = true
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("project.project.newProject")))]
                )
              ]
            },
            {
              on: function on() {
                return (_vm.dialogActive = true)
              }
            }
          )
        ],
        2
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialogActive,
            title: _vm.$t("project.project.newProject"),
            leftLoading: _vm.isLoading,
            rightLoading: _vm.isLoading,
            rightDisabled: !(_vm.project.title && _vm.project.description),
            width: "850",
            supressKeyboardActions: true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialogActive = $event
            },
            leftClick: function leftClick($event) {
              _vm.dialogActive = false
            },
            rightClick: _vm.createProject,
            close: function close($event) {
              _vm.dialogActive = false
            }
          }
        },
        [
          _vm.dialogActive
            ? _c("project-form", {
                attrs: {
                  valid: _vm.isValid
                },
                on: {
                  "update:valid": function updateValid($event) {
                    _vm.isValid = $event
                  }
                },
                model: {
                  value: _vm.project,
                  callback: function callback($$v) {
                    _vm.project = $$v
                  },
                  expression: "project"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }