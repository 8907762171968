
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class DocumentDetailMixin extends Vue {
  get sideCardHeight() {
    if (this.isMobile) {
      return "calc(100vh)";
    }

    return "calc(100vh - 200px)";
  }

  get sideCardWidth() {
    return "600px";
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  getTokenText(token: string) {
    if (!token) {
      return this.$t("sign.DocumentTemplateDetailEdit.selectToken");
    }

    let t = token.replaceAll("{", "");
    t = t.replaceAll("}", "");
    t = t.trim();

    if (t.includes("#") && t.includes(" ") && t.includes("/")) {
      const bits = t.split(" ");
      let text = this.$t(`components.template.editor.placeholder.path.${bits[1]}`);
      t = bits[0];
      t = t.replaceAll("#", "");
      text += " " + this.$t(`components.template.editor.placeholder.formatters.${t}`);

      return text;
    }

    return this.$t(`components.template.editor.placeholder.path.${t}`);
  }

  get highlightedToken() {
    const highlightedToken = DocumentTemplateModule.highlightedToken;

    return highlightedToken;
  }

  set highlightedToken(token: number) {
    DocumentTemplateModule.setHighlightedToken(token);
  }
}
