

























import AnalyticsDataTableDialog from "@/components/analytics/AnalyticsDataTableDialog.vue";
import AnalyticsKpiColumn from "@/components/analytics/AnalyticsKpiColumn.vue";
import { IKpi } from "@/lib/interfaces/kpi-interface";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBaseChartItemViewmodelGen } from "@/services/thg/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";
import { EChartsOption } from "echarts/types/dist/shared";
import VChart from "vue-echarts";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";

@Component({
  components: { VChart, AnalyticsDataTableDialog, AnalyticsKpiColumn, Card }
})
export default class PieChartCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: undefined })
  colorSchema!: string[];

  @Prop({ default: "" })
  title!: string;

  @Prop({ default: [] })
  data!: ThgBaseChartItemViewmodelGen[];

  @Prop({
    default() {
      return [];
    }
  })
  kpiGroup!: IKpi[];

  @Prop({ default: 3 })
  showMaxItems!: number;

  @Prop({ default: true })
  showTitle?: boolean;

  @Prop({ default: true })
  outlined?: boolean;

  get shopwKpiGroup(): boolean {
    return this.kpiGroup.length != 0;
  }

  get chartOptions(): EChartsOption {
    return this.createEchartOtpions(this.data);
  }

  kpiColor(i: number) {
    if (this.colorSchema) {
      return this.colorSchema[i];
    }
    return ConfigModule.color.analyticsColors[i];
  }

  createEchartOtpions(data: ThgBaseChartItemViewmodelGen[]): EChartsOption {
    return {
      color: this.colorSchema || ConfigModule.color.analyticsColors,
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)"
      },
      series: [
        {
          name: this.$t(this.title).toString(),
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2
          },
          data: data
        }
      ]
    };
  }
}
