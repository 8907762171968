
































































































































































































































import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import { ICost } from "@/models/cost.entity";
import { ICostGroup, CostGroup } from "@/models/cost-group.entity";
import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";
import { handleError } from "@/lib/utility/handleError";
import { PartnerModule } from "@/store/modules/partner";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import RefsSelect from "../utility/RefsSelect.vue";
import { CostModule } from "@/store/modules/cost.store";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import { $t } from "@/lib/utility/t";
import { CostGroupModule } from "@/store/modules/cost-group.store";
import RefsCostGroup from "@/components/utility/RefsCostGroup.vue";
import CostGroupCreateDialog from "@/components/cost/CostGroupCreateDialog.vue";
import RefsCost from "@/components/utility/RefsCost.vue";
import CostCardDocuments from "@/components/cost/CostCardDocuments.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import { debounce } from "debounce";
import CostChip from "./CostChip.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MoneyInput from "../utility/MoneyInput.vue";
import { CostTypeEnum } from "@/lib/enum/cost-type.enum";
import Debug from "@/components/utility/Debug.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { CustomFieldValue } from "@/models/custom-field-value.entity";

@Component({
  components: {
    MHeader,
    MDetailViewGrid,
    ActivityCard: () => import("@/components/thg/ActivityCard.vue"),
    RefsSelect,
    RefsCostGroup,
    RefsCost,
    CostCardDocuments,
    CustomFieldListForm,
    CostChip,
    MActionList,
    ConfirmActionDialog,
    MoneyInput,
    CostGroupCreateDialog,
    Debug
  }
})
export default class CostCard extends mixins(PartnerFallbackMixin) {
  readonly CostTypeEnum = CostTypeEnum;

  @Prop()
  value!: ICost;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  costGroup: ICostGroup | null = null;

  isLoadingRefs = false;

  isLoadingTags = false;

  isLoadingFiles = false;

  isLoadingGroup = false;

  isLoadingCustomFieldValues = false;

  isLoadingTotal = false;

  isEditAbsoluteTotal = false;

  absoluteTotalCopy = this.value.absoluteTotal;

  isLoadingTitle = false;

  isEditTitle = false;

  editTitleCopy = this.value.title;

  typeCopy = this.value.expenseOrIncome;

  isLoadingDescription = false;

  isEditDescription = false;

  descriptionCopy = this.value.description ?? "";

  isLoadingDate = false;

  isLoadingCostGroups = false;

  get subtitle() {
    if (this.value.userId && this.getUserNameForId(this.value.userId)) {
      return $t("createdOnBy", {
        date: simpleDoubleDigitDate(this.value.timestamp.created),
        name: this.getUserNameForId(this.value.userId)
      });
    }

    return $t("createdOn", { date: simpleDoubleDigitDate(this.value.timestamp.created) });
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }
    const goTo = CostGoToHelper.locations;

    return [
      { text: $t("cost.costTable"), exact: true, to: goTo.costTable({ partnerId: this.value.partnerId }) },
      {
        text: this.value.title,
        exact: true,
        to: goTo.costDetail({ partnerId: this.value.partnerId, costId: this.value.id })
      }
    ];
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("project.ticket.actions.openInNewTab"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CostGoToHelper(this.$router).goToCostDetail({
            partnerId: this.value.partnerId,
            costId: this.value.id,
            newTab: true
          })
      });
    }

    actions.push({
      text: $t("project.ticket.editTitle"),
      key: "title",
      icon: "mdi-pencil",
      color: "",
      exec: this.startEditTitle
    });

    return actions;
  }

  get bottomActions() {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("project.ticket.actions.openInNewTab"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CostGoToHelper(this.$router).goToCostDetail({
            partnerId: this.value.partnerId,
            costId: this.value.id,
            newTab: true
          })
      });
    }

    const costGroupId = this.value.group;
    if (costGroupId) {
      actions.push({
        text: $t("cost.toCostGroup"),
        key: "toCostGroup",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CostGoToHelper(this.$router).goToCostGroupCustomView({
            partnerId: this.value.partnerId,
            costGroupId: costGroupId,
            viewId: "0",
            newTab: true
          })
      });
    }

    actions.push({
      text: $t("delete"),
      key: "title",
      icon: "mdi-trash-can",
      color: "red",
      exec: this.onDeleteItem
    });

    return actions;
  }

  get groups() {
    return CostGroupModule.entities;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.COST
    };
  }

  get refsCategories(): BackendResourceEnum[] {
    return [BackendResourceEnum.VEHICLE, BackendResourceEnum.REPORT];
  }

  get tags() {
    const tags = new Set<string>();
    CostModule.entities.forEach(t => t.tags?.forEach(tag => tags.add(tag)));

    return Array.from(tags)
      .sort()
      .filter(t => !this.value.tags.includes(t));
  }

  get date() {
    return this.value.date.slice(0, 10);
  }

  set date(value: string) {
    this.value.date = value;
  }

  mounted() {
    this.setCostGroup();
  }

  onDeleteItem() {
    this.isDeleteDialogActive = true;
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }

  async onDelete() {
    try {
      this.isDeleteLoading = true;

      await this.value.delete();

      this.$toast.success("👍");

      this.isDeleteDialogActive = false;

      this.$emit("deleted");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
    }
  }

  goToCostGroupCustomView() {
    if (!this.value.group) return;

    new CostGoToHelper(this.$router).goToCostGroupCustomView({
      partnerId: this.value.partnerId,
      costGroupId: this.value.group,
      viewId: "0",
      newTab: true
    });
  }

  @Watch("value.group")
  async setCostGroup() {
    this.costGroup = null;

    const costGroupId = this.value.group;
    if (costGroupId) {
      this.isLoadingCustomFieldValues = true;
      const costGroup =
        CostGroupModule.maps.id.get(costGroupId)[0] ||
        (await new CostGroup({ partnerId: this.partner.id, id: costGroupId }).fetch().catch(handleError));
      this.isLoadingCustomFieldValues = false;

      this.$nextTick(() => {
        this.costGroup = costGroup;
      });
    }
  }

  startEditTitle() {
    this.editTitleCopy = this.value.title;
    this.isEditTitle = true;
  }

  cancelEditTitle() {
    this.isEditTitle = false;
    this.editTitleCopy = this.value.title;
  }

  async saveEditTitle() {
    this.isLoadingTitle = true;
    this.value.title = this.editTitleCopy;
    await this.value
      .updatePartial({
        title: this.editTitleCopy
      })
      .catch(handleError);
    this.isLoadingTitle = false;
    this.isEditTitle = false;
  }

  startEditDescription() {
    this.descriptionCopy = this.value.description ?? "";
    this.isEditDescription = true;
  }

  abortEditDesciption() {
    this.descriptionCopy = this.value.description ?? "";
    this.isEditDescription = false;
  }

  async saveEditDescription() {
    this.isLoadingDescription = true;
    await this.value
      .updatePartial({
        description: this.descriptionCopy
      })
      .catch(handleError);
    this.isLoadingDescription = false;
    this.isEditDescription = false;
  }

  async saveRefs() {
    this.isLoadingRefs = true;
    await this.value
      .updatePartial({
        refs: this.value.refs
      })
      .catch(handleError);
    this.isLoadingRefs = false;
  }

  async saveDate() {
    this.isLoadingDate = true;
    await this.value
      .updatePartial({
        date: this.value.date
      })
      .catch(handleError);
    this.isLoadingDate = false;
  }

  async saveTags() {
    this.isLoadingTags = true;
    await this.value
      .updatePartial({
        tags: Array.from(new Set([...this.value.tags]))
      })
      .catch(handleError);
    this.isLoadingTags = false;
  }

  async saveFiles(files: string[]) {
    this.isLoadingFiles = true;
    await this.value
      .updatePartial({
        files
      })
      .catch(handleError);
    this.isLoadingFiles = false;
  }

  async saveGroup() {
    this.isLoadingGroup = true;
    await this.value
      .updatePartial({
        group: this.value.group
      })
      .catch(handleError);
    this.isLoadingGroup = false;
  }

  async saveTotal() {
    this.isLoadingTotal = true;

    this.value.expenseOrIncome = this.typeCopy;
    this.value.absoluteTotal = Math.round(Number(this.absoluteTotalCopy) * 100) / 100;
    await this.value
      .updatePartial({
        total: this.value.total
      })
      .catch(handleError);
    this.absoluteTotalCopy = this.value.absoluteTotal;
    this.typeCopy = this.value.expenseOrIncome;
    this.isLoadingTotal = false;
    this.isEditAbsoluteTotal = false;
  }

  startEditTotal() {
    this.absoluteTotalCopy = this.value.absoluteTotal ?? 0;
    this.typeCopy = this.value.expenseOrIncome;
  }

  abortEditTotal() {
    this.absoluteTotalCopy = this.value.absoluteTotal ?? 0;
    this.typeCopy = this.value.expenseOrIncome;
    this.isEditAbsoluteTotal = false;
  }

  debounceSaveCustomFields = debounce(() => this.saveCustomFieldValues(), 1000, false);

  async saveCustomFieldValues() {
    this.isLoadingCustomFieldValues = true;
    await this.value
      .updatePartial({
        values: CustomFieldValue.buildCustomFieldValuesDto(
          this.value.values.map(v => ({ id: v.id, value: v.value, timezone: v.timezone }))
        )
      })
      .catch(handleError);
    this.isLoadingCustomFieldValues = false;
  }

  async refreshCostGroups() {
    this.isLoadingCostGroups = true;
    try {
      CostGroupModule.setFilter([]);
      CostGroupModule.setHiddenFilter([]);
      await CostGroupModule.fetchAll({ partnerId: PartnerModule.partner.id });
    } catch (e) {
      handleError(e);
    }
    this.isLoadingCostGroups = false;
  }
}
