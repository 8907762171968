



































import ViewedPreview from "@/components/utility/ViewedPreview.vue";
import { IPartnerMessage } from "@/models/partner-message.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import Tooltip from "@/components/utility/tooltip.vue";

@Component({
  components: { ViewedPreview, Tooltip },
  filters: {}
})
export default class RefsMessage extends mixins(Vue) implements IRefDetail<IPartnerMessage> {
  @Prop()
  item!: IPartnerMessage;

  @Prop()
  small!: boolean;

  confirmable = false;

  get body() {
    let body = this.item.content.body.replace("<br><br><br>", " ");
    body = this.item.content.body.replace("<br><br>", " ");
    body = body.replace("<br>", " ");

    return body.replace(/<[^>]+>/g, "");
  }

  get subject() {
    return this.item.content.subject;
  }

  get mail() {
    if (this.item.folder === "outbox") {
      return this.item.to;
    }

    if (this.item.from) {
      return this.item.from;
    }

    return "";
  }

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  async goToDetail() {
    this.item.goToDetail(this.$router);
  }
}
