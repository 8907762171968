var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-autocomplete", {
    attrs: {
      label: _vm.$t(
        "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.vehicle"
      ),
      loading: _vm.loading,
      items: _vm.items,
      rules: _vm.rules,
      "item-text": "displayName",
      "item-value": "id",
      hint: _vm.hint,
      "persistent-hint": _vm.persistentHint,
      "search-input": _vm.search,
      "cache-items": "",
      required: "",
      clearable: _vm.clearable,
      flat: "",
      outlined: "",
      "data-test-vehicle-api-selection": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event
      },
      change: _vm.change
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function fn(_ref) {
          var item = _ref.item,
            attrs = _ref.attrs,
            on = _ref.on
          return [
            _c(
              "refs-vehicle",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      item: item,
                      "data-test-vehicle-api-selection-item": ""
                    }
                  },
                  "refs-vehicle",
                  attrs,
                  false
                ),
                on
              )
            )
          ]
        }
      }
    ]),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v
      },
      expression: "selected"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }