

























import AnalyticsDataTableDialog from "@/components/analytics/AnalyticsDataTableDialog.vue";
import AnalyticsKpiColumn from "@/components/analytics/AnalyticsKpiColumn.vue";
import { IKpi } from "@/lib/interfaces/kpi-interface";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBaseChartItemViewmodelGen } from "@/services/thg/v1/data-contracts";
import { MrfiktivMultiPartnerStatusDistributionItemGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";
import { EChartsOption } from "echarts/types/dist/shared";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";

@Component({
  components: { AnalyticsKpiColumn, AnalyticsDataTableDialog, Card }
})
export default class BarChartCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: undefined })
  colorSchema!: string[];

  @Prop({ default: false })
  reverseAxis!: boolean;

  @Prop({ default: true })
  sort!: boolean;

  @Prop({ default: "" })
  title!: string;

  @Prop({ default: [] })
  data!: ThgBaseChartItemViewmodelGen[] | MrfiktivMultiPartnerStatusDistributionItemGen[];

  @Prop({ default: false })
  useSeries!: boolean;

  @Prop({
    default() {
      return [];
    }
  })
  kpiGroup!: IKpi[];

  @Prop({ default: 10 })
  showMaxItems!: number;

  get shopwKpiGroup(): boolean {
    return this.kpiGroup.length != 0;
  }

  kpiColor(i: number) {
    if (this.colorSchema) {
      return this.colorSchema[i];
    }
    return ConfigModule.color.analyticsColors[i];
  }

  get chartOptions(): EChartsOption {
    if (this.useSeries) {
      if (this.data.length > this.showMaxItems) {
        return this.createEChartOptionsWithSeries(
          (this.data as MrfiktivMultiPartnerStatusDistributionItemGen[]).slice(0, this.showMaxItems)
        );
      } else {
        return this.createEChartOptionsWithSeries(this.data as MrfiktivMultiPartnerStatusDistributionItemGen[]);
      }
    }

    if (this.data.length > this.showMaxItems) {
      return this.createEchartOtpions((this.data as ThgBaseChartItemViewmodelGen[]).slice(0, this.showMaxItems));
    } else {
      return this.createEchartOtpions(this.data as ThgBaseChartItemViewmodelGen[]);
    }
  }

  createEChartOptionsWithSeries(data: MrfiktivMultiPartnerStatusDistributionItemGen[]): EChartsOption {
    // Extract values for each status type for all partners
    const getChartDataByType = (
      data: MrfiktivMultiPartnerStatusDistributionItemGen[],
      type: "new" | "inProgress" | "finished"
    ): number[] => {
      return data.map(d => d.data.filter(data => data.name === type).map(nameValue => nameValue.value)).flat();
    };

    const chartOptions = {
      color: this.colorSchema || ConfigModule.color.analyticsColors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        left: "10%",
        containLabel: true
      },
      xAxis: {
        data: data.map(data => data.partnerId),
        type: "category",
        axisTick: { show: false }
      },
      yAxis: { type: "value" },
      series: [
        {
          name: "New",
          type: "bar",
          barGap: 0,
          emphasis: {
            focus: "series"
          },
          data: getChartDataByType(data, "new")
        },
        {
          name: "In Progress",
          type: "bar",
          barGap: 0,
          label: "In Progress",
          emphasis: {
            focus: "series"
          },
          data: getChartDataByType(data, "inProgress")
        },
        {
          name: "Finished",
          type: "bar",
          barGap: 0,
          label: "Finished",
          emphasis: {
            focus: "series"
          },
          data: getChartDataByType(data, "finished")
        }
      ]
    } as EChartsOption;

    if (this.reverseAxis) {
      chartOptions.xAxis = { type: "category" };
      chartOptions.yAxis = {} as any;
    }

    return chartOptions as EChartsOption;
  }

  createEchartOtpions(data: ThgBaseChartItemViewmodelGen[]): EChartsOption {
    const chartOptions = {
      dataset: [
        {
          dimensions: ["name", "value"],
          source: data
        }
      ],
      color: this.colorSchema || ConfigModule.color.analyticsColors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        left: "10%",
        containLabel: true
      },
      xAxis: {},
      yAxis: { type: "category" },
      series: {
        type: "bar"
      }
    };

    if (this.reverseAxis) {
      chartOptions.xAxis = { type: "category" };
      chartOptions.yAxis = {} as any;
    }

    if (this.sort) {
      chartOptions.dataset.push({
        transform: {
          type: "sort",
          config: { dimension: "value", order: "asc" }
        }
      } as any);
      chartOptions.series = {
        type: "bar",
        datasetIndex: 1
      } as any;
    }
    return chartOptions as EChartsOption;
  }
}
