






































import SideCard from "@/components/utility/SideCard.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { Component, Vue } from "vue-property-decorator";
import DocumentTemplateDetailTokenList from "./DocumentTemplateDetailTokenList.vue";

@Component({
  components: {
    SideCard,
    DocumentTemplateDetailTokenList,
    Tooltip,
    DocumentTemplateDetailTokenListKeyboardInstructions
  }
})
export default class DocumentTemplateDetailTokenListKeyboardInstructions extends Vue {
  get options(): {
    alt?: boolean;
    shift?: boolean;
    enter?: boolean;
    key?: string;
    text: string;
  }[] {
    return [
      { alt: true, enter: true, text: "save" },
      { alt: true, key: "mdi-arrow-up-down-bold-outline", text: "moveUpDown" },
      { alt: true, key: "mdi-arrow-left-right-bold-outline", text: "moveLeftRight" },
      { alt: true, key: "mdi-plus", text: "fontDecrease" },
      { alt: true, key: "mdi-minus", text: "fontIncrease" },
      { alt: true, shift: true, key: "mdi-arrow-up-down-bold-outline", text: "changeHeight" },
      { alt: true, shift: true, key: "mdi-arrow-left-right-bold-outline", text: "changeWidth" }
    ];
  }
}
