import { render, staticRenderFns } from "./PartnerMessageListPaginated.vue?vue&type=template&id=3fdb2eae&scoped=true&"
import script from "./PartnerMessageListPaginated.vue?vue&type=script&lang=ts&"
export * from "./PartnerMessageListPaginated.vue?vue&type=script&lang=ts&"
import style0 from "./PartnerMessageListPaginated.vue?vue&type=style&index=0&id=3fdb2eae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fdb2eae",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAvatar,VIcon,VListItem,VListItemAction,VListItemActionText,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3fdb2eae')) {
      api.createRecord('3fdb2eae', component.options)
    } else {
      api.reload('3fdb2eae', component.options)
    }
    module.hot.accept("./PartnerMessageListPaginated.vue?vue&type=template&id=3fdb2eae&scoped=true&", function () {
      api.rerender('3fdb2eae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partner/PartnerMessageListPaginated.vue"
export default component.exports