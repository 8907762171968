















import CredentialTable from "@/components/credential/CredentialTable.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction, IChips } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    CredentialTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CredentialTableView extends mixins(PartnerFallbackMixin) {
  loadingPartnerUsers = false;

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    return crumbs;
  }

  get chips(): IChips[] {
    return [
      {
        key: "partnerId",
        text: "PartnerId: " + this.partnerId,
        color: "primary",
        exec: () => {
          navigator.clipboard.writeText(this.partnerId);
          this.$toast.info(this.$t("components.CopyClipboardText.confirmCopy"));
        },
        tooltip: this.$t("sign.SignInitializeCard.copy").toString()
      }
    ];
  }

  get actions(): IAction[] {
    return [];
  }

  async mounted() {
    this.trySetByRouteOrDefault();
  }
}
