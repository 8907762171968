var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "pb-4",
      style: _vm.mobileClass,
      attrs: {
        flat: "",
        outlined: _vm.outlined
      }
    },
    [
      _vm.title ? _c("v-card-title", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
      _vm.description
        ? _c("v-card-text", [_vm._v(_vm._s(_vm.description))])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "mx-4"
        },
        [_vm._t("description")],
        2
      ),
      _c(
        "v-simple-table",
        {
          style: _vm.tableStyle
        },
        [
          _c(
            "tbody",
            [
              _vm._l(_vm.tableData, function(item, idx) {
                return _c(
                  "tr",
                  {
                    key: idx,
                    on: {
                      click: function click($event) {
                        return _vm.copyToClipBoard(item)
                      }
                    }
                  },
                  [
                    _c("td", [_vm._v(" " + _vm._s(_vm.$t(item.text)) + " ")]),
                    _vm._t(
                      "item.".concat(item.key),
                      function() {
                        return [
                          _vm._t(
                            "slot.".concat(item.key),
                            function() {
                              return [
                                _c(
                                  "td",
                                  _vm._l(_vm.getItemValue(item), function(
                                    value
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: value + Math.random()
                                      },
                                      [
                                        item.visualization ===
                                        _vm.DetailtableVisualizationEnum.DATE
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.simpleDoubleDigitDate(
                                                      value
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : item.visualization ===
                                            _vm.DetailtableVisualizationEnum
                                              .CHIP
                                          ? _c(
                                              "div",
                                              [
                                                value
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        staticStyle: {
                                                          margin: "1px"
                                                        },
                                                        attrs: {
                                                          dark: "",
                                                          small: "",
                                                          color: _vm.getChipColor(
                                                            item
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(value) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              _vm._v(" " + _vm._s(value) + " ")
                                            ])
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _vm.copyClipboard
                                  ? _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "actionButtons  text-right"
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                small: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: ""
                                                  }
                                                },
                                                [_vm._v("mdi-content-copy")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            },
                            {
                              item: item
                            }
                          )
                        ]
                      },
                      {
                        item: item,
                        value: _vm.getItemValue(item)
                      }
                    )
                  ],
                  2
                )
              }),
              _vm._t("additionalTableRows")
            ],
            2
          ),
          _c("div", [_vm._t("additionalData")], 2)
        ]
      ),
      _c(
        "div",
        {
          staticClass: "px-4 mt-2"
        },
        [
          _vm.showMoreAction
            ? _c(
                "v-btn",
                {
                  attrs: {
                    outlined: "",
                    block: "",
                    color: "info",
                    small: ""
                  },
                  on: {
                    click: function click($event) {
                      return _vm.actionClicked(_vm.showMoreAction)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.showMoreAction.text) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }