import { MessageFolderEnum } from "@/lib/enum/message-folder.enum";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IPartnerMessage, PartnerMessage } from "@/models/partner-message.entity";
import { MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module } from "vuex-module-decorators";
import { ActionEnum } from "../enum/authActionEnum";
import { ResourceEnum } from "../enum/authResourceEnum";
import { PaginatedBaseStore } from "../paginated-base.store";
import { PartnerMessageDataAccessLayer } from "./access-layers/partner-message.access-layer";
import { PaginationFilterListElement, PaginationFilterOperationEnum } from "./base-pagination.store";
import { IPartnerMessageStore } from "./inbox-message.store";
import { UserModule } from "./me-user.store";
import { PartnerMessagePageDataProvider } from "./page-data-provider/partner-message.page-data-provider";
import { PartnerModule } from "./partner";

@Module({
  dynamic: true,
  namespaced: true,
  name: "allmessages",
  store
})
export class AllMessagesStore
  extends PaginatedBaseStore<IPartnerMessage, MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen>
  implements IPartnerMessageStore {
  _data = PartnerMessageDataAccessLayer;
  _pageProvider = PartnerMessagePageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = PartnerMessage.filterables;

  @Action
  async reset() {
    super.reset();

    const partnerId = PartnerModule.partner.id;
    if (
      !PartnerModule.partner ||
      !PartnerModule.partner.id ||
      !UserModule.abilities.can(ActionEnum.READ, ResourceEnum.MESSAGE, partnerId)
    ) {
      return;
    }

    // no need to look up messages that belong into an inbox. these are already fetched @ inbox-message.store.ts .initializeModule()
    const filters = [];
    for (const inbox of PartnerModule.partner?.settings?.inboxes ?? []) {
      filters.push(
        new PageFilterElement({
          key: "to",
          operation: PaginationFilterOperationEnum.NOT_EQUAL,
          value: inbox.identifier
        }),
        new PageFilterElement({
          key: "folder",
          operation: PaginationFilterOperationEnum.EQUAL,
          value: MessageFolderEnum.INBOX
        })
      );
    }

    this.setFilters(filters);
    await this.fetchFirstPage({ partnerId });
    this.setFilters([]);
  }
}

export const AllMessagesModule = getModule(AllMessagesStore);
