import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { requiredRule } from "@/lib/rules/requiredRule";
import { CreateDto } from "@/lib/utility/data/create-dto.interface";
import { handleError } from "@/lib/utility/handleError";
import sharedContentService from "@/services/mrfiktiv/services/sharedContentService";
import {
  MrfiktivBaseCreateSharedContentDtoGen,
  MrfiktivCreateSharedContentElementDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { SharedContentDataAccessLayer } from "@/store/modules/access-layers/shared-content.access-layer";
import { PartnerModule } from "@/store/modules/partner";
import { ISharedContent, SharedContent } from "./shared-content.entity";

@IsFormable
class CreateSharedContent extends CreateDto<ISharedContent> implements MrfiktivCreateSharedContentElementDtoGen {
  partnerId: string;

  @FormConfig({
    category: "objects.sharedContent.validBy",
    searchKeywords: ["objects.sharedContent.validBy"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.sharedContent.validBy",
      type: "date",
      rules: [requiredRule()]
    }
  })
  validBy?: string;

  @FormConfig({
    category: "objects.sharedContent.refs",
    searchKeywords: ["objects.sharedContent.refs"],
    type: DetailFormComponentsEnum.REFS_SELECT,
    props: {
      label: "objects.sharedContent.refs",
      getPartnerId: () => PartnerModule.partner.id
    }
  })
  refs: MrfiktivBaseCreateSharedContentDtoGen[];

  constructor() {
    super();
    this.partnerId = PartnerModule.partner.id;
    this.refs = [];
  }

  async create(silent = true): Promise<ISharedContent | undefined> {
    try {
      const dto: MrfiktivCreateSharedContentElementDtoGen = {
        refs: this.refs,
        validBy: this.validBy
      };

      const res = await sharedContentService.create(this.partnerId, dto);

      const created = new SharedContent(res);

      SharedContentDataAccessLayer.set(created);

      return created;
    } catch (e) {
      handleError(e);

      if (!silent) throw e;
    }
  }
}

type ICreateSharedContentDto = CreateSharedContent;
const CreateSharedContentDto = Form.createForClass(CreateSharedContent);

export { CreateSharedContentDto, ICreateSharedContentDto };
