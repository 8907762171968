var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return !_vm.item.loading
    ? _c(
        "v-list-item",
        [
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                {
                  staticClass: "text-truncate "
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex"
                      }
                    },
                    [
                      _c("div", [
                        _vm.hasOpenDetailListener()
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function click($event) {
                                    $event.stopPropagation()
                                    return _vm.emitOpenDetail.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.item.companyName) + " ")]
                            )
                          : _c("span", [
                              _vm._v(" " + _vm._s(_vm.item.companyName) + " ")
                            ])
                      ])
                    ]
                  )
                ]
              ),
              !_vm.small
                ? _c(
                    "v-list-item-subtitle",
                    {
                      staticClass: "text-xs"
                    },
                    [
                      _vm.item.isFleet
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    "x-small": ""
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("objects.company.isFleet")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.item.services
                        ? _c(
                            "span",
                            _vm._l(_vm.item.services, function(service) {
                              return _c(
                                "v-chip",
                                {
                                  key: "service-enum" + service,
                                  staticClass: "mr-1",
                                  attrs: {
                                    "x-small": ""
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "enums.CompanyServiceEnum.".concat(
                                            service
                                          )
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        [
                          _vm.loadingProject
                            ? _c("v-skeleton-loader", {
                                attrs: {
                                  type: "chip"
                                }
                              })
                            : _vm.group
                            ? _c(
                                "v-chip",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    "x-small": ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      $event.stopPropagation()
                                      return _vm.goToCompanyGroupCustomView.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("company.group")) +
                                      ": " +
                                      _vm._s(_vm.group.title) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        "x-small": ""
                                      }
                                    },
                                    [_vm._v("mdi-open-in-new")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _c("v-skeleton-loader", {
        attrs: {
          type: "list-item-three-line"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }