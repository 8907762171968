





















































































import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { MessageFolderEnum } from "@/lib/enum/message-folder.enum";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IPartnerMessage, messageFolderIconMap } from "@/models/partner-message.entity";
import { MrfiktivInboxDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { AllMessagesModule } from "@/store/modules/all-messages.store";
import { InboxModule, IPartnerMessageStore } from "@/store/modules/inbox-message.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import TemplateDialog from "../template/TemplateDialog.vue";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import Tooltip from "../utility/tooltip.vue";
import PaginatedTable from "../utility/v2/PaginatedTable.vue";
import PartnerMessageListPaginated from "./PartnerMessageListPaginated.vue";
import PartnerMessageDetail from "./PartnerMessageDetail.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";

@Component({
  components: {
    TemplateDialog,
    TheLayoutPortalSplit,
    PaginatedTable,
    LatestEntriesCardEmpty,
    PartnerMessageListPaginated,
    PartnerMessageDetail,
    Tooltip
  }
})
export default class PartnerMessageInbox extends mixins(PermissionMixin, PartnerFallbackMixin) {
  message: IPartnerMessage | null = null;

  isDetailLoading = false;

  get messageFolderIconMap() {
    return messageFolderIconMap;
  }

  get MessageFolderEnum() {
    return MessageFolderEnum;
  }

  get folder() {
    const folder = this.inbox?.module.filters.find(filter => filter.key === "folder")?.value;

    if (folder) {
      new GoToHelper(this.$router).changeQuery(this.$route, { folder: folder });
      return folder;
    }

    this.setNewFolder(MessageFolderEnum.INBOX);
    new GoToHelper(this.$router).changeQuery(this.$route, { folder: MessageFolderEnum.INBOX });
    return MessageFolderEnum.INBOX;
  }

  get partner() {
    return PartnerModule.partner;
  }

  isNewFolderLoading = false;

  get folderOptions() {
    return Object.values(MessageFolderEnum);
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: "",
      value: "identifier"
    });

    return headers;
  }

  get inbox():
    | (MrfiktivInboxDtoGen & {
        module: IPartnerMessageStore;
      })
    | undefined {
    return (
      InboxModule.inboxes.find(inbox => inbox.identifier === this.$route.params.inboxId) ?? {
        identifier: "",
        isActive: true,
        module: AllMessagesModule,
        name: "all",
        source: "email"
      }
    );
  }

  async mounted() {
    if (!PartnerModule.partner || PartnerModule.partner.id !== this.$route.params.partnerId) {
      await this.trySetByRouteOrDefault();
    }

    this.inbox?.module.setFilters([]);
    const folder = this.$route.query.folder as string;
    if (folder) {
      this.setNewFolder(folder);
    } else {
      this.setNewFolder(MessageFolderEnum.INBOX);
    }
  }

  goToInbox() {
    this.$router.push({ name: "PartnerMessageInboxList", params: { partnerId: this.partner._id } });
  }

  async setMessage(item: IPartnerMessage) {
    this.message = null;
    this.isDetailLoading = true;
    try {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$router.push({
          name: "PartnerMessagesDetailView",
          params: { partnerId: this.partnerId, messageId: item.id }
        });
      } else {
        await item.fetch();
        this.$nextTick(() => {
          this.message = item;
        });
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isDetailLoading = false;
    }
  }

  applyFolderFilter(folder: string) {
    const module = this.inbox?.module;
    if (module) {
      const filters = module.filters || [];
      const filtersWithoutFolder = filters.filter(filter => filter.key !== "folder");
      module.setFilters([
        ...filtersWithoutFolder,
        new PageFilterElement({
          key: "folder",
          operation: PageFilterOperationEnum.EQUAL,
          value: folder
        })
      ]);
    }
  }

  async setNewFolder(folder: string) {
    this.message = null;
    try {
      this.isNewFolderLoading = true;

      const module = this.inbox?.module;
      if (module) {
        this.applyFolderFilter(folder);
        await module.fetchFirstPage({ partnerId: this.partnerId });
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isNewFolderLoading = false;
    }
  }
}
