var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.internalPdf
    ? _c("pdf-viewer", {
        attrs: {
          pdf: _vm.internalPdf
        },
        on: {
          onImageExport: _vm.onImageExport
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }