var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list-item",
    {
      attrs: {
        "three-line": ""
      }
    },
    [
      _c(
        "v-list-item-content",
        [
          !_vm.small
            ? _c(
                "v-list-item-subtitle",
                [
                  _vm._v(" " + _vm._s(_vm.date) + " "),
                  _vm.item.isExpired
                    ? _c(
                        "v-chip",
                        {
                          attrs: {
                            color: "error",
                            "x-small": ""
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common.states.expired")) + " "
                          )
                        ]
                      )
                    : _c(
                        "v-chip",
                        {
                          attrs: {
                            color: "success",
                            "x-small": ""
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common.states.active")) + " "
                          )
                        ]
                      )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item-title",
            {
              staticClass: "text-truncate "
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex"
                  }
                },
                [
                  _c("div", [
                    _vm.hasOpenDetailListener()
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function click($event) {
                                $event.stopPropagation()
                                return _vm.emitOpenDetail.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.item.title) + " ")]
                        )
                      : _c("span", [_vm._v(" " + _vm._s(_vm.item.title) + " ")])
                  ]),
                  _c("v-spacer")
                ],
                1
              )
            ]
          ),
          _vm.item.description
            ? _c(
                "v-list-item-subtitle",
                {
                  staticClass: "text-truncate text-xs"
                },
                [_vm._v(" " + _vm._s(_vm.item.description) + " ")]
              )
            : _vm._e(),
          !_vm.small
            ? _c(
                "v-list-item-subtitle",
                {
                  staticClass: "text-xs text-truncate"
                },
                [
                  !_vm.small && _vm.item.validBy
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "timeLine.ActivityTimeLineItemShare.validBy",
                                {
                                  date: _vm.simpleDate(_vm.item.validBy)
                                }
                              )
                            ) +
                            " "
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _c("v-list-item-subtitle", [
            _c(
              "span",
              _vm._l(_vm.item.screenOrder, function(screen) {
                return _c(
                  "v-chip",
                  {
                    key: screen,
                    staticClass: "mr-1",
                    attrs: {
                      small: ""
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("screenEnum.".concat(screen, ".title"))) +
                        " "
                    )
                  ]
                )
              }),
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }