






























































import { MenuActionOptionEnum } from "@/lib/enum/templateEnums/menuActionOptionEnum.enum";
import { MenuStyleOptionEnum } from "@/lib/enum/templateEnums/menuStyleOptionEnum";
import { ConfigModule } from "@/store/modules/config";
import BulletList from "@tiptap/extension-bullet-list";
import Highlight from "@tiptap/extension-highlight";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import OrderedList from "@tiptap/extension-ordered-list";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-2";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import TemplateEditorActions, {
  IActionSpecialItem,
  ITemplateActionConfig,
  ITemplateEditorConfig,
  TemplateActionSpecialItemType
} from "./TemplateEditorActions.vue";

@Component({
  components: { EditorContent, Card, TemplateEditorActions }
})
export default class TemplateEditor extends Vue {
  @Prop({ default: "" })
  value?: string;

  @Prop()
  partnerId?: string;

  @Prop({ default: "250px" })
  height!: string;

  @Prop({ default: false })
  readOnly!: boolean;

  @Prop({ default: true })
  outlined!: boolean;

  @Prop()
  label?: string;

  @Prop()
  hint?: string;

  @Prop()
  persistentHint?: string;

  @Prop()
  editConfiguration?: TemplateEditConfiguration;

  @Prop()
  isMobile?: boolean;

  @Prop()
  isPlain?: boolean;

  @Prop({ default: false })
  hideCounter?: boolean;

  get dynamicLabelClasses() {
    return this.getDynamicStyleClasses("Label");
  }

  get dynamicTipTapClasses() {
    const classes: string = this.getDynamicStyleClasses("TipTap");

    return classes;
  }

  getDynamicStyleClasses(suffix: string) {
    const classes: string[] = [];

    if (this.outlined) {
      classes.push("border" + suffix);
      const prefix = ConfigModule.darkMode ? "dark" : "light";
      const name = this.isFocused ? "Focused" : this.isMouseOver ? "Hover" : "Outline";
      classes.push(`${prefix}${name}${suffix}`);
    } else {
      classes.push("noBorder" + suffix);
    }

    return classes.join(" ");
  }

  get templateLocal() {
    return this.value;
  }
  set templateLocal(t) {
    this.$emit("input", t);
  }

  get bodyLength() {
    return this.templateLocal ? this.templateLocal.length : 0;
  }

  get MenuStyleOptionEnum() {
    return MenuStyleOptionEnum;
  }

  get MenuActionOptionEnum() {
    return MenuActionOptionEnum;
  }

  get bodyCounterStyle() {
    const color = this.templateLocal?.length || 0 > 50000 ? "orange" : "green";

    return `color: ${color}`;
  }

  get editConfig() {
    const editConfiguration = this.editConfiguration || new TemplateEditConfiguration(this.partnerId);

    return editConfiguration.getConfig(this.isMobile ?? this.$vuetify.breakpoint.smAndDown);
  }

  showCode = false;

  editor: null | Editor = null;

  handleEdit(editConfig?: ITemplateActionConfig) {
    if (editConfig?.content) {
      this.executeAction(editConfig?.content, editConfig?.param);
    }
  }

  executeAction(menuOption: MenuStyleOptionEnum | MenuActionOptionEnum, param?: object) {
    if (!this.editor) {
      return;
    }

    const action: string = menuOption;
    if (menuOption === MenuStyleOptionEnum.SetImage) {
      (this.$refs.editorActions as TemplateEditorActions).startImageUpload(url => {
        this.editor
          ?.chain()
          .focus()
          [action]({ src: url })
          .run();
      });
      return;
    }
    if (menuOption === MenuStyleOptionEnum.SetLink) {
      (this.$refs.editorActions as TemplateEditorActions).startUrlUpload(url => {
        this.editor
          ?.chain()
          .focus()
          [action]({ href: url })
          .run();
      });
      return;
    }

    this.editor
      .chain()
      .focus()
      [menuOption](param as any)
      .run();
  }

  mounted() {
    // this.randomEditorUUId = uniqueId();

    this.editor = new Editor({
      content: this.templateLocal,
      extensions: [
        StarterKit,
        Highlight.configure({ multicolor: true }),
        Image,
        Link,
        OrderedList,
        BulletList,
        Underline
      ],
      onUpdate: this.onUpdate,
      editable: !this.readOnly,
      onFocus: () => this.setFocused(true),
      onBlur: () => this.setFocused(false)
    });

    const editor = this.$refs.editor as Vue;
    editor?.$el?.addEventListener("mouseenter", () => this.setMouseOver(true));
    editor?.$el?.addEventListener("mouseleave", () => this.setMouseOver(false));
  }
  setMouseOver(isMouseOver: boolean) {
    this.isMouseOver = isMouseOver;
  }
  isMouseOver = false;

  setFocused(isFocused: boolean) {
    this.isFocused = isFocused;
  }
  isFocused = false;

  /**
   * Can be used to lock or unlock the editor
   * @param isEditable
   */
  @Watch("isEditable")
  updateLock(isEditable: boolean) {
    this.editor?.setEditable(isEditable);
  }

  beforeDestroy() {
    const editor = this.$refs.editor as Vue;
    editor?.$el?.removeEventListener("mouseenter", () => this.setMouseOver(true));
    editor?.$el?.removeEventListener("mouseleave", () => this.setMouseOver(false));

    this.editor?.destroy();
  }

  onUpdate(arg: any) {
    if (!arg.editor) {
      return;
    }

    this.templateLocal = arg.editor.getHTML();
  }

  addPlaceholderToBody(placeholder: string) {
    if (!this.editor) {
      return;
    }

    this.editor.commands.insertContent(placeholder, {
      parseOptions: { preserveWhitespace: true }
    });
  }

  clear() {
    this.templateLocal = "";
    this.editor?.commands.clearContent();
  }
}

export class TemplateEditConfiguration {
  constructor(private readonly partnerId?: string, private readonly isPlaceholderOption = false) {}
  private readonly colors = [
    "#212529",
    "#4283FF",
    "#3BC8C1",
    "#63B2A9",
    "#8BD6B8",
    "#AAEB94",
    "#ff5661",
    "#FFCA0D",
    "#F0F0F0"
  ];
  private readonly placeholder: IActionSpecialItem = { item: TemplateActionSpecialItemType.ADD_PLACEHOLDER };

  private readonly actions: ITemplateEditorConfig[] = [
    {
      content: MenuActionOptionEnum.Undo,
      display: {
        icon: "mdi-undo"
      }
    },
    {
      content: MenuActionOptionEnum.Redo,
      display: {
        icon: "mdi-redo"
      }
    }
  ];

  private readonly fontStyleOptions: ITemplateEditorConfig[] = [
    {
      content: MenuStyleOptionEnum.Bold,
      display: {
        icon: "mdi-format-bold"
      }
    },
    {
      content: MenuStyleOptionEnum.Italic,
      display: {
        icon: "mdi-format-italic"
      }
    },
    {
      content: MenuStyleOptionEnum.Strike,
      display: {
        icon: "mdi-format-strikethrough"
      }
    },
    {
      content: MenuStyleOptionEnum.Underline,
      display: {
        icon: "mdi-format-underline"
      }
    },
    {
      content: MenuStyleOptionEnum.SetLink,
      display: {
        icon: "mdi-link-variant"
      }
    }
  ];

  private readonly listOptions: ITemplateEditorConfig[] = [
    {
      content: MenuStyleOptionEnum.BulletList,
      display: {
        icon: "mdi-format-list-bulleted"
      }
    },
    {
      content: MenuStyleOptionEnum.OrderedList,
      display: {
        icon: "mdi-format-list-numbered"
      }
    }
    // {
    //   content: MenuStyleOptionEnum.TaskList,
    //   display: {
    //     icon: "mdi-format-list-checks"
    //   }
    // }
  ];

  private readonly headingOptions: ITemplateEditorConfig[] = [
    {
      sublist: [
        {
          content: MenuStyleOptionEnum.Heading,
          param: { level: 1 },
          display: {
            icon: "mdi-format-header-1"
          }
        },
        {
          content: MenuStyleOptionEnum.Heading,
          param: { level: 2 },
          display: {
            icon: "mdi-format-header-2"
          }
        },
        {
          content: MenuStyleOptionEnum.Heading,
          param: { level: 3 },
          display: {
            icon: "mdi-format-header-3"
          }
        },
        {
          content: MenuStyleOptionEnum.Heading,
          param: { level: 4 },
          display: {
            icon: "mdi-format-header-4"
          }
        },
        {
          content: MenuStyleOptionEnum.Heading,
          param: { level: 5 },
          display: {
            icon: "mdi-format-header-5"
          }
        },
        {
          content: MenuStyleOptionEnum.Heading,
          param: { level: 6 },
          display: {
            icon: "mdi-format-header-6"
          }
        }
      ],
      display: {
        icon: "mdi-format-header-pound"
      }
    }
  ];

  private insertionOptions: ITemplateEditorConfig = ((partnerId?: string) => {
    const image = {
      content: MenuStyleOptionEnum.SetImage,
      display: {
        icon: "mdi-image-plus"
      }
    };

    const placeholder = this.placeholder;

    const insertionOptions: ITemplateEditorConfig = {
      sublist: [],
      display: {
        icon: "mdi-plus-thick"
      }
    };

    if (partnerId) {
      insertionOptions.sublist.push(image);
    }

    if (this.isPlaceholderOption) {
      insertionOptions.sublist.push(placeholder);
    }

    return insertionOptions;
  })(this.partnerId);

  private readonly highLightOptions: ITemplateEditorConfig = (() => {
    const highlightColor: ITemplateEditorConfig = {
      sublist: [],
      display: {
        icon: "mdi-marker"
      }
    };
    for (const color of this.colors) {
      highlightColor?.sublist?.push({
        content: MenuStyleOptionEnum.ToggleHighlight,
        display: {
          color
        },
        param: { color }
      });
    }
    highlightColor?.sublist?.push({
      content: MenuStyleOptionEnum.UnsetHighlight,
      display: {
        icon: "mdi-format-color-marker-cancel"
      }
    });

    return highlightColor;
  })();

  getConfig(isMobile?: boolean): ITemplateEditorConfig[] {
    if (isMobile) {
      return [
        ...this.actions,
        {
          sublist: [...this.fontStyleOptions],
          display: {
            icon: "mdi-format-text"
          }
        },
        {
          sublist: [...this.headingOptions],
          display: {
            icon: "mdi-format-line-style"
          }
        },
        {
          sublist: [this.highLightOptions],
          display: {
            icon: "mdi-invert-colors"
          }
        },
        {
          sublist: [this.insertionOptions, ...this.listOptions],
          display: {
            icon: "mdi-format-float-right"
          }
        }
      ];
    } else {
      return [
        ...this.actions,
        { item: TemplateActionSpecialItemType.LINE },
        ...this.headingOptions,
        ...this.fontStyleOptions,
        this.highLightOptions,
        { item: TemplateActionSpecialItemType.LINE },
        ...this.listOptions,
        { item: TemplateActionSpecialItemType.LINE },
        this.insertionOptions
      ];
    }
  }
}
