import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { IPaginationParams } from "@/lib/utility/data/pagination-params.interface";
import { AdminTemplate, IAdminTemplate } from "@/models/admin-template.entity";
import store from "@/store/VuexPlugin";
import { getModule, Module } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { AdminTemplateDataAccessLayer } from "./access-layers/admin-template.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { AdminTemplatePageDataProvider } from "./page-data-provider/admin-template.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "admin-template",
  store
})
export class AdminTemplateStore extends PaginatedBaseStore<IAdminTemplate, IPaginationParams> {
  protected _data = AdminTemplateDataAccessLayer;
  protected _pageProvider = AdminTemplatePageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = AdminTemplate.filterables;
}

export const AdminTemplateModule = getModule(AdminTemplateStore);
