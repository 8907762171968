var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm._attachments && _vm._attachments.length
    ? _c(
        "div",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.checkOverflow,
              expression: "checkOverflow"
            }
          ]
        },
        [
          _vm._attachments.length > 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex"
                  }
                },
                [
                  _vm.isSelecting
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                "x-small": "",
                                elevation: 0
                              },
                              on: {
                                click: _vm.abortReportAttachment
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("abort")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                "x-small": "",
                                elevation: 0,
                                color: "info",
                                loading: _vm.isDownloading,
                                disabled: !_vm.selectedAttachments.length
                              },
                              on: {
                                click: _vm.downloadSelected
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common.verbs.download")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "x-small": "",
                                elevation: 0
                              },
                              on: {
                                click: _vm.startSelecting
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common.verbs.select")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              ref: "container",
              staticClass: "attachments-container mx-n1",
              class: _vm.isExpanded ? "expand" : "no-expand"
            },
            _vm._l(_vm._attachments, function(attachment, index) {
              return _c(
                "div",
                {
                  key: "attachments" + index,
                  style: _vm.$vuetify.breakpoint.mdAndUp ? "" : "width: 100%"
                },
                [
                  _c("partner-message-detail-attachment-card", {
                    style: _vm.$vuetify.breakpoint.mdAndUp
                      ? "width: 278px"
                      : "width: 98%",
                    attrs: {
                      value: attachment,
                      message: _vm.value
                    },
                    on: {
                      onCostCreated: function onCostCreated($event) {
                        return _vm.$emit("onCostCreated", $event)
                      },
                      onTicketCreated: function onTicketCreated($event) {
                        return _vm.$emit("onTicketCreated", $event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.isSelecting
                          ? {
                              key: "item-action",
                              fn: function fn() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          value: index
                                        },
                                        on: {
                                          click: function click($event) {
                                            $event.stopPropagation()
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedAttachments,
                                          callback: function callback($$v) {
                                            _vm.selectedAttachments = $$v
                                          },
                                          expression: "selectedAttachments"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex"
              }
            },
            [
              _c("v-spacer"),
              _vm.isOverflowing && !_vm.isSelecting
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        "x-small": "",
                        text: ""
                      },
                      on: {
                        click: _vm.toggleExpand
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isExpanded
                              ? _vm.$t("showLess")
                              : _vm.$t("showMore")
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }