var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.UPDATE,
            a: _vm.ResourceEnum.TEMPLATE
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                loading: _vm.loading
              },
              on: {
                click: _vm.initialize
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("components.template.detail.meta.update.title")
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialog,
            fullscreen: true,
            title: _vm.$t("components.template.detail.meta.update.title"),
            supressKeyboardActions: true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            leftClick: function leftClick($event) {
              _vm.dialog = false
            },
            rightClick: _vm.update
          }
        },
        [
          _c("template-detail-meta-fields", {
            staticClass: "ma-2",
            attrs: {
              partnerId: _vm.partnerId,
              meta: _vm.updateTemplateMeta,
              isPublic: _vm.updateIsPublic
            },
            on: {
              "update:meta": function updateMeta($event) {
                _vm.updateTemplateMeta = $event
              },
              "update:isPublic": function updateIsPublic($event) {
                _vm.updateIsPublic = $event
              },
              "update:is-public": function updateIsPublic($event) {
                _vm.updateIsPublic = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }