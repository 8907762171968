

















































import { TemplateTokenConfig } from "@/lib/configuration/template-token.configuration";
import { TokenCategoriesEnum } from "@/lib/enum/templateEnums/tokenCategories.enum";
import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivPartnerTemplateViewModelGen
} from "../../services/mrfiktiv/v1/data-contracts";
import { AdminTemplateModule } from "../../store/modules/admin-template.store";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TableWrapper, { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import Tooltip from "../utility/tooltip.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { MessageContentFormatEnum } from "@/lib/enum/templateEnums/messageContentFormat.enum";
import TemplateTable from "./TemplateTable.vue";
import { ITemplate } from "@/models/template.entity";
import { PartnerTemplateGoToHelper } from "@/lib/utility/partner-template.go-to-helper";
import { AdminTemplateGoToHelper } from "@/lib/utility/admin-template.go-to-helper";

@Component({
  components: { ConfirmActionDialog, Card, TableWrapper, Tooltip, TemplateTable },
  filters: {
    getFlagEmojiByLanguage
  }
})
export default class TemplateSelectionDialog extends Vue {
  @Prop()
  partnerId?: string;

  @Prop()
  context!: ITemplateContext;

  @Prop()
  isPlain?: boolean;

  @Prop({ default: "success" })
  color!: string;

  isDialogActive = false;

  selectedItems: ITemplate[] = [];

  loading = false;

  get filters() {
    if (!this.isPlain) {
      return [];
    }

    const filters: IPageFilterElement[] = [
      new PageFilterElement({
        key: "meta.contentFormat",
        operation: PageFilterOperationEnum.EQUAL,
        value: MessageContentFormatEnum.PLAIN
      })
    ];

    if (this.partnerId) {
      filters.push(
        new PageFilterElement({
          key: "isAdminTemplate",
          operation: PageFilterOperationEnum.EQUAL,
          value: false as any
        })
      );
    }

    return filters;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [
      { text: String(this.$t("components.template.dialog.selection.tempalteTitle")), value: "meta.title" },
      { text: String(this.$t("language")), value: "meta.language" },
      {
        text: String(this.$t("components.template.dialog.selection.description")),
        value: "meta.description",
        sortable: false
      },
      {
        text: String(this.$t("components.template.dialog.selection.categories")),
        value: "meta.categories",
        sortable: false
      },
      {
        text: String(this.$t("components.template.dialog.selection.context")),
        value: "context",
        sortable: false
      }
    ];

    if (this.partnerId) {
      headers.push({
        text: String(this.$t("components.template.dialog.selection.isAdminTemplate")),
        value: "isAdminTemplate",
        align: "center",
        width: "30px",
        sortable: false
      });
    }

    headers.push({
      text: "",
      value: "timestamp.created",
      type: "date",
      width: "40px",
      align: "end"
    });

    return headers;
  }

  get templates(): MrfiktivPartnerTemplateViewModelGen[] | MrfiktivAdminTemplateViewModelGen[] {
    let templates: MrfiktivPartnerTemplateViewModelGen[] | MrfiktivAdminTemplateViewModelGen[] = [];
    if (this.partnerId) {
      templates = PartnerTemplateModule.filteredAndSorted;
    } else {
      templates = AdminTemplateModule.filteredAndSorted;
    }

    if (!templates) {
      return [];
    }

    return templates;
  }

  icon(item: any) {
    return item.isAdminTemplate ? "mdi-check" : "mdi-close";
  }

  getDescription(item: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    const text = item.meta.description || item.content.subject || item.content.body || "";
    if (text.length > 200) {
      return text.substring(0, 200) + "...";
    }

    return text;
  }

  getTemplateContext(template: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    const available: string[] = [];
    const missing: string[] = [];
    const tokens = template.content?.tokens;

    if (!tokens) {
      return { available, missing };
    }

    const tokenCategories = tokens
      .map(token => token.split(".")[0])
      .filter((cat: string) => TemplateTokenConfig.objectCategories.includes(cat as TokenCategoriesEnum));

    tokenCategories.forEach(category => {
      if (!this.context[category]) {
        missing.push(category);
      } else {
        available.push(category);
      }
    });

    return { available: [...new Set(available)], missing: [...new Set(missing)] };
  }

  goToTemplates() {
    if (this.partnerId) {
      new PartnerTemplateGoToHelper(this.$router).goToPartnerTemplateTable({ partnerId: this.partnerId });
    } else {
      new AdminTemplateGoToHelper(this.$router).goToAdminTemplateTable({});
    }
  }
}
