var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list",
    {
      attrs: {
        "three-line": ""
      }
    },
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-content",
            [
              _vm.price.title
                ? _c("v-list-item-title", {
                    domProps: {
                      innerHTML: _vm._s(_vm.price.title)
                    }
                  })
                : _vm._e(),
              _vm.price.subtitle
                ? _c("v-list-item-subtitle", {
                    domProps: {
                      innerHTML: _vm._s(_vm.price.subtitle)
                    }
                  })
                : _vm._e(),
              _vm.price.description
                ? _c("v-list-item-subtitle", {
                    domProps: {
                      innerHTML: _vm._s(_vm.price.description)
                    }
                  })
                : _vm._e(),
              _vm.price.hint
                ? _c("v-list-item-subtitle", {
                    domProps: {
                      innerHTML: _vm._s(_vm.price.hint)
                    }
                  })
                : _vm._e(),
              _vm.showPrice
                ? _c("v-list-item-subtitle", [
                    _c("b", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.price.prefix) +
                              " " +
                              _vm.price.totalAmountAsString
                          )
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-list-item-action",
            [
              _c("v-radio", {
                attrs: {
                  value: _vm.product.years
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }