var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.currentPage
    ? _c(
        "div",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.debounceOnResize,
              expression: "debounceOnResize"
            }
          ],
          staticStyle: {
            height: "100%"
          }
        },
        [
          _vm.isMobile
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: {
                        "background-color": "#f5f5f5"
                      },
                      style: "height: ".concat(
                        _vm.getBodyHeight(_vm.pages.length)
                      ),
                      attrs: {
                        "no-gutters": ""
                      }
                    },
                    [
                      _c("image-position-selector", {
                        key: "selector" + _vm.currentPageNumber,
                        ref: "selector",
                        attrs: {
                          editValues: _vm.editValues,
                          src: _vm.currentPage.src,
                          scaleX: _vm.currentPage.scaleX,
                          scaleY: _vm.currentPage.scaleY,
                          isSigning: _vm.isSigning,
                          hideTokenFields: _vm.hideTokenFields,
                          documentTokens: _vm.currentDocumentToken,
                          currentPageNumber: _vm.currentPageNumber,
                          supressKeyboardActions: _vm.supressKeyboardActions,
                          page: _vm.pages[_vm.currentPageNumber]
                        },
                        on: {
                          previousPage: _vm.previousPage,
                          nextPage: _vm.nextPage,
                          save: _vm.save,
                          onSelect: function onSelect($event) {
                            return _vm.$emit("onSelect", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticStyle: {
                        "background-color": "#f5f5f5"
                      },
                      style: "height: 100px",
                      attrs: {
                        "no-gutters": ""
                      }
                    },
                    [
                      _c("document-template-detail-page-list-mobile", {
                        attrs: {
                          outlined: _vm.outlined,
                          pages: _vm.pages,
                          currentPageNumber: _vm.currentPageNumber
                        },
                        on: {
                          setCurrentPage: _vm.setCurrentPage
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-row",
                {
                  staticStyle: {
                    "background-color": "#f5f5f5",
                    height: "100%"
                  },
                  style:
                    _vm.sideBarElement ===
                    _vm.DocumentDetailSideBarElements.CLOSED
                      ? "100%"
                      : "width: calc(100% ".concat(
                          _vm.fullwidth ? "" : "- 400px",
                          ")"
                        ),
                  attrs: {
                    "no-gutters": ""
                  }
                },
                [
                  _vm.pages.length > 1
                    ? _c(
                        "v-col",
                        {
                          staticClass: "colContainer",
                          staticStyle: {
                            height: "100%"
                          },
                          attrs: {
                            cols: "1"
                          }
                        },
                        [
                          _c("document-template-detail-page-list", {
                            staticStyle: {
                              height: "100%"
                            },
                            attrs: {
                              outlined: _vm.outlined,
                              pages: _vm.pages,
                              currentPageNumber: _vm.currentPageNumber
                            },
                            on: {
                              setCurrentPage: _vm.setCurrentPage
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "colContainer",
                      staticStyle: {
                        height: "100%",
                        overflow: "hidden"
                      },
                      attrs: {
                        cols: _vm.pages.length > 1 ? 11 : 12
                      }
                    },
                    [
                      _c("image-position-selector", {
                        key: "selector" + _vm.currentPageNumber,
                        ref: "selector",
                        attrs: {
                          editValues: _vm.editValues,
                          isSigning: _vm.isSigning,
                          hideTokenFields: _vm.hideTokenFields,
                          src: _vm.currentPage.src,
                          scaleX: _vm.currentPage.scaleX,
                          scaleY: _vm.currentPage.scaleY,
                          documentTokens: _vm.currentDocumentToken,
                          page: _vm.pages[_vm.currentPageNumber],
                          currentPageNumber: _vm.currentPageNumber
                        },
                        on: {
                          previousPage: _vm.previousPage,
                          nextPage: _vm.nextPage,
                          save: _vm.save,
                          onSelect: function onSelect($event) {
                            return _vm.$emit("onSelect", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    : _c("v-skeleton-loader", {
        attrs: {
          type: "image"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }