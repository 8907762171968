













































import { requiredRule } from "@/lib/rules/requiredRule";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { IRefSuggestion } from "@/store/modules/fleet-aggregation.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import RefsSelected from "./RefsSelected.vue";
import RefsSelectionByType from "./RefsSelectionByType.vue";
import { IReference } from "@/models/reference.entity";

/**
 * Component that can handle the selection of entities for a given refType
 * Note: The ref type MUST BE registered in the RefTypeMap (@see RefTypeMap @ refs.store.ts)
 */
@Component({
  components: {
    RefsSelected,
    RefsSelectionByType
  },
  filters: {}
})
export default class SelectEntities<T> extends Vue {
  @Prop()
  value!: string[];

  /**
   * The kind of entity that is selectable
   */
  @Prop()
  refType!: BackendResourceEnum;

  /**
   * For pagination
   */
  @Prop()
  partnerId!: string;

  /**
   * Element ins suggested will be visually highlighted with AI logo
   */
  @Prop({ default: () => [] })
  suggested!: IRefSuggestion[];

  @Prop()
  label!: string;

  @Prop()
  required!: boolean;

  isMenu = false;

  get localValue() {
    return this.value;
  }

  set localValue(values: string[]) {
    this.value.splice(0, this.value.length, ...values);
    this.$emit("input", this.value);
  }

  get rules() {
    if (this.required) {
      return [requiredRule()];
    }
    return [];
  }

  onSelect(item: { id: string }) {
    const values = Array.from(new Set([...this.value, item.id]));
    this.localValue = values;
    this.isMenu = false;
  }

  onRemoved(item: IReference) {
    const valueSet = new Set([...this.value]);
    valueSet.delete(item.refId);
    const values = Array.from(valueSet);
    this.localValue = values;
  }
}
