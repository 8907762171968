import { AbstractPageDataProvider, PageDefaults } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { MrfiktivThirdPartySystemControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IThirdPartySystem, ThirdPartySystem } from "@/models/third-party-system.entity";
import thirdPartySystemIntegrationService from "@/services/mrfiktiv/services/third-party-system-integration.service";

export const ThirdPartySystemPageDataProvider = new (class extends AbstractPageDataProvider<
  IThirdPartySystem,
  MrfiktivThirdPartySystemControllerGetParamsGen
> {
  itemsPerPage = PageDefaults.DEFAULT_PAGE_SIZES[3];

  async getPage(query: MrfiktivThirdPartySystemControllerGetParamsGen): Promise<IPageViewModel<IThirdPartySystem>> {
    const res = await thirdPartySystemIntegrationService.get(query);

    const meta = res.meta;
    const data = (res.data?.map(d => new ThirdPartySystem(d)) ?? []) as IThirdPartySystem[];

    return { meta, data };
  }
})();
