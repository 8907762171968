



































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import TemplateEditor, { TemplateEditConfiguration } from "@/components/template/TemplateEditor.vue";
import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { MessageContentFormatEnum } from "@/lib/enum/templateEnums/messageContentFormat.enum";
import { TemplateEditorEnum } from "@/lib/enum/templateEnums/tempalteEditor.enum";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { getAutocompleteFilter } from "@/lib/utility/filter";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { IReference } from "@/models/reference.entity";
import { buildTemplate, ITemplate } from "@/models/template.entity";
import { TemplateMeta } from "@/models/template-meta.entity";
import { ConfigModule } from "@/store/modules/config";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import CustomFieldBoolean from "../report/CustomFieldBoolean.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TemplateEditorAddPlaceholder from "./TemplateEditorAddPlaceholder.vue";
import TemplateEditorSubject from "./TemplateEditorSubject.vue";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    CustomFieldListForm,
    LatestEntriesCardEmpty,
    Debug,
    CustomFieldBoolean,
    TemplateEditorSubject,
    TemplateEditor,
    TemplateEditorAddPlaceholder
  },
  filters: {
    simpleDoubleDigitDate,
    getFlagEmojiByLanguage
  }
})
export default class TemplateCreateDialog extends mixins(PartnerFallbackMixin, RulesMixin) implements IDialog {
  readonly getAutocompleteFilter = getAutocompleteFilter;

  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  @Prop({ default: false })
  fullscreen?: boolean;

  @Prop({ default: () => [] })
  refs!: IReference[];

  toggle_exclusive = "";

  isDialogActive = false;

  isLoading = false;

  isFormValid = false;

  template: ITemplate = buildTemplate({
    partnerId: this.partnerId,
    meta: new TemplateMeta({
      title: "",
      editor: TemplateEditorEnum.TIPTAP,
      contentFormat: MessageContentFormatEnum.HTML,
      categories: [],
      language: LanguageCodeEnum.DE
    })
  });

  isLoadingCustomFieldValues = false;

  isPlain = false;

  get editConfiguration() {
    return new TemplateEditConfiguration(this.partnerId, true);
  }

  get availableLanguages() {
    return ConfigModule.availableLanguages;
  }

  open() {
    this.template = buildTemplate({
      partnerId: this.partnerId,
      meta: new TemplateMeta({
        title: "",
        editor: TemplateEditorEnum.TIPTAP,
        contentFormat: MessageContentFormatEnum.HTML,
        categories: [],
        language: PartnerModule.partner.language || LanguageCodeEnum.DE
      })
    });
    this.isPlain =
      this.template.meta?.editor === TemplateEditorEnum.DEFAULT &&
      this.template.meta?.contentFormat === MessageContentFormatEnum.PLAIN;

    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.startField as HTMLInputElement)?.focus();
    });
  }

  close() {
    this.isDialogActive = false;
  }

  async onRightClick() {
    try {
      this.isLoading = true;
      this.isDialogActive = true;

      if (this.isPlain) {
        this.template.meta.contentFormat = MessageContentFormatEnum.PLAIN;
        this.template.meta.editor = TemplateEditorEnum.DEFAULT;
      } else {
        this.template.meta.contentFormat = MessageContentFormatEnum.HTML;
        this.template.meta.editor = TemplateEditorEnum.TIPTAP;
      }

      await this.template.create();
      this.$emit("created", this.template);
      this.close();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
