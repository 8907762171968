import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";
import { IBreadcrumb } from "../interfaces/utility/breadcrumb-interface";
import { $t } from "./t";

export enum PartnerTemplateRouteNames {
  PARTNER_TEMPLATE_TABLE = "PARTNER_TEMPLATE_TABLE",
  PARTNER_TEMPLATE_DETAIL = "PARTNER_TEMPLATE_DETAIL",
  PARTNER_TEMPLATE_DETAIL_FORM = "PARTNER_TEMPLATE_DETAIL_FORM"
}

type Tab = { newTab?: boolean };
type Partner = { partnerId: string };
type Template = { templateId: string };
type Query = { query?: Record<string, string> };

export class PartnerTemplateGoToHelper extends BaseGoToHelper {
  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static get breadCrumbs() {
    const locations = PartnerTemplateGoToHelper.locations;

    return {
      TemplateTable: (d: Query & Partner) => {
        return {
          text: $t("template.templates").toString(),
          exact: true,
          to: locations.templateTable(d)
        } as IBreadcrumb;
      },
      TemplateDetail: (d: Template & Query & Partner) => {
        return {
          text: $t("template.template").toString(),
          exact: true,
          to: locations.templateDetail(d)
        } as IBreadcrumb;
      },
      TemplateDetailForm: (d: Template & Query & Partner) => {
        return {
          text: $t("template.templateForm").toString(),
          exact: true,
          to: locations.templateDetailForm(d)
        } as IBreadcrumb;
      }
    };
  }

  static locations = {
    templateTable: (d: Partner & Query) => {
      return {
        name: PartnerTemplateRouteNames.PARTNER_TEMPLATE_TABLE,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    templateDetail: (d: Partner & Template & Query) => {
      return {
        name: PartnerTemplateRouteNames.PARTNER_TEMPLATE_DETAIL,
        params: { partnerId: d.partnerId, templateId: d.templateId },
        query: d.query
      };
    },
    templateDetailForm: (d: Partner & Template & Query) => {
      return {
        name: PartnerTemplateRouteNames.PARTNER_TEMPLATE_DETAIL_FORM,
        params: { partnerId: d.partnerId, templateId: d.templateId },
        query: d.query
      };
    }
  };

  goToPartnerTemplateTable(d: Tab & Partner) {
    this.go(PartnerTemplateGoToHelper.locations.templateTable(d), d.newTab);
  }
  goToPartnerTemplateDetail(d: Tab & Partner & Template) {
    this.go(PartnerTemplateGoToHelper.locations.templateDetail(d), d.newTab);
  }
  goToPartnerTemplateDetailForm(d: Tab & Partner & Template) {
    this.go(PartnerTemplateGoToHelper.locations.templateDetailForm(d), d.newTab);
  }
}
