var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.selectedService
    ? _c(
        "v-list",
        [
          _c(
            "v-subheader",
            {
              staticClass: "mb-n4"
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "bookingForm.BookingSelectedService.selectedServiceHeader"
                  )
                )
              )
            ]
          ),
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    [
                      _vm._v(" " + _vm._s(_vm.selectedService.name) + " "),
                      _vm.showBackButton
                        ? _c("booking-back-button", {
                            on: {
                              click: _vm.goToServiceOverview
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.selectedService.isDisplayDuration
                    ? _c("v-list-item-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("bookingForm.common.minutes", {
                                value: _vm.selectedService.duration / 60 / 1000
                              })
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }