import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { CompanyGroup, ICompanyGroup } from "@/models/company-group.entity";
import { MrfiktivCompanyGroupControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, Module, getModule } from "vuex-module-decorators";
import { ActionEnum } from "../enum/authActionEnum";
import { ResourceEnum } from "../enum/authResourceEnum";
import { PaginatedBaseStore } from "../paginated-base.store";
import { CompanyGroupDataAccessLayer } from "./access-layers/company-group.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { UserModule } from "./me-user.store";
import { CompanyGroupPageDataProvider } from "./page-data-provider/company-group.page-data-provider.ts";
import { PartnerModule } from "./partner";

@Module({
  dynamic: true,
  namespaced: true,
  name: "company-group",
  store
})
export class CompanyGroupPaginationStore extends PaginatedBaseStore<
  ICompanyGroup,
  MrfiktivCompanyGroupControllerGetParamsGen
> {
  protected _data = CompanyGroupDataAccessLayer;
  protected _pageProvider = CompanyGroupPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = CompanyGroup.filterables;

  @Action
  async reset(): Promise<void> {
    super.reset();
    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.COMPANY_GROUP, PartnerModule.partner.id)) {
      this.setFilters([]);
      await this.fetchFirstPage({ partnerId: PartnerModule.partner.id }).catch(e => Vue.$log.error(e));
    }
  }
}

export const CompanyGroupModule = getModule(CompanyGroupPaginationStore);
