















































































































































































































import GeoFinderField from "@/components/utility/GeoFinderField.vue";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { WeekDaysEnum } from "@/lib/enum/weekDays.enum";
import { companyUserNameRule } from "@/lib/rules/companyUserNameRule";
import { phoneRuleOptional } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { PartnerEntity } from "@/models/partnerEntity";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { GeoFinderField }
})
export default class PartnerCreateOrUpdateGeneralInfos extends Vue {
  @Prop({ default: true })
  showUsername!: boolean;
  @Prop()
  partnerDto!: PartnerEntity;

  partnerTypes = Object.keys(PartnerTypeEnum).map(k => PartnerTypeEnum[k as any]);
  countryCodes = Object.keys(CountryCodeEnum).map(k => CountryCodeEnum[k as any]);

  get rules() {
    return [requiredRule()];
  }

  get phoneRules() {
    const rules = [];

    rules.push(phoneRuleOptional());

    return rules;
  }

  get companyUserNameRules() {
    const rules = [];
    rules.push(requiredRule());
    rules.push(companyUserNameRule());

    return rules;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get WeekDays() {
    return WeekDaysEnum;
  }
}
