


































































































































































































import { RefTypeMap } from "@/store/modules/refs.store";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import debounce from "debounce";
import { Component, Prop, Vue } from "vue-property-decorator";
import VirtualList from "vue-virtual-scroll-list";
import draggable from "vuedraggable";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import RefsList from "@/components/utility/RefsList.vue";
import RefsSelectionSuggestion from "./RefsSelectionSuggestion.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { handleError } from "@/lib/utility/handleError";
import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { IRefSuggestion } from "@/store/modules/fleet-aggregation.store";
import AEicon from "@/components/utility/AEicon.vue";
import { PaginatedBaseStore } from "@/store/paginated-base.store";

/**
 * Component that can handle the selection of entities for a given refType
 * Note: The ref type MUST BE registered in the RefTypeMap (@see RefTypeMap @ refs.store.ts)
 */
@Component({
  components: {
    RefsList,
    draggable,
    VirtualList,
    Tooltip,
    RefsSelectionSuggestion,
    AEicon,
    LatestEntriesCardEmpty,
    FilterCardPagination
  },
  filters: {}
})
export default class RefsSelectionByType<T> extends Vue {
  /**
   * The kind of entity that is selectable
   */
  @Prop()
  refType!: BackendResourceEnum;

  /**
   * For pagination
   */
  @Prop()
  partnerId!: string;

  /**
   * Elements in selected list will not be displayed as selectable options
   */
  @Prop({ default: () => [] })
  selected!: MrfiktivReferenceGen[];

  /**
   * Element ins suggested will be visually highlighted with AI logo
   */
  @Prop({ default: () => [] })
  suggested!: IRefSuggestion[];

  @Prop({ default: undefined })
  predefinedFilter!: [{ name: string; filter: IPageFilterElement[] }];

  /**
   * The selection will be displayed in a menu that opens on search bar click
   */
  @Prop({ default: false })
  showAsMenu!: boolean;

  @Prop({ default: true })
  small!: boolean;

  @Prop({ default: "400px" })
  height!: string;

  readonly RefTypeMap = RefTypeMap;

  readonly BackendResourceEnum = BackendResourceEnum;

  isLoading = false;

  menu = false;

  debounceFetchFirstPage = debounce(this.fetchFirstPage, 500);

  debounceSetSearch = debounce(this.store.setSearch, 500);

  get isMenu() {
    if (!this.showAsMenu) {
      return false;
    }
    return this.menu;
  }

  set isMenu(value: boolean) {
    this.menu = value;
  }

  get refTypeMapEntry() {
    return RefTypeMap.get(this.refType);
  }

  get store(): PaginatedBaseStore<any, any> {
    const module = this.refTypeMapEntry?.module;

    if (!module || !(module as PaginatedBaseStore<any, any>).fetchFirstPage) {
      throw new Error(`No pagination module found for refType ${this.refType} @EntitySelection`);
    }

    return module as PaginatedBaseStore<any, any>;
  }

  get entities() {
    const entities = [...this.store.filteredAndSorted];

    entities.sort((a, b) => {
      const aSuggested = this.isSuggested(a.id);
      const bSuggested = this.isSuggested(b.id);
      if (aSuggested && !bSuggested) {
        return -1;
      }
      if (!aSuggested && bSuggested) {
        return 1;
      }
      return 0;
    });

    return entities.filter(e => !this.selected.some(s => s.refId === e.id));
  }

  get isLoadMorePossible() {
    if (this.refType === BackendResourceEnum.EVENT) {
      return false;
    }

    return this.store?.totalItems > this.store.filtered.length;
  }

  mounted() {
    this.init();
  }

  isSuggested(refId: string) {
    return this.suggested.some(s => s.id === refId);
  }

  async init() {
    this.store.setFilters([]);
    await this.fetchFirstPage({ partnerId: this.partnerId });
  }

  openMenu() {
    this.isMenu = true;
  }

  /**
   * Set the filter in the store without making backend call
   */
  setFilter(filter: IPageFilterElement[]) {
    this.$log.debug("setFilterAndFetch", filter);
    this.store.filters.splice(0, this.store.filters.length, ...filter);
  }

  async fetchFirstPage(baseQuery: any) {
    this.isLoading = true;
    await this.store.fetchFirstPage(baseQuery).catch(handleError);
    this.isLoading = false;
  }

  async fetchNextPage(baseQuery: any) {
    this.isLoading = true;
    await this.store.fetchNextPage(baseQuery).catch(handleError);
    this.isLoading = false;
  }

  async select(item: T) {
    if (this.refType === BackendResourceEnum.EVENT) {
      const event = (item as any) as IEventUIDto;
      if (event.isVirtual) {
        this.isLoading = true;
        await event.createVirtual().catch(handleError);
        this.isLoading = false;
        item = (event as any) as T;
      }
    }

    this.$emit("select", item);
    this.isMenu = false;
  }
}
