














































import { formatHoursAndMinutes, simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { VSkeletonLoader } from "vuetify/lib";
import Card from "../utility/Card.vue";
import CopyClipboardText from "../utility/CopyClipboardText.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import PartnerReportUpdateReportDialog, { ReportUpdateTabEnum } from "./PartnerReportUpdateReportDialog.vue";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { CompanyServiceEnum } from "@/lib/enum/company-service.enum";

const PartnerMap = () => ({
  component: import(/* webpackPrefetch: true  */ /* webpackChunkName: "map" */ "@/components/form/PartnerMap.vue"),
  loading: VSkeletonLoader
});

@Component({
  components: {
    TimelineCard,
    Card,
    PartnerMap,
    CopyClipboardText,
    PartnerReportUpdateReportDialog
  }
})
export default class PartnerAccidentDetailsTimeLineElement extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop()
  report!: MrfiktivReportViewModelGen;

  get overlay(): boolean {
    return !this.report.accidentDetails?.address?.geo.lat || !this.report.accidentDetails?.address?.geo.lng;
  }

  get ReportUpdateTabEnum() {
    return ReportUpdateTabEnum;
  }

  roleMap = new Map<string | undefined, string>([
    ["victim", "timeLine.PartnerAccidentDetailsTimeLineElement.victim"],
    ["responsible", "timeLine.PartnerAccidentDetailsTimeLineElement.responsible"],
    ["unclear", "timeLine.PartnerAccidentDetailsTimeLineElement.unclear"]
  ]);

  get coordinates() {
    return this.report.accidentDetails?.address?.geo || undefined;
  }

  get comment() {
    return this.report.accidentDetails?.comment || "";
  }

  get role() {
    return this.roleMap.get(this.report.accidentDetails?.role) || "";
  }

  get date() {
    if (this.report.accidentDetails?.date) {
      return simpleDate(this.report.accidentDetails?.date);
    }
    return "";
  }

  get time() {
    if (this.report.accidentDetails?.date) {
      return formatHoursAndMinutes(new Date(this.report.accidentDetails?.date));
    }
    return "";
  }

  get addressText() {
    let street = "";
    if (this.report.accidentDetails?.address?.street) {
      street = this.report.accidentDetails?.address?.street + ", ";
    }

    let city = "";
    if (this.report.accidentDetails?.address?.city) {
      city = this.report.accidentDetails?.address?.city;
    }

    let zip = "";
    if (this.report.accidentDetails?.address?.zip) {
      zip = this.report.accidentDetails?.address?.zip + " ";
    }

    return `${street}${zip}${city}`;
  }

  get locationText() {
    if (!this.addressText) {
      return this.$t("timeLine.PartnerAccidentDetailsTimeLineElement.noAddress");
    }
    return this.addressText;
  }

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  openMap() {
    if (this.addressText) {
      new CompanyGoToHelper(this.$router).goToCompanyMap({
        partnerId: this.partnerId,
        query: { s: this.addressText, f: CompanyServiceEnum.WORKSHOP }
      });
    } else if (this.coordinates) {
      new CompanyGoToHelper(this.$router).goToCompanyMap({
        partnerId: this.partnerId,
        query: { s: this.coordinates.lat + "," + this.coordinates.lng, f: CompanyServiceEnum.WORKSHOP }
      });
      return;
    }
  }
}
