var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        width: _vm.width,
        fullscreen: _vm.fullscreen || _vm.isMobile,
        persistent: _vm.persistent,
        transition: "dialog-bottom-transition",
        scrollable: "",
        "aria-labelledby": "title"
      },
      model: {
        value: _vm.dialogModel,
        callback: function callback($$v) {
          _vm.dialogModel = $$v
        },
        expression: "dialogModel"
      }
    },
    [
      _c(
        "card",
        {
          attrs: {
            loading: _vm.loading,
            outlined: _vm.outlined,
            margin: 0,
            title: _vm.titleLocal,
            subtitle: _vm.subtitle,
            showGradient: _vm.showGradient,
            height: _vm.height,
            hideDivider: _vm.showGradient,
            slotClass: _vm.cardClass,
            fullscreen: _vm.fullscreen || _vm.isMobile
          },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function fn() {
                  return [
                    _vm._t("actions"),
                    !_vm.hideClose || _vm.isMobile
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              icon: ""
                            },
                            on: {
                              click: _vm.close
                            }
                          },
                          [_c("v-icon", [_vm._v(" mdi-close ")])],
                          1
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              },
              {
                key: "card-actions",
                fn: function fn() {
                  return [
                    _vm._t("left"),
                    !_vm.hideLeft
                      ? _c(
                          "v-btn",
                          {
                            staticStyle: {
                              width: "35%"
                            },
                            attrs: {
                              disabled: _vm.leftDisabled,
                              loading: _vm.leftLoading,
                              color: _vm.leftColor,
                              elevation: 0,
                              "x-large": "",
                              "data-test-delete-file": ""
                            },
                            on: {
                              click: _vm.leftClick
                            }
                          },
                          [
                            _vm._t("leftText", function() {
                              return [
                                _vm._v(" " + _vm._s(_vm.leftTextLocal) + " ")
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("v-spacer"),
                    !_vm.hideRight
                      ? _c(
                          "v-btn",
                          {
                            staticStyle: {
                              width: "60%"
                            },
                            attrs: {
                              disabled: _vm.rightDisabled,
                              loading: _vm.rightLoading,
                              color: _vm.rightColor,
                              elevation: 0,
                              "x-large": ""
                            },
                            on: {
                              click: _vm.rightClick
                            }
                          },
                          [
                            _vm._t("rightText", function() {
                              return [_vm._v(_vm._s(_vm.rightTextLocal) + " ")]
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }