






















import Card from "@/components/utility/Card.vue";
import { ISignRequest } from "@/models/sign-request.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";

@Component({
  components: {
    Card
  }
})
export default class ReportDocumentSignRequestStepperMailSuccess extends mixins(ReportDocumentSignRequestStepperMixin)
  implements IStepper {
  @Prop()
  signRequest!: ISignRequest;

  previous() {
    this.currentStep = SignRequestStepperPageEnum.SUCCESS;
  }

  next() {
    this.$emit("close");
  }
}
