import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { MessageContentFormatEnum } from "@/lib/enum/templateEnums/messageContentFormat.enum";
import { TemplateEditorEnum } from "@/lib/enum/templateEnums/tempalteEditor.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { $t } from "@/lib/utility/t";
import { MrfiktivTemplateMetaViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";

@IsFilterable
class TemplateMetaBase implements MrfiktivTemplateMetaViewModelGen {
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.template.meta.title" })
  title: string;

  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.template.meta.description" })
  description?: string;

  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.template.meta.categories" })
  categories: string[];

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.template.meta.editor",
    config: {
      items: Object.values(TemplateEditorEnum).map(v => {
        return {
          text: `enums.TemplateEditorEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  editor: TemplateEditorEnum;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.template.meta.contentFormat",
    config: {
      items: Object.values(MessageContentFormatEnum).map(v => {
        return {
          text: `enums.MessageContentFormatEnum.${v}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  contentFormat: MessageContentFormatEnum;

  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.template.meta.language",
    config: {
      items: ConfigModule.availableLanguages.map(v => {
        return {
          text: `${getFlagEmojiByLanguage(v)} ${$t(`enums.LanguageCodeEnum.${v}`)}`,
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  language: LanguageCodeEnum;

  get updateDto() {
    return {
      title: this.title,
      description: this.description,
      categories: this.categories,
      editor: this.editor,
      contentFormat: this.contentFormat
    };
  }

  constructor(meta?: Partial<ITemplateMeta | MrfiktivTemplateMetaViewModelGen>) {
    this.title = meta?.title || "";
    this.description = meta?.description || "";
    this.categories = meta?.categories || [];
    this.editor = (meta?.editor || TemplateEditorEnum.DEFAULT) as TemplateEditorEnum;
    this.contentFormat = (meta?.contentFormat || MessageContentFormatEnum.PLAIN) as MessageContentFormatEnum;
    this.language = (meta?.language || LanguageCodeEnum.DE) as LanguageCodeEnum;
  }
}

type ITemplateMeta = TemplateMetaBase;
const TemplateMeta = Filter.createForClass(TemplateMetaBase);

export { ITemplateMeta, TemplateMeta };
