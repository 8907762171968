



































































import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IActivityLog } from "@/models/activity-log.entity";
import { PartnerMessage } from "@/models/partner-message.entity";
import { AllMessagesModule } from "@/store/modules/all-messages.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { IPartnerMessage } from "@/models/partner-message.entity";
import PartnerMessageSideCard from "@/components/partner/PartnerMessageSideCard.vue";
import PartnerMessageDetailAttachmentRow from "@/components/partner/PartnerMessageDetailAttachmentRow.vue";
import { ISignDocument } from "@/models/sign-document.entity";
import { IPartnerMessageAttachment } from "@/models/partner-message-attachment.entity";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { ActivityLogDataAccessLayer } from "@/store/modules/access-layers/activity-log-service.access-layer";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { PartnerUser } from "@/models/user.entity";

@Component({
  components: {
    TemplateEditor,
    TimelineCard,
    ActivityTimeLineItemDelete,
    ContextMenu,
    PartnerMessageSideCard,
    PartnerMessageDetailAttachmentRow,
    ConfirmActionDialog
  }
})
export default class ActivityTimeLineItemMessage extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: IActivityLog;

  message: IPartnerMessage | null = null;

  loading = false;

  isMessageSideCardOpen = false;

  attachmentsReferencingSource: IPartnerMessageAttachment[] = [];

  isDeleteDialogActive = false;

  isDeleteDialogLoading = false;

  get title() {
    return this.$t("timeLine.ActivityTimeLineEmailComment.titleReceiver", {
      name: this.name,
      date: this.date,
      receiver: this.message?.to || ""
    });
  }

  get name() {
    // show from-mail as name for incoming messages
    if (this.message?.virusVerdict || this.message?.spamVerdict) {
      return this.message.from;
    }

    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  async mounted() {
    if (!this.activityLogEntry.target || !this.activityLogEntry.target[0]) {
      this.$log.error("no message target");
      this.$toast.error("No message");

      return;
    }

    this.loading = true;

    const partnerId = PartnerModule.partner._id;
    const messageId = this.activityLogEntry.target[0].refId;

    try {
      if (!this.activityLogEntry.user?.id && this.activityLogEntry.userId) {
        let user = PartnerUserModule.maps?.id?.get(this.activityLogEntry.userId)[0];
        if (!user) {
          user = await new PartnerUser({ partnerId: partnerId, id: this.activityLogEntry.userId }).fetch();
        }
        this.activityLogEntry.user = user;
      }
    } catch (e) {
      this.$log.error(e);
    }

    try {
      this.message =
        AllMessagesModule.maps.id?.get(messageId)[0] ??
        (await new PartnerMessage({ partnerId: partnerId, id: messageId }).fetch());
    } catch (e) {
      handleError(e);
      this.message = null;
    } finally {
      this.loading = false;
    }
  }

  /**
   * Load attached documents that reference the source of the activity log entry.
   */
  async getAttachmentsReferencingSource(message: IPartnerMessage) {
    /**
     * get all referncing objects in the message
     */
    const attachments = (
      await Promise.all(
        this.message?.attachments
          /**
           * Only consider PDFs
           */
          ?.filter(a => a.isPdf)
          /**
           * Populate the reference of the attachment
           */
          .map(async a => {
            if (!a.populatedReference) await a.populateReference(message).catch(e => this.$log.error(e));
            if (a.populatedReference) return a;
          }) ?? []
      )
    )
      /**
       * Filter out attachments that do not reference the source of the activity log entry
       */
      .filter(a =>
        (a?.populatedReference as ISignDocument)?.refs?.find(r => r.refId === this.activityLogEntry.source.refId)
      ) as IPartnerMessageAttachment[];

    /**
     * set the attachments to the local state
     */
    this.attachmentsReferencingSource.splice(0);
    this.attachmentsReferencingSource.push(...attachments);
  }

  async removeSourceRefFromMessageAndRemoveActivity() {
    try {
      this.isDeleteDialogLoading = true;
      /**
       * Filter out the references of the message to the source of the activity log entry
       */
      const remainingRefs = this.message?.refs?.filter(r => r.refId !== this.activityLogEntry?.source?.refId) ?? [];
      if (this.message && remainingRefs.length !== this.message?.refs?.length) {
        await this.message.updatePartial({ refs: remainingRefs });
      }
      ActivityLogDataAccessLayer.delete(this.activityLogEntry);
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteDialogLoading = false;
      this.isDeleteDialogActive = false;
    }
  }
}
