var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: "Datenschutzerklärung"
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "padded"
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "h1",
                {
                  attrs: {
                    id: "datenschutzinformationen"
                  }
                },
                [_vm._v("Datenschutzinformationen")]
              ),
              _c(
                "h2",
                {
                  attrs: {
                    id: "geltungsbereich-und-verantwortlichkeit"
                  }
                },
                [_vm._v("Geltungsbereich und Verantwortlichkeit")]
              ),
              _c("p", [
                _vm._v(
                  " Die mint software GmbH , Marie-Curie-Str. 3, 49076 Osnabrück, E-Mail: info@mmmint.ai („"
                ),
                _c("strong", [_vm._v("Anbieter")]),
                _vm._v('", „'),
                _c("strong", [_vm._v("wir")]),
                _vm._v('" oder „'),
                _c("strong", [_vm._v("uns")]),
                _vm._v('" genannt) betriebt die Website '),
                _c("em", [_vm._v("schadensmeldung.digital")]),
                _vm._v(" („"),
                _c("strong", [_vm._v("Website")]),
                _vm._v("“) und hat eine Web-App mit dem Namen "),
                _c("strong", [_vm._v("schadensmeldung.digital")]),
                _vm._v(" entwickelt („"),
                _c("strong", [_vm._v("Plattform")]),
                _vm._v("”) (zusammen im folgenden "),
                _c("strong", [_vm._v("„digitale Angebote“")]),
                _vm._v(" genannt). ")
              ]),
              _c("p", [
                _vm._v(
                  " Diese Datenschutzinformationen gelten für die Verarbeitung personenbezogener Daten bei dem Besuch und der Nutzung unserer digitalen Angebote als registrierter Nutzer mit Nutzerkonto (siehe hierzu insbesondere Ziffer 3) ebenso wie für die Nutzung der digitalen Angebote als Gastnutzer. Die Datenschutzinformationen gelten nicht für Websiten oder Plattformen anderer Anbieter, auf die wir im Rahmen unserer digitalen Angebote verweisen. Bitte informiere dich auch bei dem jeweiligen Anbieter über den Umgang mit deinen Daten, falls du von unseren digitalen Angeboten auf andere Websiten oder Plattformen weitergeleitet wirst. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Personenbezogene Daten sind nach Art. 4 Nr. 1 DS-GVO alle Informationen, die sich auf eine identifizierte oder identifizierbare Person beziehen („"
                ),
                _c("strong", [_vm._v("betroffene Person")]),
                _vm._v(
                  "“). Hierunter fallen z. B. persönliche Angaben wie Name, Anschrift, E-Mail-Adresse und Telefonnummer, aber auch weitergehende Informationen, wie z. B. Nachrichten, die du uns schickst, dein Nutzerverhalten oder die Daten, die du im Rahmen der Nutzung eingibst. Die Erkärung für alle weiteren Begrifflichkeiten findest du in den Begriffsbestimmungen des Art. 4 DS-GVO. "
                )
              ]),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "Verantwortlicher im Sinne des Art. 4 Nr. 7 DS-GVO ist:"
                  )
                ])
              ]),
              _c("p", [
                _vm._v(
                  "mint software GmbH Marie-Curie-Str. 3 49076 Osnabrück info@mmmint.ai"
                )
              ]),
              _c("p", [
                _vm._v(
                  "Inhaltlich Verantwortlicher gem. § 18 Abs. 2 MStV: Maximilian Stein (Anschrift s.o.)"
                )
              ]),
              _c("p", [
                _vm._v(
                  " Wir möchten dich mit diesen Datenschutzhinweisen über unsere Verarbeitungsvorgänge informieren und zugleich den gesetzlichen Pflichten, insbesondere aus der EU-Datenschutz-Grundverordnung (DS-GVO), nachkommen. "
                )
              ]),
              _c(
                "h2",
                {
                  attrs: {
                    id: "welche-personenbezogenen-daten-erfassen-wir-"
                  }
                },
                [_vm._v("Welche personenbezogenen Daten erfassen wir?")]
              ),
              _c("p", [
                _vm._v(
                  " Bei der Benutzung unserer digitalen Angebote erheben wir verschiedene Kategorien personenbezogener Daten zu unterschiedlichen Zwecken. Wir möchten diese Datenverarbeitungsvorgänge im Folgenden so übersichtlich wie möglich für dich darstellen. "
                )
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "besuch-unserer-website-logfiles-"
                  }
                },
                [_vm._v("Besuch unserer Website (Logfiles)")]
              ),
              _c("p", [
                _vm._v(
                  " Wenn du unsere digitalen Angebote besuchst, erfassen wir automatisch jeden Zugriff in einer Protokolldatei, die dein Browser an uns übermittelt. Dabei werden folgende Daten von uns gespeichert („"
                ),
                _c("strong", [_vm._v("Logfiles")]),
                _vm._v("“): ")
              ]),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    " Datum, Uhrzeit, Ort und Land des Abrufs unserer Website [oder unserer Plattform] (bzw. der Serveranfrage) "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    "Betriebssystem, Browsertyp, Browserversion und Anonymisierte IP-Adresse des Endgeräts,"
                  )
                ]),
                _c("li", [_vm._v("Internet-Service-Provider,")]),
                _c("li", [
                  _vm._v("Website, die zuvor besucht wurde (Referrer URL),")
                ]),
                _c("li", [_vm._v("Übertragene Datenmenge.")])
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung dieser Informationen ist technisch notwendig, um unsere digitalen Angebote ordnungsgemäß anzuzeigen und die Stabilität und Sicherheit zu gewährleisten. Die Informationen werden ausschließlich zu den vorgenannten Zwecken verarbeitet. Eine weitere Auswertung der Nutzerdaten findet nicht statt. Die Daten werden gelöscht, sobald sie für die Erreichung des Zwecks ihrer Erhebung nicht mehr erforderlich sind. Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung dieser Daten erfolgt durch den Host unserer Website: Der Host agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag zur Auftragsdatenverarbeitung mit ihm geschlossen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  "Die Verarbeitung dieser Daten erfolgt durch die Hosts unserer Website:"
                )
              ]),
              _c("ol", [
                _c("li", [
                  _c("strong", [_vm._v("Fastly Inc")]),
                  _vm._v("., 475 Brannan St. 300, San Francisco, CA 94107,")
                ]),
                _c("li", [
                  _c("strong", [_vm._v("GitHub Inc.")]),
                  _vm._v(
                    ", 88 Colin P. Kelly Jr. St., San Francisco, CA 94107, USA und"
                  )
                ]),
                _c("li", [
                  _c("strong", [_vm._v("Microsoft Corporation")]),
                  _vm._v(",One Microsoft Way, Redmond, WA 98052-6399 (USA) („"),
                  _c("strong", [_vm._v("Microsoft")]),
                  _vm._v("“) (nachfolgend gemeinsam "),
                  _c("strong", [_vm._v("“Hosts”")]),
                  _vm._v(" genannt). ")
                ])
              ]),
              _c("p", [
                _vm._v(
                  " Die Hosts agieren als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag zur Auftragsdatenverarbeitung mit ihnen geschlossen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Rechtsgrundlage für den Einsatz unserer Hosts ist unser berechtigtes Interesse, an einem sicheren und funktionierenden Betrieb unserer Website, Art. 6 Abs. 1 S. 1 lit. f DS-GVO. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Teilweise werden unsere Hosts die auf unserer Website erfassten Daten (die nachfolgend dargestellt werden) in die USA übertragen und dort verarbeiten. Unsere Hosts haben sich als sichere Datenempfänger, die die Datenschutzgrundsätze des Data Privacy Framework berücksichtigen, zertifiziert. Bei einer Übertragung von Daten in die USA durch unsere Hosts wird also ein Datenschutzstandard gewahrt, der dem der DS-GVO entspricht. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Weitere Informationen zur Datenverarbeitung durch unseren Host Fastly Inc. findest du "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.fastly.com/de/privacy"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(", durch den Host GitHub Inc. "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://docs.github.com/de/pages/getting-started-with-github-pages/about-github-pages#data-collection"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(
                  " und für die Datenverarbeitung durch den Host Microsoft findest du weitere Informationen "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://azure.microsoft.com/de-de/explore/trusted-cloud/privacy"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(" und für den Dienst Microsoft Application Insights "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://learn.microsoft.com/de-de/azure/azure-monitor/app/app-insights-overview#how-does-application-insights-handle-data-collection-retention-storage-and-privacy"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(". ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "-besuch-unserer-digitalen-angebote-cookies"
                  }
                },
                [_vm._v("(Besuch unserer digitalen Angebote) Cookies")]
              ),
              _c("p", [
                _vm._v(
                  " Wenn du unsere digitalen Angebote besuchst und nutzt, setzten wir gegebenenfalls Cookies ein. Cookies sind kleine Textdateien, die von unserem Webserver an den Browser deines Endgeräts versandt und auf diesem gespeichert werden. Cookies helfen unter vielen Aspekten, deinen Besuch auf unseren digitalen Angeboten einfacher, angenehmer und sicherer zu gestalten. Unsere digitalen Angebote nutzen folgende Arten von Cookies: "
                )
              ]),
              _c("p", [
                _c("strong", [_vm._v("Notwendige Cookies:")]),
                _vm._v(
                  " Notwendige Cookies sind Cookies, die für die Nutzung unserer digitalen Angebote und ihrer jeweils grundlegenden Funktionen erforderlich sind. Ohne diese Cookies funktionieren unsere digitalen Angebote nicht oder nicht richtig; diese Cookies können daher nicht abgewählt werden. Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Diese Cookies werden grundsätzlich nach Ende deines Besuchs, spätestens bei Schließen der digitalen Angebote gelöscht. "
                )
              ]),
              _c("p", [
                _c("strong", [_vm._v("Optionale Cookies:")]),
                _vm._v(
                  " Optionale Cookies sind Cookies, die es beispielsweise ermöglichen, den Datenverkehr, das Nutzungsverhalten und die technische Funktionalität unserer digitalen Angebote zu analysieren, um die Qualität und den Nutzungskomfort der digitalen Angebote zu optimieren (Tracking- und Performance-Cookies), die persönliche Einstellungen speichern, damit diese bei dem nächsten Besuch nicht erneut eingegeben werden müssen (Funktionale Cookies) oder die bestimmte Informationen zum Nutzungsverhalten auf unserer Seite mit solchen Informationen anderer Anbieter kombinieren, um personalisierte Werbeangebote machen zu können (Marketing Cookies). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Jeder Einsatz von Optionalen Cookies stellt eine Datenverarbeitung dar, die nur mit deiner ausdrücklichen und aktiven Einwilligung über unseren Cookie-Banner erlaubt ist und jederzeit über diesen verwaltet und widerrufen werden kann. Rechtsgrundlage ist dann Art. 6 Abs. 1 S. 1 lit. a DS-GVO. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Der Einsatz von Optionalen Cookies und anderen Analyse- und Marketingtechnologien wird in den folgenden Abschnitten weiter erläutert. "
                )
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "google-analytics"
                  }
                },
                [_vm._v("Google Analytics")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden in unseren digitalen Angeboten Google Analytics, einen Webtracking-Dienst von Google Inc., Mountain View, USA, im Europäischen Wirtschaftsraum (EWR) und der Schweiz werden Google-Dienste angeboten von der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland („"
                ),
                _c("strong", [_vm._v("Google")]),
                _vm._v("“). ")
              ]),
              _c("p", [
                _vm._v(
                  " Wir nutzen Google Analytics, um das Nutzerverhalten besser zu verstehen und unsere Inhalte sowie Dienstleistungen kontinuierlich zu verbessern. Google Analytics ermöglicht es uns, Berichte über Aktivitäten in unseren digitalen Angeboten zu erstellen und so unsere Online-Präsenz zu optimieren. Dabei werden verschiedene Daten erfasst, die uns helfen, die Interaktionen der Besucher unserer digitalen Angebote zu analysieren. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Im Rahmen der Nutzung von Google Analytics erhebt und verarbeitet Google deine IP-Adresse, Informationen über das verwendete Endgerät (z. B. Gerätetyp, Betriebssystem, Browsertyp), Informationen zu deinem Surfverhalten (z. B. besuchte Seiten, Verweildauer, Klickverhalten), Standortdaten und eindeutige Kennungen, wie Cookie-IDs oder Google Analytics-IDs. Zu deinem Schutz nutzen wir aber natürlich die Anonymisierungsfunktion („IP Masking“), d.h. dass Google innerhalb der EU/des EWR die IP-Adressen um das letzte Oktett kürzt. Diese Daten werden verwendet, um Reports über die Aktivitäten in unseren digitalen Angeboten zu erstellen und uns eine detaillierte Analyse des Nutzerverhaltens zu ermöglichen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Google agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit Google geschlossen. Die durch das Cookie erzeugten Informationen und die (in der Regel gekürzten) IP-Adressen über deine Benutzung dieser digitalen Angebote werden in der Regel an einen Server von Google in den USA übertragen und dort verarbeitet. Google hat sich als sicherer Datenempfänger, der die Datenschutzgrundsätze des Data Privacy Framework berücksichtigt, zertifiziert. Bei einer Übertragung von Daten in die USA durch Google wird also ein Datenschutzstandard gewahrt, der dem der DS-GVO entspricht. Wir haben zudem sog. Standardvertragsklauseln mit Google vereinbart, deren Zweck die Einhaltung eines angemessenen Datenschutzniveaus im Drittland ist. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 lit. a DS-GVO, die du durch die Nutzung unserer digitalen Angebote und Zustimmung zu den entsprechenden Cookies gegeben hast. Du kannst deine Einwilligung zur Verwendung von Google Analytics und der damit verbundenen Datenerhebung jederzeit mit Wirkung für die Zukunft widerrufen. Dies kannst du tun, indem du deine Cookie-Einstellungen in unseren digitalen Angeboten anpasst. Den Widerruf kannst du auch über das "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://tools.google.com/dlpage/gaoptout?hl=de/"
                    }
                  },
                  [_vm._v("Browser-Add-on von Google")]
                ),
                _vm._v(" durchführen. ")
              ]),
              _c("p", [
                _vm._v(
                  " Nähere Informationen zum Leistungsumfang von Google Analytics erhältst du "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://marketingplatform.google.com/about/analytics/terms/de/"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(
                  ". Informationen zur Datenverarbeitung bei Nutzung von Google Analytics stellt Google "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://support.google.com/analytics/answer/6004245?hl=de/"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(
                  " bereit. Generelle Hinweise zur Datenverarbeitung erhältst du in der "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "http://www.google.de/intl/de/policies/privacy/"
                    }
                  },
                  [_vm._v("Datenschutzerklärung")]
                ),
                _vm._v(" von Google. ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "google-ads-inkl-erweiterter-conversions"
                  }
                },
                [_vm._v("Google Ads inkl. Erweiterter Conversions")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden das Online-Werbeprogramm Google Ads und die Funktionen der Erweiterten Conversions, Diensten von Google. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Wir nutzen Google Ads, um auf Drittseiten im Internet auf unsere Angebote aufmerksam zu machen. Wenn du unsere digitalen Angebote besuchst, können durch Google Ads Anzeigen auf den von dir besuchten Seiten der digitalen Angebote ausgespielt werden. Google Ads ermöglicht uns, unsere Werbeanzeigen gezielt an Personen auszuspielen, die bereits Interesse an unseren digitalen Angeboten gezeigt haben. Durch den Einsatz von Google Ads können wir unsere Werbemaßnahmen effektiver gestalten und deine Nutzererfahrung verbessern. Im Rahmen der Nutzung von Google Ads erhebt und verarbeitet Google unter anderem deine IP-Adresse, Informationen über dein verwendetes Endgerät (z. B. Gerätetyp, Betriebssystem, Browsertyp), Angaben zu deinem Surfverhalten (z. B. besuchte Webseiten, Interaktionen mit Werbeanzeigen), Standortdaten und eindeutige Kennungen, wie Cookie-IDs oder Google Ads-IDs. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Google agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit Google geschlossen. Die durch das/die Cookie/s erzeugten Informationen und die (in der Regel gekürzten) IP-Adressen über deine Benutzung dieser digitalen Angebote werden in der Regel an einen Server von Google in den USA übertragen und dort verarbeitet. Für die Einbindung von Google Ads verweisen wir auf die Zertifizierung von Google als sicherer Datenempfänger (s. Ziffer 2.3). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DS-GVO, die du durch die Nutzung unserer digitalen Angebote und Zustimmung zu den entsprechenden Cookies gegeben hast. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Du kannst deine Einwilligung zur Verwendung von Google Ads und der damit verbundenen Datenerhebung jederzeit mit Wirkung für die Zukunft widerrufen, ohne dass davon die Zulässigkeit der Verarbeitung bis zum Widerruf berührt wird. Dies kannst du tun, indem du deine Cookie-Einstellungen auf unserer Website anpasst oder über die Google Ads-Einstellungen die Personalisierung der Werbung deaktivierst. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Informationen zur Datenverarbeitung von Google bei Nutzung von Google Ads stellt Google "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://support.google.com/google-ads/topic/11337105?hl=de&ref_topic=10286612&sjid=11937524379447575685-EU"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(
                  " bereit. Generelle Hinweise zur Datenverarbeitung durch Google erhältst du in der "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://policies.google.com/privacy?hl=de&gl=de"
                    }
                  },
                  [_vm._v("Datenschutzerklärung")]
                ),
                _vm._v(" von Google. ")
              ]),
              _c("p", [
                _vm._v(
                  " Mehr Informationen zu der Funktionsweise der Erweiterten Conversions findest du "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://support.google.com/google-ads/answer/9888656?hl=de"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(". ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "youtube"
                  }
                },
                [_vm._v("YouTube")]
              ),
              _c("p", [
                _vm._v(
                  "Wir verwenden in unseren digitalen Angeboten YouTube, eine Online-Videoplattform von Google."
                )
              ]),
              _c("p", [
                _vm._v(
                  "Zweck unserer Nutzung dieses Tools ist es, dir Videos von Produkten zu zeigen."
                )
              ]),
              _c("p", [
                _vm._v(
                  " Wenn du eine Seite unserer digitalen Angebote aufrufst, die YouTube enthält und du der Nutzung optionaler Cookies zugestimmt hast, baut dein Browser eine Verbindung mit den Servern von Google zur Einbindung des jeweiligen Videos auf. Informationen über deine Benutzung dieser digitalen Angebote einschließlich deiner IP-Adresse werden hierbei an Google übermittelt. YouTube agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit YouTube geschlossen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Auch für die Einbindung von YouTube verweisen wir auf die Zertifizierung von Google als sicherer Datenempfänger (s. Ziffer 2.3). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Rechtsgrundlage für die Erhebung und weitere Verarbeitung der Informationen ist deine erteilte Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DS-GVO). Der Widerruf deiner Einwilligung ist jederzeit möglich, ohne dass davon die Zulässigkeit der Verarbeitung bis zum Widerruf berührt wird. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Informationen zur Datenverarbeitung bei Nutzung von YouTube stellt Google "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://policies.google.com/privacy"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(" bereit. ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "google-tag-manager"
                  }
                },
                [_vm._v("Google Tag Manager")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden in unseren digitalen Angeboten den Google Tag Manager, ein Tool von Google, das uns Website-Tags über eine Oberfläche verwalten lässt. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Zweck unserer Nutzung dieses Tools ist es, JavaScript- und HTML-Tags für Tracking und Analysen mit eigener und Drittanbieter-Software zu verwalten. Tags sind kleine Code-Elemente, die uns anderem dabei helfen, Traffic und Besucherverhalten zu messen und unsere digitalen Angebote zu testen und zu optimieren. Der Google Tag Manager setzt (im leeren Zustand) keine eigenen Cookies und erfasst selbst keine personenbezogenen Daten. Er löst andere Tags aus, die wiederum ggf. personenbezogene Daten erfassen. Jedoch greift der Google Tags Manager selbst nicht auf diese Daten zu. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Google agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit Google geschlossen. Die durch das Cookie erzeugten Informationen und die (in der Regel gekürzten) IP-Adressen über deine Benutzung dieser digitalen Angebote werden in der Regel an einen Server von Google in den USA übertragen und dort verarbeitet. Auch für die Einbindung des Google Tag Managers verweisen wir auf die Zertifizierung von Google als sicherer Datenempfänger (s. Ziffer 2.3). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Auch für den Einsatz des Google Tag Managers holen wir deine Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DS-GVO) ein. Der Widerruf deiner Einwilligung ist jederzeit möglich, ohne dass davon die Zulässigkeit der Verarbeitung bis zum Widerruf berührt wird. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Informationen zur Nutzung des Google Tag Managers stellt Google in den "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/"
                    }
                  },
                  [_vm._v("AGB")]
                ),
                _vm._v(" bereit, es gilt die "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://policies.google.com/privacy"
                    }
                  },
                  [_vm._v("Datenschutzerklärung")]
                ),
                _vm._v(" von Google. ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "google-fonts"
                  }
                },
                [_vm._v("Google Fonts")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden in unseren digitalen Angeboten sogenannte Google Fonts, also Schriftarten, die von Google bereitgestellt werden. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Aufgrund von Google Fonts können wir die Schriftarten in unseren digitalen Angeboten einheitlich darstellen. Wenn du unsere Website [oder unsere Plattform] besuchst, werden die Schriftarten (Fronts) direkt von den Servern von Google geladen. Dabei kann es zu einer Übertragung personenbezogener Daten (z. B. IP-Adressen) an Google kommen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Google agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit Google geschlossen. Die durch das Cookie erzeugten Informationen und die (in der Regel gekürzten) IP-Adressen über deine Benutzung unserer digitalen Angebote werden in der Regel an einen Server von Google in den USA übertragen und dort verarbeitet. Auch für die Einbindung von Google Fonts verweisen wir auf die Zertifizierung von Google als sicherer Datenempfänger (s. Ziffer 2.3). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DS-GVO, die du durch die Nutzung unserer digitalen Angebote und Zustimmung zu den entsprechenden Cookies gegeben hast. Du kannst deine Einwilligung zur Verwendung von Google Fonts und der damit verbundenen Datenerhebung jederzeit mit Wirkung für die Zukunft widerrufen. Dies kannst du tun, indem du die Cookie-Einstellungen in unseren digitalen Angeboten anpasst. Den Widerruf kannst du auch über das Browser-Add-on von Google durchführen, das über "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://tools.google.com/dlpage/gaoptout?hl=de/"
                    }
                  },
                  [_vm._v("diesen Link")]
                ),
                _vm._v(" abrufbar ist. ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "microsoft-advertising"
                  }
                },
                [_vm._v("Microsoft Advertising")]
              ),
              _c("p", [
                _vm._v(
                  " Zum Zwecke des Online-Marketings nutzen wir die Dienste und Funktionen von Microsoft Advertising, einem Dienst von Microsoft. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Wir nutzen Microsoft Advertising als Werbetool, das es uns ermöglicht, unseren Nutzern zielgerichtete und personalisierte Werbung u. a. in den Suchmaschine Bing, DuckDuckGo und bei Yahoo anzuzeigen. Hierbei setzen wir Cookies und ähnliche Technologien ein, die das Nutzerverhalten analysieren und die Nutzer in unseren digitalen Angeboten wiedererkennen. Durch den Einsatz von Microsoft Advertising können wir unsere Werbemaßnahmen effektiver gestalten und deine Nutzererfahrung verbessern. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die durch diese Technologien gesammelten Informationen helfen uns, die Relevanz der von uns ausgespielten Werbung zu optimieren. Microsoft Advertising ermöglicht uns außerdem Werbung basierend auf Nutzerverhaltensprofilen und geografischem Standort gezielt auszuspielen. In diesem Zusammenhang übermitteln wir deine IP-Adresse und Informationen über dein verwendetes Endgerät (z. B. Gerätetyp, Betriebssystem, Browsertyp) an den Betreiber von Microsoft Advertising die Microsoft Corporation. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Microsoft agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit Microsoft geschlossen. Die Daten werden an einen Server von Microsoft übertragen und dort verarbeitet. Microsoft hat sich als sicherer Datenempfänger, der die Datenschutzgrundsätze des Data Privacy Framework berücksichtigt, zertifiziert. Bei einer Übertragung von Daten durch Microsoft wird also ein Datenschutzstandard gewahrt, der dem der DS-GVO entspricht. Wir haben zudem sog. Standardvertragsklauseln mit Microsoft vereinbart, deren Zweck die Einhaltung eines angemessenen Datenschutzniveaus im Drittland ist. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DS-GVO, die du durch die Nutzung unserer Website und Zustimmung zu den entsprechenden Cookies gegeben hast. Du kannst deine Einwilligung zur Verwendung von Google Ads und der damit verbundenen Datenerhebung jederzeit mit Wirkung für die Zukunft widerrufen. Dies kannst du tun, indem du deine Cookie-Einstellungen in unseren digitalen Angeboten anpasst oder über die Microsoft Advertising-Einstellungen die Personalisierung der Werbung deaktivierst. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Generelle Hinweise zur Datenverarbeitung durch Microsoft erhältst du in der "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://privacy.microsoft.com/de-de/privacystatement"
                    }
                  },
                  [_vm._v("Datenschutzerklärung")]
                ),
                _vm._v(" von Microsoft. ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "microsoft-application-insights"
                  }
                },
                [_vm._v("Microsoft Application Insights")]
              ),
              _c("p", [
                _vm._v(
                  " Zur Optimierung der Leistung, der Zuverlässigkeit und der Qualität unserer digitalen Angebote nutzen wir Microsoft Application Insights, ein Dienst von Microsoft. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Hierzu erfasst und analysiert der Dienst Anwendungsdaten wie Benutzerinteraktionen, Fehler- und Problemquellen und Leistungsmetriken. Der Dienst gibt uns beispielsweise eine Übersicht über die Interaktion der Anwendungskomponenten und eine Möglichkeit in Echtzeit die Aktivitäten unserer digitalen Angebote zu sehen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Der Dienst sammelt hierfür Telemetriedaten der genutzten Anwendungen. Diese Daten sind anonyme statistische Daten. Die Herstellung eines Personenbezugs ist aufgrund dieser Daten nicht möglich. Deine für den Dienst erhobene IP-Adresse wird verkürzt und anonymisiert. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Microsoft agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit Microsoft geschlossen. Für die Einbindung der Microsoft Application Insights verweisen wir auf die Zertifizierung von Microsoft als sicherer Datenempfänger (s. Ziffer 2.8). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DS-GVO, die du durch die Nutzung unserer Website und Zustimmung zu den entsprechenden Cookies gegeben hast. Du kannst deine Einwilligung zur Verwendung von Google Ads und der damit verbundenen Datenerhebung jederzeit mit Wirkung für die Zukunft widerrufen. Dies kannst du tun, indem du deine Cookie-Einstellungen in unseren digitalen Angeboten anpasst oder über die Microsoft Advertising-Einstellungen die Personalisierung der Werbung deaktivierst. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Generelle Hinweise zur Datenverarbeitung durch Microsoft erhältst du in der "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://privacy.microsoft.com/de-de/privacystatement"
                    }
                  },
                  [_vm._v("Datenschutzerklärung")]
                ),
                _vm._v(
                  " von Microsoft. Weitere Informationen zur Datenverarbeitung durch Microsoft erhältst du "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://eu-central-1.protection.sophos.com/?d=microsoft.com&u=aHR0cHM6Ly9sZWFybi5taWNyb3NvZnQuY29tL2RlLWRlL2F6dXJlL2F6dXJlLW1vbml0b3IvYXBwL2RhdGEtcmV0ZW50aW9uLXByaXZhY3k=&i=NjIzOWNjNmNjOTIwOTUxMTMzOGJmNTYz&t=dW5sT2EwYmNKNFdGc1JBNEMwQWQ4SHRDMDhPS0c1L1lqTHZteFVUNjEwaz0=&h=dd4f1a1d5cd54505ad43574ae4828517&s=AVNPUEhUT0NFTkNSWVBUSVafW8D7KLbh_QWX9Tt0WCpirmmXNmg5XzygYRCYuKLAKA"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(". ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "clarity"
                  }
                },
                [_vm._v("Clarity")]
              ),
              _c("p", [
                _vm._v(
                  " Wir nutzen in unseren digitalen Angeboten Dienste und Funktionen von Clarity, die von Microsoft angeboten werden. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Clarity hilft uns festzustellen, wie unsere digitalen Angebote genutzt werden. Im Rahmen der Analyse erfahren wir, wie du u. a. mit der Maus auf agierst und wie lange du dir einen Inhalt in unseren digitalen Angeboten anschaust. Anhand dieser Informationen kann Clarity sogenannte Heatmaps erstellen. Die Heatmaps zeigen uns, welche Teile unserer digitalen Angebote von Besuchern am häufigsten aufgerufen werden. Des Weiteren kann Clarity Sitzungen von Besuchern der digitalen Angebote aufzeichnen, sodass wir die Seitennutzung in Form von Videos ansehen können. Hierdurch können wir unsere Onlinepräsenz und unsere Angebote für dich optimieren. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Hierbei verwendet Clarity Technologien (z. B. Cookies oder Fingerprinting-Systeme), um Besucher wiederzuerkennen, wenn sie die digitalen Angebote erneut besuchen. Clarity agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit Clarity geschlossen. Die Informationen, die Clarity darüber sammelt, wie du diese digitalen Angebote nutzt, werden auf Servern von Microsoft (Microsoft Azure Cloud Service) in den USA gespeichert. Für die Einbindung von Clarity verweisen wir auf die Zertifizierung von Microsoft als sicherer Datenempfänger (s. Ziffer 2.8). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DS-GVO, die du durch die Nutzung unserer digitalen Angebote und Zustimmung zu den entsprechenden Cookies gegeben hast. Du kannst deine Einwilligung zur Verwendung von Clarity und der damit verbundenen Datenerhebung jederzeit mit Wirkung für die Zukunft widerrufen. Dies kannst du tun, indem du deine Cookie-Einstellungen in unseren digitalen Angeboten anpasst oder über die Clarity-Einstellungen die Verarbeitung deaktivierst. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Generelle Hinweise zur Datenverarbeitung durch Microsoft erhältst du in der "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://privacy.microsoft.com/de-de/privacystatement"
                    }
                  },
                  [_vm._v("Datenschutzerklärung")]
                ),
                _vm._v("von Microsoft. ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "elfsight"
                  }
                },
                [_vm._v("Elfsight")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden in unseren digitalen Angeboten die Dienste und Funktionen von Elfsight, einem Dienst der Elfsight, LLC., 0015, Armenia, Yerevan, Paronyana str., 19/3, 201 („Elfsight“). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Elfsight ermöglicht es uns, Inhalte wie Bewertungen direkt von Google zu beziehen und in unseren digitalen Angeboten darzustellen. Dafür wird in der Regel deine IP-Adresse, Informationen über dein Betriebssystem und den verwendeten Browsertyp an Elfsight, übermittelt. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DS-GVO, die du durch die Nutzung unserer digitalen Angebote und Zustimmung zu den entsprechenden Cookies gegeben hast. Du kannst deine Einwilligung zur Verwendung von Elfsight und der damit verbundenen Datenerhebung jederzeit mit Wirkung für die Zukunft widerrufen. Dies kannst du tun, indem du deine Cookie-Einstellungen in unseren digitalen Angeboten anpasst. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Generelle Hinweise zur Datenverarbeitung durch Elfsight erhältst du in der "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://elfsight.com/privacy-policy/"
                    }
                  },
                  [_vm._v("Datenschutzerklärung")]
                ),
                _vm._v(" von Elfsight. ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "linkedin-insight-tag"
                  }
                },
                [_vm._v("LinkedIn Insight Tag")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden den LinkedIn Insight Tag, einen Webtracking-Dienst der LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA, 90403, USA. Innerhalb des Europäischen Wirtschaftsraums (EWR) und der Schweiz wird der Dienst von der LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland („LinkedIn“) angeboten. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Wir nutzen den LinkedIn Insight Tag, um von LinkedIn einen zusammenfassenden Bericht über die Demographie der Besucher unserer digitalen Angebote zu erhalten, etwa zu Branche, Jobbezeichnung, Unternehmensgröße, Karrierestufe und Standort unserer Websitebesucher. LinkedIn teilt uns dabei keine personenbezogenen Daten mit, sondern es erfolgt eine pseudonymisierte Verknüpfung der Daten, was bedeutet, dass LinkedIn die erhobenen Informationen nicht direkt einer bestimmten Person zuordnen kann, es sei denn, du bist bei LinkedIn eingeloggt. Auf Grundlage dieser Daten ist es uns möglich, Informationen zu unserer Zielgruppe zu erhalten, die Attraktivität unseres Angebots und insbesondere den Erfolg unserer Werbung zu messen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Im Rahmen der Nutzung des LinkedIn Insight Tag erhebt und verarbeitet LinkedIn verschiedene Daten, darunter deine IP-Adresse, Informationen über das verwendete Endgerät (z. B. Gerätetyp, Betriebssystem), Informationen zu Ihrem Browser und Surfverhalten, URL und Referrer-URL. LinkedIn anonymisiert die Daten vor Bereitstellung des entsprechenden Berichts an uns (spätestens binnen sieben Tagen) und löscht die Daten innerhalb von 180 Tagen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DS-GVO. Der Widerruf deiner Einwilligung ist jederzeit möglich, ohne dass davon die Zulässigkeit der Verarbeitung bis zum Widerruf berührt wird. Du kannst die Einwilligung widerrufen, indem du deine Cookie-Einstellungen in unseren digitalen Angeboten anpasst oder über die Werbeeinstellungen deines LinkedIn-Kontos änderst. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Dadurch, dass wir den LinkedIn Insight Tag zur Optimierung unseres Online-Service und unserer Marketingmaßnahmen verwenden, haben wir auch ein berechtigtes Interesse i. S. d. Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Gleichwohl wird der LinkedIn Insight Tag nur eingesetzt, wenn du eine Einwilligung erteilt hast. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " LinkedIn verarbeitet deine personenbezogenen Daten wie deinen Beruf, Daten zum Beschäftigungsstatuts, Land, Dienstalter und Unternehmensgröße, um uns Statistiken über personenbezogene Daten mit Filter- bzw. Einstellungsmöglichkeiten zur Verfügung zu stellen. Durch diese Daten können wir Statistiken beispielsweise über die Interessen oder die berufliche Situation unserer Nutzer erstellen lassen. Wir und LinkedIn sind dann gemeinsame Verantwortliche im Sinne des Art. 26 DS-GVO. Über die gemeinsame Verantwortlichkeit haben wir mit dem jeweiligen Partner einen Vertrag geschlossen. Es gelten ebenfalls die Datenschutzinformationen des jeweiligen Partners. Weitere Informationen zum LinkedIn Insight Tag bekommst du "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://www.linkedin.com/help/linkedin/answer/a427660"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(
                  ". Bei weiteren Fragen zur Datenverarbeitung durch LinkedIn, wende dich bitte direkt an LinkedIn. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Daten werden an einen Server von LinkedIn übertragen und dort verarbeitet. LinkedIn hat sich als sicherer Datenempfänger, der die Datenschutzgrundsätze des Data Privacy Framework berücksichtigt, zertifiziert. Bei einer Übertragung von Daten durch LinkedIn wird also ein Datenschutzstandard gewahrt, der dem der DS-GVO entspricht. Wir haben zudem sog. Standardvertragsklauseln mit LinkedIn vereinbart, deren Zweck die Einhaltung eines angemessenen Datenschutzniveaus im Drittland ist. Mehr Informationen zu den Standardvertragsklauseln findest du "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://de.linkedin.com/legal/l/dpa"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(" oder "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.linkedin.com/legal/l/eu-sccs"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(". ")
              ]),
              _c("p", [
                _vm._v(
                  " Die Datenschutzbestimmungen von LinkedIn findest du in der "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.linkedin.com/legal/privacy-policy"
                    }
                  },
                  [_vm._v("Datenschutzerklärung")]
                ),
                _vm._v(". ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "meta-pixel"
                  }
                },
                [_vm._v("Meta Pixel")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden Meta Pixel, einen Webtracking-Dienst von Meta Platforms, Inc., Menlo Park, USA. Innerhalb des Europäischen Wirtschaftsraums (EWR) und der Schweiz wird der Dienst von Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland („Meta“) angeboten. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Wir nutzen Meta Pixel, um das Nutzerverhalten in unseren digitalen Angeboten besser zu verstehen und unsere Marketingkampagnen auf Plattformen wie Facebook und Instagram zu optimieren. Meta Pixel ermöglicht es uns, Berichte über Aktivitäten in unseren digitalen Angeboten zu erstellen, Conversions zu messen und gezielte Werbeanzeigen zu schalten. Dadurch können wir unsere Online-Präsenz und Werbeeffizienz kontinuierlich verbessern. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Im Rahmen der Nutzung von Meta Pixel erhebt und verarbeitet Meta verschiedene Daten, darunter deine IP-Adresse, Informationen über das verwendete Endgerät (z. B. Gerätetyp, Betriebssystem, Browsertyp), Informationen zu deinem Surfverhalten (z. B. besuchte Seiten, Verweildauer, Klickverhalten), Standortdaten und eindeutige Kennungen, wie Cookie-IDs oder Facebook-IDs. Diese Daten werden verwendet, um Berichte über die Aktivitäten in unseren digitalen Angeboten zu erstellen, gezielte Werbung zu schalten und uns eine detaillierte Analyse des Nutzerverhaltens zu ermöglichen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Meta agiert als Auftragsverarbeiter, und wir haben einen entsprechenden Vertrag mit Meta geschlossen. Die durch das Cookie erzeugten Informationen und die IP-Adressen über deine Benutzung der digitalen Angebote werden in der Regel an einen Server von Meta in den USA übertragen und dort verarbeitet. Meta hat sich als sicherer Datenempfänger zertifiziert, indem es die Datenschutzgrundsätze des EU-U.S. Data Privacy Framework berücksichtigt. Dies bedeutet, dass ein Datenschutzstandard gewahrt wird, der dem der DS-GVO entspricht. Zudem haben wir sog. Standardvertragsklauseln mit Meta vereinbart, deren Zweck die Einhaltung eines angemessenen Datenschutzniveaus im Drittland ist. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DS-GVO. Der Widerruf deiner Einwilligung ist jederzeit möglich, ohne dass davon die Zulässigkeit der Verarbeitung bis zum Widerruf berührt wird. Dies kannst du tun, indem du deine Cookie-Einstellungen in unseren digitalen Angeboten anpasst oder über die Werbeeinstellungen deines Meta-Kontos die Personalisierung der Werbung deaktivierst. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Nähere Informationen zum Leistungsumfang von Meta Pixel erhältst du im "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://www.facebook.com/business/help/651294705016616"
                    }
                  },
                  [_vm._v("Meta Business Help Center")]
                ),
                _vm._v(". Informationen zur Datenverarbeitung stellt Meta "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.facebook.com/policy.php"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(" bereit. ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id:
                      "hubspot-pixel-analytics-cdn-chat-cookie-banner-forms-leadflow-"
                  }
                },
                [
                  _vm._v(
                    " HubSpot (Pixel, Analytics, CDN, Chat, Cookie Banner, Forms, LeadFlow) "
                  )
                ]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden im Rahmen unserer digitalen Angebote die im Folgenden angeführten Dienste von HubSpot. Der Anbieter dieser Dienste ist HubSpot Inc. 25 First Street, Cambridge, Massachusetts 02141 USA („HubSpot“). "
                )
              ]),
              _c(
                "h4",
                {
                  attrs: {
                    id: "hubspot-pixel"
                  }
                },
                [_vm._v("HubSpot Pixel")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden HubSpot Pixel, um Besuchergruppen unserer digitalen Angebote aufzuteilen („Custom Audiences“), die Conversion-Rate zu bestimmen und zu optimieren. Die Conversion Rate zeigt uns das Verhältnis zwischen Anzahl der Besucher des digitalen Angebotes zur Anzahl einer gewünschten Handlung auf. HubSpot Pixel wird vor allem dann verwendet, wenn du mit unseren Werbeanzeigen interagierst, die wir in Zusammenarbeit mit HubSpot geschaltet haben. "
                )
              ]),
              _c(
                "h4",
                {
                  attrs: {
                    id: "hubspot-analytics"
                  }
                },
                [_vm._v("HubSpot Analytics")]
              ),
              _c("p", [
                _vm._v(
                  " In unseren digitalen Angeboten setzen wir die Analysefunktion HubSpot Analytics ein, um die Nutzung unserer digitalen Angebote statistisch auswerten zu können. Im Rahmen dieser Auswertung möchten wir insbesondere die Gesamtzahl der Besucher, die aufgerufenen Unterseiten und die jeweilige Verweildauer unserer Besucher erfahren. Für diese Auswertungen setzt HubSpot Analytics Technologien wie Cookies ein, die das Wiedererkennen von Nutzern und die Analyse ihres Verhaltens ermöglichen. Die so erhaltenen Informationen dienen dazu, die Aktivitäten in unseren digitalen Angeboten umfassend darzustellen. "
                )
              ]),
              _c(
                "h4",
                {
                  attrs: {
                    id: "hubspot-cdn"
                  }
                },
                [_vm._v("HubSpot CDN")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden auf in unseren digitalen Angeboten die Funktionen von HubSpot CDN als Content Delivery Network. Dieses Netzwerk ermöglicht das Laden unserer digitalen Angebote insbesondere für datenintensive Anwendungen zu beschleunigen und Inhalte sicher für dich bereitzustellen. Deine Daten werden insbesondere dazu genutzt, Inhalte wie Grafiken oder Scripts mit Hilfe von regional oder international verteilten Servern schneller bereitzustellen und die Sicherheit und Funktionsfähigkeit von HubSpot CDN zu gewährleisten. "
                )
              ]),
              _c(
                "h4",
                {
                  attrs: {
                    id: "hubspot-chat"
                  }
                },
                [_vm._v("HubSpot Chat")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden in unseren digitalen Angeboten zudem auch Funktionen des HubSpot Chats, einer Kundenkommunikationsplattform. Dieser Dienst ermöglicht es uns mit unseren Kunden in Kontakt zu treten und über den Chat zu kommunizieren. So können wir gezielt bei deinen Fragestellungen Hilfe leisten. HubSpot Chat nutzt Cookies und weitere Browser-Technologien, um Nutzerverhalten auszuwerten und unsere Nutzer wiederzuerkennen. Des Weiteren wird HubSpot Chat verwendet, um in Chats eingegebene Daten mittels Cookies zu speichern und zu übertragen, samt deiner IP-Adresse. "
                )
              ]),
              _c(
                "h4",
                {
                  attrs: {
                    id: "hubspot-cookie-banner"
                  }
                },
                [_vm._v("HubSpot Cookie Banner")]
              ),
              _c("p", [
                _vm._v(
                  " Den HubSpot Cookie Banner verwenden wir als Zustimmungslösung zur Einholung der Einwilligung von Nutzern zur Datenverarbeitung. So kannst du im Rahmen der Nutzung Cookies gezielt annehmen oder ablehnen. Des Weiteren dokumentiert der Cookie Banner deine Einwilligungen. HubSpot Cookie Banner nutzt Cookies oder andere Web-Technologien, um Nutzer wiederzuerkennen und die erteilte oder widerrufene Einwilligung zu speichern. "
                )
              ]),
              _c(
                "h4",
                {
                  attrs: {
                    id: "hubspot-forms"
                  }
                },
                [_vm._v("HubSpot Forms")]
              ),
              _c("p", [
                _vm._v(
                  " HubSpot Forms ist ein Formulardesigner, um benutzerdefinierte Formulare für unsere Website [und unsere Plattform] zu erstellen. Mithilfe der Formulare sammeln wir wichtige Informationen über dich. Wenn du ein Formular ausfüllst, ermöglicht HubSpot Forms uns, dass du automatisch in unsere Datenbank aufgenommen wirst. So können wir deine Kontaktanfrage effizient bearbeiten und dich mit personalisierten Emails und Newslettern weiter ansprechen. "
                )
              ]),
              _c(
                "h4",
                {
                  attrs: {
                    id: "hubspot-leadflow"
                  }
                },
                [_vm._v("HubSpot LeadFlow")]
              ),
              _c("p", [
                _vm._v(
                  " HubSpot LeadFlow verwenden wir zur Identifikation und Sammlung von Informationen potenzieller Kunden. Zudem hilft uns HubSpot LeadFlow Einblicke in den Besuchsverlauf zu bekommen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " HubSpot LeadFlow nutzt Cookies und weitere Browser-Technologien, um Nutzerverhalten auszuwerten und Nutzer wiederzuerkennen. HubSpot LeadFlow sammelt und verarbeitet Daten über Unternehmen wie beispielsweise Unternehmensname, Telefonnummer, Adresse, Web-Adresse, Industrie, Unternehmensprofil und Umsatz. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " HubSpot agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit HubSpot geschlossen. Nähere Informationen hierzu findest du "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://legal.hubspot.com/dpa"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(
                  ". Teilweise werden von HubSpot erfasste Daten an Server von HubSpot in die USA zu übertragen. HubSpot hat sich als sicherer Datenempfänger zertifiziert, der die Datenschutzgrundsätze des Data Privacy Framework berücksichtigt. Bei einer Übertragung von Daten in die USA durch HubSpot wird also ein Datenschutzstandard gewahrt, der dem der DS-GVO entspricht. Wir haben zudem sog. Standardvertragsklauseln mit HubSpot vereinbart, deren Zweck die Einhaltung eines angemessenen Datenschutzniveaus im Drittland ist. Nähere Informationen findest du "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.hubspot.de/data-privacy/privacy-shield"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(". ")
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten durch die Dienste von HubSpot ist nach den jeweiligen Diensten zu differenzieren. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner Daten durch die Dienste Pixel, Analytics, Chat und LeadFlow erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DS-GVO. Der Widerruf deiner Einwilligung ist jederzeit möglich, ohne dass davon die Zulässigkeit der Verarbeitung bis zum Widerruf berührt wird. Dies kannst du tun, indem du deine Cookie-Einstellungen in unseren digitalen Angeboten anpasst. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner Daten durch den Dienst HubSpot CDN erfolgt aufgrund unseres berechtigten Interesses an einer möglichst fehlerfreien, sicheren und effizienten Bereitstellung unserer digitalen Angebote und der damit zusammenhängenden Inhalten (Art. 6 Abs. 1 S. 1 lit. f DS-GVO). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Rechtsgrundlage für die effiziente Verarbeitung deiner personenbezogenen Daten in Form deiner Kontaktanfrage durch den Dienst HubSpot Forms ist Art. 6 Abs. 1 S. 1 lit. b DS-GVO. Dadurch, dass deine Daten ausschließlich zweckgebunden zur Beantwortung und Bearbeitung nur aus dem Anlass deiner Frage genutzt werden, haben wir auch ein berechtigtes Interesse i. S. d. Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Wir wollen dir eine schnelle, sichere und unkomplizierte Kontaktaufnahme ermöglichen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Rechtsgrundlage für die Verarbeitung deiner personenbezogenen Daten durch den Dienst HubSpot Cookie-Banner ist wiederrum Art. 6 Abs. 1 S. 1 lit. c DS-GVO, da der Cookie-Banner zur Erfüllung der gesetzlichen Verpflichtungen einer Einwilligung i. S. d. Art. 6 Abs. 1 S. 1 lit. a DS-GVO i. V. m. Art. 7 DS-GVO erforderlich ist. "
                )
              ]),
              _c("p", [
                _vm._v(" Details zum Umgang von HubSpot mit Daten findest du "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://legal.hubspot.com/de/privacy-policy"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v(". ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "jsdelivr-cdn"
                  }
                },
                [_vm._v("JSDelivr CDN")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden in unseren digitalen Angeboten JSDelivr CDN als Content Delivery Network. JSDelivr CDN ist ein Produkt von ProspectOne, Królewska 65A/1,30-081, Kraków, Polen. Deine Daten werden insbesondere dazu genutzt, Inhalte wie Grafiken oder Scripts mit Hilfe von regional oder international verteilten Servern schneller bereitzustellen und die Sicherheit und Funktionsfähigkeit des JSDelivr CDN zu gewährleisten. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Dieses Netzwerk ermöglicht das Laden unserer digitalen Angebote insbesondere für datenintensive Anwendungen zu beschleunigen, sodass wir auch große Lastenspitzen ausgleichen können und den Download von JavaScript-Bibliotheken sowie von auf npm- und GitHub-Servern gehosteten Dateien. Darüber hinaus können auch WordPress-Plugins geladen werden, sofern diese über WordPress.org bereitgestellt werden. Hierfür schickt dein Browser personenbezogene Daten wie deine IP-Adresse, dein Browsertyp, die Browserversion und die von dir aufgerufenen Seiten an die Server von JSDelivr. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner Daten durch das JSDelivr CDN erfolgt aufgrund unseres berechtigten Interesses an einer möglichst fehlerfreien, sicheren und effizienten Bereitstellung unserer digitalen Angebote und der damit zusammenhängenden Inhalten sowie der Optimierung unserer digitalen Angebote (Art. 6 Abs. 1 S. 1 lit. f DS-GVO). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Prospect One agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit ProspectOne geschlossen. Die durch das/die Cookie/s erzeugten Informationen können auch auf Server außerhalb des Europäischen Wirtschaftsraums übermittelt werden. Bezüglich einer Datenübermittlung an einen Server von ProspectOne in den USA (und anschließender Verarbeitung) hat sich ProspectOne als sicherer Datenempfänger, der die Datenschutzgrundsätze des Data Privacy Framework berücksichtigt, zertifiziert. Bei einer Übertragung von Daten in die USA durch ProspectOne wird also ein Datenschutzstandard gewahrt, der dem der DS-GVO entspricht. Wir haben zudem sog. Standardvertragsklauseln mit ProspectOne vereinbart, deren Zweck die Einhaltung eines angemessenen Datenschutzniveaus in anderen Drittländern ist. "
                )
              ]),
              _c("p", [
                _vm._v(" Nähere Informationen erhältst du in der "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://www.jsdelivr.com/privacy-policy-jsdelivr-net/"
                    }
                  },
                  [_vm._v("Datenschutzerklärung")]
                ),
                _vm._v(". ")
              ]),
              _c(
                "h3",
                {
                  attrs: {
                    id: "outbrain"
                  }
                },
                [_vm._v("Outbrain")]
              ),
              _c("p", [
                _vm._v(
                  " Wir verwenden Outbrain, einen Dienst von Outbrain Inc., 111 West 19th Street, 3rd Foor, New York, NY 10011, USA („Outbrain“). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Wir nutzen Outbrain, um das Nutzerverhalten auf unserer Website besser zu verstehen und um für unsere Inhalte sowie Dienstleistungen zielgerichtet Werbung auf Grundlage von Verhaltensprofilen und geografischer Lage. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Im Rahmen der Nutzung von Outbrain erhebt und verarbeitet Outbrain deine IP-Adresse, Informationen über das verwendete Endgerät (z. B. Gerätetyp, Betriebssystem, Browsertyp), Informationen zu deinem Surfverhalten (z. B. besuchte Seiten, Verweildauer, Klickverhalten), Standortdaten und eindeutige Kennungen, die vereisenden URLs und andere normalerweise bei HTTP-Anfragen übertragenen Informationen (z. B. Informationen, die uns mitteilen, wie du i auf unsere digitalen Angebote gelangt bist). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Outbrain agiert als Auftragsverarbeiter und wir haben einen entsprechenden Vertrag mit Outbrain geschlossen. Die erzeugten Informationen und die (in der Regel gekürzten) IP-Adressen über deine Benutzung dieser digitalen Angebote werden in der Regel an einen Server von Outbrain in den USA übertragen und dort verarbeitet. Outbrain hat sich als sicherer Datenempfänger, der die Datenschutzgrundsätze des Data Privacy Framework berücksichtigt, zertifiziert. Bei einer Übertragung von Daten in die USA durch Outbrain wird also ein Datenschutzstandard gewahrt, der dem der DS-GVO entspricht. Wir haben zudem sog. Standardvertragsklauseln mit Outbrain vereinbart, deren Zweck die Einhaltung eines angemessenen Datenschutzniveaus im Drittland ist. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Verarbeitung deiner personenbezogenen Daten erfolgt auf Grundlage deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DS-GVO, die du durch die Nutzung unserer digitalen Angebote und Zustimmung zu den entsprechenden Cookies gegeben hast. Du kannst deine Einwilligung zur Verwendung von Outbrain und der damit verbundenen Datenerhebung jederzeit mit Wirkung für die Zukunft widerrufen. Dies kannst du tun, indem du die Cookie-Einstellungen in unseren digitalen Angeboten anpasst. "
                )
              ]),
              _c("p", [
                _vm._v(" Nähere Informationen findest du in der "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://www.outbrain.com/privacy/wp-content/uploads/2022/02/Outbrain-Privacy-Policy-German-4-November-2021-1.pdf"
                    }
                  },
                  [_vm._v("Datenschutzerklärung")]
                ),
                _vm._v(" von Outbrain. ")
              ]),
              _c(
                "h2",
                {
                  attrs: {
                    id: "nutzung-der-plattform-nutzeraccount"
                  }
                },
                [_vm._v("Nutzung der Plattform | Nutzeraccount")]
              ),
              _c("p", [
                _vm._v(
                  " Entscheidest du dich dazu, unsere digitalen Angebote, insbesondere jene auf unserer Plattform, zu verwenden, um einem unserer gewerblichen Partner deine Daten zu übermitteln, und legst du ggf. auch einen Nutzeraccount bei uns an, verarbeiten wir deine jeweils vor dir eingegebenen Daten (z. B. Kontaktdaten wie Name, Anschrift, E-Mail-Adresse, Telefonnummer und Daten über dein Kraftfahrzeug) neben unserem Partner auch für eigene Zwecke. Wir und der jeweilige Partner sind dann gemeinsame Verantwortliche im Sinne des Art. 26 DS-GVO. Mehr zur gemeinsamen Verarbeitung mit unserem Partner erfährst du in der folgenden Ziffer 4. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Der Zweck der Verarbeitung deiner Daten durch uns liegt in der Regel in der Durchführung des mit dir vereinbarten (für dich unentgeltlichen) Vertrags zur Nutzung unserer Plattform als Nutzer bzw. der Bereitstellung eines von dir angefragten Services. Die Rechtsgrundlage für die Erhebung und weitere Verarbeitung ist dann Art. 6 Abs. 1 S. 1 lit. b DS-GVO. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Möchtest du als registrierter Nutzer über unser digitales Angebot einen weiteren, anderen Partner kontaktieren als jenen, über den du ursprünglich auf unsere Plattform gelangt bist, so geben wir die von dir in deinem Nutzeraccount hinterlegten Daten hierzu auch an diesen Partner weiter. Die Weitergabe deiner Daten an einen weiteren Partner erfolgt ausschließlich auf deine Veranlassung und im Rahmen der Vertragserfüllung. Die Rechtsgrundlage für diese weitere Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. b DS-GVO. "
                )
              ]),
              _c(
                "h2",
                {
                  attrs: {
                    id:
                      "gemeinsame-verantwortlichkeit-mit-unseren-partnern-als-nutzer-unserer-digitalen-angebote-mit-nutzeraccount"
                  }
                },
                [
                  _vm._v(
                    " Gemeinsame Verantwortlichkeit mit unseren Partnern als Nutzer unserer digitalen Angebote mit Nutzeraccount "
                  )
                ]
              ),
              _c("p", [
                _vm._v(
                  " Wenn du unsere Plattform (als Gastnutzer oder als registrierter Nutzer mit Nutzeraccount) nutzt und dabei Daten eingibst, verarbeiten wir diese Daten gemeinsam mit unserem Partner, dessen Leistung du über die Plattform beziehst, buchst oder anfragst. Wir sind dann gemeinsame Verantwortliche im Sinne des Art. 26 DSGVO. Wir haben einen Vertrag über die gemeinsame Verantwortlichkeit mit unseren Partnern geschlossen. "
                )
              ]),
              _vm.partner.settings &&
              _vm.partner.settings.privacyUrl &&
              _vm.partner.settings.privacyUrl.link
                ? _c("p", [
                    _vm._v(
                      " Für die Datenverarbeitung durch uns gelten diese Datenschutzbestimmungen. Wie unser jeweiliger Partner deine Daten verarbeiten, erfährst du in der jeweiligen Datenschutzerklärung unseres Partners. Unser Partner " +
                        _vm._s(_vm.partner.companyName) +
                        ", dessen Leistung du aktuell über die Plattform beziehst, buchst oder anfragen kannst, findest du "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.partner.settings.privacyUrl.link
                        }
                      },
                      [_vm._v("hier")]
                    ),
                    _vm._v(". ")
                  ])
                : _vm._e(),
              _c(
                "h2",
                {
                  attrs: {
                    id: "sonstige-kontaktaufnahme"
                  }
                },
                [_vm._v("Sonstige Kontaktaufnahme")]
              ),
              _c("p", [
                _vm._v(
                  " Falls du Informationen über uns und unsere Leistungen wünscht oder mit uns aus sonstigem Grund Kontakt aufnehmen möchten, kannst du uns jederzeit über unsere angegebenen Kontaktdaten kontaktieren. Wir verarbeiten die von dir mitgeteilten Informationen dann, um dein Anliegen zu bearbeiten und mir dir zu kommunizieren. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO oder, falls wir bereits in einem Vertragsverhältnis mit dir stehen oder die Kontaktaufnahme der Anbahnung eines solches Vertragsverhältnisses dient, Art. 6 Abs. 1 S. 1 lit. b DS-GVO. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Du kannst der weiteren Verarbeitung deiner Daten jederzeit widersprechen, wenn du dies nicht mehr wünschst. Die Daten, die wir im Zusammenhang mit einer Bestellung von dir erheben, werden gelöscht, sobald der vereinbarte Vertrag bzw. die Bereitstellung eines von dir angefragten Services vollständig und abschließend bearbeitet ist und keine weitere Kommunikation oder Aufbewahrung von Daten erforderlich ist. "
                )
              ]),
              _c(
                "h2",
                {
                  attrs: {
                    id: "weitergabe-deiner-daten"
                  }
                },
                [_vm._v("Weitergabe deiner Daten")]
              ),
              _c("p", [
                _vm._v(
                  " Wir geben deine Daten nur an Dritte weiter, wenn wir hierzu aufgrund deiner Einwilligung berechtigt oder aufgrund des geltenden Rechts berechtigt oder verpflichtet sind. Gleiches gilt, wenn wir deine Daten von Dritten, d. h. weder von euch noch von uns beauftragten Unternehmen, erhalten. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Neben den in diesen Datenschutzhinweisen ausdrücklich genannten Dritten können gegebenenfalls auch externe Dienstleister für den Betrieb unserer digitalen Angebote im Rahmen von Auftragsverarbeitungsverträgen, Staatliche Stellen und Behörden, soweit dies zur Erfüllung einer gesetzlichen Verpflichtung erforderlich ist, oder zur Durchführung unseres Geschäftsbetriebs eingesetzte Personen (wie z. B. Auditoren, Rechtsberater, Versicherungen, Banken, Aufsichtsbehörden) Zugriff auf deine Daten erhalten. Soweit externe Dienstleister mit deinen personenbezogenen Daten in Berührung kommen, stellen wir durch rechtliche, technische und organisatorische Maßnahmen sicher, dass diese die Vorschriften der Datenschutzgesetze einhalten und – soweit sie als Auftragsverarbeiter tätig werden – deine Daten nur in unserem Auftrag und nach unseren Weisungen verarbeiten. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Einige datenverarbeitende Drittgesellschaften können sich auch außerhalb des Europäischen Wirtschaftsraums (EWR), also in Drittländern, befinden. Eine derartige Verarbeitung erfolgt ausschließlich aufgrund deiner Einwilligung, zur Erfüllung der vertraglichen und geschäftlichen Verpflichtungen und zur Pflege deiner Geschäftsbeziehung zu uns (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit a, lit. b oder lit. f jeweils i. V. m. Art. 44 ff. DS-GVO). Über die jeweiligen Einzelheiten der Weitergabe unterrichten wir dich an den dafür relevanten Stellen. Einigen Drittländern bescheinigt die Europäische Kommission durch sog. Angemessenheitsbeschlüsse einen Datenschutzstandard, der mit dem EWR-Standard vergleichbar ist (eine Liste dieser Länder sowie eine Kopie der Angemessenheitsbeschlüsse erhältst du "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
                    }
                  },
                  [_vm._v("hier")]
                ),
                _vm._v("). ")
              ]),
              _c("p", [
                _vm._v(
                  " Unter anderem bescheinigt die Europäische Kommission den USA durch einen Angemessenheitsbeschluss, dass der im Data Privacy Framework vorgesehene Datenschutzstandard mit dem EWR-Standard vergleichbar ist. Bei einer Übertragung von Daten in die USA durch ein nach dem Data Privacy Framework zertifiziertes Unternehmen wird also ein Datenschutzstandard gewahrt, der dem der DS-GVO entspricht. "
                )
              ]),
              _c(
                "h2",
                {
                  attrs: {
                    id: "speicherdauer-und-l-schung-von-daten"
                  }
                },
                [_vm._v("Speicherdauer und Löschung von Daten")]
              ),
              _c("p", [
                _vm._v(
                  " Falls oben nicht bereits ausdrücklich oder anders angegeben, werden die von uns erhobenen Daten gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Kontaktformulardaten und andere Daten, die du uns im Rahmen einer sonstigen Kontaktaufnahme übermittelst, werden gelöscht, sobald der Zweck der Verarbeitung entfallen ist, d. h. beispielsweise dein Anliegen vollständig und abschließend bearbeitet und erledigt ist und keine weitere Kommunikation mit dir erforderlich oder von dir gewünscht ist. "
                )
              ]),
              _c(
                "h2",
                {
                  attrs: {
                    id: "deine-betroffenenrechte"
                  }
                },
                [_vm._v("Deine Betroffenenrechte")]
              ),
              _c("p", [
                _c("strong", [_vm._v("Recht auf Auskunft:")]),
                _vm._v(
                  " Du hast das Recht, unter den Voraussetzungen des Art. 15 DS-GVO jederzeit unentgeltlich Einsicht in deine bei uns gespeicherten personenbezogenen Daten zu verlangen, wenn wir diese bearbeiten. So hast du die Möglichkeit, zu prüfen, welche personenbezogene Daten wir über dich bearbeiten, und dass wir diese gemäß geltenden Datenschutzbestimmungen verwenden. "
                )
              ]),
              _c("p", [
                _c("strong", [_vm._v("Recht auf Berichtigung:")]),
                _vm._v(
                  " Du hast das Recht, unter den Voraussetzungen des Art. 16 DS-GVO unrichtige oder unvollständige personenbezogene Daten berichtigen zu lassen und über die Berichtigung informiert zu werden. Wir informieren in diesem Fall die Empfänger der betroffenen Daten über die vorgenommenen Anpassungen, sofern dies nicht für uns unmöglich oder mit unverhältnismäßigem Aufwand verbunden ist. "
                )
              ]),
              _c("p", [
                _c("strong", [_vm._v("Recht auf Löschung:")]),
                _vm._v(
                  " Du hast das Recht, dass deine personenbezogenen Daten unter den Voraussetzungen des Art. 17 DS-GVO gelöscht werden. Im Einzelfall kann das Recht auf Löschung gemäß Art. 17 Abs. 3 DS-GVO oder Art. 35 DS-GVO eingeschränkt oder ausgeschlossen sein. "
                )
              ]),
              _c("p", [
                _c("strong", [
                  _vm._v("Recht auf Einschränkung der Bearbeitung:")
                ]),
                _vm._v(
                  " Du hast unter den Voraussetzungen des Art. 18 DS-GVO das Recht zu verlangen, dass die Bearbeitung deiner personenbezogenen Daten eingeschränkt wird. "
                )
              ]),
              _c("p", [
                _c("strong", [_vm._v("Recht auf Datenübertragung:")]),
                _vm._v(
                  " Unter den Voraussetzungen des Art. 20 DS-GVO hast du das Recht, von uns die personenbezogenen Daten, welche du uns bereitgestellt hast, unentgeltlich in einem lesbaren Format zu erhalten. Dabei sind allerdings die Einschränkungen des Art. 20 Abs. 3 und 4 DS-GVO, § 28 BDSG sind zu berücksichtigen. "
                )
              ]),
              _c("p", [
                _c("strong", [_vm._v("Recht auf Widerruf:")]),
                _vm._v(
                  " Du hast das Recht, eine gegebenenfalls erteilte datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt. "
                )
              ]),
              _c(
                "h2",
                {
                  attrs: {
                    id: "widerspruch"
                  }
                },
                [_vm._v("Widerspruch")]
              ),
              _c("p", [
                _vm._v(
                  " Du kannst der Verarbeitung dich betreffender personenbezogener Daten durch uns aus Gründen, welche sich aus deiner besonderen Situation ergeben, jederzeit widersprechen, soweit wir diese Daten zur Wahrnehmung berechtigter Interessen (Art. 6 Abs. 1 S. 1 lit. f DS-GVO) verwenden. Im Fall des berechtigten Widerspruchs haben wir jede weitere Verarbeitung deiner Daten zu unterlassen, soweit sie nicht aus zwingenden schutzwürdigen Gründen, die deine Interessen, Rechte und Freiheiten überwiegen, oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen notwendig ist. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Soweit deine personenbezogenen Daten durch uns für Zwecke der Direktwerbung verarbeitet werden, hast du das recht, jederzeit uneingeschränkt Widerspruch gegen die Verarbeitung deiner Daten für derartige Werbezwecke einzulegen. In diesem Fall ist die Angabe einer besonderen Situation nicht erforderlich. Im Falle deines Widerspruchs wird die Verarbeitung für Zwecke der Direktwerbung umgehend eingestellt. "
                )
              ]),
              _c("p", [
                _vm._v(
                  "Zum Zwecke des Widerspruchs kannst du formfrei die oben genannten Kontaktmöglichkeiten nutzen."
                )
              ]),
              _c(
                "h2",
                {
                  attrs: {
                    id: "beschwerderecht"
                  }
                },
                [_vm._v("Beschwerderecht")]
              ),
              _c("p", [
                _vm._v(
                  " Sofern du der Auffassung bist, dass die Verarbeitung der dich betreffenden personenbezogenen Daten durch uns gegen datenschutzrechtliche Bestimmungen verstößt, steht dir ein Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat deines gewöhnlichen Aufenthaltsorts, deines Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, nach Art. 77 DS-GVO zu. "
                )
              ]),
              _c("p", [
                _vm._v(" In Niedersachsen ist die "),
                _c("strong", [
                  _vm._v(
                    "Landesbeauftragte für Datenschutz und Informationsfreiheit Niedersachsen"
                  )
                ]),
                _vm._v(
                  " die zuständige Aufsichtsbehörde, die unter nachfolgenden Kontaktdaten zu erreichen ist: "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Landesbeauftragte für Datenschutz und Informationsfreiheit Niedersachsen Prinzenstraße 5 30159 Hannover Telefon: 0511/120-4500 Fax: 0511/120-4599 E-Mail: poststelle@lfd.niedersachsen.de "
                )
              ]),
              _c(
                "h2",
                {
                  attrs: {
                    id: "datensicherheit"
                  }
                },
                [_vm._v("Datensicherheit")]
              ),
              _c("p", [
                _vm._v(
                  " Zur Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, werden deine Daten verschlüsselt übertragen. Wir sichern unsere Website und die sonstigen Systeme durch technische und organisatorische Maßnahmen, insbesondere die Verschlüsselungstechnologie TSL (Transport Layer Security), gegen Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung deiner Daten durch unbefugte Personen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Wir treffen unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der Eintrittswahrscheinlichkeit und Schwere der Verletzung von Rechten und Freiheiten natürlicher Personen angemessene technische und organisatorische Maßnahmen im Sinne von Art. 32 DS-GVO. "
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }