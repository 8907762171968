










































import { simpleDate, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IAttachmentRequest } from "@/models/attachment-request.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {},
  filters: { simpleDate }
})
export default class RefsAttachmentRequest extends mixins(Vue) implements IRefDetail<IAttachmentRequest> {
  @Prop()
  item!: IAttachmentRequest;

  @Prop()
  small!: boolean;

  confirmable = false;

  get date() {
    return simpleDoubleDigitDate(this.item.timestamp.created);
  }

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  simpleDate(date: string) {
    return simpleDate(date);
  }

  get report() {
    return this.item.refs.find(ref => ref.refType === ResourceEnum.REPORT);
  }

  async goToDetail() {
    if (!this.report) {
      return;
    }

    new GoToHelper(this.$router).goToReportDetail(this.report.refId, this.item.partnerId);
  }
}
