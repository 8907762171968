












import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import TemplateCard from "../template/TemplateCard.vue";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { IReference } from "@/models/reference.entity";
import { PartnerTemplate } from "@/models/partner-template.entity";

@Component({
  components: {
    TemplateCard
  }
})
export default class PreselectTemplateCard extends Vue {
  @Prop()
  private templateKey!: string;

  @Prop()
  private language!: LanguageCodeEnum;

  @Prop({ default: [] })
  to!: string[];

  @Prop({ default: {} })
  context!: ITemplateContext;

  @Prop({ default: () => [] })
  refs!: IReference[];

  loading = true;

  get partnerId() {
    return this.$route.params.partnerId || PartnerModule.partner._id;
  }

  get partner() {
    return PartnerModule.partner;
  }

  async mounted() {
    try {
      this.loading = true;
      (this.$refs.templateCard as TemplateCard).setLoading(true);
      const template = new PartnerTemplate({
        partnerId: this.partnerId,
        key: this.templateKey
      });
      template.meta.language = this.language;
      await template.fetch();

      if (template) {
        (this.$refs.templateCard as TemplateCard).setTemplate(template);
      }
    } catch (e) {
      this.$log.error(e);
    } finally {
      (this.$refs.templateCard as TemplateCard).setLoading(false);
      this.loading = false;
    }
  }

  async send() {
    await (this.$refs.templateCard as TemplateCard).send();
  }
}
