var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report",
    {
      attrs: {
        renderBackAndForthFooter: false,
        isProgressDone: true,
        showFooter: false
      }
    },
    [
      !_vm.isProd
        ? _c(
            "v-alert",
            {
              attrs: {
                prominent: "",
                type: "error",
                elevation: "24"
              }
            },
            [
              _c("strong", [_vm._v("Test Umgebung")]),
              _vm._v(" - die AGBs gelten ausdrücklich "),
              _c("strong", [_vm._v("nicht")]),
              _vm._v(
                " für diese Webseite. Da es sich um eine Test Umgebung die nur zu Entwicklungszwecken genutzt wird gelten keine der beschriebenen Rechte und Pflichten des Betreibers. Jegliche Haftungs ist ausgeschlossen. "
              )
            ]
          )
        : _vm._e(),
      _c(
        "v-container",
        {
          staticClass: "padded"
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c("v-card-title", [
                _vm._v("Allgemeine Geschäftsbedingungen mint future")
              ]),
              _c("v-card-subtitle", [
                _vm._v("(zuletzt aktualisiert am 02.04.2025)")
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id: "präambel"
                      }
                    },
                    [_vm._v("Präambel")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " (1) Diese mint future Nutzungsbedingungen (“Bedingungen” oder „Vereinbarung“) beschreiben deine Rechte und Pflichten als Nutzer unserer Dienstleistung für die transparente und einfache Abwicklung der Vermarktung der Treibhausgasminderungsquote (“THG-Quote”) deines E-Fahrzeugs oder deiner öffentlichen E-Ladesäule (der „Service“) sowie die Nutzung der mint future Plattform. Durch Bestätigung dieser Bedingungen im Rahmen einer abgeschlossenen Registrierung nach Ziffer 2 wird eine rechtsverbindliche Vereinbarung geschlossen. Maßgebend ist jeweils die bei Registrierung gültige Fassung der AGB. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " (2) Diese Bedingungen gelten zwischen dir und dem Plattformbetreiber von www.mintfuture.de, der mint future GmbH, Marie-Curie-Str. 3, 49076 Osnabrück, Deutschland, E-Mail: info@mintfuture.de, Website: www.mintfuture.de, (“mint future”, „Anbieter“ “wir” oder “uns” genannt). "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " (3) “Nutzer” ist Halter eines E-Fahrzeugs oder eine vom Halter zur Geltendmachung der THG-Quote eines E-Fahrzeugs bevollmächtigte Person bzw. der Betreiber einer öffentlichen E-Ladesäule. Der Service richtet sich sowohl an Verbraucher (§ 13 BGB) (nachfolgend „Privatnutzer“) als auch an Unternehmer (§ 14 BGB) (nachfolgend „Gewerbenutzer“). Der Service richtet sich ausschließlich an volljährige Personen; ein Nutzer muss, sofern er eine natürliche Person ist, das 18. Lebensjahr vollendet haben. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " (4) „E-Fahrzeuge” sind reine Batterie-Elektrofahrzeugen, d.h. solche, deren Fahrzeugschein bei der Kraftstoffart “Elektro” (Feld P.3) und beim Kraftstoffcode (Feld 10) “0004” eingetragen hat. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " (5) Öffentliche E-Ladesäulen „E-Ladesäulen“ sind öffentlich zugängliche Einrichtungen im Sinne des § 2 Nr. 2, 5 LSV, die bei der Bundesnetzagentur (BNetzA) registriert sind. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " (6) Der Service von mint future unterliegt jederzeit der jeweils gültigen Fassung des Bundesimmissionsschutzgesetzes i.V.m. der jeweils gültigen Fassung der Verordnung zur Festlegung weiterer Bestimmungen zur Treibhausgasminderung bei Kraftstoffen. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " (7) mint future unterhält neben diesen Bedingungen auch Datenschutzbestimmungen und ein Verteilmodell. Diese sind auf der Website von mint future einzusehen. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id: "1-vertragsgegenstand"
                      }
                    },
                    [_vm._v("1 Vertragsgegenstand")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " mint future bietet dem Nutzer einen „Service“, der die Anmeldung eines E-Fahrzeugs beim Umweltbundesamt zur Geltendmachung der THG-Quote, die Bündelung der THG-Quote des Nutzers mit den Quoten anderer Nutzer (Pooling) und die Vermarktung der gebündelten THG-Quoten der Nutzer umfasst. Im Anschluss erhält der Nutzer von der mint future einen vorab im Rahmen des Verteilmodells (Ziffer 6.1) festgelegten Anteil aus dem Erlös der THG-Quotenvermarkung. mint future verfolgt darüber hinaus das Ziel soziale, ökologische und nachhaltige Projekte zu unterstützen. Hierzu kann der Nutzer den ihm zustehenden Erlös aus der THG-Quotenvermarktung vollständig oder teilweise zur Förderung sozialer, ökologischer und nachhaltiger Projekte durch mint future freigeben (Ziffer 6.2). "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id:
                          "2-registrierung-und-vertragsschluss-zur-plattformnutzung"
                      }
                    },
                    [
                      _vm._v(
                        " 2 Registrierung und Vertragsschluss zur Plattformnutzung "
                      )
                    ]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 2.1 Zur Nutzung des Service muss sich der Nutzer zunächst kostenlos auf der Plattform von mint future registrieren. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 2.2 Der Nutzer registriert sich auf der Plattform durch Eingabe der abgefragten Daten in dem entsprechenden Eingabeformular. Durch Bestätigung der Angaben, der Zustimmung zu diesen Bedingungen und der Erklärung des Registrierungsgesuchs übermittelt der Nutzer die Daten aus dem Eingabeformular und gibt ein Angebot zur Nutzung der Plattform ab. Nach Eingang des Angebots versendet mint future eine E-Mail an die vom Nutzer angegebene E-Mail-Adresse mit einem Bestätigungscode. Der Registrierungsvorgang und der Vertrag kommen erst mit der Bestätigung durch Eingabe des Bestätigungscodes auf der Plattform zustande. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 2.3 Ein Gewerbenutzer muss im Registrierungsprozess den Namen des Unternehmens angeben und eine E-Mail-Adresse des Unternehmens verwenden. Eine im Namen des Unternehmens handelnde Person versichert mit der Registrierung, zur Vertretung des Unternehmens berechtigt zu sein. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 2.4 Der Nutzer ist bei seiner Registrierung verpflichtet, die Richtigkeit, Vollständigkeit und Aktualität seiner Angaben sicherzustellen. Während der Vertragslaufzeit ist der Nutzer verpflichtet mint future etwaige Änderungen seiner angegebenen Daten, durch Aktualisierung dieser über die Plattform, anzuzeigen. Die Zugangsdaten zur Plattform sind als vertrauliche Informationen zu behandeln und nicht an Dritte weiterzugeben. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id:
                          "3-anmeldung-von-e-fahrzeugen-und-vertragsschluss-über-den-service"
                      }
                    },
                    [
                      _vm._v(
                        " 3 Anmeldung von E-Fahrzeugen und Vertragsschluss über den Service "
                      )
                    ]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 3.1 Ein registrierter Nutzer kann auf der Plattform E-Fahrzeuge für den Service anmelden. Die Anmeldung eines E-Fahrzeugs für den Service kann für das laufende Kalenderjahr bis spätestens zum 10.11 erfolgen. Für den Geltungszeitraum der Anmeldung kann der Nutzer zwischen zwei Möglichkeiten wählen. Er kann einzeln Auswählen für welche Kalenderjahre die Anmeldung erfolgen soll oder das Fahrzeug auf unbestimmte Zeit anmelden. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 3.2 Besteht zwischen dem Nutzer und mint future keine abweichende Vereinbarung, kann der Nutzer auf der Plattform höchstens " +
                        _vm._s(_vm.maxVehicleCount) +
                        " E Fahrzeuge für den Service jährlich anmelden. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 3.3 Die erstmalige Anmeldung eines E-Fahrzeugs erfolgt durch Bereitstellung einer Abbildung der KFZ-Zulassungsbescheinigung Teil I („Fahrzeugschein“). Ist der Nutzer nicht selbst Halter des jeweiligen E-Fahrzeugs, bestätigt er mit der Registrierung seine Berechtigung, das E-Fahrzeug für den Service anmelden zu dürfen. Die erneute Anmeldung eines E-Fahrzeugs für ein weiteres Jahr erfolgt entweder durch einen erneuten Upload des Fahrzeugscheins oder durch ein anderes auf der mint future-Plattform vorgesehenes Verfahren mit geringeren Anforderungen. Das Verfahren zur erneuten Anmeldung kann durch mint future vorgegeben werden. Sofern gesetzlich oder untergesetzlich weitere Nachweise erforderlich sein sollten, kann mint future diese vom Nutzer für die Anmeldung oder Bestätigung fordern und die Anmeldung von deren Bereitstellung abhängig machen. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 3.4 Durch Bestätigen der Anmeldung übermittelt der Nutzer die Daten aus dem Eingabeformular, gibt ein Angebot zur Nutzung des Service und zur Abtretung sämtlicher Rechte im Hinblick auf die Geltendmachung und Vermarktung der THG-Quote für das in dem jeweiligen Fahrzeugschein genannte E-Fahrzeug für das jeweils ausgewählte Kalenderjahr oder auf unbestimmte Zeit ab und stimmt erneut diesen Bedingungen und dem Verteilmodell (s. Ziffer 6.1) zu. Nach Eingang des Angebots und nach Prüfung der hochgeladenen Nachweise kann mint future eine Bestätigung an die vom Nutzer angegebene E-Mail-Adresse versenden. Der Vertrag über den Service und die Abtretung kommt erst mit dieser Bestätigung durch mint future zustande. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 3.5 mint future ist berechtigt, sämtliche Ansprüche aus oder im Zusammenhang mit der THQ-Quote des Nutzers an Partnerunternehmen abzutreten. Der Nutzer stimmt bereits mit Abgabe seines Angebots einer entsprechenden Abtretung zu. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 3.6 Mit der Anmeldung des Elektrofahrzeugs stimmt der Nutzer der Anmeldung der abgetretenen THG-Quote des E-Fahrzeugs durch mint future oder ein Partnerunternehmen und der Übermittlung des Fahrzeugscheins sowie sämtlicher sonstiger angegebener Daten an das Umweltbundesamt, alle anderen zuständigen Behörden und Stellen sowie an notwendige Dritte, etwa Abnehmer der THG-Quoten (einschließlich Partnerunternehmen) und ähnlichen Personen zu. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 3.7 Der Nutzer versichert, dass er über das von der Abtretung erfasste Recht uneingeschränkt verfügungsberechtigt ist und dieses für den gesamten Zeitraum der Anmeldung nicht anderweitig abgetreten wurde/wird oder auf sonstige Weise über dieses Recht verfügt wurde/wird. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 3.8 Der Nutzer sichert zu, dass er bei der Anmeldung eines E-Fahrzeuges alle Informationen wahrheitsgetreu angibt und diese nicht verfälscht oder manipuliert. Der Nutzer hat dafür Sorge zu tragen, dass die Abbildung des Fahrzeugscheins vollständig und gut lesbar ist. Für Schäden, die mint future falsche Angaben oder Nachweise des Nutzers entstehen, haftet der Nutzer. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 3.9 Wurde eine Anmeldung für das laufende Kalenderjahr vorgenommen und wird das Fahrzeug anschließend verkauft, kann der neue Besitzer die Strommengen für das laufende Jahr nicht geltend machen, da die Strommengen für ein Fahrzeug nur einmal in einem Jahr gemeldet werden können. Im Sinne des Verbraucherschutzes weisen wir den Nutzer darauf hin, dass der Verkäufer des Fahrzeuges dem Käufer mitzuteilen hat, ob die Strommengen bereits angemeldet wurden. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id:
                          "4-anmeldung-von-e-ladesäulen-und-vertragsschluss-über-den-service"
                      }
                    },
                    [
                      _vm._v(
                        " 4 Anmeldung von E-Ladesäulen und Vertragsschluss über den Service "
                      )
                    ]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 4.1 Ein registrierter Nutzer kann auf der Plattform E-Ladesäulen für den Service anmelden. Die Anmeldung einer E-Ladesäule für den Service kann spätestens zum 23.02. für das vergangene Kalenderjahr erfolgen. Die Anmeldung einer E-Ladesäule gilt jeweils für das bei der Anmeldung ausgewählte Kalenderjahr. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 4.2 Besteht zwischen dem Nutzer und mint future keine abweichende Vereinbarung, kann Nutzer an der E-Ladensäule entnommene Strommengen bis zu einer Höchstmenge 50.000,00 kWh jährlich für den Service anmelden. Die Höchstmenge übersteigende Anmeldungen werden individuell zwischen dem Nutzer und mint future unter Abschluss eines gesonderten Vertrages mit individueller Preisabsprache vereinbart. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 4.3 Die erstmalige Anmeldung einer E-Ladesäule erfolgt durch Eingabe der abgefragten Daten in dem entsprechenden Eingabeformular. Die vom Nutzer bereitzustellenden Informationen umfassen insbesondere Betreiberinformationen, technische Informationen betreffend die Ladesäule, Registrierungsinformationen, Zählernummer und Ableseständen zu verschiedenen Zeitpunkten, sowie eventuelle Nachweise hierüber. mint future kann weitere notwendige Informationen sowie Nachweise darüber von Nutzer verlangen und die Anmeldung von deren Bereitstellung abhängig machen. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 4.4 Durch Bestätigen der Anmeldung der Ladesäule übermittelt der Nutzer die Daten aus dem Eingabeformular, gibt ein Angebot zur Nutzung des Service und zur Abtretung sämtlicher Rechte im Hinblick auf die Geltendmachung und Vermarktung der THG-Quote für die jeweilige E-Ladesäule für das jeweils ausgewählte Kalenderjahr ab und stimmt erneut diesen Bedingungen und dem Verteilmodell (s. Ziffer 6.1) zu. Nach Eingang des Angebots und nach Prüfung der hochgeladenen Nachweise kann mint future eine Bestätigung an die vom Nutzer angegebene E-Mail-Adresse versenden. Der Vertrag über den Service und die Abtretung kommt erst mit dieser Bestätigung durch mint future zustande. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 4.5 mint future ist berechtigt, sämtliche Ansprüche aus oder im Zusammenhang mit der THQ-Quote des Nutzers an Partnerunternehmen abzutreten. Der Nutzer stimmt bereits mit Abgabe seines Angebots einer entsprechenden Abtretung zu. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 4.6 Mit der Anmeldung der E-Ladesäule stimmt der Nutzer der Anmeldung der abgetretenen THG-Quote der E-Ladesäule durch mint future oder ein Partnerunternehmen und der Übermittlung sämtlicher angegebener Daten an das Umweltbundesamt, alle anderen zuständigen Behörden und Stellen sowie an notwendige Dritte, etwa Abnehmer der THG-Quoten (einschließlich Partnerunternehmen) und ähnlichen Personen zu. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 4.7 Der Nutzer versichert, dass er über das von der Abtretung erfasste Recht uneingeschränkt verfügungsberechtigt ist und dieses für das jeweils ausgewählte Kalenderjahr nicht anderweitig abgetreten wurde oder auf sonstige Weise über dieses Recht verfügt wurde. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 4.8 Der Nutzer sichert zu, dass er bei der Anmeldung einer E-Ladesäule alle Informationen wahrheitsgetreu angibt und diese nicht verfälscht oder manipuliert. Für Schäden, die mint future falsche Angaben oder Nachweise des Nutzers entstehen, haftet der Nutzer. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id: "5-vermarktung-der-thg-quote"
                      }
                    },
                    [_vm._v("5 Vermarktung der THG-Quote")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 5.1 mint future vertreibt die THG-Quoten im eigenen Namen und auf eigene Rechnung. Der Nutzer wird nach Verkauf der gesammelten THG-Quoten anteilig gemäß Ziffer 6.1 am Verkaufserlös beteiligt. mint future wird sich bemühen, THG-Quoten zu einem möglichst hohen Preis zu verkaufen. Die Entscheidung über den Preis, den Zeitpunkt, sowie die Art und Weise der Vermarktung bzw. Verwertung steht dabei im alleinigen Ermessen von mint future. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 5.2 Die erfolgreiche Geltendmachung und Vermarktung der THG-Quote eines Nutzers hängt unter anderem davon ab, dass die zuständigen Behörden das Bestehen des Rechts bestätigen. Sofern die Geltendmachung der THG-Quote durch die zuständigen Behörden nicht akzeptiert wird, hat der Nutzer keinen Anspruch auf eine Auszahlung des Erlöses nach Ziffer 6. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id: "6-vergütung"
                      }
                    },
                    [_vm._v("6 Vergütung")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 6.1 Der Erlös aus dem THG-Quotenhandel wird anhand des aktuellen „Verteilmodells“ verteilt, welches die anteilige Verteilung der Erlöser auf Nutzer, die Provision der mint future und die Möglichkeit der Freigabe von Erlösen zur Investition durch mint future in soziale, nachhaltige und/ oder ökologische Projekte angibt. Diesem Verteilmodell hat der Nutzer im Rahmen der Anmeldung eines Fahrzeugs zuzustimmen. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 6.2 Es steht dem Nutzer bei der Anmeldung eines E-Fahrzeugs frei, darüber zu entscheiden, bis zu welcher Höhe er einen ihm zustehenden Anteil des Erlöses maximal ausgezahlt bekommen möchte und ab welcher Höhe mint future darüberhinausgehende Anteile des dem Nutzer zustehenden Erlöses zur Förderung sozialer, nachhaltiger und/ oder ökologischer Projekte genutzt werden soll („freigegebene Erlöse“). Solche Projekte können im Verteilmodell oder auf der Plattform beispielhaft aufgeführt werden; es steht aber im freien Ermessen von mint future welche Projekte mit freigegebenen Erlösen anschließend gefördert werden. Der dem Nutzer aus dem Erlös der THG-Quotenvermarktung zustehende Auszahlungsbetrag reduziert sich um die freigegebenen Erlöse. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 6.3 mint future informiert die Nutzer nach Vermarktung der THG-Quote über den damit erzielten Erlös und zahlt den diesem nach dem Verteilmodell anteilig zustehenden Erlös auf das bei der Anmeldung angegebene Konto aus. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id: "7-vertragslaufzeit-und-kündigung"
                      }
                    },
                    [_vm._v("7 Vertragslaufzeit und Kündigung")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 7.1 Die Vereinbarung über den Service der mint future beginnt mit Anmeldung eines E-Fahrzeugs oder einer E-Ladesäule. Die Anmeldung erfolgt immer für volle Kalenderjahre. Die Vereinbarung über den Service für E-Ladesäulen endet automatisch mit Beendigung des Services für das jeweilige Jahr, für das die Anmeldung erfolgt ist. Der Service ist beendet, nachdem mint future den THG-Quotenhandel für den Nutzer durchgeführt hat und dieser seinen Erlös nach Ziffer 6.3 erhalten hat. Das Ende der Vereinbarung für E-Fahrzeuge ist abhängig von der Wahl der unter Ziffer 3.1 genannten Art der Anmeldung. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 7.1.1 Erfolgt die Anmeldung des E-Fahrzeugs nur für einzeln ausgewählte Kalenderjahre, dann endet die Vereinbarung über den Service automatisch mit Beendigung des Services für das jeweilige Jahr, für das das die Anmeldung erfolgt ist. Der Service ist beendet, nachdem mint future den THG-Quotenhandel für den Nutzer durchgeführt hat und dieser seinen Erlös nach Ziffer 6.3 erhalten hat. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 7.1.2 Erfolgt die Anmeldung des E-Fahrzeugs für eine unbestimmte Zeit endet die Vereinbarung über den Service mit der Kündigung. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 7.1.3 Bei einer Anmeldung für eine unbefristete Zeit kann jede Vertragspartei die Vereinbarung über den Service eines E-Fahrzeugs zum 15 Dezember des laufenden Kalenderjahres ohne Angaben von Gründen in Textform ordentlich kündigen. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 7.2 Die Vereinbarung über die Nutzung der Plattform tritt mit Registrierung in Kraft und wird auf unbestimmte Zeit geschlossen. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 7.3 Die Vereinbarung ist für die Zeit, in der eine E-Ladesäule für den mint future Service angemeldet ist oder eine E-Fahrzeug gemäß Ziffer 7.1.1 angemeldet ist, nicht ordentlich kündbar. Ist noch kein E-Fahrzeug oder eine E-Ladesäule angemeldet oder ist der Service nach Ziffer 7.1.1 beendet und es ist keine erneute Anmeldung erfolgt, kann diese Vereinbarung jederzeit gekündigt werden. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      "7.4 Das Recht zur außerordentlichen Kündigung bleibt hiervon unberührt."
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 7.5 mint future kann diese Vereinbarung jederzeit, sofort und ohne Vorankündigung kündigen und den Zugang zur Plattform einstellen, wenn ein Nutzer gegen diese Bedingungen oder gegen geltende Gesetze verstößt oder mint future vernünftigerweise glaubt, dass eine Kündigung zum Schutz von mint future oder Dritten notwendig ist. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 7.6 Nach Beendigung dieser Vereinbarung wird der Zugang des Nutzers zur Plattform gesperrt. Es besteht kein Anspruch auf Wiederherstellung von hinterlegten Inhalten. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      "7.7 Teile dieser Bedingungen, die ihrer Natur nach die Beendigung überdauern, gelten fort."
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 7.8 Kündigungen haben stets schriftlich oder in Textform per E-Mail von der für das Nutzerkonto hinterlegten E-Mail-Adresse zu erfolgen. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id: "8-haftung-auf-schadensersatz"
                      }
                    },
                    [_vm._v("8 Haftung auf Schadensersatz")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 8.1 Der Anbieter haftet dem Nutzer gegenüber in allen Fällen vertraglicher und außervertraglicher Haftung bei Vorsatz und grober Fahrlässigkeit nach Maßgabe der gesetzlichen Bestimmungen auf Schadensersatz oder Ersatz vergeblicher Aufwendungen. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 8.2 In sonstigen Fällen haftet der Anbieter - soweit in Ziffer 7.3 nicht abweichend geregelt - nur bei Verletzung einer Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf (sog. Kardinalpflicht), und zwar beschränkt auf den Ersatz des vorhersehbaren und typischen Schadens. In allen übrigen Fällen ist die Haftung des Anbieters - vorbehaltlich der Regelung in Ziffer 7.3 - ausgeschlossen. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 8.3 Die Haftung des Anbieters für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und nach dem Produkthaftungsgesetz bleibt von den vorstehenden Haftungsbeschränkungen und –ausschlüssen unberührt. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id: "10-sonderbedingungen-für-einen-neukunden--bonus"
                      }
                    },
                    [_vm._v(" 9 Sonderbedingungen für einen Neukunden-Bonus ")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 9.1 Diese Sonderbedingungen gelten für den Fall, dass einem Nutzer von mint future vor oder bei Vertragsschluss mit dem Anbieter ein Neukunden-Bonus versprochen oder in Aussicht gestellt wird; es handelt sich hierbei um ein einseitiges Bonusversprechen. Ein Nutzer kann den Neukunden-Bonus nur einmal in Anspruch nehmen; auch bei Registrierung mehrerer E-Fahrzeuge wird der Neukunden-Bonus höchstens einmal fällig. "
                    )
                  ]),
                  _c("v-card-text", [_vm._v(" 9.2 Bonusberechtigung ")]),
                  _c("v-card-text", [
                    _vm._v(
                      " 9.2.1 9.2.1 Der Nutzer, der einen Neukunden-Bonus für sich in Anspruch nehmen möchte, darf in der Vergangenheit nicht in einem Vertragsverhältnis mit mint future gestanden haben oder mit einer Person in dem gleichen Haushalt wohnen, die in der Vergangenheit in einem Vertragsverhältnis mit mint future stand („Neukunde“). "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 9.2.2 Kooperationspartner, die mint future über eine sog. White Label – Lösung im Rahmen ihres eigenen Online-Angebots Kunden vermitteln, gelten nicht als Neukunden im Sinne dieser Vorschrift. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 9.2.3 Nicht bonusberechtigt sind Neukunden, die über mehr als fünf THG-Quoten-berechtigte Einheiten (E-Fahrzeug und/oder E-Ladesäule) verfügen oder innerhalb von sechs (6) Monaten mehr als fünf THG-Quoten-berechtigte Einheiten bei mint future anmelden. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 9.2.4 mint future ist berechtigt, einzelne Neukunden von dem Neukunden-Bonus auszuschließen bzw. eine Auszahlung zu verweigern, wenn der Verdacht einer rechtsmissbräuchlichen Inanspruchnahme des Neukunden-Bonus besteht. Lässt sich ein solcher Rechtsmissbrauch nachweisen, können bereits ausgezahlte Beträge von dem Nutzer zurückverlangt werden. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(" 9.3 Bonusbedingungen und Bonuszahlung ")
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 9.3.1 Voraussetzung der Gewährung eines Neukunden-Bonus ist die Registrierung für das THG-Programm von mint future und die Anmeldung (mindestens) eines E-Fahrzeug zur Vermarktung der THG-Quote durch mint future. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 9.3.2 Ein Neukunden-Bonus wird nur und erst dann gewährt, wenn der zur Bonus berechtigende THG-Antrag erfolgreich abgewickelt wurde und ein Anspruch ein Vergütungsanspruch gemäß Ziffer 6 dieser AGB zugunsten des Nutzers entstanden ist. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 9.3.3 Die Auszahlung eines Neukunden-Bonus erfolgt durch eine einmalige Erhöhung der dem Nutzer zustehenden Vergütung gemäß Ziffer 6 um den als Neukunden-Bonus versprochenen Betrag. Ein Anspruch auf eine frühere oder separate Auszahlung des Neukunden-Bonus besteht nicht. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 9.3.4 mint future kann vor oder bei Vertragsschluss weitere Bonusbedingungen vorsehen. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id: "10-sonderbedingungen-des-freunde-werben--programms"
                      }
                    },
                    [
                      _vm._v(
                        " 10 Sonderbedingungen des „Freunde werben“ – Programms "
                      )
                    ]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 10.1 Diese Sonderbedingungen gelten für die Werbung von Neukunden für das THG-Programm von mint future durch eine werbende Person („Empfehlungsgeber“). "
                    )
                  ]),
                  _c("v-card-text", [_vm._v("10.2 Teilnahmeberechtigung")]),
                  _c("v-card-text", [
                    _vm._v(
                      " 10.2.1 Der Neukunde und der Empfehlungsgeber müssen verschiedene natürliche und volljährige Personen sein. Sie dürfen nicht in demselben Haushalt wohnen. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 10.2.2 Jeder Neukunde kann nur einen Empfehlungsgeber angeben bzw. einen persönlichen Einladungslink verwenden. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 10.2.3 10.2.3 Kooperationspartner die mint future über eine sog. White Label – Lösung im Rahmen ihres eigenen Online-Angebots Kunden vermitteln, sind von der Teilnahme an dem „Freunde werben“ – Programm ausgeschlossen. Dasselbe gilt für ihre Organe oder Mitarbeiter und deren nahestehenden Personen im Sinne von § 15 AO. "
                    )
                  ]),
                  _c("v-card-text", [_vm._v("10.3 Bonusbedingungen")]),
                  _c("v-card-text", [
                    _vm._v(
                      " Voraussetzung einer Bonusgewährung ist die erfolgreiche Werbung eines Neukunden durch den Empfehlungsgeber. Eine solche Werbung liegt nur vor, wenn ein Neukunde auf den für den Empfehlungsgeber generierten persönlichen Einladungslink klickt, sich für das THG-Programm von mint future registriert und (mindestens) ein E-Fahrzeug oder eine E-Ladesäule zur Vermarktung der THG-Quote durch mint future anmeldet. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      "10.4 Einschränkungen des „Freunde werben“ – Programms"
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 10.4.1 Mint behält sich vor, die Bedingungen des „Freunde werben“ – Programms jederzeit zu ändern oder das Programm jederzeit ersatzlos einzustellen. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 10.4.2 Die Änderung oder Beendigung des Programms erfolgt unmittelbar durch die Änderung dieser Bedingungen auf der Homepage von mint future. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 10.4.3 10.4.3 mint future ist berechtigt, einzelne Empfehlungsgeber oder (Neu-)Kunden von dem Programm auszuschließen, wenn der Verdacht einer rechtsmissbräuchlichen Nutzung des Programms besteht. Lässt sich eine solche Verwendung nachweisen, können bereits ausgezahlte Beträge von dem Empfehlungsgeber zurückverlangt werden. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id: "11-widerrufsrecht-des-verbrauchers"
                      }
                    },
                    [_vm._v("11 Widerrufsrecht des Verbrauchers")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 11.1 Diese Vereinbarung räumt dem Verbraucher ein Widerrufsrecht nach Maßgabe der nachfolgenden Widerrufsbelehrung ein. Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann (§ 13 BGB). "
                    )
                  ]),
                  _c("v-card-text", [_vm._v("11.2 Widerrufsbelehrung")]),
                  _c("v-card-text", [
                    _vm._v(
                      " Widerrufsrecht Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (mint future GmbH, Marie-Curie-Straße 3, 49076 Osnabrück, E-Mail: info@mintfuture.de mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief￼ oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " Folgen des Widerrufs Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. "
                    )
                  ]),
                  _c("v-card-text", [_vm._v("Ende der Widerrufsbelehrung")]),
                  _c("v-card-text", [_vm._v("Muster-Widerrufsformular")]),
                  _c("v-card-text", [
                    _vm._v(
                      " (wenn Sie den Vertrag widerrufen möchten, dann füllen Sie bitte dieses Formular aus und senden es zurück) "
                    )
                  ]),
                  _c(
                    "v-sheet",
                    {
                      staticClass: "ma-4",
                      attrs: {
                        elevation: 5,
                        rounded: ""
                      }
                    },
                    [
                      _c("v-card-text", [
                        _vm._v(" mint future GmbH"),
                        _c("br"),
                        _vm._v(" Marie-Curie-Straße 3"),
                        _c("br"),
                        _vm._v(" 49076 Osnabrück"),
                        _c("br"),
                        _vm._v(" E-Mail: info@mintfuture.de"),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung: ____________________________________"
                        ),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _vm._v(" – Bestellt am: ______________"),
                        _c("br"),
                        _vm._v(" – Name des/der Verbraucher(s)"),
                        _c("br"),
                        _vm._v(" – Anschrift des/der Verbraucher(s)"),
                        _c("br"),
                        _vm._v(" – Unterschrift des/der Verbraucher(s)"),
                        _c("br"),
                        _vm._v(" – Datum ")
                      ])
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 11.3 Wir sind verpflichtet, dich als Verbraucher auf die Plattform zur Online-Streitbeilegung (OS-Plattform) der Europäischen Kommission hinzuweisen. Diese OS-Plattform ist über folgenden Link erreichbar: https://webgate.ec.europa.eu/odr. Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle allerdings nicht teil. "
                    )
                  ]),
                  _c(
                    "v-card-title",
                    {
                      attrs: {
                        id: "12-schlussbestimmungen"
                      }
                    },
                    [_vm._v("12 Schlussbestimmungen")]
                  ),
                  _c("v-card-text", [
                    _vm._v(
                      " 12.1 Diese Vereinbarung gilt für alle Anwendungsfälle des Nutzers. Dies gilt insbesondere auch dann, wenn der Nutzer Allgemeine Geschäftsbedingungen verwendet und diese entgegenstehende oder von der hier aufgeführten Vereinbarung abweichende Bedingungen enthalten. Auch gilt diese Vereinbarung, wenn der Anbieter in Kenntnis entgegenstehender oder von den hier aufgeführten Bedingungen abweichender Bedingungen des Nutzers den Vertrag vorbehaltlos ausführt. Abweichungen von der hier aufgeführten Vereinbarung sind nur dann gültig, wenn ihnen der Anbieter ausdrücklich schriftlich zustimmt. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 12.2 Diese Vertragsbedingungen unterliegen ausschließlich deutschem Recht unter Ausschluss des UN-Kaufrechts. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 12.3 Ist der Nutzer Kaufmann, eine juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, ist ausschließlicher Gerichtsstand der Sitz des Anbieters in Osnabrück. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 12.4 Die Parteien sind nicht berechtigt, Ansprüche oder sonstige Rechte aus diesem Vertrag ohne Zustimmung der jeweils anderen Parteien zu übertragen, zu verpfänden oder in sonstiger Weise zu belasten. "
                    )
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " 12.5 Sollten Bestimmungen dieses Vertrages ganz oder teilweise nicht rechtswirksam oder durchführbar sein oder werden, so wird hierdurch die Gültigkeit der übrigen Bestimmungen dieses Vertrages nicht berührt. Das gleiche gilt, soweit sich in diesem Vertrag eine Lücke herausstellen sollte. Anstelle der unwirksamen oder undurchführbaren Bestimmung oder zur Ausfüllung der Lücke soll eine angemessene Regelung gelten, die, soweit rechtlich möglich, dem am nächsten kommt, was die Parteien gewollt haben oder nach dem Sinn und Zweck dieses Vertrages gewollt hätten, sofern sie bei dem Abschluss dieses Vertrages oder der späteren Aufnahme einer Bestimmung den Punkt bedacht hätten. Dies gilt auch dann, wenn die Unwirksamkeit einer Bestimmung etwa auf einem in diesem Vertrag vorgesehenen Umfang der Leistung oder Zeit (Frist oder Termin) beruht; es tritt in solchen Fällen ein dem Gewollten möglichst nah kommendes rechtlich zulässiges Maß der Leistung oder Zeit (Frist oder Termin) an die Stelle des Vereinbarten. Es ist ausdrücklich Wille der Parteien, dass diese salvatorische Klausel nicht eine bloße Beweislastumkehr zur Folge hat, sondern § 139 BGB insgesamt abbedungen ist. "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }