var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-window",
    {
      model: {
        value: _vm.window,
        callback: function callback($$v) {
          _vm.window = $$v
        },
        expression: "window"
      }
    },
    [
      _c("debug", [
        _vm.thirdPartySystem
          ? _c("div", [
              _vm._v(
                "thirdPartySystem.externalId " +
                  _vm._s(_vm.thirdPartySystem.externalId)
              )
            ])
          : _vm._e(),
        _vm.thirdPartySystem
          ? _c("div", [
              _vm._v(
                "thirdPartySystem.setting " +
                  _vm._s(_vm.thirdPartySystem.setting)
              )
            ])
          : _vm._e(),
        _c("div", [_vm._v("this.externalId " + _vm._s(_vm.externalId))]),
        _c("div", [_vm._v("this.search " + _vm._s(_vm.search))])
      ]),
      _c(
        "v-window-item",
        [
          _vm.dossier && _vm.dossier.data
            ? _c(
                "v-alert",
                {
                  attrs: {
                    text: "",
                    prominent: "",
                    dense: "",
                    icon: "mdi-car-connected",
                    type: "info"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.PartnerReportDetail.SendToDaDialog.confirmDialogTitle"
                              )
                            ) +
                            " " +
                            _vm._s(_vm.dossier.data.dossier.id) +
                            " "
                        ),
                        _vm.dossier.data.dossier.vehicle
                          ? _c(
                              "span",
                              {
                                staticClass: "mr-1"
                              },
                              [
                                _vm._v(
                                  " (" +
                                    _vm._s(
                                      _vm.dossier.data.dossier.vehicle
                                        .license_plate
                                    ) +
                                    ") "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.dossier.object
                          ? _c("span", [
                              _vm._v(
                                "vom " +
                                  _vm._s(
                                    _vm._f("simpleDate")(
                                      _vm.dossier.object.attributes.updated_at
                                    )
                                  )
                              )
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        row: ""
                      },
                      model: {
                        value: _vm.searchOption,
                        callback: function callback($$v) {
                          _vm.searchOption = $$v
                        },
                        expression: "searchOption"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToDaDialog.numberplate"
                          ),
                          value: _vm.SearchOptions.NUMBERPLATE
                        }
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToDaDialog.jobId"
                          ),
                          value: _vm.SearchOptions.DOSSIER_ID
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.SendToDaDialog.search"
                      ),
                      hint: _vm.$t("confirmSearchWithEnter"),
                      outlined: "",
                      "append-icon": "mdi-magnify",
                      loading: _vm.isLoadingDaServiceEvents,
                      "persistent-hint": true
                    },
                    on: {
                      "click:append": _vm.fetchDossiers,
                      keydown: function keydown($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.fetchDossiers.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.search,
                      callback: function callback($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _vm.isLoadingDaServiceEvents
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-list",
                        [
                          _c("v-skeleton-loader", {
                            attrs: {
                              type: "list-item-avatar-three-line"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-card-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.PartnerReportDetail.SendToDaDialog.titleExisting"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _vm.daServiceEvents && _vm.daServiceEvents.length > 0
                        ? _c(
                            "v-list",
                            [
                              _vm._l(this.daServiceEvents, function(
                                item,
                                index
                              ) {
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      key: ""
                                        .concat(item.serviceEventId, "+")
                                        .concat(index),
                                      on: {
                                        click: function click($event) {
                                          return _vm.selectDossier(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [
                                          _c("v-icon", [_vm._v("mdi-calendar")])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.firstName) +
                                                " " +
                                                _vm._s(item.lastName) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.numberPlate) +
                                                " " +
                                                _vm._s(item.email) +
                                                " "
                                            )
                                          ]),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.serviceEventId) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm.showExternalLink &&
                                      _vm.getExternalLink(item)
                                        ? _c(
                                            "v-list-item-action",
                                            {
                                              key:
                                                "center" +
                                                _vm.center +
                                                "group" +
                                                _vm.group
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    href: _vm.getExternalLink(
                                                      item
                                                    ),
                                                    target: "_blank",
                                                    icon: "",
                                                    loading:
                                                      _vm.isLoadingSettings
                                                  },
                                                  on: {
                                                    click: function click(
                                                      $event
                                                    ) {
                                                      $event.stopPropagation()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-link-variant")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        : _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-alert-circle-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.partner.PartnerReportDetail.SendToDaDialog.jobSearchingNotFound",
                                                {
                                                  search: _vm.search
                                                }
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ],
                1
              ),
          !_vm.disableCreation
            ? _c("v-divider", {
                staticClass: "mt-2"
              })
            : _vm._e(),
          !_vm.disableCreation
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-card-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partner.PartnerReportDetail.SendToDaDialog.tooltipRequestNew"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: _vm.switchToNew
                          }
                        },
                        [
                          _c(
                            "v-list-item-avatar",
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.partner.PartnerReportDetail.SendToDaDialog.titleRequestNew"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-window-item",
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.resourcesLoading,
                      loading: _vm.resourcesLoading,
                      items: _vm.daResources,
                      clearable: "",
                      outlined: "",
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.SendToDaDialog.resource"
                      ),
                      "item-text": "name",
                      "item-value": "id"
                    },
                    on: {
                      input: _vm.fetchFreeSlots
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function fn(data) {
                          return [
                            data.item
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(data.item.name) +
                                      " (" +
                                      _vm._s(data.item.id) +
                                      ") "
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function fn(data) {
                          return [
                            [
                              data.item
                                ? _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          innerHTML: _vm._s(data.item.name)
                                        }
                                      }),
                                      _c("v-list-item-subtitle", {
                                        domProps: {
                                          innerHTML: _vm._s(data.item.id)
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectionResourceId,
                      callback: function callback($$v) {
                        _vm.selectionResourceId = $$v
                      },
                      expression: "selectionResourceId"
                    }
                  }),
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.eventTypesLoading,
                      loading: _vm.eventTypesLoading,
                      items: _vm.daEventTypes,
                      clearable: "",
                      outlined: "",
                      label: _vm.$t(
                        "components.partner.PartnerReportDetail.SendToDaDialog.eventType"
                      ),
                      "item-text": "name",
                      "item-value": "id"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function fn(data) {
                          return [
                            data.item
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(data.item.name) +
                                      " (" +
                                      _vm._s(data.item.id) +
                                      ") "
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function fn(data) {
                          return [
                            [
                              data.item
                                ? _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          innerHTML: _vm._s(data.item.name)
                                        }
                                      }),
                                      _c("v-list-item-subtitle", {
                                        domProps: {
                                          innerHTML: _vm._s(data.item.id)
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectionEventTypeId,
                      callback: function callback($$v) {
                        _vm.selectionEventTypeId = $$v
                      },
                      expression: "selectionEventTypeId"
                    }
                  }),
                  _vm.daFreeSlots || _vm.freeSlotLoading
                    ? _c("v-autocomplete", {
                        attrs: {
                          disabled: _vm.freeSlotLoading,
                          loading: _vm.freeSlotLoading,
                          items: _vm.daFreeSlots,
                          clearable: "",
                          outlined: "",
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToDaDialog.freeSlot"
                          ),
                          "item-text": "display",
                          "item-value": "value"
                        },
                        model: {
                          value: _vm.selectionFreeSlot,
                          callback: function callback($$v) {
                            _vm.selectionFreeSlot = $$v
                          },
                          expression: "selectionFreeSlot"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-window-item",
        [
          _vm.selectedServiceEvent
            ? _c(
                "v-card",
                {
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-avatar",
                            [_c("v-icon", [_vm._v("mdi-calendar")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.selectedServiceEvent.firstName) +
                                    " " +
                                    _vm._s(_vm.selectedServiceEvent.lastName) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedServiceEvent.numberPlate
                                    ) +
                                    " " +
                                    _vm._s(_vm.selectedServiceEvent.email) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedServiceEvent.serviceEventId
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.isExport,
                            callback: function callback($$v) {
                              _vm.isExport = $$v
                            },
                            expression: "isExport"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t("thirdPartySystem.exportReport"),
                              value: true
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t("message.attachToReport"),
                              value: false
                            }
                          })
                        ],
                        1
                      ),
                      !_vm.isExport
                        ? _c("span", {
                            staticClass: "text--caption text--disabled",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "sign.DocumentTemplateDetailEdit.subtitle"
                                )
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _vm.isExport
                        ? _c("v-autocomplete", {
                            attrs: {
                              disabled: _vm.workshopServicesLoading,
                              loading: _vm.workshopServicesLoading,
                              items: _vm.daWorkshopServices,
                              clearable: "",
                              outlined: "",
                              label: _vm.$t(
                                "components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.workshopService"
                              ),
                              "item-text": "name",
                              "item-value": "id"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function fn(data) {
                                    return [
                                      data.item
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.name) +
                                                " (" +
                                                _vm._s(data.item.id) +
                                                ") "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                },
                                {
                                  key: "item",
                                  fn: function fn(data) {
                                    return [
                                      [
                                        data.item
                                          ? _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      data.item.name
                                                    )
                                                  }
                                                }),
                                                _c("v-list-item-subtitle", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      data.item.id
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3035213167
                            ),
                            model: {
                              value: _vm.selectionWorkshopServiceId,
                              callback: function callback($$v) {
                                _vm.selectionWorkshopServiceId = $$v
                              },
                              expression: "selectionWorkshopServiceId"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }