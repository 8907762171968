























import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { IPersonGroup } from "@/models/person-group.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";

@Component({
  components: { LatestEntriesCardEmpty },
  filters: {}
})
export default class RefsPersonGroup extends mixins(Vue) implements IRefDetail<IPersonGroup> {
  @Prop()
  item!: IPersonGroup;

  @Prop()
  itemAsync?: Promise<IPersonGroup>;

  @Prop()
  small!: boolean;

  @Prop()
  confirmable!: boolean;

  isLoading = false;

  group: IPersonGroup | null = null;

  loadingProject = false;

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  async beforeMount() {
    this.isLoading = true;

    if (this.item) {
      this.group = this.item;
    } else if (this.itemAsync) {
      try {
        this.group = await this.itemAsync;
      } catch (e) {
        this.$log.error(e);
      }
    }
    this.isLoading = false;
  }

  async goToDetail() {
    if (!this.group) return;
    const partnerId = this.group.partnerId;
    const personGroupId = this.group.id;
    const newTab = true;

    new PersonGoToHelper(this.$router).goToPersonGroupDetail({ personGroupId, partnerId, newTab });
  }
}
