var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: " mb-8",
      staticStyle: {
        width: "100%"
      },
      attrs: {
        outlined: ""
      }
    },
    [
      _c("partner-map", {
        key: _vm.key,
        staticClass: "z-index: 0; !important;",
        attrs: {
          coordinates: _vm.value,
          height: _vm.height,
          colour: _vm._color
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }