var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list-item",
    _vm._g(
      {},
      _vm.hasClickListener
        ? {
            click: function click() {
              return _vm.$emit("click")
            }
          }
        : {}
    ),
    [
      _vm._t("icon"),
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", [
            _vm.hasOpenDetailListener
              ? _c(
                  "a",
                  {
                    on: {
                      click: function click($event) {
                        $event.stopPropagation()
                        return _vm.emitOpenDetail.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.item.companyName) + " ")]
                )
              : _c("div", [_vm._v(_vm._s(_vm.item.companyName))])
          ]),
          _c("v-list-item-subtitle", [
            _vm._v(
              " " +
                _vm._s(_vm.item.companyUsername) +
                " " +
                _vm._s(_vm.item.id) +
                " "
            )
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }