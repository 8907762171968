import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";
import { IBreadcrumb } from "../interfaces/utility/breadcrumb-interface";
import { $t } from "./t";

export enum AdminTemplateRouteNames {
  TEMPLATE_TABLE = "TEMPLATE_TABLE",
  TEMPLATE_DETAIL = "TEMPLATE_DETAIL",
  TEMPLATE_DETAIL_FORM = "TEMPLATE_DETAIL_FORM"
}

type Tab = { newTab?: boolean };
type Template = { templateId: string };
type Query = { query?: Record<string, string> };

export class AdminTemplateGoToHelper extends BaseGoToHelper {
  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static get breadCrumbs() {
    const locations = AdminTemplateGoToHelper.locations;

    return {
      TemplateTable: (d: Query) => {
        return {
          text: $t("template.templates").toString(),
          exact: true,
          to: locations.templateTable(d)
        } as IBreadcrumb;
      },
      TemplateDetail: (d: Template & Query) => {
        return {
          text: $t("template.template").toString(),
          exact: true,
          to: locations.templateDetail(d)
        } as IBreadcrumb;
      },
      TemplateDetailForm: (d: Template & Query) => {
        return {
          text: $t("template.templateForm").toString(),
          exact: true,
          to: locations.templateDetailForm(d)
        } as IBreadcrumb;
      }
    };
  }

  static locations = {
    templateTable: (d: Query) => {
      return {
        name: AdminTemplateRouteNames.TEMPLATE_TABLE,
        params: {},
        query: d.query
      };
    },
    templateDetail: (d: Template & Query) => {
      return {
        name: AdminTemplateRouteNames.TEMPLATE_DETAIL,
        params: { templateId: d.templateId },
        query: d.query
      };
    },
    templateDetailForm: (d: Template & Query) => {
      return {
        name: AdminTemplateRouteNames.TEMPLATE_DETAIL_FORM,
        params: { templateId: d.templateId },
        query: d.query
      };
    }
  };

  goToAdminTemplateTable(d: Tab) {
    this.go(AdminTemplateGoToHelper.locations.templateTable({}), d.newTab);
  }
  goToAdminTemplateDetail(d: Tab & Template) {
    this.go(AdminTemplateGoToHelper.locations.templateDetail(d), d.newTab);
  }
  goToAdminTemplateDetailForm(d: Tab & Template) {
    this.go(AdminTemplateGoToHelper.locations.templateDetailForm(d), d.newTab);
  }
}
