












import Card from "@/components/utility/Card.vue";
import ExcelImport from "@/components/utility/ExcelImport.vue";
import ExcelImportReport from "@/components/utility/ExcelImportReport.vue";
import {
  booleanValidation,
  countrycodeValidation,
  emailValidation,
  languageValidation,
  phoneValidation
} from "@/lib/excelImportTransformation/transformers";
import { IExcelImportConfig } from "@/lib/interfaces/excel-import.interface";
import { AdminUser, IAdminUser } from "@/models/user.entity";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { Card, ExcelImport, ExcelImportReport }
})
export default class UserImportDialog extends Vue {
  @Prop({ default: true })
  outlined!: boolean;

  @Prop({ default: false })
  small!: boolean;

  loading = false;
  success = false;
  importedItems: IAdminUser[] = [];
  failedImports: { userName: string; error: string }[] = [];
  totalItems = 0;

  /**
   * company import config (as getter to have reactive translations)
   */
  get config(): { [key: string]: IExcelImportConfig } {
    return {
      userName: {
        label: this.$t("components.UserImportDialog.userNameLabel").toString(),
        hint: this.$t("components.UserImportDialog.userNameHint").toString(),
        required: true,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.userNameLabel").toString(),
        transform: value => emailValidation(value)
      },
      email: {
        label: this.$t("components.UserImportDialog.mailLabel").toString(),
        hint: this.$t("components.UserImportDialog.mailHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.mailLabel").toString(),
        transform: value => emailValidation(value)
      },
      phone: {
        label: this.$t("components.UserImportDialog.phoneLabel").toString(),
        hint: this.$t("components.UserImportDialog.phoneHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.phoneLabel").toString(),
        transform: value => phoneValidation(value)
      },
      firstName: {
        label: this.$t("components.UserImportDialog.firstNameLabel").toString(),
        hint: this.$t("components.UserImportDialog.firstNameHint").toString(),
        required: true,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.firstNameLabel").toString(),
        transform: value => value.toString()
      },
      lastName: {
        label: this.$t("components.UserImportDialog.lastNameLabel").toString(),
        hint: this.$t("components.UserImportDialog.lastNameHint").toString(),
        required: true,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.lastNameLabel").toString(),
        transform: value => value.toString()
      },
      isMarketingOptIn: {
        label: this.$t("components.UserImportDialog.isMarketingOptIn").toString(),
        hint: this.$t("components.UserImportDialog.isMarketingOptIn").toString(),
        required: true,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.isMarketingOptIn").toString(),
        transform: value => booleanValidation(value)
      },
      isCompany: {
        label: this.$t("components.UserImportDialog.isCompanyLabel").toString(),
        hint: this.$t("components.UserImportDialog.isCompanyHint").toString(),
        required: true,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.isCompanyLabel").toString(),
        transform: value => booleanValidation(value)
      },
      isTaxDeductible: {
        label: this.$t("components.UserImportDialog.isTaxDeductible").toString(),
        hint: this.$t("components.UserImportDialog.isTaxDeductible").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.isTaxDeductible").toString(),
        transform: value => booleanValidation(value)
      },
      taxnumber: {
        label: this.$t("components.UserImportDialog.taxnumberLabel").toString(),
        hint: this.$t("components.UserImportDialog.taxnumberHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.taxnumberLabel").toString(),
        transform: value => value.toString()
      },
      company: {
        label: this.$t("components.UserImportDialog.companyLabel").toString(),
        hint: this.$t("components.UserImportDialog.companyHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.companyLabel").toString(),
        transform: value => value.toString()
      },
      zip: {
        label: this.$t("components.UserImportDialog.zipLabel").toString(),
        hint: this.$t("components.UserImportDialog.zipHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.zipLabel").toString(),
        transform: value => value.toString()
      },
      city: {
        label: this.$t("components.UserImportDialog.cityLabel").toString(),
        hint: this.$t("components.UserImportDialog.cityHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.cityLabel").toString(),
        transform: value => value.toString()
      },
      street: {
        label: this.$t("components.UserImportDialog.streetLabel").toString(),
        hint: this.$t("components.UserImportDialog.streetHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.streetLabel").toString(),
        transform: value => value.toString()
      },
      state: {
        label: this.$t("components.UserImportDialog.stateLabel").toString(),
        hint: this.$t("components.UserImportDialog.stateHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.stateLabel").toString(),
        transform: value => value.toString()
      },
      countryCode: {
        label: this.$t("components.UserImportDialog.countryCodeLabel").toString(),
        hint: this.$t("components.UserImportDialog.countryCodeHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.countryCodeLabel").toString(),
        transform: value => countrycodeValidation(value)
      },
      language: {
        label: this.$t("components.UserImportDialog.language").toString(),
        hint: this.$t("components.UserImportDialog.language").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.UserImportDialog.language").toString(),
        transform: value => languageValidation(value)
      }
    };
  }

  async create(items: { data: any[]; headers: any[] }) {
    this.importedItems = [];
    this.failedImports = [];
    this.totalItems = items.data.length;
    this.loading = true;

    for (const user of items.data) {
      try {
        const newUser = new AdminUser({
          ...user,
          isTermsAccepted: true
        });

        if (user["zip"] || user["city"] || user["street"] || user["countryCode"] || user["state"]) {
          newUser.address = {
            zip: user["zip"],
            city: user["city"],
            street: user["street"],
            countryCode: user["countryCode"],
            state: user["state"]
          };
        }

        if (user["phone"] || user["email"]) {
          newUser.contact = {
            phone: user["phone"],
            email: user["email"]
          };
        }

        this.$log.debug("createCompany", newUser);

        this.importedItems.push(await newUser.invite());
      } catch (error) {
        const errorMessage = (error as Error)?.message || "Undefined import error";
        this.failedImports.push({ userName: user["userName"], error: errorMessage });
      }
    }
    this.loading = false;
    this.success = true;
  }
}
