/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivAiControllerGetParamsGen,
  MrfiktivAiControllerSummarizeParamsGen,
  MrfiktivGooglePlaceDtoGen,
  MrfiktivImageAnalysisAltDtoGen,
  MrfiktivImageAnalysisCostDtoGen,
  MrfiktivLocationControllerSearchParamsGen,
  MrfiktivOpenAiImageAnalysisAltViewModelGen,
  MrfiktivOpenAiImageAnalysisCostViewModelGen,
  MrfiktivOpenAiPartnerViewModelGen,
  MrfiktivOpenAiRegistrationRecognitionViewModelGen,
  MrfiktivOpenAiReportSummaryViewModelGen,
  MrfiktivPageViewModelGen,
  MrfiktivTranslationInputDtoGen,
  MrfiktivTranslationInputViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Ai<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ai
   * @name AiControllerGet
   * @summary (AI - READ) Get all ai results
   * @request GET:/partner/{partnerId}/ai
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivOpenAiPartnerViewModelGen)[] })`
   */
  aiControllerGet = ({ partnerId, ...query }: MrfiktivAiControllerGetParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivOpenAiPartnerViewModelGen[] }, any>({
      path: `/partner/${partnerId}/ai`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name AiControllerGetOne
   * @summary (AI - READ) Get a result
   * @request GET:/partner/{partnerId}/ai/{id}
   * @secure
   * @response `200` `MrfiktivOpenAiPartnerViewModelGen` The ticket
   */
  aiControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivOpenAiPartnerViewModelGen, any>({
      path: `/partner/${partnerId}/ai/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name AiControllerReadRegistrationResult
   * @summary Get registration result for image
   * @request GET:/partner/{partnerId}/ai/image/{imageId}/registration-recognition
   * @secure
   * @response `200` `MrfiktivOpenAiRegistrationRecognitionViewModelGen` The registration result
   */
  aiControllerReadRegistrationResult = (partnerId: string, imageId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivOpenAiRegistrationRecognitionViewModelGen, any>({
      path: `/partner/${partnerId}/ai/image/${imageId}/registration-recognition`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name AiControllerSummarize
   * @summary Get summary of report
   * @request GET:/partner/{partnerId}/ai/report/{reportId}/report-summarization
   * @secure
   * @response `200` `MrfiktivOpenAiReportSummaryViewModelGen` The summary of the report
   */
  aiControllerSummarize = (
    { partnerId, reportId, ...query }: MrfiktivAiControllerSummarizeParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivOpenAiReportSummaryViewModelGen, any>({
      path: `/partner/${partnerId}/ai/report/${reportId}/report-summarization`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name AiControllerTranslate
   * @summary Get translation
   * @request POST:/ai/translation/{language}
   * @secure
   * @response `201` `MrfiktivTranslationInputViewModelGen` The translated input
   */
  aiControllerTranslate = (
    language:
      | "af"
      | "sq"
      | "ar"
      | "hy"
      | "eu"
      | "be"
      | "bn"
      | "bg"
      | "ca"
      | "zh"
      | "hr"
      | "cs"
      | "da"
      | "nl"
      | "en"
      | "eo"
      | "et"
      | "fi"
      | "fr"
      | "gl"
      | "ka"
      | "de"
      | "de_ch"
      | "el"
      | "gu"
      | "he"
      | "hi"
      | "hu"
      | "is"
      | "id"
      | "ga"
      | "it"
      | "ja"
      | "jv"
      | "kn"
      | "kk"
      | "ko"
      | "ku"
      | "ky"
      | "lv"
      | "lt"
      | "lb"
      | "mk"
      | "mg"
      | "ms"
      | "ml"
      | "mt"
      | "mi"
      | "mr"
      | "mn"
      | "ne"
      | "no"
      | "or"
      | "ps"
      | "fa"
      | "pl"
      | "pt"
      | "pa"
      | "ro"
      | "ru"
      | "sm"
      | "gd"
      | "sr"
      | "si"
      | "sk"
      | "sl"
      | "es"
      | "su"
      | "sw"
      | "sv"
      | "tl"
      | "tg"
      | "ta"
      | "te"
      | "th"
      | "bo"
      | "tr"
      | "uk"
      | "ur"
      | "uz"
      | "vi"
      | "cy"
      | "xh"
      | "yi"
      | "yo"
      | "zu",
    data: MrfiktivTranslationInputDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivTranslationInputViewModelGen, any>({
      path: `/ai/translation/${language}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name AiControllerTranslateAsPartner
   * @summary Get translation
   * @request POST:/partner/{partnerId}/ai/translation/{language}
   * @secure
   * @response `201` `MrfiktivTranslationInputViewModelGen` The translated input
   */
  aiControllerTranslateAsPartner = (
    partnerId: string,
    language:
      | "af"
      | "sq"
      | "ar"
      | "hy"
      | "eu"
      | "be"
      | "bn"
      | "bg"
      | "ca"
      | "zh"
      | "hr"
      | "cs"
      | "da"
      | "nl"
      | "en"
      | "eo"
      | "et"
      | "fi"
      | "fr"
      | "gl"
      | "ka"
      | "de"
      | "de_ch"
      | "el"
      | "gu"
      | "he"
      | "hi"
      | "hu"
      | "is"
      | "id"
      | "ga"
      | "it"
      | "ja"
      | "jv"
      | "kn"
      | "kk"
      | "ko"
      | "ku"
      | "ky"
      | "lv"
      | "lt"
      | "lb"
      | "mk"
      | "mg"
      | "ms"
      | "ml"
      | "mt"
      | "mi"
      | "mr"
      | "mn"
      | "ne"
      | "no"
      | "or"
      | "ps"
      | "fa"
      | "pl"
      | "pt"
      | "pa"
      | "ro"
      | "ru"
      | "sm"
      | "gd"
      | "sr"
      | "si"
      | "sk"
      | "sl"
      | "es"
      | "su"
      | "sw"
      | "sv"
      | "tl"
      | "tg"
      | "ta"
      | "te"
      | "th"
      | "bo"
      | "tr"
      | "uk"
      | "ur"
      | "uz"
      | "vi"
      | "cy"
      | "xh"
      | "yi"
      | "yo"
      | "zu",
    data: MrfiktivTranslationInputDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivTranslationInputViewModelGen, any>({
      path: `/partner/${partnerId}/ai/translation/${language}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name AiControllerGenerateAltTextForImages
   * @summary Derive information from an image depending on context
   * @request POST:/partner/{partnerId}/ai/image-analysis/alt
   * @secure
   * @response `201` `MrfiktivOpenAiImageAnalysisAltViewModelGen` Analysis result.
   */
  aiControllerGenerateAltTextForImages = (
    partnerId: string,
    data: MrfiktivImageAnalysisAltDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivOpenAiImageAnalysisAltViewModelGen, any>({
      path: `/partner/${partnerId}/ai/image-analysis/alt`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name AiControllerAnalyzeCostForImages
   * @summary Derive information from an image depending on context
   * @request POST:/partner/{partnerId}/ai/image-analysis/cost
   * @secure
   * @response `201` `MrfiktivOpenAiImageAnalysisCostViewModelGen` Analysis result.
   */
  aiControllerAnalyzeCostForImages = (
    partnerId: string,
    data: MrfiktivImageAnalysisCostDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivOpenAiImageAnalysisCostViewModelGen, any>({
      path: `/partner/${partnerId}/ai/image-analysis/cost`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name LocationControllerSearch
   * @summary (AI Location - READ) Get a result
   * @request GET:/partner/{partnerId}/ai/location/{search}
   * @secure
   * @response `200` `(MrfiktivGooglePlaceDtoGen)[]` The places
   */
  locationControllerSearch = (
    { partnerId, search, ...query }: MrfiktivLocationControllerSearchParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivGooglePlaceDtoGen[], any>({
      path: `/partner/${partnerId}/ai/location/${search}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name LocationControllerGetOne
   * @summary (AI Location - READ) Get a result
   * @request GET:/partner/{partnerId}/ai/location/{id}/byId
   * @secure
   * @response `200` `MrfiktivGooglePlaceDtoGen` The place
   */
  locationControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivGooglePlaceDtoGen, any>({
      path: `/partner/${partnerId}/ai/location/${id}/byId`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
