var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "ma-1",
          attrs: {
            outlined: ""
          },
          on: {
            click: _vm.openAttachment
          }
        },
        [
          _c("v-hover", {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function fn(_ref) {
                    var hover = _ref.hover
                    return [
                      _c(
                        "v-list-item",
                        [
                          _vm.value.type
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        large: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.value.typeIcon) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(_vm.value.name) + " ")
                              ]),
                              _c("v-list-item-subtitle", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.value.sizeReadable) + " "
                                    ),
                                    _c("v-spacer")
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          ),
                          _vm.$slots["item-action"]
                            ? _c(
                                "v-list-item-action",
                                [_vm._t("item-action")],
                                2
                              )
                            : hover ||
                              _vm.isTouch ||
                              _vm.value.isLoadingDownload
                            ? _c("v-list-item-action", [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          icon: "",
                                          loading: _vm.value.isLoadingDownload
                                        },
                                        on: {
                                          click: function click($event) {
                                            $event.stopPropagation()
                                            return function() {
                                              return _vm.value.download(
                                                _vm.message
                                              )
                                            }.apply(null, arguments)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [_vm._v(" mdi-download ")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.SIGN)
        ? _c(
            "div",
            {
              attrs: {
                hidden: ""
              }
            },
            [
              _c("report-document-sign-request-dialog", {
                ref: "signRequestDialog"
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isDialog,
            title: _vm.value.name,
            fullscreen: _vm.$vuetify.breakpoint.smAndDown || _vm.value.isPdf,
            subtitle: _vm.value.sizeReadable,
            hideRight: true,
            loading: _vm.value.isLoadingReference
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialog = $event
            },
            close: function close($event) {
              _vm.isDialog = false
            },
            leftClick: function leftClick($event) {
              _vm.isDialog = false
            }
          },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function fn() {
                return [
                  !_vm.hideActions &&
                  _vm.value.isPdf &&
                  _vm.value.populatedReference &&
                  _vm.canCreateSign
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            loading: _vm.isSignDialogLoading
                          },
                          on: {
                            click: function click($event) {
                              $event.stopPropagation()
                              return _vm.initializeSignatureRequestDialog.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-signature-freehand ")])],
                        1
                      )
                    : _vm._e(),
                  !_vm.hideActions &&
                  _vm.value.isPdf &&
                  _vm.value.populatedReference &&
                  _vm.canCreateCost
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            loading: _vm.isCostDialogLoading
                          },
                          on: {
                            click: function click($event) {
                              $event.stopPropagation()
                              return _vm.openCreateCostDialog.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-cash-multiple ")])],
                        1
                      )
                    : _vm._e(),
                  !_vm.hideActions &&
                  _vm.value.isPdf &&
                  _vm.value.populatedReference &&
                  _vm.canCreateTicket
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: function click($event) {
                              $event.stopPropagation()
                              return _vm.openCreateTicketDialog.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-ticket-outline ")])],
                        1
                      )
                    : _vm._e(),
                  !_vm.hideActions &&
                  (_vm.value.isPdf || _vm.value.isImage) &&
                  _vm.value.populatedReference &&
                  _vm.attachDialog
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: function click($event) {
                              $event.stopPropagation()
                              return _vm.exportSelectedAttachment.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-link-variant ")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        loading:
                          _vm.value.isLoadingReference ||
                          _vm.value.isLoadingDownload
                      },
                      on: {
                        click: function click($event) {
                          $event.stopPropagation()
                          return function() {
                            return _vm.value.download(_vm.message)
                          }.apply(null, arguments)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(" mdi-download ")])],
                    1
                  ),
                  _vm.value.isPdf && _vm.value.populatedReference
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            loading: _vm.value.isLoadingReference
                          },
                          on: {
                            click: function click($event) {
                              $event.stopPropagation()
                              return function() {
                                return _vm.value.populatedReference.goToDetail(
                                  _vm.$router,
                                  true
                                )
                              }.apply(null, arguments)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-open-in-new ")])],
                        1
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.value.isLoadingReference
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "image"
                }
              })
            : _vm.value.populatedReference && _vm.value.isImage
            ? _c("v-img", {
                attrs: {
                  src: _vm.value.populatedReference.url,
                  contain: "",
                  height: "100%",
                  width: "100%"
                }
              })
            : _vm.value.populatedReference && _vm.value.isPdf
            ? _c("pdf-viewer", {
                attrs: {
                  pdf: _vm.value.populatedReference.url,
                  horizontal: true,
                  vertical: false
                }
              })
            : _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-card",
                        {
                          attrs: {
                            elevation: 0
                          }
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticStyle: {
                                height: "150px"
                              }
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ma-auto",
                                  staticStyle: {
                                    transform: "scale(300%)"
                                  },
                                  attrs: {
                                    "x-large": ""
                                  }
                                },
                                [_vm._v(" mdi-file-question-outline ")]
                              ),
                              _c("v-spacer")
                            ],
                            1
                          ),
                          _c(
                            "v-card-title",
                            [
                              _c("v-spacer"),
                              _vm._v(" Keine Vorschau verfügbar "),
                              _c("v-spacer")
                            ],
                            1
                          ),
                          _c(
                            "v-card-subtitle",
                            [
                              _c("v-spacer"),
                              _vm._v(" Stattdessen "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function click() {
                                      return _vm.value.download(_vm.message)
                                    }
                                  }
                                },
                                [_vm._v("herunterladen")]
                              ),
                              _vm._v("? "),
                              _c("v-spacer")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _c("partner-message-detail-attach-dialog", {
        ref: "attachDialog",
        attrs: {
          value: _vm.message
        }
      }),
      _c("cost-create-dialog", {
        key: "cost-create-dialog-attachment-card",
        ref: "costCreateDialog",
        attrs: {
          isDialogActive: _vm.isCostCreateDialogActive,
          hideButton: true,
          partnerId: _vm.message.partnerId,
          refs: [
            {
              refType: _vm.ResourceEnum.MESSAGE,
              refId: _vm.message.id
            }
          ]
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isCostCreateDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isCostCreateDialogActive = $event
          },
          created: _vm.onCostCreated
        }
      }),
      _vm.value && _vm.value.ref
        ? _c("ticket-create-dialog", {
            key: "ticket-create-dialog-attachment-card",
            ref: "ticketCreateDialog",
            attrs: {
              isDialogActive: _vm.isTicketCreateDialogActive,
              hideButton: true,
              partnerIdProp: _vm.message.partnerId,
              refs: _vm.ticketCreateRefs
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isTicketCreateDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isTicketCreateDialogActive = $event
              },
              created: _vm.onTicketCreated
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }