var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "div",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "flex-grow-1"
                    },
                    "div",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  !_vm.localValue
                    ? _c(
                        "v-text-field",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                value: "",
                                readonly: "",
                                outlined: "",
                                label: _vm.label,
                                required: _vm.required,
                                rules: _vm.rules
                              }
                            },
                            "v-text-field",
                            attrs,
                            false
                          ),
                          on
                        )
                      )
                    : _c(
                        "refs-selected",
                        _vm._g(
                          _vm._b(
                            {
                              key:
                                "refs-selected-" + _vm.localValue + _vm.refType,
                              staticClass: "mb-8",
                              attrs: {
                                value: [
                                  {
                                    refId: _vm.localValue,
                                    refType: _vm.refType
                                  }
                                ],
                                partnerId: _vm.partnerId,
                                removable: _vm.clearable,
                                confirmable: false,
                                outlined: true,
                                draggable: false
                              },
                              on: {
                                removed: function removed($event) {
                                  _vm.localValue = ""
                                }
                              }
                            },
                            "refs-selected",
                            attrs,
                            false
                          ),
                          on
                        )
                      )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isMenu,
        callback: function callback($$v) {
          _vm.isMenu = $$v
        },
        expression: "isMenu"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pa-1"
        },
        [
          _c("refs-selection-by-type", {
            attrs: {
              refType: _vm.refType,
              partnerId: _vm.partnerId,
              showAsMenu: false
            },
            on: {
              select: _vm.onSelect
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }