
















































import PreselectTemplateCard from "@/components/partner/PreselectTemplateCard.vue";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { CreateSignatureUrlFactory } from "@/lib/utility/createSignatureUrlFactory";
import { handleError } from "@/lib/utility/handleError";
import { IReference } from "@/models/reference.entity";
import { IReport } from "@/models/report.entity";
import { ISignRequest } from "@/models/sign-request.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";
import { IPartnerMessage } from "@/models/partner-message.entity";
import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";

@Component({
  components: {
    PreselectTemplateCard
  }
})
export default class ReportDocumentSignRequestStepperSendMail extends mixins(ReportDocumentSignRequestStepperMixin)
  implements IStepper {
  @Prop()
  signRequest!: ISignRequest;

  @Prop()
  report?: IReport;

  @Prop()
  private url!: string;

  @Prop()
  private source?: MrfiktivReferenceGen;

  get preselectLanguage() {
    return this.report?.language ?? PartnerModule.partner.language ?? LanguageCodeEnum.DE;
  }

  get baseUrlFromUrl() {
    const url = this.url.substring(8); //remove https://
    return url.split("/")[0];
  }

  get baseReportUrl() {
    return (this.baseUrlFromUrl || PartnerModule.partner.settings?.defaultUrl) ?? "";
  }

  get urlWithQuery() {
    return this.url;
  }

  set urlWithQuery(url: string) {
    this.$emit("setUrl", url);
  }

  get partnerId() {
    return this.$route.params.partnerId || PartnerModule.partner._id;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get context(): ITemplateContext {
    return {
      url: { request: this.urlWithQuery },
      partner: this.partner,
      report: this.report,
      request: this.signRequest
    };
  }

  get baseReportUrls() {
    return PartnerModule.partner.settings?.urls;
  }

  get mailRefs(): IReference[] {
    const refs: IReference[] = [];

    if (this.report?.id) {
      refs.push({
        refType: ResourceEnum.REPORT,
        refId: this.report.id
      });
    }
    if (this.signRequest?.id) {
      refs.push({
        refType: ResourceEnum.SIGN,
        refId: this.signRequest.id
      });
    }

    return refs;
  }

  createUrlWithQuery() {
    this.urlWithQuery = new CreateSignatureUrlFactory(
      this.baseReportUrl,
      this.signRequest.id,
      this.signRequest.recipient.firstName,
      this.signRequest.recipient.lastName
    ).build();
  }

  copyLinkToClipboard() {
    navigator.clipboard.writeText(this.urlWithQuery);
    this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }

  async next() {
    try {
      this.isLoading = true;
      await (this.$refs.templateCard as PreselectTemplateCard)?.send();
      await this.signRequest.updateState(DigitalSignatureRequestStatusEnum.SENT);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async save(messages: IPartnerMessage[]) {
    this.$emit("sent", messages);
    this.currentStep = SignRequestStepperPageEnum.MAIL_SUCCESS;
  }

  previous() {
    this.currentStep = SignRequestStepperPageEnum.SUCCESS;
  }
}
