var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            supressKeyboardConfirm: true,
            isDialogActive: _vm.isDialogActive,
            title: _vm.$t(
              "components.partner.PartnerReportDetail.SendToKsrDialog.title"
            ),
            hideRight: true,
            hideLeft: true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            leftClick: _vm.close,
            rightClick: _vm.confirm
          }
        },
        [
          _c("debug", [
            _vm._v(" metaDataNew " + _vm._s(_vm.metaDataNew)),
            _c("br"),
            _vm._v(" metaDataExisting " + _vm._s(_vm.metaDataExisting) + " ")
          ]),
          _c("partner-report-detail-send-to-ksr-dialog-export-requests", {
            attrs: {
              metaDataNew: _vm.metaDataNew,
              metaDataExisting: _vm.metaDataExisting
            },
            on: {
              submit: _vm.confirm
            }
          }),
          _c("partner-report-detail-send-to-ksr-dialog-existing", {
            attrs: {
              initialAmount: _vm.initialAmount
            },
            on: {
              submit: _vm.confirm
            }
          }),
          _c("partner-report-detail-send-to-ksr-dialog-new", {
            on: {
              submit: _vm.confirm
            }
          })
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isConfirmationDialogActive,
            supressKeyboardConfirm: false,
            title: _vm.$t(
              "components.partner.PartnerReportDetail.SendToKsrDialog.title"
            ),
            rightLoading: _vm.isLoadingSend
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isConfirmationDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isConfirmationDialogActive = $event
            },
            leftClick: _vm.cancelConfirmationDialog,
            rightClick: _vm.send
          }
        },
        [
          _vm.ksrExportReportDto
            ? _c(
                "div",
                [
                  _vm._l(_vm.dtoKeys, function(dtoKey) {
                    return _c(
                      "v-list-item",
                      {
                        key: dtoKey
                      },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-subtitle", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.partner.PartnerReportDetail.SendToKsrDialog.".concat(
                                        dtoKey
                                      )
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("v-list-item-title", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.ksrExportReportDto[dtoKey]) +
                                  " "
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  !_vm.ksrExportReportDto.jobId
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.partner.PartnerReportDetail.SendToKsrDialog.titleRequestNew"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ksrExportReportDto.jobId
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-radio-group",
                                {
                                  model: {
                                    value: _vm.isExport,
                                    callback: function callback($$v) {
                                      _vm.isExport = $$v
                                    },
                                    expression: "isExport"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "thirdPartySystem.exportReport"
                                      ),
                                      value: true
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t("message.attachToReport"),
                                      value: false
                                    }
                                  })
                                ],
                                1
                              ),
                              !_vm.isExport
                                ? _c("span", {
                                    staticClass: "text--caption text--disabled",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "sign.DocumentTemplateDetailEdit.subtitle"
                                        )
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }