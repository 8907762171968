var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    _vm._l(_vm.activityLog.slice().reverse(), function(activityLogEntry) {
      return _c(
        "div",
        {
          key: "activityLogEntry" + activityLogEntry.id
        },
        [
          [
            _vm.ActivityTypeEnum.CREATE_ASSIGNEE,
            _vm.ActivityTypeEnum.DELETE_ASSIGNEE
          ].includes(activityLogEntry.activity)
            ? _c("activity-time-line-item-assignees", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity ==
              _vm.ActivityTypeEnum.MANAGE_TARGETS_ON_SOURCE
            ? _c("activity-time-line-item-manage-targets-on-source", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity ==
              _vm.ActivityTypeEnum.MANAGE_PROPERTIES_ON_SOURCE
            ? _c("activity-time-line-item-manage-properties-on-source", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity ==
              _vm.ActivityTypeEnum.REQUEST_SIGNATURE
            ? _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.READ,
                    a: _vm.ResourceEnum.SIGN,
                    field: _vm.permissionPartnerId
                  }
                },
                [
                  _c("activity-time-line-item-sign-request", {
                    attrs: {
                      activityLogEntry: activityLogEntry
                    }
                  })
                ],
                1
              )
            : activityLogEntry.activity == _vm.ActivityTypeEnum.COMMENT
            ? _c("activity-time-line-item-comment", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity ==
              _vm.ActivityTypeEnum.UPDATE_PROGRESS_STATUS
            ? _c("activity-time-line-item-report-status-update", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity == _vm.ActivityTypeEnum.CREATE
            ? _c("activity-time-line-item-created", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity == _vm.ActivityTypeEnum.CREATE_COST
            ? _c("activity-time-line-item-create-cost", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity == _vm.ActivityTypeEnum.UPDATE_REPORT ||
              activityLogEntry.activity == _vm.ActivityTypeEnum.UPDATE_BANKING
            ? _c("activity-time-line-item-report-update", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity ==
              _vm.ActivityTypeEnum.CREATE_REPORT_SUMMARY
            ? _c("activity-time-line-item-report-summary-created", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity == _vm.ActivityTypeEnum.MESSAGE
            ? _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.READ,
                    a: _vm.ResourceEnum.MESSAGE,
                    field: _vm.permissionPartnerId
                  }
                },
                [
                  _c("activity-time-line-item-message", {
                    attrs: {
                      activityLogEntry: activityLogEntry
                    }
                  })
                ],
                1
              )
            : activityLogEntry.activity ==
              _vm.ActivityTypeEnum.SEND_PROGRESS_STATUS_UPDATE_MESSAGE
            ? _c("activity-time-line-item-progress-status-message", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity ==
              _vm.ActivityTypeEnum.UPDATE_IS_FIXED_STATUS
            ? _c("activity-time-line-item-is-fixed-update", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
            : activityLogEntry.activity == _vm.ActivityTypeEnum.EXPORT
            ? _c("time-line-item-text", {
                attrs: {
                  color: "primary",
                  icon: "mdi-export",
                  text: "<b>"
                    .concat(_vm.activityLogName(activityLogEntry), " </b> ")
                    .concat(
                      _vm.$t(
                        "timeLine.ActivityTimeLineItemIsFixedUpdate.title",
                        {
                          date: _vm.detailedDate(
                            activityLogEntry.timestamp.created
                          )
                        }
                      ),
                      " "
                    )
                    .concat(
                      _vm.$t(
                        "timeLine.PartnerReportDetailMetaTimeLineElement.description"
                      )
                    )
                }
              })
            : activityLogEntry.activity == _vm.ActivityTypeEnum.UPDATE_STATUS
            ? _c("time-line-item-text", {
                attrs: {
                  color: "primary",
                  icon: "mdi-eye-plus-outline",
                  text: "<b>"
                    .concat(_vm.activityLogName(activityLogEntry), " </b> ")
                    .concat(
                      _vm.$t(
                        "timeLine.ActivityTimeLineItemReportStatusUpdate.title",
                        {
                          date: _vm.detailedDate(
                            activityLogEntry.timestamp.created
                          )
                        }
                      ),
                      " "
                    )
                    .concat(
                      _vm.$t(
                        "timeLine.ActivityTimeLineItemReportStatusUpdate.status.default",
                        {
                          status: activityLogEntry.comment
                        }
                      )
                    )
                }
              })
            : activityLogEntry.activity ==
              _vm.ActivityTypeEnum.SHARE_REPORT_INITIAL
            ? _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.READ,
                    a: _vm.ResourceEnum.SHARED_CONTENT,
                    field: _vm.permissionPartnerId
                  }
                },
                [
                  _c("activity-time-line-item-share-link", {
                    attrs: {
                      activityLogEntry: activityLogEntry,
                      title: _vm.$t(
                        "timeLine.ActivityTimeLineItemShare.titleReportInitialShare"
                      )
                    }
                  })
                ],
                1
              )
            : activityLogEntry.activity == _vm.ActivityTypeEnum.SHARE
            ? _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.READ,
                    a: _vm.ResourceEnum.SHARED_CONTENT,
                    field: _vm.permissionPartnerId
                  }
                },
                [
                  _c("activity-time-line-item-share-link", {
                    attrs: {
                      activityLogEntry: activityLogEntry
                    }
                  })
                ],
                1
              )
            : activityLogEntry.activity ==
              _vm.ActivityTypeEnum.REQUEST_ATTACHMENT
            ? _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.READ,
                    a: _vm.ResourceEnum.ATTACHMENT_REQUEST,
                    field: _vm.permissionPartnerId
                  }
                },
                [
                  _c("activity-time-line-item-share-link", {
                    attrs: {
                      activityLogEntry: activityLogEntry,
                      isEditable: true
                    }
                  })
                ],
                1
              )
            : activityLogEntry.activity ==
              _vm.ActivityTypeEnum.CREATE_ATTACHMENT_RESPONSE
            ? _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.READ,
                    a: _vm.ResourceEnum.REPORT,
                    field: _vm.permissionPartnerId
                  }
                },
                [
                  _c("activity-time-line-item-attachment-response", {
                    attrs: {
                      activityLogEntry: activityLogEntry,
                      isEditable: true
                    }
                  })
                ],
                1
              )
            : activityLogEntry.activity ==
                _vm.ActivityTypeEnum.CREATE_DOCUMENT &&
              _vm.isDocumentUploadActive
            ? _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.READ,
                    a: _vm.ResourceEnum.DOCUMENT,
                    field: _vm.permissionPartnerId
                  }
                },
                [
                  _c("activity-time-line-item-document", {
                    attrs: {
                      activityLogEntry: activityLogEntry
                    }
                  })
                ],
                1
              )
            : activityLogEntry.activity == _vm.ActivityTypeEnum.CREATE_TICKET
            ? _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.READ,
                    a: _vm.ResourceEnum.TICKET,
                    field: _vm.permissionPartnerId
                  }
                },
                [
                  _c("activity-time-line-item-ticket", {
                    attrs: {
                      activityLogEntry: activityLogEntry
                    }
                  })
                ],
                1
              )
            : activityLogEntry.activity == _vm.ActivityTypeEnum.CREATE_IMAGE
            ? _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.READ,
                    a: _vm.ResourceEnum.IMAGE,
                    field: _vm.permissionPartnerId
                  }
                },
                [
                  _c("activity-time-line-item-image", {
                    attrs: {
                      activityLogEntry: activityLogEntry
                    }
                  })
                ],
                1
              )
            : activityLogEntry.activity ==
                _vm.ActivityTypeEnum.CREATE_EVENT_SERIES_FROM_OLD ||
              activityLogEntry.activity ==
                _vm.ActivityTypeEnum.INTERRUPT_EVENT_SERIES_WITH_NEW
            ? _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.READ,
                    a: _vm.ResourceEnum.EVENT,
                    field: _vm.permissionPartnerId
                  }
                },
                [
                  _c("activity-time-line-item-event-series", {
                    attrs: {
                      activityLogEntry: activityLogEntry
                    }
                  })
                ],
                1
              )
            : _c("base-activity-time-line-item", {
                attrs: {
                  activityLogEntry: activityLogEntry
                }
              })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }