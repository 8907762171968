import { VehicleTypes } from "@/store/enum/vehicle-types.enum";
import Vue from "vue";
import { CompanyServiceEnum } from "../enum/company-service.enum";
import { CountryCodeEnum } from "../enum/country-code.enum";
import { TransmissionTypeEnum } from "../enum/transmission.enum";
import { emailRule, phoneRule } from "../rules/contactRule";
import { LanguageCodeEnum } from "../enum/language-code.enum";

/**
 * Transform excel date to javascript date
 *
 * @param serial the excel time format
 * @param time should the time be parsed. Defaults to `false`. On `true` returns the parsed serial including time as local date.
 * @returns date. On `time` = `false` returns the utc date day. On `time` = `false` returns a local date object (not utc).
 */
export function excelDateToJSDate(serial: number, time = false): Date | undefined {
  if (!serial) {
    return undefined;
  }

  const utc_days = Math.floor(serial - 25569);
  const utc_ms = utc_days * 86400 * 1000;

  Vue.$log.debug(serial, utc_ms, new Date(utc_ms).toISOString());

  const date_info = new Date(utc_ms);

  if (!time) {
    return date_info;
  }

  const fractional_day = serial - Math.floor(serial) + 0.0000001;

  let total_seconds = Math.floor(86400 * fractional_day);

  const seconds = total_seconds % 60;

  total_seconds -= seconds;

  const hours = Math.floor(total_seconds / (60 * 60));
  const minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(date_info.getUTCFullYear(), date_info.getUTCMonth(), date_info.getUTCDate(), hours, minutes, seconds);
}

/**
 * Transform excel date to javascript iso date string
 *
 * @param serial
 * @returns iso date string
 */
export function numberValidation(value: number | string | undefined): number | undefined {
  if (!value) {
    return undefined;
  }

  const transformedValue = +value;

  if (isNaN(transformedValue)) {
    throw new Error("Not a numeric value");
  }
  return transformedValue;
}

/**
 * Boolean validation
 *
 * @param serial
 * @returns iso date string
 */
export function booleanValidation(value: number | string | boolean | undefined): boolean | undefined {
  if (value === false || value === true) {
    return value;
  }

  if (!value) {
    return undefined;
  }

  throw new Error("No boolean");
}

/**
 * Contry code Validation
 */
export function emailValidation(value: number | string | boolean | undefined): string | undefined {
  if (value === undefined) {
    return;
  }
  if (emailRule()(value) === true) {
    return value.toString();
  }

  throw new Error(value + " is not a valid E-Mail");
}

/**
 * Contry code Validation
 */
export function phoneValidation(value: number | string | boolean | undefined): string | undefined {
  if (value === undefined) {
    return;
  }
  if (phoneRule()(value) === true) {
    return value.toString();
  }

  throw new Error(value + " is not a valid Phone number");
}

/**
 * Contry code Validation
 */
export function countrycodeValidation(value: number | string | undefined): string | undefined {
  return baseEnumValidation(value, CountryCodeEnum, "Not valid countrycode");
}

/**
 * Language code Validation
 */
export function languageValidation(value: number | string | undefined): string | undefined {
  return baseEnumValidation(value, LanguageCodeEnum, "Not valid language code");
}

/**
 * Valid vehciletypes
 */
export function transmissionValidation(value: number | string | undefined): string | undefined {
  return baseEnumValidation(value, TransmissionTypeEnum, "Not valid transmissiontype");
}

/**
 * Valid vehciletypes
 */
export function vehicleTypeValidation(value: number | string | undefined): string | undefined {
  return baseEnumValidation(value, VehicleTypes, "Not valid vehicleType");
}

/**
 *
 * @param value
 * @param enumObject
 * @param message
 * @returns
 */
export function baseEnumValidation(value: number | string | undefined, enumObject: any, message = "Validationerror") {
  if (!value) {
    return undefined;
  }
  let isValid = false;

  Object.values(enumObject).forEach(enumValue => {
    if (value == enumValue) {
      isValid = true;
    }
  });

  if (!isValid) {
    throw new Error(message);
  }

  return value.toString();
}

/**
 * Base function to validate if all items of a comma-separated string are of the given enum type
 */
export function baseEnumListValidation<T>(
  value: string | number | boolean | T[] | undefined,
  enumObject: Record<string, T>,
  errorMessage = "Not a valid enum value"
): T[] | undefined {
  if (!value) return undefined;

  let stringValue: string;

  if (Array.isArray(value)) {
    stringValue = value.join(","); // Convert array to string
  } else if (typeof value === "string") {
    stringValue = value;
  } else {
    return undefined;
  }

  const list: T[] = [];
  const items = stringValue.split(",").map(item => item.trim());

  for (const item of items) {
    const validatedValue = baseEnumValidation(item, enumObject, errorMessage);
    if (validatedValue !== undefined) {
      list.push(validatedValue as any);
    }
  }

  return list.length > 0 ? list : undefined;
}

/**
 * Specific validation function for CompanyServiceEnum
 */
export function companyEnumListValidation(
  value: string | number | boolean | undefined
): CompanyServiceEnum[] | undefined {
  return baseEnumListValidation(value, CompanyServiceEnum, value + " is not a valid company service enum value");
}

/**
 * MongoDB ObjectId Validation
 *
 * Checks if the provided value is a valid MongoDB ObjectId.
 * A valid ObjectId consists of exactly 24 hexadecimal characters.
 *
 * @param value The value to validate (number, string, boolean, or undefined)
 * @returns The validated ObjectId string, or undefined if no value is provided
 * @throws Error if the value is not a valid ObjectId
 */
export function mongodbIdValidation(value: number | string | boolean | undefined): string | undefined {
  if (value === undefined) {
    return undefined;
  }

  const id = value.toString();
  const objectIdRegex = /^[0-9a-fA-F]{24}$/;

  if (objectIdRegex.test(id)) {
    return id;
  }

  throw new Error(`${value} is not a valid MongoDB ObjectId`);
}

/**
 * Validates a comma-separated string or array of values as MongoDB ObjectIds.
 *
 * This function accepts a string or an array containing values and validates each one
 * as a valid MongoDB ObjectId (24 hexadecimal characters). It returns an array of valid ObjectIds.
 *
 * @param value The value to validate (string, number, boolean, array, or undefined)
 * @returns An array of validated MongoDB ObjectIds, or undefined if no value is provided
 * @throws Error if any of the values is not a valid MongoDB ObjectId
 */
export function mongodbIdListValidation(
  value: string | number | boolean | (string | number | boolean)[] | undefined
): string[] | undefined {
  if (!value) return undefined;

  let stringValue: string;

  if (Array.isArray(value)) {
    stringValue = value.join(","); // Join array elements into a comma-separated string
  } else {
    stringValue = value.toString();
  }

  const list: string[] = [];
  const items = stringValue.split(",").map(item => item.trim());

  for (const item of items) {
    // Use the mongodbIdValidation function to validate each item
    const validatedValue = mongodbIdValidation(item);
    if (validatedValue !== undefined) {
      list.push(validatedValue);
    }
  }

  return list.length > 0 ? list : undefined;
}
