
















import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { IPersonPhone } from "@/models/person-phone.entity";

@Component({
  components: {},
  filters: {}
})
export default class RefsPersonPhone extends mixins(Vue) implements IRefDetail<IPersonPhone> {
  @Prop()
  item!: IPersonPhone;

  @Prop()
  small!: boolean;

  @Prop()
  confirmable!: boolean;

  async goToDetail() {
    throw new Error("Method not implemented.");
  }

  copy() {
    this.item.copyToClipboard();
  }
}
