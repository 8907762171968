






























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IActivityLog } from "@/models/activity-log.entity";
import { Component, Prop } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { activityLogName } from "@/lib/utility/activityLogName";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import TimeLineItemText from "../utility/TimeLineItemText.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";

@Component({
  components: {
    TimeLineItemText,
    ActivityTimeLineItemDelete,
    ContextMenu
  }
})
export default class ActivityTimeLineItemManageTargetsOnSource extends DarkModeHighlightMixin {
  @Prop({})
  activityLogEntry!: IActivityLog;

  get icon() {
    switch (this.activityLogEntry.actionType) {
      case ActionEnum.CREATE:
        return "mdi-plus";
      case ActionEnum.DELETE:
        return "mdi-delete";
      default:
        return "mdi-pencil";
    }
  }

  get iconColor() {
    switch (this.activityLogEntry.actionType) {
      case ActionEnum.CREATE:
        return "success";
      case ActionEnum.DELETE:
        return "error";
      default:
        return "info";
    }
  }

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get description() {
    const date = simpleDoubleDigitDate(this.activityLogEntry.timestamp.created);
    const count = this.activityLogEntry.target?.length ?? 0;

    let description = count + " ";
    if (count > 1) {
      const refTypes = Array.from(new Set(this.activityLogEntry.target?.map(target => target.refType) ?? []));
      description += refTypes.map(t => $t(`BackendResourceEnum.plural.${t}`)).join(` ${$t("and")} `);
    } else {
      const target = this.activityLogEntry.target?.[0];
      description += $t(`BackendResourceEnum.${target?.refType}`);
    }

    let hint = "";
    if (this.activityLogEntry.comment) {
      hint += ` (${this.activityLogEntry.comment})`;
    }

    return $t("timeLine.ActivityTimeLineItemManageTargetsOnSource.title", {
      date,
      description,
      action: $t(`ActionEnum.verb.past.${this.activityLogEntry.actionType}`),
      hint
    });
  }
}
