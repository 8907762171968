import { render, staticRenderFns } from "./PartnerTemplateTableView.vue?vue&type=template&id=1eab4ebc&"
import script from "./PartnerTemplateTableView.vue?vue&type=script&lang=ts&"
export * from "./PartnerTemplateTableView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VContainer,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1eab4ebc')) {
      api.createRecord('1eab4ebc', component.options)
    } else {
      api.reload('1eab4ebc', component.options)
    }
    module.hot.accept("./PartnerTemplateTableView.vue?vue&type=template&id=1eab4ebc&", function () {
      api.rerender('1eab4ebc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/template/PartnerTemplateTableView.vue"
export default component.exports