




















































































































































































import Card from "@/components/utility/Card.vue";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { convertPdfToImg } from "@/lib/utility/convertPdfToImage";
import { handleError } from "@/lib/utility/handleError";
import { renderTemplate } from "@/lib/utility/renderTemplate";
import DocumentTemplateMixin from "@/mixins/DocumentTemplateMixin.vue";
import { ActivityLog } from "@/models/activity-log.entity";
import { IReport } from "@/models/report.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { SignRequest } from "@/models/sign-request.entity";
import { MrfiktivCreateActivityLogDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import {
  SignCreateDocumentDtoGen,
  SignCreateSignRequestDtoGen,
  SignCreateThirdPartySystemDtoGen
} from "@/services/sign/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import DocumentTemplateDetailPageList from "./DocumentTemplateDetailPageList.vue";
import { SignatureImageTokenConfig, SignatureQRTokenConfig } from "./DocumentTemplateDetailTokenList.vue";
import { CreateReportDocumentDtoGen, CreateReportSignRequestDto } from "./ReportDocumentSignRequestForm.vue";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";
import { UserModule } from "@/store/modules/me-user.store";
import { SharedContentModule } from "@/store/modules/shared-content.store";
import { ISharedContent } from "@/models/shared-content.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { simpleDate } from "@/lib/utility/date-helper";
const pdfMeGenerator = () => import("@pdfme/generator");
import SharedContentCardRefTypes from "@/components/partner/SharedContentCardRefTypes.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ActionEnum } from "@/store/enum/authActionEnum";
import TemplateCardSubjectBody from "@/components/template/TemplateCardSubjectBody.vue";

@Component({
  components: {
    Card,
    DocumentTemplateDetailPageList,
    SharedContentCardRefTypes,
    TemplateCardSubjectBody
  },
  filters: {
    simpleDate
  }
})
export default class ReportDocumentSignRequestStepperInputs
  extends mixins(ReportDocumentSignRequestStepperMixin, DocumentTemplateMixin)
  implements IStepper {
  @Prop()
  signDocument!: ISignDocument;

  @Prop()
  report?: IReport;

  @Prop({ default: () => [] })
  tpss?: SignCreateThirdPartySystemDtoGen[];

  @Prop()
  height!: string;

  src = "";

  createDocumentDto: SignCreateDocumentDtoGen = new CreateReportDocumentDtoGen(this.report);

  createSignRequestDto: SignCreateSignRequestDtoGen = new CreateReportSignRequestDto(this.report);

  isFormValid = false;

  form: any = null;

  images: string[] = [];

  isLoadingSharedContent = false;
  isShareWithAll = true;
  toBeSharedWithIndex: number[] = [];

  currentPageNumber = 0;

  @Watch("isFormValid")
  emitFormValid() {
    this.$emit("update:valid", this.isFormValid);
  }

  setCurrentPage(p: number) {
    this.currentPageNumber = p;
    DocumentTemplateModule.setCurrentPage(this.currentPageNumber);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get ActionEnum() {
    return ActionEnum;
  }

  get BackendResourceEnum() {
    return BackendResourceEnum;
  }

  get pages() {
    return this.images.map(src => {
      return {
        scaleX: 0,
        scaleY: 0,
        src: src
      };
    });
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.report?.partnerId ?? this.$route.params.partnerId;
  }

  get sharedContent(): ISharedContent[] {
    return SharedContentModule.filteredAndSorted;
  }

  async mounted() {
    this.isLoading = true;
    const schemas: any[] = [];
    let inputs = {};

    if (this.report && UserModule.abilities.can(ActionEnum.READ, BackendResourceEnum.SHARED_CONTENT, this.partner.id)) {
      this.isLoadingSharedContent = true;
      SharedContentModule.setFilters([
        new PageFilterElement({
          key: "refs.refId",
          operation: "$eq",
          value: this.report._id
        }),
        new PageFilterElement({
          key: "refs.refType",
          operation: "$eq",
          value: BackendResourceEnum.REPORT
        })
      ]);
      SharedContentModule.fetchFirstPage({ partnerId: this.partnerId }).finally(
        () => (this.isLoadingSharedContent = false)
      );
    } else {
      SharedContentModule.reset();
    }

    for (const documentTokensPage of this.signDocument.tokens ?? []) {
      let schemaPage = {};

      let page = 0;
      for (const documentToken of documentTokensPage) {
        const key = this.getKey(documentToken, page);
        page++;
        schemaPage = {
          ...schemaPage,
          [key]: {
            type: "text",
            position: { x: documentToken.coordinates.x, y: documentToken.coordinates.y },
            width: documentToken.coordinates.w,
            height: documentToken.coordinates.h,
            fontSize: documentToken.coordinates.fontSize
          }
        };

        let input = documentToken.value ?? "";
        if (!input && documentToken.token !== "value") {
          input = renderTemplate(documentToken.token, {
            report: this.report,
            partner: this.partner
          });
        }

        documentToken.value = input;

        inputs = { ...inputs, [key]: input };
      }

      schemas.push(schemaPage);
    }

    const basePdf = this.signDocument.url;

    const template = {
      basePdf: basePdf,
      schemas: schemas ?? []
    };

    const pdfMeGen = await pdfMeGenerator();
    // PDF with tokens
    const generatedAsync = pdfMeGen.generate({
      template: {
        basePdf: template.basePdf,
        schemas: template.schemas
      },
      inputs: [inputs]
    });
    // PDF without tokens
    const originalAsync = pdfMeGen.generate({
      template: {
        basePdf: template.basePdf,
        schemas: []
      },
      inputs: [{}]
    });
    const [generated, original] = await Promise.all([generatedAsync, originalAsync]);

    this.src = URL.createObjectURL(new Blob([generated]));
    this.images = await convertPdfToImg(generated);
    this.setFile(generated, original);
    this.isLoading = false;
  }

  setFile(generatedPdf: Uint8Array, original: Uint8Array) {
    if (!this.createDocumentDto) {
      return;
    }

    if (!generatedPdf) {
      return;
    }

    let fileBits = generatedPdf;
    if (FeatureModule.isSignatureFormActive) {
      // PDF without rendered tokens. Tokens will be shown in editor
      fileBits = original;
    }
    let fileName = this.signDocument.name ?? this.createSignRequestDto?.title ?? "signature";
    if (!fileName.endsWith(".pdf")) {
      fileName = `${fileName}.pdf`;
    }

    const file = new File([fileBits], fileName, {
      type: "application/pdf"
    });

    this.createDocumentDto.file = file;
    this.createDocumentDto.description = this.signDocument.description;
    this.createDocumentDto.folder = this.signDocument.folder;
    this.createDocumentDto.name = this.signDocument.name;

    /**
     * When we create a sign document for a report, reports refs from the document should not become part of the sign document
     */
    this.createDocumentDto.refs = this.signDocument.refs.filter(
      ref => !this.report || ref.refType !== BackendResourceEnum.REPORT
    );
    this.createDocumentDto.tags?.push(...this.signDocument.tags);
    this.createDocumentDto.tokens = this.signDocument.tokens;
  }

  previous() {
    this.currentStep = SignRequestStepperPageEnum.SIGNATURE_SELECTION;
  }

  updateSubject(value: string) {
    this.createSignRequestDto.title = value;
  }

  updateBody(value: string) {
    this.createSignRequestDto.description = value;
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }

  simpleDate(date: string) {
    return simpleDate(date);
  }

  async next() {
    try {
      this.isLoading = true;
      this.$log.debug(this.createDocumentDto);
      this.$log.debug(this.createSignRequestDto);

      this.createDocumentDto.title = this.signDocument.title;
      this.createDocumentDto.name = this.signDocument.name;
      this.createDocumentDto.description = this.signDocument.description;

      // Remove all non-signature-tokens from document in case the tokens are already rendered on PDF (because the backend will render them on the pdf at a later point)
      if (!FeatureModule.isSignatureFormActive) {
        this.createDocumentDto.tokens = this.createDocumentDto.tokens?.map(tokenPage =>
          tokenPage.filter(token =>
            [SignatureQRTokenConfig.token.toString(), SignatureImageTokenConfig.token.toString()].includes(token.token)
          )
        );
      }

      const refs = [...(this.createDocumentDto.refs ?? [])];
      if (this.report?._id && !refs.find(r => r.refId === this.report?._id)) {
        refs.push({
          refType: BackendResourceEnum.REPORT,
          refId: this.report._id
        });
      }

      const doc = await new SignDocument({
        partnerId: this.partnerId,
        ...this.createDocumentDto,
        refs
      }).create();

      if (!doc.id) {
        throw Error("Document was not uploaded");
      }

      const sign = await new SignRequest({
        partnerId: this.partnerId,
        ...this.createSignRequestDto,
        documents: [doc.id],
        refs,
        thirdPartySystemConnections: this.tpss,
        assignees: [UserModule.userId]
      }).create();

      this.$emit("setSignRequest", sign);

      if (this.report && this.report._id) {
        const data: MrfiktivCreateActivityLogDtoGen = {
          source: {
            refType: BackendResourceEnum.REPORT,
            refId: this.report._id
          },
          actionType: ActionEnum.CREATE,
          target: [
            {
              refType: BackendResourceEnum.SIGN,
              refId: sign.id
            }
          ],
          activity: ActivityTypeEnum.REQUEST_SIGNATURE
        };

        await new ActivityLog({
          partnerId: this.partner.id || this.partner._id,
          ...data
        })
          .create()
          .catch(handleError);

        this.$toast.success("👍");
      }
      this.$emit("save", { document: doc, signatureRequest: sign });

      if (this.isShareWithAll) {
        this.toBeSharedWithIndex = this.sharedContent.map((_, index) => index);
      }

      for (const index of this.toBeSharedWithIndex) {
        const content = this.sharedContent[index];
        if (
          content &&
          UserModule.abilities.can(ActionEnum.UPDATE, BackendResourceEnum.SHARED_CONTENT, this.partner.id)
        ) {
          await content.addRef({ refId: sign.id, refType: BackendResourceEnum.SIGN });
        }
      }

      this.currentStep = SignRequestStepperPageEnum.SUCCESS;
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
