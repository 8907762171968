





































import { requiredRule } from "@/lib/rules/requiredRule";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { IRefSuggestion } from "@/store/modules/fleet-aggregation.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import RefsSelected from "./RefsSelected.vue";
import RefsSelectionByType from "./RefsSelectionByType.vue";

/**
 * Component that can handle the selection of entities for a given refType
 * Note: The ref type MUST BE registered in the RefTypeMap (@see RefTypeMap @ refs.store.ts)
 */
@Component({
  components: {
    RefsSelected,
    RefsSelectionByType
  },
  filters: {}
})
export default class SelectEntity<T> extends Vue {
  @Prop()
  value!: string;

  get localValue() {
    return this.value;
  }

  set localValue(value: string) {
    this.$emit("input", value);
  }

  /**
   * The kind of entity that is selectable
   */
  @Prop()
  refType!: BackendResourceEnum;

  /**
   * For pagination
   */
  @Prop()
  partnerId!: string;

  /**
   * Element ins suggested will be visually highlighted with AI logo
   */
  @Prop({ default: () => [] })
  suggested!: IRefSuggestion[];

  @Prop()
  label!: string;

  @Prop()
  required!: boolean;

  @Prop()
  clearable!: boolean;

  isMenu = false;

  get rules() {
    if (this.required) {
      return [requiredRule()];
    }
    return [];
  }

  onSelect(item: { id: string }) {
    this.value = item.id;
    this.localValue = item.id;
    this.isMenu = false;
  }
}
