var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("table-wrapper", {
    attrs: {
      loading: _vm.loading,
      hideSearch: true,
      title: _vm.title,
      subtitle: _vm.subtitle,
      selectedItems: _vm.selected,
      allItems: _vm.items,
      headers: _vm.items.length ? _vm.headers : [],
      controlElements: _vm.controlElements,
      emptyItem: _vm.emptyItem,
      sortBy: _vm.sortBy,
      sortDesc: _vm.sortDesc,
      dense: _vm.dense,
      showSelect: _vm.showSelect,
      itemsPerPage: _vm.itemsPerPage
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event
      },
      "click:row": _vm.clickRow
    },
    scopedSlots: _vm._u(
      [
        {
          key: "no-data",
          fn: function fn() {
            return [
              _c("latest-entries-card-empty", {
                staticClass: "mt-2 mb-4"
              })
            ]
          },
          proxy: true
        },
        {
          key: "search",
          fn: function fn() {
            return [
              _c("filter-card-pagination", {
                staticClass: "ma-n4",
                attrs: {
                  filterList: _vm.paginationFilterList,
                  filter: _vm.paginationFilter,
                  search: _vm.search,
                  hideDivider: true,
                  predefinedFilter: _vm.predefinedFilter
                },
                on: {
                  "update:filter": function updateFilter($event) {
                    _vm.paginationFilter = $event
                  },
                  "update:search": function updateSearch($event) {
                    _vm.search = $event
                  },
                  confirmFilter: _vm.refresh,
                  confirmSearch: _vm.refresh
                }
              })
            ]
          },
          proxy: true
        },
        {
          key: "selectActions",
          fn: function fn() {
            return [_vm._t("selectActions")]
          },
          proxy: true
        },
        {
          key: "bodyActions",
          fn: function fn() {
            return [_vm._t("bodyActions")]
          },
          proxy: true
        },
        _vm._l(_vm.headers, function(header) {
          return {
            key: "item.".concat(header.value),
            fn: function fn(_ref) {
              var item = _ref.item,
                value = _ref.value
              return [
                header.value
                  ? _vm._t("item.".concat(header.value), null, {
                      item: item,
                      value: value
                    })
                  : _vm._e()
              ]
            }
          }
        }),
        {
          key: "item",
          fn: function fn(_ref2) {
            var expand = _ref2.expand,
              index = _ref2.index,
              item = _ref2.item,
              isExpanded = _ref2.isExpanded,
              isMobile = _ref2.isMobile,
              isSelected = _ref2.isSelected,
              select = _ref2.select,
              headers = _ref2.headers,
              on = _ref2.on,
              attrs = _ref2.attrs
            return [
              _vm._t("item", null, {
                expand: expand,
                index: index,
                item: item,
                isExpanded: isExpanded,
                isMobile: isMobile,
                isSelected: isSelected,
                select: select,
                headers: headers,
                on: on,
                attrs: attrs
              })
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }