


















































































import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ComboBox from "../utility/ComboBox.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import Tooltip from "../utility/tooltip.vue";
import TemplateEditor from "./TemplateEditor.vue";
import TemplateEditorActionDivider from "./TemplateEditorActionDivider.vue";
import TemplateSelectionDialog from "./TemplateSelectionDialog.vue";
import TemplateCard from "./TemplateCard.vue";
import { MessageContentFormatEnum } from "@/lib/enum/templateEnums/messageContentFormat.enum";

@Component({
  components: {
    Card,
    ComboBox,
    Debug,
    ConfirmActionDialog,
    TemplateEditor,
    TemplateSelectionDialog,
    TemplateEditorActionDivider,
    Tooltip
  }
})
export default class TemplateCardSubjectBody extends mixins(TemplateCard) {
  @Prop()
  isPlain!: boolean;

  @Prop()
  subjectLabel?: string;

  @Prop()
  subjectHint?: string;

  @Prop()
  bodyLabel?: string;

  @Prop()
  bodyHint?: string;

  get isPlainLocal() {
    if (this.isPlain !== undefined) {
      return this.isPlain;
    }

    return this.template.meta.contentFormat === MessageContentFormatEnum.PLAIN;
  }

  @Watch("renderedSubject")
  emitSubject() {
    this.$emit("update:subject", this.renderedSubject);
  }

  @Watch("renderedBody")
  emitBody() {
    this.$emit("update:body", this.renderedBody);
  }
}
