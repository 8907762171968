































































































import { formatNumberPlate } from "@/lib/formatNumberplate";
import { IKsrJobDetailViewmodel } from "@/lib/interfaces/ksr/ksr-job-detail-viewmodel.interface";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivKsrExportReportDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ExportModule } from "@/store/modules/export.store";
import { KsrModule } from "@/store/modules/ksr.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Tooltip from "../utility/tooltip.vue";
import PartnerReportDetailSendToKsrDialogKsrDetail from "./PartnerReportDetailSendToKsrDialogKsrDetail.vue";

export enum KsrSearchOptions {
  JOB_ID,
  NUMBERPLATE
}

@Component({
  components: {
    ConfirmActionDialog,
    PartnerReportDetailSendToKsrDialogKsrDetail,
    Card,
    Tooltip,
    LatestEntriesCardEmpty
  }
})
export default class PartnerReportDetailSendToKsrDialogExisting extends mixins(PermissionMixin) {
  /**
   * initial amount determines the amount of ksr reports to be loaded from the backend if existant
   */
  @Prop()
  initialAmount!: number;

  @Prop({ default: true })
  loadOnMount!: boolean;

  selected = -1;

  /**
   * Nach Kennzeichen oder Auftragsnummer suchen
   */
  searchOption = KsrSearchOptions.NUMBERPLATE;

  /**
   * the amount of ksr documents to get details for from backend
   */
  amount = this.initialAmount;

  /**
   * id of a ksr job to send to - used when user wants to select their own from the list
   */
  customKsrJobId = "";

  /**
   * are existing jobs loading
   */
  isExistingJobsLoading = false;

  /**
   * is the loading of existing jobs finished
   */
  isExistingJobsLoaded = false;

  /**
   * list of existing jobs for numberplate
   */
  existingJobs: IKsrJobDetailViewmodel[] = [];

  /**
   * userinput for a well formatted numberplate
   */
  search = "";

  isSearchEditActive = false;

  get searchText() {
    const prefix =
      this.searchOption === KsrSearchOptions.NUMBERPLATE
        ? this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.numberplate")
        : this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.jobId");

    return `${prefix}  <b>${this.search}</b>`;
  }

  /**
   * checks if change amount button is visble
   * when fewer jobs than the amount that was requested are found, loading more won't help
   */
  get isChangeAmountButtonVisible() {
    if (this.existingJobs.length < this.amount) {
      return false;
    }

    return true;
  }

  /**
   * a custom input has to be a number
   */
  get customKsrJobIdInputRules() {
    return [isNumberRule()];
  }

  /**
   * checks if no job was found for that numberplate after they were loaded
   */
  get isNoJobMessageVisible() {
    return this.existingJobs.length === 0 && this.isExistingJobsLoaded;
  }

  /**
   * The id of the partner for whom the report is done
   */
  get partnerId() {
    return this.$route.params.partnerId ?? PartnerModule.partner._id;
  }

  /**
   * look for a job id or a numberplate
   */
  get SearchOptions() {
    return KsrSearchOptions;
  }

  /**
   * initialize component
   */
  async mounted() {
    this.amount = this.initialAmount;
    if (!this.loadOnMount) {
      this.isSearchEditActive = true;
      return;
    }

    this.search = this.formatNumberPlate(PartnerModule.report.numberplate ?? "");

    try {
      this.isExistingJobsLoaded = false;
      this.isExistingJobsLoading = true;

      const numberplate = PartnerModule.report.numberplate ?? PartnerModule.report.registrationResults?.numberplate;

      if (numberplate) {
        this.loadJobsByNumberPlate();
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isExistingJobsLoaded = true;
      this.isExistingJobsLoading = false;
    }
  }

  toggleSearchEdit() {
    this.isSearchEditActive = !this.isSearchEditActive;

    if (!this.isSearchEditActive) {
      this.loadJobs();
    }
  }

  /**
   * when the user picks the numberplate as search, format the search input
   */
  @Watch("searchOption")
  initializeSearch() {
    this.selected = -1;
    this.isExistingJobsLoaded = false;
    this.existingJobs.splice(0);
    if (this.searchOption === KsrSearchOptions.JOB_ID) {
      this.search = "";
    } else {
      this.search = this.formatNumberPlate(PartnerModule.report.numberplate ?? "");
    }
  }

  /**
   * removes all special characters from numberplate
   *
   * @example 'AB CD 123' becomes 'AB-CD 123'
   * @example 'AB-CD   123' becomes 'AB-CD 123'
   * @example 'AB-CD-123' becomes 'AB-CD 123'
   * @example 'AB - CD - 123' becomes 'AB-CD 123'
   * @param numberPlate the numberplate to format
   * @returns the formatted numberplate
   */
  formatNumberPlate(numberPlate: string): string {
    return formatNumberPlate(numberPlate);
  }

  /**
   * load ksr jobs for numberplate or job id search
   */
  async loadJobs() {
    try {
      this.isSearchEditActive = false;
      this.isExistingJobsLoaded = false;
      this.isExistingJobsLoading = true;

      if (!this.search) {
        this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.noNumberPlateGiven"));
        return;
      }

      if (this.searchOption === KsrSearchOptions.NUMBERPLATE) {
        await this.loadJobsByNumberPlate();
      } else {
        await this.loadJobsByJobId();
      }
    } catch (error) {
      this.existingJobs.splice(0);
      this.$log.error(error);
      this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.byNumberPlateFailed"));
    } finally {
      this.isExistingJobsLoading = false;
      this.isExistingJobsLoaded = true;
    }
  }

  /**
   * Loads jobs by jobId from ksr
   */
  async loadJobsByJobId() {
    const document = await ExportModule.getJobDetailById({
      partnerId: this.partnerId,
      jobId: Number(this.search)
    });
    this.existingJobs.splice(0, this.existingJobs.length, document);
  }

  /**
   * loads jobs by formatted numberplate
   */
  async loadJobsByNumberPlate() {
    const documents = await KsrModule.getKsrDocuments({
      partnerId: this.partnerId,
      numberPlate: this.search,
      amount: this.amount
    });
    this.existingJobs.splice(0, this.existingJobs.length, ...documents);
  }

  /**
   * Load more jobs by numberplate
   */
  async profillMore() {
    this.amount += this.initialAmount;
    await this.loadJobs();
  }

  /**
   * confirm export to ksr
   */
  confirm(item: IKsrJobDetailViewmodel) {
    const dto: MrfiktivKsrExportReportDtoGen = { jobId: Number(item.jobId) };
    this.$emit("submit", dto);
  }
}
