var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.item
    ? _c(
        "v-list-item",
        {
          staticClass: "pa-0",
          on: {
            click: _vm.copy
          }
        },
        [
          _vm.item
            ? _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    [
                      _vm._v(" " + _vm._s(_vm.item.phone) + " "),
                      _vm.item.label
                        ? _c(
                            "v-chip",
                            {
                              attrs: {
                                small: ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "enums.PersonLabelEnum.".concat(
                                        _vm.item.label
                                      )
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.item.notes && !_vm.small
                    ? _c("v-list-item-subtitle", [
                        _vm._v(" " + _vm._s(_vm.item.notes) + " ")
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }