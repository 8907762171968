import { Filter, FilterConfig, IsFilterable } from "@/lib/filterable";
import adminTemplateService from "@/services/shared/adminTemplateService";
import { AdminTemplateDataAccessLayer } from "@/store/modules/access-layers/admin-template.access-layer";
import { ITemplateContent, TemplateContent } from "./template-content.entity";
import { ITemplateMeta, TemplateMeta } from "./template-meta.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { IEntity } from "@/lib/utility/data/entity.interface";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivCreateAdminTemplateDtoGen,
  MrfiktivUpdateAdminTemplateDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ThgUpdateAdminTemplateDtoGen } from "@/services/thg/v1/data-contracts";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { $t } from "@/lib/utility/t";
import VueRouter from "vue-router";
import { AdminTemplateGoToHelper } from "@/lib/utility/admin-template.go-to-helper";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";

@IsFilterable
export class AdminTemplateBase
  implements IEntity<MrfiktivAdminTemplateViewModelGen, MrfiktivUpdateAdminTemplateDtoGen> {
  static get KEY_DIVIDER() {
    return "--language--";
  }

  get id() {
    return AdminTemplateBase.getIdFromKeyAndLanguage(this.key, this.meta.language);
  }

  static getIdFromKeyAndLanguage(key: string, language: string) {
    return `${key}${AdminTemplateBase.KEY_DIVIDER}${language}`;
  }

  static getKeyFromId(id?: string) {
    const split = id?.split(AdminTemplateBase.KEY_DIVIDER) || [];
    return split[0];
  }

  static getLanguageFromId(id?: string) {
    const split = id?.split(AdminTemplateBase.KEY_DIVIDER) || [];
    return split[1];
  }

  /**
   * Unique identifier per template
   * @example greeting-template
   */
  @FilterConfig({
    type: PageFilterTypes.STRING,
    displayName: "objects.template.key"
  })
  key: string;

  @FilterConfig({
    type: TemplateContent
  })
  content: ITemplateContent;

  /** Meta information of template */
  @FilterConfig({
    type: TemplateMeta
  })
  meta: ITemplateMeta;

  /** The timestamps of the message */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  /** A flag to determine if a template is public */
  @FilterConfig({
    type: PageFilterTypes.ENUM,
    displayName: "objects.template.isPublic",
    config: {
      items: [
        {
          text: $t("yes"),
          value: true
        },
        {
          text: $t("no"),
          value: false
        }
      ]
    }
  })
  isPublic: boolean;

  loading = false;

  get updateDto(): MrfiktivUpdateAdminTemplateDtoGen {
    return {
      content: this.content.dto,
      meta: this.meta.updateDto,
      isPublic: this.isPublic
    };
  }

  constructor(adminTemplate?: Partial<AdminTemplateBase | MrfiktivAdminTemplateViewModelGen>) {
    this.key = adminTemplate?.key || AdminTemplateBase.getKeyFromId((adminTemplate as AdminTemplateBase).id) || "";
    this.isPublic = adminTemplate?.isPublic ?? false;
    this.content = new TemplateContent(adminTemplate?.content);
    this.meta = new TemplateMeta({
      ...(adminTemplate?.meta ?? {}),
      language: (adminTemplate?.meta?.language ||
        AdminTemplateBase.getLanguageFromId((adminTemplate as AdminTemplateBase).id) ||
        LanguageCodeEnum.DE) as LanguageCodeEnum
    });
    this.timestamp = new Timestamp(adminTemplate?.timestamp);
  }

  map(adminTemplate?: Partial<AdminTemplateBase | MrfiktivAdminTemplateViewModelGen>) {
    if (!adminTemplate) return;
    this.key = adminTemplate?.key || AdminTemplateBase.getKeyFromId((adminTemplate as AdminTemplateBase).id) || "";
    this.isPublic = adminTemplate?.isPublic ?? false;
    this.content = new TemplateContent(adminTemplate?.content);
    this.meta = new TemplateMeta({
      ...(adminTemplate?.meta ?? {}),
      language: (adminTemplate?.meta?.language ||
        AdminTemplateBase.getLanguageFromId((adminTemplate as AdminTemplateBase).id) ||
        LanguageCodeEnum.DE) as LanguageCodeEnum
    });
    this.timestamp = new Timestamp(adminTemplate?.timestamp);
  }

  async fetch(): Promise<this> {
    try {
      this.loading = true;
      const fetched = await adminTemplateService.getByKey(this.key, this.meta.language);
      this.map(fetched);
      AdminTemplateDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }

    return this;
  }

  async create() {
    try {
      this.loading = true;
      const dto: MrfiktivCreateAdminTemplateDtoGen = {
        key: this.key || undefined,
        content: this.content.dto,
        meta: this.meta,
        isPublic: this.isPublic
      };
      const res = await adminTemplateService.create(dto);
      this.map(res);
      AdminTemplateDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }

    return this;
  }

  async delete() {
    try {
      this.loading = true;
      await adminTemplateService.delete(this.key, this.meta.language);
      AdminTemplateDataAccessLayer.delete(this);
    } catch (e) {
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }
  }

  async update() {
    const dto: MrfiktivUpdateAdminTemplateDtoGen | ThgUpdateAdminTemplateDtoGen = {
      content: this.content.dto,
      meta: this.meta.updateDto,
      isPublic: this.isPublic
    };

    return this.updatePartial(dto);
  }

  async updatePartial(dto: MrfiktivUpdateAdminTemplateDtoGen) {
    try {
      this.loading = true;
      const res = await adminTemplateService.update(this.key, this.meta.language, dto);
      this.map(res);
      AdminTemplateDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }

    return this;
  }

  goTo(router: VueRouter) {
    return {
      table: () => new AdminTemplateGoToHelper(router).goToAdminTemplateTable({}),
      detail: () =>
        new AdminTemplateGoToHelper(router).goToAdminTemplateDetail({
          templateId: this.id
        }),
      form: () =>
        new AdminTemplateGoToHelper(router).goToAdminTemplateDetailForm({
          templateId: this.id
        })
    };
  }
}

type IAdminTemplate = AdminTemplateBase;
const AdminTemplate = Filter.createForClass(AdminTemplateBase);

export { AdminTemplate, IAdminTemplate };
