








































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import TemplateEditorPlaceholderDropdown from "@/components/template/TemplateEditorPlaceholderDropdown.vue";
import { SignDocumentTokenDtoGen } from "@/services/sign/v1/data-contracts";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IDocumentPageMeta } from "./DocumentDetailEditorMixin.vue";
import { SignatureQRTokenConfig, TextFieldConfig } from "./DocumentTemplateDetailTokenList.vue";
import { getDocumentEditorPositionOptions } from "./ImagePositionSelector.vue";

@Component({
  components: {
    LatestEntriesCardEmpty,
    TemplateEditorPlaceholderDropdown
  }
})
export default class ImagePositionSelectorMenu extends Vue {
  @Prop()
  token?: SignDocumentTokenDtoGen;

  @Prop({ default: false })
  editValues!: boolean;

  @Prop()
  documentTokens!: SignDocumentTokenDtoGen[];

  @Prop()
  partnerId!: string;

  @Prop()
  page!: IDocumentPageMeta;

  selected = "";

  edit: SignDocumentTokenDtoGen | null = null;

  get border() {
    return `
      border-style: solid;
      border-radius: 5px;
      border-color: ${this.$vuetify.theme.currentTheme.info};
      border-width: 1px;
    `;
  }

  close() {
    this.selected = "";
    this.$emit("close");
  }

  isSignature(token: string) {
    return [SignatureQRTokenConfig.token.toString(), SignatureQRTokenConfig.token.toString].includes(token);
  }

  isTextField(token: string) {
    return token === TextFieldConfig.token;
  }

  positionEditOptionsMobile(e?: SignDocumentTokenDtoGen) {
    const elements = getDocumentEditorPositionOptions(e, this.page);

    const options: {
      collapsedIcon?: string;
      isDisabled?: (token: string) => boolean;
      content: {
        icon: string;
        click: () => any;
        bordered?: boolean;
      }[];
    }[] = [
      {
        collapsedIcon: "mdi-arrow-up-down-bold",
        content: [
          { icon: "mdi-minus", click: elements.y.increase.click },
          { icon: "mdi-plus", click: elements.y.decrease.click }
        ]
      },
      {
        collapsedIcon: "mdi-arrow-left-right-bold",
        content: [
          { icon: "mdi-minus", click: elements.x.decrease.click },
          { icon: "mdi-plus", click: elements.x.increase.click }
        ]
      },
      {
        collapsedIcon: "mdi-arrow-collapse-vertical",
        isDisabled: (token: string) => this.isSignature(token),
        content: [
          {
            icon: "mdi-minus",
            click: elements.h.decrease.click
          },
          { icon: "mdi-plus", click: elements.h.increase.click }
        ]
      },
      {
        collapsedIcon: "mdi-arrow-collapse-horizontal",
        isDisabled: (token: string) => this.isSignature(token),
        content: [
          { icon: "mdi-minus", click: elements.w.decrease.click },
          { icon: "mdi-plus", click: elements.w.increase.click }
        ]
      },
      {
        collapsedIcon: "mdi-format-size",
        isDisabled: (token: string) => this.isSignature(token),
        content: [
          { icon: "mdi-minus", click: elements.f.decrease.click },
          { icon: "mdi-plus", click: elements.f.increase.click }
        ]
      }
    ];

    if (e?.token) {
      options.push({
        content: [
          {
            icon: "mdi-trash-can",
            click: () => this.remove(e)
          }
        ]
      });
    }

    return options;
  }

  toggleSelected(selected: string) {
    if (this.selected === selected) {
      this.selected = "";
    } else {
      this.selected = selected;
    }
  }

  remove(e: SignDocumentTokenDtoGen) {
    const index = this.documentTokens.findIndex(d => {
      return JSON.stringify(e) === JSON.stringify(d);
    });
    if (index > -1) {
      this.documentTokens.splice(index, 1);
    }
    this.edit = null;

    DocumentTemplateModule.setHighlightedToken(-1);
  }

  confirmDropdown(e?: SignDocumentTokenDtoGen) {
    if (!e) {
      return;
    }
    const token = (this.$refs.dropdown as TemplateEditorPlaceholderDropdown).select();
    e.token = token;
  }

  isButtonDisabled(icon: string, token: string) {
    if (
      [
        "mdi-unfold-less-horizontal",
        "mdi-unfold-more-horizontal",
        "mdi-unfold-less-vertical",
        "mdi-unfold-more-vertical",
        "mdi-format-font-size-decrease",
        "mdi-format-font-size-increase"
      ].includes(icon) &&
      SignatureQRTokenConfig.token === token
    ) {
      return true;
    }

    return false;
  }
}
