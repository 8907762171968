var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        elevation: "0"
      }
    },
    [
      _vm.showText
        ? _c(
            "v-card-text",
            {
              staticClass: "px-0"
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("components.thg.thgDialogYearSelection.subtitle", {
                      numberplate: _vm.numberplate
                    })
                  ) +
                  " "
              )
            ]
          )
        : _vm._e(),
      _c(
        "v-card-text",
        {
          staticClass: "px-0"
        },
        [
          _c(
            "v-radio-group",
            {
              model: {
                value: _vm.years,
                callback: function callback($$v) {
                  _vm.years = $$v
                },
                expression: "years"
              }
            },
            _vm._l(_vm.activeProducts, function(products, i) {
              return _c(
                "v-sheet",
                {
                  key: i,
                  staticClass: "mb-2 py-n6",
                  attrs: {
                    outlined: "",
                    rounded: "",
                    color: _vm.activeCardStyle(products.years)
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      },
                      on: {
                        click: function click($event) {
                          _vm.years = products.years
                        }
                      }
                    },
                    [
                      products.prices.length > 1
                        ? _c("thg-products-price-combo-card", {
                            attrs: {
                              showPrice: true,
                              product: products
                            }
                          })
                        : _c("thg-products-price-card", {
                            attrs: {
                              showPrice: true,
                              product: products
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }