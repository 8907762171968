var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list-item",
    {
      attrs: {
        outlined: ""
      },
      on: {
        click: function click($event) {
          return _vm.$emit("click", _vm.item)
        }
      }
    },
    [
      _c(
        "v-list-item-avatar",
        {
          attrs: {
            tile: "",
            size: "60"
          }
        },
        [
          _vm.item.imageUrl
            ? _c("v-img", {
                staticStyle: {
                  "border-radius": "4px"
                },
                attrs: {
                  src: _vm.item.imageUrl,
                  contain: ""
                }
              })
            : _c(
                "v-avatar",
                {
                  staticClass: "mb-8 mt-8",
                  attrs: {
                    color: _vm.color,
                    size: "80"
                  }
                },
                [
                  _vm.item.icon
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            dark: "",
                            "x-large": ""
                          }
                        },
                        [_vm._v(" mdi-" + _vm._s(_vm.item.icon) + " ")]
                      )
                    : _c(
                        "v-icon",
                        {
                          attrs: {
                            dark: "",
                            "x-large": ""
                          }
                        },
                        [_vm._v(" mdi-account-wrench-outline ")]
                      )
                ],
                1
              )
        ],
        1
      ),
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", [_vm._v(_vm._s(_vm.item.name))]),
          _c("v-list-item-subtitle", [_vm._v(_vm._s(_vm.item.description))]),
          _vm.item.isDisplayDuration
            ? _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("bookingForm.common.minutes", {
                        value: _vm.item.duration / 1000 / 60
                      })
                    ) +
                    " "
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }