





































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IAdminUser, IPartnerUser } from "@/models/user.entity";
import { MrfiktivPermissionDtoGen as PermissionDto } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { PartnerModule } from "@/store/modules/partner";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";

@Component({
  components: { ConfirmActionDialog }
})
export default class UserAdministrationDetailPermissionsRemoveDialog
  extends mixins(DarkModeHighlightMixin, PermissionMixin)
  implements IDialog {
  readonly BackendResourceEnum = BackendResourceEnum;

  @Prop()
  permissions!: PermissionDto[];

  @Prop()
  permissionToDelete!: PermissionDto;

  @Prop()
  selectedUser!: IAdminUser | IPartnerUser;

  isDialogActive = false;

  isLoading = false;

  close() {
    this.isDialogActive = false;
  }

  open() {
    this.isDialogActive = true;
  }

  getPartnerNameForId(partnerId: string) {
    return PartnerModule.partners.find(partner => partner.id === partnerId)?.companyName;
  }

  async removePermission(permission: PermissionDto) {
    this.isLoading = true;

    const index = this.permissions.indexOf(permission);
    if (index > -1) {
      this.permissions.splice(index, 1);
    }
    await this.selectedUser?.removePermission([permission]);

    this.isDialogActive = false;
    this.isLoading = false;
  }
}
