























































import UserAdministrationDetailPermissionsAddDialog from "@/components/partner/UserAdministrationDetailPermissionsAddDialog.vue";
import UserPermissionAddDialog from "@/components/partner/UserPermissionAddDialog.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import TableWrapper, { IControlElements } from "@/components/utility/TableWrapper.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivPermissionDtoGen as PermissionDto } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { Component } from "vue-property-decorator";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    LayoutSimple,
    TheLayoutPortal,
    TableWrapper,
    Tooltip,
    UserAdministrationDetailPermissionsAddDialog,
    UserPermissionAddDialog,
    ConfirmActionDialog
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PermissionTable extends PartnerFallbackMixin {
  isLoadingAll = false;
  isLoading = false;

  isConfirm = true;

  selectedUser: IAdminUser | null = null;

  /**
   * Opening delete confirmation
   */
  isDeleteDialogActive = false;
  isDeleteDialogLoading = false;

  search = "";

  itemsPerPage = 25;

  snack = false;
  snackColor = "";
  snackText = "";

  expanded = [];
  selected: PermissionDto[] = [];

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  headers = [
    { text: this.$t("components.partner.PermissionTable.id"), align: "start", value: "id", width: 300 },
    { text: this.$t("components.partner.PermissionTable.type"), align: "start", value: "type", width: 150 },
    { text: this.$t("components.partner.PermissionTable.action"), align: "start", value: "action" },
    { text: "", align: "end", value: "controls", width: 200, sortable: false }
  ];

  async mounted() {
    await this.load();
  }

  get userId() {
    return this.$route.params.userId;
  }

  async load() {
    this.isLoadingAll = true;

    let permissions: PermissionDto[] = [];

    try {
      this.isLoading = true;

      if (this.$route.params.userId) {
        this.selectedUser = await AdminUserPaginationModule.getSelectedUser(this.$route.params.userId);
      } else {
        this.$toast.error("No user id provided");
        return;
      }

      if (!this.selectedUser) {
        this.$toast.warning("User konnten nicht geladen.");
        this.$router.push({ name: "UserAdministration" });
      } else {
        if (this.selectedUser?.permission && this.selectedUser?.permission.length >= 1) {
          permissions = this.selectedUser?.permission;
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
      this.isLoadingAll = false;
    }

    try {
      this.isLoadingAll = true;

      const partnerIds = new Set<string>();
      permissions.map(p => partnerIds.add(p.id));

      await PartnerModule.getPartners(Array.from(partnerIds));
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingAll = false;
    }
  }

  get permissions(): PermissionDto[] {
    if (!this.selectedUser?.permission) {
      return [];
    }

    return this.selectedUser?.permission;
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-delete-outline",
        text: this.$t("sign.PermissionTable.delete").toString(),
        action: (item: PermissionDto) => {
          this.selected = [item];
          if (this.isConfirm) {
            this.isDeleteDialogActive = true;
          } else {
            this.deleteDocument();
          }
        }
      }
    ];
  }

  get partners() {
    return PartnerModule.partners;
  }

  getPartnerById(id: string) {
    return PartnerModule.partnersMap.get(id);
  }

  async deleteDocument() {
    this.isDeleteDialogLoading = true;
    this.isLoading;

    const permission = this.selected[0];

    if (!permission) {
      this.$toast.error("No permission selected");
    }

    try {
      await this.selectedUser?.removePermission([permission]);

      if (!this.isConfirm) {
        this.$toast.success("👍");
      }

      await this.load();
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("sign.Sign.error.network");
    } finally {
      this.isDeleteDialogLoading = false;
      this.isDeleteDialogActive = false;
      this.isLoading = false;
    }
  }
}
