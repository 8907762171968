var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    {
      attrs: {
        align: "stretch"
      }
    },
    [
      _c(
        "v-col",
        {
          staticClass: "d-flex justify-center align-center",
          attrs: {
            cols: "12"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "text-center ",
              staticStyle: {
                height: "100%",
                "overflow-y": "auto",
                "overflow-x": "hidden"
              },
              attrs: {
                flat: "",
                "max-width": "600"
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c("v-img", {
                    staticClass: "mt-8",
                    attrs: {
                      align: "center",
                      justify: "center",
                      contain: "",
                      "max-height": "250px",
                      src: require("@/assets/undraw_pending_approval_xuu9.svg")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-title",
                {
                  staticClass: "justify-center"
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("sign.DocumentTemplateDetailEdit.title")) +
                      " "
                  )
                ]
              ),
              _c("v-card-subtitle", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("sign.DocumentTemplateDetailEdit.subtitle")
                  )
                }
              }),
              _c(
                "v-card-text",
                [
                  _c("i18n", {
                    attrs: {
                      path: "sign.DocumentTemplateDetailEdit.text"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "request",
                        fn: function fn() {
                          return [
                            _c(
                              "a",
                              {
                                on: {
                                  click: _vm.goToSignRequest
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "sign.DocumentTemplateDetailSignature.signRequest"
                                    )
                                  )
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "sign",
                        fn: function fn() {
                          return [
                            _c(
                              "a",
                              {
                                on: {
                                  click: _vm.goToSignPage
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .$t("common.verbs.sign")
                                      .toString()
                                      .toLocaleLowerCase()
                                  )
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _vm.baseUrls && _vm.baseUrls.length > 1
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-select", {
                        staticClass: "mb-n6",
                        attrs: {
                          label: _vm.$t(
                            "sign.DocumentTemplateDetailEdit.selectUrl"
                          ),
                          items: _vm.baseUrls,
                          outlined: "",
                          dense: ""
                        },
                        model: {
                          value: _vm.url,
                          callback: function callback($$v) {
                            _vm.url = $$v
                          },
                          expression: "url"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-text-field", {
                    attrs: {
                      hint: _vm.$t(
                        "components.partner.PartnerReportInitializeCard.hint"
                      ),
                      "persistent-hint": "",
                      value: _vm.link,
                      readonly: "",
                      outlined: "",
                      solo: "",
                      "append-icon": "mdi-content-copy"
                    },
                    on: {
                      "click:append": _vm.copyLinkToClipboard,
                      click: _vm.copyLinkToClipboard
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }