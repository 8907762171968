/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { PaginationFilterListElement } from "@/lib/utility/data/page-filter-list-element.interface";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { ReportImageType } from "@/models/Report/ReportImageType";
import partnerImageService from "@/services/mrfiktiv/services/partnerImageService";
import {
  MrfiktivPartnerImageViewModelGen,
  MrfiktivReportControllerFindAllParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "./../paginated-base.store";
import { PartnerImageDataAccessLayer } from "./access-layers/partner-image.access-layer";
import { PartnerImagePageDataProvider } from "./page-data-provider/partner-image.page-data-provider";
import { IPartnerImage } from "@/models/partner-image.entity";

/**
 * Example store for testing
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "imageStore",
  store
})
export class PartnerImageStore extends PaginatedBaseStore<IPartnerImage, MrfiktivReportControllerFindAllParamsGen> {
  _data = PartnerImageDataAccessLayer;
  _pageProvider = PartnerImagePageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = [
    { key: "id", type: PageFilterTypes.OBJECT_ID, displayName: "objects.id" }
  ].map(f => new PaginationFilterListElement(f));

  @Action
  async create(data: {
    partnerId: string;
    file: File;
    type: ReportImageType;
  }): Promise<MrfiktivPartnerImageViewModelGen> {
    const imageResult = await partnerImageService.create(data.partnerId, { image: data.file, type: data.type });
    return imageResult;
  }

  @Action
  async fetchOne(data: { partnerId: string; id: string }) {
    const imageResult = await partnerImageService.getOne(data.partnerId, data.id);
    return imageResult;
  }
}

export const PartnerImageModule = getModule(PartnerImageStore);
