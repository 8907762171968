var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.DELETE,
            a: _vm.ResourceEnum.TEMPLATE
          }
        },
        [
          _vm.template && !(_vm.partnerId && _vm.template.isAdminTemplate)
            ? _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialog = true
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-trash-can")])],
                1
              )
            : _c(
                "tooltip",
                {
                  attrs: {
                    text: _vm.$t("components.template.detail.delete.systemMail")
                  }
                },
                [_c("v-icon", [_vm._v("mdi-account")])],
                1
              )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "800",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("components.template.detail.delete.title"))
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        text: ""
                      },
                      on: {
                        click: function click($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.template.detail.delete.abort")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        text: ""
                      },
                      on: {
                        click: _vm.deleteTemplate
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.template.detail.delete.delete")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }