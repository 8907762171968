





















import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { handleError } from "@/lib/utility/handleError";
import { reportStateMap } from "@/store/mapper/report-icon.map";

@Component({ components: {} })
export default class PartnerReportDetailChangeProgress extends Vue {
  /**
   * local loading status is used here next to isProgressUpdateLoading (managed by store), because if progress status is deleted in other dialog, this dialog should be disabled but not loading
   */
  isProgressUpdating = false;

  get isProgressUpdateLoading() {
    return PartnerModule.progressUpdateLoading;
  }

  /*
   * States in dropdown list
   */
  get items(): ProgressStatusEnum[] {
    return [
      ProgressStatusEnum.DRAFT,
      ProgressStatusEnum.NEW,
      ProgressStatusEnum.IN_PROGRESS,
      ProgressStatusEnum.FINISHED
    ];
  }

  get progress() {
    return PartnerModule.report.progressStatus;
  }

  get ProgressStatusEnum() {
    return ProgressStatusEnum;
  }

  color(progressStatus: ProgressStatusEnum) {
    return reportStateMap.get(progressStatus)?.color || "blue-grey lighten-4";
  }

  /*
   * sends request to update progress to backend
   */
  async setProgress(progressStatus: ProgressStatusEnum) {
    const current = this.progress;
    if (progressStatus === current) {
      this.$log.info("Status did not change");
      return;
    }

    try {
      this.isProgressUpdating = true;
      await PartnerModule.report.updateProgressStatus(progressStatus);
    } catch (e) {
      handleError(e);
    } finally {
      this.isProgressUpdating = false;
    }
  }
}
