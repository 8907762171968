

































import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import TimeLineItemText from "../utility/TimeLineItemText.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { IActivityLog } from "@/models/activity-log.entity";

@Component({
  components: { ActivityTimeLineItemDelete, TimeLineItemText, ContextMenu }
})
export default class ActivityTimeLineItemReportStatusUpdate extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: IActivityLog;

  statusConfigurationMap = new Map<string, { icon: string; color: string; text: string }>([
    [
      "inProgress",
      {
        icon: "mdi-eye-outline",
        color: "warning",
        text: this.$t("timeLine.ActivityTimeLineItemReportStatusUpdate.status.inProgress").toString()
      }
    ],
    [
      "finished",
      {
        icon: "mdi-check",
        color: "success",
        text: this.$t("timeLine.ActivityTimeLineItemReportStatusUpdate.status.finished").toString()
      }
    ],
    [
      "new",
      {
        icon: "mdi-eye-outline",
        color: "info",
        text: this.$t("timeLine.ActivityTimeLineItemReportStatusUpdate.status.new").toString()
      }
    ]
  ]);

  get statusConfiguration() {
    let statusConfiguration = this.statusConfigurationMap.get(this.activityLogEntry.comment || "");
    if (!statusConfiguration) {
      const thgStatus = thgStatusMap.get(this.activityLogEntry.comment as ThgStatusEnum);
      if (thgStatus) {
        statusConfiguration = {
          text:
            this.$t("timeLine.ActivityTimeLineItemReportStatusUpdate.titleAppendix") +
            this.$t(thgStatus.text)?.toString(),
          icon: thgStatus.icon,
          color: thgStatus.color
        };
      }
    }

    let status = this.activityLogEntry.comment;
    const isTicketStatus = Object.values(TicketStatusEnum).includes(status as TicketStatusEnum);
    if (!statusConfiguration && status && isTicketStatus) {
      status = this.$t("enums.TicketStatusEnum." + status).toString();
    }

    if (!statusConfiguration) {
      return {
        icon: "mdi-eye-outline",
        color: "info",
        text: this.$t("timeLine.ActivityTimeLineItemReportStatusUpdate.status.default", {
          status: status
        })
      };
    }

    return statusConfiguration;
  }

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }
}
