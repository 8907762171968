var render = function() {
  var _vm$customerFieldConf,
    _vm$customerFieldConf2,
    _vm$customerFieldConf3,
    _vm$customerFieldConf4,
    _vm$selectedService,
    _vm$selectedService$i,
    _vm$selectedService2

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          class: _vm.isScrollable,
          attrs: {
            flat: "",
            "max-height": _vm.height
          }
        },
        [
          _c(
            "v-card-title",
            {
              class: _vm.titleClass
            },
            [_vm._v(_vm._s(_vm.$t("bookingForm.BookingForm.title")))]
          ),
          _vm.selectedSlot && _vm.selectedService
            ? _c(
                "v-list",
                {
                  class: {
                    "mx-4 mb-2 mt-n4": !_vm.isMobile,
                    "mx-2 mb-2 mt-n4": _vm.isMobile
                  }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _vm._v(
                                " " + _vm._s(_vm.selectedSlotDate()) + " "
                              ),
                              _c("booking-back-button", {
                                on: {
                                  click: _vm.back
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v(
                              " " + _vm._s(_vm.selectedSlotStartTime()) + " "
                            )
                          ]),
                          _vm.selectedService.isDisplayDuration
                            ? _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "bookingForm.BookingForm.service",
                                        {
                                          service: _vm.selectedService.name,
                                          duration:
                                            (_vm.selectedSlot.end -
                                              _vm.selectedSlot.start) /
                                            60 /
                                            1000
                                        }
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " + _vm._s(_vm.selectedService.name) + " "
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.selectedSlot && _vm.selectedService
            ? _c(
                "v-form",
                {
                  attrs: {
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.isValid,
                    callback: function callback($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      class: {
                        "mx-4": !_vm.isMobile,
                        "ml-2 mr-1": _vm.isMobile
                      }
                    },
                    [
                      (_vm$customerFieldConf = _vm.customerFieldConfigMap.get(
                        "firstName"
                      )) !== null &&
                      _vm$customerFieldConf !== void 0 &&
                      _vm$customerFieldConf.isActive
                        ? _c(
                            "v-col",
                            {
                              ref: "firstName-col",
                              attrs: {
                                cols: "12",
                                md: "6",
                                order: "1"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mb-n4",
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  label: _vm.$t("bookingForm.common.firstName"),
                                  "data-test-firstName": "",
                                  rules: _vm.firstNameRule
                                },
                                model: {
                                  value: _vm.customerInformation.firstName,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.customerInformation,
                                      "firstName",
                                      $$v
                                    )
                                  },
                                  expression: "customerInformation.firstName"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm$customerFieldConf2 = _vm.customerFieldConfigMap.get(
                        "lastName"
                      )) !== null &&
                      _vm$customerFieldConf2 !== void 0 &&
                      _vm$customerFieldConf2.isActive
                        ? _c(
                            "v-col",
                            {
                              ref: "lastName-col",
                              attrs: {
                                cols: "12",
                                md: "6",
                                order: "2"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mb-n4",
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  label: _vm.$t("bookingForm.common.lastName"),
                                  "data-test-lastName": "",
                                  rules: _vm.lastNameRule
                                },
                                model: {
                                  value: _vm.customerInformation.lastName,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.customerInformation,
                                      "lastName",
                                      $$v
                                    )
                                  },
                                  expression: "customerInformation.lastName"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          ref: "email-col",
                          attrs: {
                            cols: "12",
                            md: "6",
                            order: "3"
                          }
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "mb-n4",
                            attrs: {
                              dense: "",
                              outlined: "",
                              label: _vm.$t("bookingForm.common.email"),
                              type: "email",
                              "data-test-email": "",
                              rules: _vm.emailRule
                            },
                            on: {
                              blur: _vm.storeCurrentEmail
                            },
                            model: {
                              value: _vm.customerInformation.email,
                              callback: function callback($$v) {
                                _vm.$set(_vm.customerInformation, "email", $$v)
                              },
                              expression: "customerInformation.email"
                            }
                          })
                        ],
                        1
                      ),
                      (_vm$customerFieldConf3 = _vm.customerFieldConfigMap.get(
                        "phone"
                      )) !== null &&
                      _vm$customerFieldConf3 !== void 0 &&
                      _vm$customerFieldConf3.isActive
                        ? _c(
                            "v-col",
                            {
                              ref: "phone-col",
                              attrs: {
                                cols: "12",
                                md: "6",
                                order: "4"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mb-n4",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  label: _vm.$t("bookingForm.common.phone"),
                                  type: "tel",
                                  "data-test-phone": "",
                                  rules: _vm.phoneRule
                                },
                                model: {
                                  value: _vm.customerInformation.phone,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.customerInformation,
                                      "phone",
                                      $$v
                                    )
                                  },
                                  expression: "customerInformation.phone"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm$customerFieldConf4 = _vm.customerFieldConfigMap.get(
                        "numberPlate"
                      )) !== null &&
                      _vm$customerFieldConf4 !== void 0 &&
                      _vm$customerFieldConf4.isActive
                        ? _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                md: "12",
                                order: "5"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "text--secondary text-body-2"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "bookingForm.BookingForm.numberPlateTitle"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("numberplate-form", {
                                attrs: {
                                  valid: _vm.numberPlateValid,
                                  countryCode: _vm.countryCode
                                },
                                on: {
                                  valid: function valid($event) {
                                    _vm.numberPlateValid = $event
                                  }
                                },
                                model: {
                                  value: _vm.customerInformation.numberPlate,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.customerInformation,
                                      "numberPlate",
                                      $$v
                                    )
                                  },
                                  expression: "customerInformation.numberPlate"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      ((_vm$selectedService = _vm.selectedService) === null ||
                      _vm$selectedService === void 0
                        ? void 0
                        : (_vm$selectedService$i =
                            _vm$selectedService.imageConfig) === null ||
                          _vm$selectedService$i === void 0
                        ? void 0
                        : _vm$selectedService$i.length) > 0 &&
                      Object.keys(_vm.fileUploadConfiguration).length > 0
                        ? _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                order: "6"
                              }
                            },
                            _vm._l(
                              (_vm$selectedService2 = _vm.selectedService) ===
                                null || _vm$selectedService2 === void 0
                                ? void 0
                                : _vm$selectedService2.imageConfig,
                              function(imageConfigItem) {
                                var _vm$fileUploadConfigu,
                                  _vm$fileUploadConfigu2,
                                  _vm$fileUploadConfigu3,
                                  _vm$fileUploadConfigu4,
                                  _vm$fileUploadConfigu5,
                                  _vm$fileUploadConfigu6

                                return _c("FileUploadMulti", {
                                  key: imageConfigItem.type,
                                  attrs: {
                                    example:
                                      (_vm$fileUploadConfigu =
                                        _vm.fileUploadConfiguration[
                                          imageConfigItem.type
                                        ]) === null ||
                                      _vm$fileUploadConfigu === void 0
                                        ? void 0
                                        : _vm$fileUploadConfigu.example,
                                    displayImages: _vm.getDisplayImagesByType(
                                      imageConfigItem.type
                                    ),
                                    filesCb: _vm.getFilesCbByType(
                                      imageConfigItem.type
                                    ),
                                    overlay:
                                      (_vm$fileUploadConfigu2 =
                                        _vm.fileUploadConfiguration[
                                          imageConfigItem.type
                                        ]) === null ||
                                      _vm$fileUploadConfigu2 === void 0
                                        ? void 0
                                        : _vm$fileUploadConfigu2.overlay,
                                    text:
                                      (_vm$fileUploadConfigu3 =
                                        _vm.fileUploadConfiguration[
                                          imageConfigItem.type
                                        ]) === null ||
                                      _vm$fileUploadConfigu3 === void 0
                                        ? void 0
                                        : _vm$fileUploadConfigu3.text,
                                    textAlt:
                                      (_vm$fileUploadConfigu4 =
                                        _vm.fileUploadConfiguration[
                                          imageConfigItem.type
                                        ]) === null ||
                                      _vm$fileUploadConfigu4 === void 0
                                        ? void 0
                                        : _vm$fileUploadConfigu4.textAlt,
                                    handleUploadCb:
                                      (_vm$fileUploadConfigu5 =
                                        _vm.fileUploadConfiguration[
                                          imageConfigItem.type
                                        ]) === null ||
                                      _vm$fileUploadConfigu5 === void 0
                                        ? void 0
                                        : _vm$fileUploadConfigu5.handleUploadCb,
                                    handleDeleteCb:
                                      (_vm$fileUploadConfigu6 =
                                        _vm.fileUploadConfiguration[
                                          imageConfigItem.type
                                        ]) === null ||
                                      _vm$fileUploadConfigu6 === void 0
                                        ? void 0
                                        : _vm$fileUploadConfigu6.handleDeleteCb,
                                    maxFiles:
                                      _vm.fileUploadConfiguration[
                                        imageConfigItem.type
                                      ].maxFiles
                                  }
                                })
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            order: "7"
                          }
                        },
                        [
                          _c("v-textarea", {
                            staticClass: "mb-n4",
                            attrs: {
                              outlined: "",
                              label: _vm.$t("bookingForm.common.note"),
                              "data-test-notes": ""
                            },
                            model: {
                              value: _vm.bookingInformation.notes,
                              callback: function callback($$v) {
                                _vm.$set(_vm.bookingInformation, "notes", $$v)
                              },
                              expression: "bookingInformation.notes"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            order: "8"
                          }
                        },
                        [
                          _c("custom-field-list-form", {
                            key: "customFieldListFormKey" + "test",
                            attrs: {
                              valid: _vm.isBookingCustomFieldsValid,
                              customFieldConfig:
                                _vm.selectedService.customFieldConfig
                            },
                            on: {
                              "update:valid": function updateValid($event) {
                                _vm.isBookingCustomFieldsValid = $event
                              }
                            },
                            model: {
                              value: _vm.values,
                              callback: function callback($$v) {
                                _vm.values = $$v
                              },
                              expression: "values"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            order: "8"
                          }
                        },
                        [
                          _c("p", {
                            staticClass: "text-body-2 text--secondary pl-1",
                            domProps: {
                              innerHTML: _vm._s(_vm.description)
                            }
                          }),
                          _c("v-checkbox", {
                            staticClass: "padded",
                            attrs: {
                              "data-test-privacy-checkbox": "",
                              rules: [
                                function(v) {
                                  return (
                                    !!v || _vm.$t("report.summary.readPrivacy")
                                  )
                                }
                              ],
                              label: _vm.$t("report.summary.privacyPolicyText")
                            },
                            model: {
                              value: _vm.acceptedPrivacyPolicy,
                              callback: function callback($$v) {
                                _vm.acceptedPrivacyPolicy = $$v
                              },
                              expression: "acceptedPrivacyPolicy"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isMobile
            ? _c(
                "v-row",
                {
                  staticClass: "mx-4 mb-4"
                },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "6"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            color: _vm.color
                          },
                          on: {
                            click: _vm.back
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("bookingForm.common.prev")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "6",
                        align: "right"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            elevation: "0",
                            loading: _vm.loading,
                            disabled: !_vm.isValid,
                            color: _vm.color,
                            dark: _vm.isValid
                          },
                          on: {
                            click: _vm.bookSlot
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("bookingForm.common.submit")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isMobile
        ? _c("booking-navigation", {
            attrs: {
              showNext: true,
              isMobile: _vm.isMobile,
              cardClass: "pa-4 mx-auto no-top-round-corners",
              disabled: !_vm.isValid,
              nextBtnText: "bookingForm.common.submit",
              loading: _vm.loading
            },
            on: {
              back: _vm.back,
              forward: _vm.forward
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }