










import TemplateTable from "@/components/template/TemplateTable.vue";
import Debug from "@/components/utility/Debug.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ITemplate } from "@/models/template.entity";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { TemplateGoToHelper } from "@/lib/utility/template.go-to-helper";
import { AdminTemplate } from "@/models/admin-template.entity";

@Component({
  components: {
    TemplateTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class AdminTemplateTableView extends mixins(PartnerFallbackMixin) {
  readonly store = AdminTemplateModule;

  readonly AMOUNT = "amountTemplate";

  @Ref("templateTable")
  templateTable!: TemplateTable;

  isLoading = false;

  get actions(): IAction[] {
    return [];
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(TemplateGoToHelper.breadCrumbs.TemplateTable({}));

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      text: `${this.store.entities.length} ${this.$t("template.templates")}`,
      key: this.AMOUNT,
      disabled: true
    });

    return chips;
  }

  beforeMount() {
    this.store.setFilters([]);
  }

  async mounted() {
    this.isLoading = true;

    const templateId = this.$route.query.templateId as string;
    if (templateId) {
      const template = new AdminTemplate({ id: templateId });
      this.templateTable?.setSelectedItem(template);
    }

    this.isLoading = false;
  }

  onClickRow(template: ITemplate) {
    new TemplateGoToHelper(this.$router).setUrl(
      TemplateGoToHelper.locations.templateTable({
        query: template ? { templateId: template.id } : {}
      })
    );
  }
}
