























import { IFleet } from "@/models/fleet.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";

@Component({
  components: {},
  filters: {}
})
export default class RefsFleet extends mixins(Vue) implements IRefDetail<IFleet> {
  @Prop()
  item!: IFleet;

  @Prop()
  small!: boolean;

  @Prop()
  confirmable!: boolean;

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  async goToDetail() {
    const partnerId = this.item.partnerId;
    const fleetId = this.item.id;

    this.$router.push({ name: "FleetDetailView", params: { partnerId: partnerId, fleetId: fleetId } });
  }
}
