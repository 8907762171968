var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        fullscreen: _vm.isMobile,
        height: _vm.sideCardHeight,
        width: _vm.sideCardWidth,
        title: _vm.signature
          ? _vm.$t("sign.DocumentTemplateDetailSignature.sign")
          : _vm.$t("sign.DocumentTemplateDetailSignature.signRequest"),
        loading: _vm.loading
      },
      on: {
        close: function close($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _vm.signatureRequest
                ? _c(
                    "span",
                    _vm._l(_vm.signatureRequest.refs || [], function(ref) {
                      return _c("go-to-reference-button", {
                        key: "reference" + ref.refId,
                        attrs: {
                          reference: ref,
                          partnerId: _vm.partnerId
                        }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.signatureRequest
                ? _c("document-template-detail-signature-template-dialog", {
                    attrs: {
                      signRequest: _vm.signatureRequest,
                      report: _vm.report,
                      partnerId: _vm.partner._id,
                      refs: _vm.mailRefs
                    }
                  })
                : _vm._e(),
              _vm.signatureRequest
                ? _c("sign-initialize-dialog", {
                    attrs: {
                      request: _vm.signatureRequest
                    },
                    on: {
                      "update:request": function updateRequest($event) {
                        _vm.signatureRequest = $event
                      }
                    }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.isValid,
            callback: function callback($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c("v-simple-table", [
            _c("tbody", [
              _vm.signature && _vm.signature.timestamp
                ? _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("sign.DocumentTemplateDetailSignature.date")
                        )
                      )
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.signatureCreatedDateFormatted))
                    ])
                  ])
                : _vm._e(),
              _vm.signature && _vm.signature.firstName
                ? _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "sign.DocumentTemplateDetailSignature.firstName"
                          )
                        )
                      )
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.signature.firstName))])
                  ])
                : _vm._e(),
              _vm.signature && _vm.signature.lastName
                ? _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("sign.DocumentTemplateDetailSignature.name")
                        )
                      )
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.signature.lastName))])
                  ])
                : _vm._e(),
              _vm.signature &&
              _vm.signature.authentication &&
              _vm.signature.authentication.type
                ? _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "sign.DocumentTemplateDetailSignature.registration"
                          )
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.signature
                ? _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "vertical-align": "middle"
                        },
                        attrs: {
                          colspan: "2"
                        }
                      },
                      [
                        _c("v-img", {
                          staticClass: "ma-auto",
                          style: "width: ".concat(
                            _vm.$vuetify.breakpoint.smAndDown ? "90vw" : "350px"
                          ),
                          attrs: {
                            src: _vm.signatureUrl,
                            contain: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "placeholder",
                                fn: function fn() {
                                  return [
                                    _c("v-skeleton-loader", {
                                      attrs: {
                                        type: "image"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            3617021982
                          )
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.signature
                ? _c("tr", [
                    _c(
                      "td",
                      {
                        attrs: {
                          colspan: "2"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "sign.DocumentTemplateDetailSignature.signRequest"
                            )
                          ) + ":"
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm.signatureRequest
                ? _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("sign.SignRequestForm.email")))
                    ]),
                    _vm.isEditSignatureRequest
                      ? _c(
                          "td",
                          [
                            _c("v-text-field", {
                              staticClass: "mb-n1 mt-1",
                              attrs: {
                                dense: "",
                                rules: _vm.requiredRule
                              },
                              model: {
                                value: _vm.signatureRequest.recipient.email,
                                callback: function callback($$v) {
                                  _vm.$set(
                                    _vm.signatureRequest.recipient,
                                    "email",
                                    $$v
                                  )
                                },
                                expression: "signatureRequest.recipient.email"
                              }
                            })
                          ],
                          1
                        )
                      : _c("td", [
                          _vm._v(_vm._s(_vm.signatureRequest.recipient.email))
                        ])
                  ])
                : _vm._e(),
              _vm.signatureRequest
                ? _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("sign.SignRequestForm.firstName")))
                    ]),
                    _vm.isEditSignatureRequest
                      ? _c(
                          "td",
                          [
                            _c("v-text-field", {
                              staticClass: "mb-n1 mt-1",
                              attrs: {
                                dense: ""
                              },
                              model: {
                                value: _vm.signatureRequest.recipient.firstName,
                                callback: function callback($$v) {
                                  _vm.$set(
                                    _vm.signatureRequest.recipient,
                                    "firstName",
                                    $$v
                                  )
                                },
                                expression:
                                  "signatureRequest.recipient.firstName"
                              }
                            })
                          ],
                          1
                        )
                      : _c("td", [
                          _vm._v(
                            _vm._s(_vm.signatureRequest.recipient.firstName)
                          )
                        ])
                  ])
                : _vm._e(),
              _vm.signatureRequest
                ? _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("sign.SignRequestForm.lastName")))
                    ]),
                    _vm.isEditSignatureRequest
                      ? _c(
                          "td",
                          [
                            _c("v-text-field", {
                              staticClass: "mb-n1 mt-1",
                              attrs: {
                                dense: ""
                              },
                              model: {
                                value: _vm.signatureRequest.recipient.lastName,
                                callback: function callback($$v) {
                                  _vm.$set(
                                    _vm.signatureRequest.recipient,
                                    "lastName",
                                    $$v
                                  )
                                },
                                expression:
                                  "signatureRequest.recipient.lastName"
                              }
                            })
                          ],
                          1
                        )
                      : _c("td", [
                          _vm._v(
                            _vm._s(_vm.signatureRequest.recipient.lastName)
                          )
                        ])
                  ])
                : _vm._e(),
              _vm.signatureRequest
                ? _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("sign.SignRequestForm.companyName")))
                    ]),
                    _vm.isEditSignatureRequest
                      ? _c(
                          "td",
                          [
                            _c("v-text-field", {
                              staticClass: "mb-n1 mt-1",
                              attrs: {
                                dense: ""
                              },
                              model: {
                                value:
                                  _vm.signatureRequest.recipient.companyName,
                                callback: function callback($$v) {
                                  _vm.$set(
                                    _vm.signatureRequest.recipient,
                                    "companyName",
                                    $$v
                                  )
                                },
                                expression:
                                  "signatureRequest.recipient.companyName"
                              }
                            })
                          ],
                          1
                        )
                      : _c("td", [
                          _vm._v(
                            _vm._s(_vm.signatureRequest.recipient.companyName)
                          )
                        ])
                  ])
                : _vm._e(),
              _vm.signatureRequest
                ? _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("sign.SignRequestForm.validBy")))
                    ]),
                    _vm.isEditSignatureRequest
                      ? _c(
                          "td",
                          [
                            !_vm.signatureRequest.validBy
                              ? _c("v-text-field", {
                                  staticClass: "mb-n1 mt-1",
                                  attrs: {
                                    dense: "",
                                    type: "date"
                                  },
                                  on: {
                                    change: _vm.updateDate
                                  }
                                })
                              : _c("v-text-field", {
                                  staticClass: "mb-n1 mt-1",
                                  attrs: {
                                    dense: "",
                                    value: _vm.getValidBy(),
                                    type: "date"
                                  },
                                  on: {
                                    change: _vm.updateDate
                                  }
                                })
                          ],
                          1
                        )
                      : _c("td", [_vm._v(_vm._s(_vm.getValidByText()))])
                  ])
                : _vm._e(),
              _vm.signatureRequest
                ? _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("sign.SignRequestForm.externalId")))
                    ]),
                    _vm.isEditSignatureRequest
                      ? _c(
                          "td",
                          [
                            _c("v-text-field", {
                              staticClass: "mb-n1 mt-1",
                              attrs: {
                                dense: ""
                              },
                              model: {
                                value: _vm.signatureRequest.externalId,
                                callback: function callback($$v) {
                                  _vm.$set(
                                    _vm.signatureRequest,
                                    "externalId",
                                    $$v
                                  )
                                },
                                expression: "signatureRequest.externalId"
                              }
                            })
                          ],
                          1
                        )
                      : _c("td", [
                          _vm._v(_vm._s(_vm.signatureRequest.externalId))
                        ])
                  ])
                : _vm._e(),
              _vm.signatureRequest
                ? _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("sign.SignRequestForm.title")))
                    ]),
                    _vm.isEditSignatureRequest
                      ? _c(
                          "td",
                          [
                            _c("v-text-field", {
                              staticClass: "mb-n1 mt-1",
                              attrs: {
                                dense: "",
                                rules: _vm.requiredRule
                              },
                              model: {
                                value: _vm.signatureRequest.title,
                                callback: function callback($$v) {
                                  _vm.$set(_vm.signatureRequest, "title", $$v)
                                },
                                expression: "signatureRequest.title"
                              }
                            })
                          ],
                          1
                        )
                      : _c("td", [_vm._v(_vm._s(_vm.signatureRequest.title))])
                  ])
                : _vm._e()
            ])
          ]),
          _vm.signatureRequest
            ? _c(
                "v-card-text",
                [
                  !_vm.isEditSignatureRequest
                    ? _c("div", [
                        _vm._v(
                          " " + _vm._s(_vm.signatureRequest.description) + " "
                        )
                      ])
                    : _c("v-textarea", {
                        staticClass: "mb-n2 mt-n1",
                        attrs: {
                          placeholder: _vm.$t(
                            "sign.SignRequestForm.description"
                          ),
                          "auto-grow": "",
                          dense: "",
                          rows: "1"
                        },
                        model: {
                          value: _vm.signatureRequest.description,
                          callback: function callback($$v) {
                            _vm.$set(_vm.signatureRequest, "description", $$v)
                          },
                          expression: "signatureRequest.description"
                        }
                      })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "ma-5 mb-3 mt-1"
        },
        [
          !_vm.isEditSignatureRequest
            ? _c(
                "v-btn",
                {
                  attrs: {
                    disabled:
                      _vm.disabled || !(!_vm.signature && _vm.signatureRequest),
                    block: "",
                    elevation: 0,
                    loading: _vm.loading
                  },
                  on: {
                    click: _vm.startEdit
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("sign.DocumentTemplateDetailSave.edit")) +
                      " "
                  )
                ]
              )
            : !_vm.signature && _vm.signatureRequest
            ? _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    color: "success",
                    elevation: 0,
                    disabled: !_vm.isValid
                  },
                  on: {
                    click: _vm.save
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("sign.DocumentTemplateDetailSave.save")) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }