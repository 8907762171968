var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.isDialogActive,
        title: _vm.$t("components.UsersPermissionAddDialog.dialogTitle"),
        subtitle: _vm.$t("components.UsersPermissionAddDialog.dialogSubtitle"),
        fullscreen: true,
        leftDisabled: _vm.isLoading,
        rightLoading: _vm.isLoading,
        hideClose: _vm.isLoading
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.isDialogActive = $event
        },
        leftClick: function leftClick($event) {
          _vm.isDialogActive = false
        },
        rightClick: _vm.updatepermissions
      }
    },
    [
      _vm._v(
        " " +
          _vm._s(
            _vm.$t("components.UsersPermissionAddDialog.permissionsToAdd", {
              count: _vm.users.length
            })
          ) +
          " "
      ),
      _vm.isLoading
        ? _c("v-card-text", [
            _vm._v(
              " " + _vm._s(_vm.counter) + "/" + _vm._s(_vm.users.length) + " "
            )
          ])
        : _vm._e(),
      _c("v-autocomplete", {
        attrs: {
          rules: _vm.rules,
          items: _vm.partners,
          "item-value": "_id",
          "item-text": "companyUsername",
          label: _vm.$t("components.UsersPermissionAddDialog.partnerLabel")
        },
        on: {
          change: _vm.addsPermissions
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function fn(data) {
              return [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(_vm._s(data.item.companyName))
                    ]),
                    _c("v-list-item-subtitle", [
                      _vm._v(
                        _vm._s(data.item.companyUsername) +
                          " (" +
                          _vm._s(data.item.id) +
                          ")"
                      )
                    ])
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.partnerId,
          callback: function callback($$v) {
            _vm.partnerId = $$v
          },
          expression: "partnerId"
        }
      }),
      _vm.partnerId
        ? _c("user-permission-form", {
            attrs: {
              permissions: _vm.permissionForResources
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }