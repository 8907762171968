
























import { PartnerBankingGoToHelper } from "@/lib/utility/partner-banking.go-to-helper";
import { IPartnerBanking } from "@/models/partner-banking.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";

@Component({
  components: {},
  filters: {}
})
export default class RefsPartnerBanking extends mixins(Vue) implements IRefDetail<IPartnerBanking> {
  @Prop()
  item!: IPartnerBanking;

  @Prop()
  small!: boolean;

  @Prop()
  confirmable!: boolean;

  get date() {
    return this.item.timestamp.createdReadable;
  }

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  async goToDetail() {
    const partnerId = this.item.partnerId;
    const partnerBankingId = this.item.id;
    const newTab = true;

    new PartnerBankingGoToHelper(this.$router).goToPartnerBankingDetail({ partnerBankingId, partnerId, newTab });
  }
}
