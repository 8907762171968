var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          title: ""
            .concat(_vm.getFlagEmojiByLanguage(_vm.value.meta.language), " ")
            .concat(_vm.value.meta.title),
          alerts: _vm.alerts,
          subtitle: _vm.value.meta.description,
          breadCrumbs: _vm.breadCrumbs,
          chips: _vm.chips,
          actions: _vm.actions
        }
      }),
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isFormValid,
                callback: function callback($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid"
              }
            },
            [
              _c(
                "v-expansion-panels",
                {
                  attrs: {
                    multiple: "",
                    flat: ""
                  },
                  model: {
                    value: _vm.panels,
                    callback: function callback($$v) {
                      _vm.panels = $$v
                    },
                    expression: "panels"
                  }
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        [
                          _vm._t("expansionHeader", function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "title"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("template.content")) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          })
                        ],
                        2
                      ),
                      _c(
                        "v-expansion-panel-content",
                        {
                          staticClass: "pr-0 pl-0 mb-n4"
                        },
                        [
                          _vm.searchMatch([
                            _vm.valueCopy.content.subject,
                            _vm
                              .$t("objects.template.meta.contentFormat")
                              .toString(),
                            _vm
                              .$t("objects.template.meta.contentFormatHint")
                              .toString()
                          ])
                            ? _c("v-select", {
                                attrs: {
                                  value: _vm.isPlain,
                                  label: _vm.$t(
                                    "objects.template.meta.contentFormat"
                                  ),
                                  hint: _vm.$t(
                                    "objects.template.meta.contentFormatHint"
                                  ),
                                  "persistent-hint": "",
                                  outlined: "",
                                  items: [true, false]
                                },
                                on: {
                                  change: function change($event) {
                                    _vm.isPlain = $event
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function fn(_ref) {
                                        var item = _ref.item
                                        return [
                                          item
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "enums.MessageContentFormatEnum.plain"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "enums.MessageContentFormatEnum.html"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ]
                                      }
                                    },
                                    {
                                      key: "selection",
                                      fn: function fn(_ref2) {
                                        var item = _ref2.item
                                        return [
                                          item
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "enums.MessageContentFormatEnum.plain"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "enums.MessageContentFormatEnum.html"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2120295964
                                )
                              })
                            : _vm._e(),
                          _vm.searchMatch([
                            _vm.valueCopy.content.subject,
                            _vm
                              .$t("objects.template.content.subject")
                              .toString()
                          ])
                            ? _c("template-editor-subject", {
                                attrs: {
                                  partnerId: _vm.value.partnerId,
                                  subject: _vm.valueCopy.content.subject,
                                  addPlaceholderInSubject: true
                                },
                                on: {
                                  "update:subject": function updateSubject(
                                    $event
                                  ) {
                                    return _vm.$set(
                                      _vm.valueCopy.content,
                                      "subject",
                                      $event
                                    )
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.searchMatch([
                            _vm.valueCopy.content.subject,
                            _vm.$t("objects.template.content.body").toString()
                          ])
                            ? _c(
                                "div",
                                [
                                  _vm.isPlain
                                    ? _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          "auto-grow": "",
                                          label: _vm.$t(
                                            "objects.template.content.body"
                                          )
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function fn() {
                                                return [
                                                  _c(
                                                    "template-editor-add-placeholder",
                                                    {
                                                      staticClass: "mt-n2",
                                                      attrs: {
                                                        partnerId:
                                                          _vm.value.partnerId
                                                      },
                                                      on: {
                                                        addPlaceholder: function addPlaceholder(
                                                          $event
                                                        ) {
                                                          _vm.valueCopy.content.body += $event
                                                        }
                                                      }
                                                    }
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          760456340
                                        ),
                                        model: {
                                          value: _vm.valueCopy.content.body,
                                          callback: function callback($$v) {
                                            _vm.$set(
                                              _vm.valueCopy.content,
                                              "body",
                                              $$v
                                            )
                                          },
                                          expression: "valueCopy.content.body"
                                        }
                                      })
                                    : _c("template-editor", {
                                        key:
                                          "template-editor" +
                                          _vm.isPlain +
                                          _vm.refreshKey,
                                        staticClass: "mt-1",
                                        attrs: {
                                          isPlain: _vm.isPlain,
                                          readOnly: false,
                                          editConfiguration:
                                            _vm.editConfiguration,
                                          partnerId: _vm.value.partnerId,
                                          label: _vm.$t(
                                            "objects.template.content.body"
                                          )
                                        },
                                        model: {
                                          value: _vm.valueCopy.content.body,
                                          callback: function callback($$v) {
                                            _vm.$set(
                                              _vm.valueCopy.content,
                                              "body",
                                              $$v
                                            )
                                          },
                                          expression: "valueCopy.content.body"
                                        }
                                      })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    {
                      staticClass: "mt-n4"
                    },
                    [
                      _c(
                        "v-expansion-panel-header",
                        [
                          _vm._t("expansionHeader", function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "title"
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("template.meta")) + " "
                                  )
                                ]
                              )
                            ]
                          })
                        ],
                        2
                      ),
                      _c(
                        "v-expansion-panel-content",
                        {
                          staticClass: "pr-0 pl-0 mb-n4"
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("objects.template.meta.title"),
                              hint: _vm.$t("objects.template.meta.titleHint"),
                              rules: _vm.rules(_vm.RulesEnum.REQUIRED_RULE),
                              "persistent-hint": "",
                              outlined: ""
                            },
                            model: {
                              value: _vm.valueCopy.meta.title,
                              callback: function callback($$v) {
                                _vm.$set(_vm.valueCopy.meta, "title", $$v)
                              },
                              expression: "valueCopy.meta.title"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t(
                                "objects.template.meta.description"
                              ),
                              rules: _vm.rules(),
                              "persistent-hint": "",
                              outlined: ""
                            },
                            model: {
                              value: _vm.valueCopy.meta.description,
                              callback: function callback($$v) {
                                _vm.$set(_vm.valueCopy.meta, "description", $$v)
                              },
                              expression: "valueCopy.meta.description"
                            }
                          }),
                          !_vm.value.partnerId
                            ? _c("custom-field-boolean", {
                                attrs: {
                                  customField: {
                                    label: _vm.$t("objects.template.isPublic"),
                                    hint: _vm.$t(
                                      "objects.template.isPublicHint"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.valueCopy.isPublic,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.valueCopy, "isPublic", $$v)
                                  },
                                  expression: "valueCopy.isPublic"
                                }
                              })
                            : _vm._e(),
                          _c("v-combobox", {
                            attrs: {
                              label: _vm.$t("objects.template.meta.categories"),
                              multiple: "",
                              outlined: "",
                              clearable: "",
                              items: _vm.availableCategories
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function fn(_ref3) {
                                  var item = _ref3.item
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._b(
                                        {
                                          attrs: {
                                            outlined: ""
                                          }
                                        },
                                        "v-chip",
                                        item.attrs,
                                        false
                                      ),
                                      [_vm._v(" " + _vm._s(item) + " ")]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item",
                                fn: function fn(_ref4) {
                                  var item = _ref4.item
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(" " + _vm._s(item) + " ")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.valueCopy.meta.categories,
                              callback: function callback($$v) {
                                _vm.$set(_vm.valueCopy.meta, "categories", $$v)
                              },
                              expression: "valueCopy.meta.categories"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-slide-x-reverse-transition",
        [
          _vm.changesDetected
            ? _c(
                "v-card",
                {
                  staticClass: "pa-4 changes-detected-card",
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        loading: _vm.isLoading,
                        elevation: "0",
                        color: "info",
                        disabled: _vm.isDisabled
                      },
                      on: {
                        click: _vm.sync
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        outlined: "",
                        color: "info"
                      },
                      on: {
                        click: _vm.abort
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-body-2"
                    },
                    [_vm._v(_vm._s(_vm.$t("designGuide.MDetailForm.changes")))]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.value
        ? _c("template-detail-language-dialog", {
            ref: "languageOptions",
            attrs: {
              template: _vm.value,
              partnerId: _vm.value.partnerId
            }
          })
        : _vm._e(),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isDeleteDialogActive,
          title: _vm.$t("components.template.detail.delete.title"),
          leftText: _vm.$t("components.template.detail.delete.abort"),
          rightText: _vm.$t("components.template.detail.delete.delete"),
          rightColor: "error",
          loading: _vm.isLoadingDelete,
          rightLoading: _vm.isLoadingDelete
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          close: function close($event) {
            _vm.isDeleteDialogActive = false
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteTemplate
        }
      }),
      _vm.value
        ? _c("template-detail-language-dialog", {
            ref: "languageOptions",
            attrs: {
              template: _vm.value,
              partnerId: _vm.value.partnerId
            }
          })
        : _vm._e(),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isDeleteDialogActive,
          title: _vm.$t("components.template.detail.delete.title"),
          leftText: _vm.$t("components.template.detail.delete.abort"),
          rightText: _vm.$t("components.template.detail.delete.delete"),
          rightColor: "error",
          loading: _vm.isLoadingDelete,
          rightLoading: _vm.isLoadingDelete
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          close: function close($event) {
            _vm.isDeleteDialogActive = false
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteTemplate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }