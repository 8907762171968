




























import Card from "@/components/utility/Card.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";
import { ExportModule } from "@/store/modules/export.store";
import { IReport } from "@/models/report.entity";
import Debug from "@/components/utility/Debug.vue";

@Component({
  components: {
    Card,
    Debug
  }
})
export default class ReportDocumentSignRequestStepperChoice extends mixins(ReportDocumentSignRequestStepperMixin)
  implements IStepper {
  @Prop()
  report?: IReport;

  page = SignRequestStepperPageEnum.UPLOAD;

  get pages() {
    return [
      {
        icon: "mdi-upload",
        title: this.$t("sign.ReportDocumentSignRequestStepperChoice.upload"),
        subtitle: this.$t("sign.ReportDocumentSignRequestStepperChoice.uploadSubtitle"),
        buttonText: this.$t("sign.ReportDocumentSignRequestStepperChoice.upload"),
        nextPage: SignRequestStepperPageEnum.UPLOAD,
        isEnabled: true
      },
      {
        icon: "mdi-file-document-multiple",
        title: this.$t("sign.ReportDocumentSignRequestStepperChoice.choose"),
        subtitle: this.$t("sign.ReportDocumentSignRequestStepperChoice.chooseSubtitle"),
        buttonText: this.$t("sign.ReportDocumentSignRequestStepperChoice.chooseButton"),
        nextPage: SignRequestStepperPageEnum.FILE_SELECTION,
        isEnabled: true
      },
      {
        icon: "mdi-car-connected",
        title: this.$t("components.partner.PartnerReportInitializeCard.selection.da.title"),
        subtitle: this.$t("components.partner.PartnerReportInitializeCard.selection.da.subtitle"),
        buttonText: this.$t("components.partner.PartnerReportInitializeCard.selection.da.buttonText"),
        nextPage: SignRequestStepperPageEnum.DA_IMPORT,
        isEnabled: false
      },
      {
        icon: "mdi-car-connected",
        title: this.$t("components.partner.PartnerReportInitializeCard.selection.ksr.title"),
        subtitle: this.$t("components.partner.PartnerReportInitializeCard.selection.ksr.subtitle"),
        buttonText: this.$t("components.partner.PartnerReportInitializeCard.selection.ksr.buttonText"),
        nextPage: SignRequestStepperPageEnum.KSR_IMPORT,
        isEnabled: this.isKsrSetup && !this.report
      },
      {
        icon: "mdi-car-connected",
        title: this.$t("components.partner.PartnerReportInitializeCard.selection.daAndKsr.title"),
        subtitle: this.$t("components.partner.PartnerReportInitializeCard.selection.daAndKsr.subtitle"),
        buttonText: this.$t("components.partner.PartnerReportInitializeCard.selection.daAndKsr.buttonText"),
        nextPage: SignRequestStepperPageEnum.DA_AND_KSR,
        isEnabled: false
      },
      {
        icon: "mdi-car-connected",
        title: this.$t("components.partner.PartnerReportInitializeCard.selection.da.title"),
        subtitle: this.$t("components.partner.PartnerReportInitializeCard.selection.da.subtitle"),
        buttonText: this.$t("components.partner.PartnerReportInitializeCard.selection.da.buttonText"),
        nextPage: SignRequestStepperPageEnum.DA_WEBHOOK,
        isEnabled: this.isDaSetup && !this.report
      }
    ].filter(p => p.isEnabled);
  }

  get isDaSetup() {
    return ExportModule.isDaSetup ?? false;
  }

  next() {
    this.currentStep = this.page;
  }

  previous() {
    this.$emit("close");
  }

  get isKsrSetup() {
    return ExportModule.isKsrSetup ?? false;
  }
}
