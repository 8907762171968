

























import TemplateEditorPlaceholderDropdown from "@/components/template/TemplateEditorPlaceholderDropdown.vue";
import SideCard from "@/components/utility/SideCard.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { SignDocumentTokenDtoGen } from "@/services/sign/v1/data-contracts";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { FeatureModule } from "@/store/modules/feature.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { IDocumentPageMeta } from "./DocumentDetailEditorMixin.vue";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";
import DocumentTemplateDetailTokenListExpansionHeader from "./DocumentTemplateDetailTokenListExpansionHeader.vue";
import { getDocumentEditorPositionOptions } from "./ImagePositionSelector.vue";
import Debug from "@/components/utility/Debug.vue";

/**
 * token of signature
 */
export enum SignatureTokenEnum {
  /**
   * The signature token including QR-Code, Watermark, and Sign Details
   * @deprecated
   */
  SIGNATURE_TOKEN = "{{signature}}",

  /**
   * The signature token including QR-Code, Watermark, and Sign Details.
   */
  SIGNATURE_QR_TOKEN = "{{sign.qr}}",

  /**
   * Just the signature token just image
   */
  SIGNATURE_IMAGE_TOKEN = "{{sign.img}}"
}

export function isSignatureToken(token: string) {
  return [
    SignatureTokenEnum.SIGNATURE_QR_TOKEN.toString(),
    SignatureTokenEnum.SIGNATURE_IMAGE_TOKEN.toString(),
    SignatureTokenEnum.SIGNATURE_TOKEN.toString()
  ].includes(token);
}

export function isQrCodeToken(token: string) {
  return [SignatureTokenEnum.SIGNATURE_QR_TOKEN.toString(), SignatureTokenEnum.SIGNATURE_TOKEN.toString()].includes(
    token
  );
}

export type SignatureTokenConfigType = {
  token: SignatureTokenEnum;
  w: number;
  h: number;
};

export const SignatureQRTokenConfig: SignatureTokenConfigType = {
  token: SignatureTokenEnum.SIGNATURE_QR_TOKEN,
  w: 60,
  h: 35
};

export const SignatureImageTokenConfig: SignatureTokenConfigType = {
  token: SignatureTokenEnum.SIGNATURE_IMAGE_TOKEN,
  w: 45,
  h: 20
};

export const TextFieldConfig = {
  token: "value"
};

@Component({
  components: { SideCard, TemplateEditorPlaceholderDropdown, DocumentTemplateDetailTokenListExpansionHeader, Debug }
})
export default class DocumentTemplateDetailTokenList extends mixins(
  DarkModeHighlightMixin,
  PermissionMixin,
  DocumentDetailMixin
) {
  @Prop()
  currentPageNumber!: number;

  @Prop()
  isLoadingSave!: boolean;

  @Prop({ default: "calc(100vh - 274px - 210px)" })
  height!: string;

  /**
   * Edit values or allow dropdowns
   */
  @Prop({ default: false })
  isEditValue!: boolean;

  /**
   * Suppress editing of values
   */
  @Prop({ default: false })
  disabled!: boolean;

  @Prop()
  page!: IDocumentPageMeta;

  get selectedItem() {
    return DocumentTemplateModule.highlightedToken;
  }

  get selectedPage() {
    return DocumentTemplateModule.currentPage;
  }

  isSignatureWithQR(token: SignDocumentTokenDtoGen) {
    this.$log.debug(token.token, isQrCodeToken(token.token));
    return isQrCodeToken(token.token);
  }

  change(isQrCode: boolean, token: SignDocumentTokenDtoGen) {
    this.$log.debug(`Change Type ${isQrCode}`);

    let signatureToken: SignatureTokenConfigType = SignatureQRTokenConfig;
    if (!isQrCode) {
      signatureToken = SignatureImageTokenConfig;
    }

    const x = token.coordinates.x + token.coordinates.w / 2 - signatureToken.w / 2;
    const y = token.coordinates.y + token.coordinates.h / 2 - signatureToken.h / 2;
    token.coordinates.x = x;
    token.coordinates.y = y;
    token.coordinates.h = signatureToken.h;
    token.coordinates.w = signatureToken.w;
    token.token = signatureToken.token;
  }

  get isSignatureFormActive() {
    return FeatureModule.isSignatureFormActive;
  }

  get documentTokens() {
    return DocumentTemplateModule.documentTokens;
  }

  get pageTokens() {
    return this.documentTokens[this.currentPageNumber];
  }

  getDocumentEditorPositionOptions(e: SignDocumentTokenDtoGen) {
    return getDocumentEditorPositionOptions(e, this.page);
  }

  unselect() {
    DocumentTemplateModule.setHighlightedToken(-1);
  }

  getIsSignature(token: string) {
    return isSignatureToken(token);
  }

  getIsTextField(token: string) {
    return TextFieldConfig.token === token;
  }

  removeDocumentToken(documentToken: SignDocumentTokenDtoGen) {
    DocumentTemplateModule.removeDocumentToken({ page: this.currentPageNumber, documentToken });
    this.unselect();
  }

  setCurrentPage(page: number) {
    this.$emit("setCurrentPage", page);
  }

  setHighlightedToken(page: number, token: number) {
    this.$emit("setCurrentPage", page);
    this.highlightedToken = token;
  }

  updateToken(documentToken: SignDocumentTokenDtoGen, newToken: string) {
    documentToken.token = newToken;
  }
}
