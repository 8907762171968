var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      model: {
        value: _vm.isValid,
        callback: function callback($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    _vm._l(_vm.customFieldConfig, function(config, index) {
      return _c(
        "div",
        {
          key:
            "customFieldInput" +
            index +
            config.customField.id +
            _vm.mapUpdateKey
        },
        [
          _vm.getValueForCustomFieldId(config.customField.id)
            ? _c("custom-field", {
                key:
                  "getValueForCustomFieldId" +
                  config.customField.id +
                  _vm.getValueForCustomFieldId(config.customField.id) +
                  index,
                attrs: {
                  disabled: _vm.disabled,
                  loading: _vm.loading,
                  customField: config.customField,
                  required: config.configuration.isRequired,
                  valid: _vm.isValid
                },
                on: {
                  "update:valid": function updateValid($event) {
                    _vm.isValid = $event
                  },
                  input: function input(v) {
                    return _vm.customFieldValueUpdate(v, config.customField.id)
                  }
                },
                model: {
                  value: _vm.getValueForCustomFieldId(config.customField.id)
                    .value,
                  callback: function callback($$v) {
                    _vm.$set(
                      _vm.getValueForCustomFieldId(config.customField.id),
                      "value",
                      $$v
                    )
                  },
                  expression:
                    "getValueForCustomFieldId(config.customField.id).value"
                }
              })
            : _vm._e(),
          _c("debug", {
            attrs: {
              debug: _vm.debug(config)
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }