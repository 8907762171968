




























































import { IFormableClass, IMDetailFormConfig, MDetailFormConfig } from "@/lib/formable";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../ConfirmActionDialog.vue";
import { getNestedObjectValues, setNestedObjectValues } from "@/lib/objectPath-helper";
import { mixins } from "vue-class-component";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";

@Component({
  name: "MDetailFormArrayDialog",
  components: {
    MDetailForm: () => import("./MDetailForm.vue"),
    UpdateForm: () => import("../UpdateForm.vue"),
    ConfirmActionDialog
  }
})
export default class MDetailFormArrayForm<T extends object> extends mixins(DarkModeHighlightMixin) {
  @Prop()
  value!: T[]; // this is where the result of the form should be written into

  @Prop()
  form!: IFormableClass & { new (): T };

  @Prop()
  search!: string;

  @Prop()
  partnerId!: string;

  configs: IMDetailFormConfig[][] = [];

  get countCategories() {
    return Array.from(new Set(this.form.formables.map(f => f.category))).length;
  }

  beforeMount() {
    this.configs.splice(0);
    this.configs.push(
      ...(this.value?.map(value => [
        ...this.form.formables.map(f => ({ ...f, model: getNestedObjectValues(value, f.key) }))
      ]) ?? [])
    );
  }

  removeIndex(index: number) {
    this.value.splice(index, 1);
    this.configs.splice(index, 1);
    this.$emit("input", this.value);
  }

  addNewValue() {
    const newElement = new this.form() as T;
    const config = new MDetailFormConfig(this.form.formables);
    const configKeys = config.getConfigKeys();
    for (const key of configKeys) {
      setNestedObjectValues(newElement, key, "");
    }
    if (!this.value || !Array.isArray(this.value)) {
      this.$emit("input", [newElement]);
    } else {
      this.value.push(newElement);
    }
    this.configs.push([...this.form.formables.map(f => ({ ...f, model: "" }))]);
  }

  syncChanges(index: number) {
    return () => {
      const config = new MDetailFormConfig([...this.configs[index]]);
      const configKeys = config.getConfigKeys();
      for (const key of configKeys) {
        setNestedObjectValues(
          this.value[index],
          key,
          config.getConfigValueByKey(key) ?? getNestedObjectValues(this.value[index], key) ?? ""
        );
      }

      this.$emit("input", this.value);
    };
  }

  async abortChanges(index: number) {
    const config = new MDetailFormConfig([...this.configs[index]]);
    const configKeys = config.getConfigKeys();

    return () => {
      for (const key of configKeys) {
        config.configValueByKey = { key: key, value: getNestedObjectValues(this.value[index], key) };
      }
    };
  }
}
