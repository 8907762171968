var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.loading
    ? _c("v-skeleton-loader", {
        staticClass: "mx-auto",
        attrs: {
          type: "paragraph"
        }
      })
    : _vm.loadedItem
    ? _c(
        "div",
        {
          staticStyle: {
            width: "100%"
          }
        },
        [
          _vm.refType === _vm.ResourceEnum.REPORT
            ? _c("refs-report", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.VEHICLE
            ? _c("refs-vehicle", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.EVENT
            ? _c("refs-event", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.TICKET
            ? _c("refs-ticket", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable,
                  showAssignees: false,
                  customConfig: _vm.customConfig
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.COST
            ? _c("refs-cost", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable,
                  customConfig: _vm.customConfig
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.COST_GROUP
            ? _c("refs-cost-group", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable,
                  customConfig: _vm.customConfig
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.DOCUMENT
            ? _c("refs-document", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable,
                  showAssignees: false
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.SNAPSHOT
            ? _c("refs-snapshot", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable,
                  showAssignees: false
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.HANDOVER
            ? _c("refs-handover", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable,
                  showAssignees: false
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.SIGN
            ? _c("refs-sign-request", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable,
                  showAssignees: false
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.MESSAGE
            ? _c("refs-message", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable,
                  showAssignees: false
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.COMPANY
            ? _c("refs-company", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.COMPANY_GROUP
            ? _c("refs-company-group", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.ATTACHMENT_REQUEST
            ? _c("refs-attachment-request", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable,
                  showAssignees: false
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.PERSON
            ? _c("refs-person", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.PERSON_GROUP
            ? _c("refs-person-group", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.PERSON_EMAIL
            ? _c("refs-person-email", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.PERSON_PHONE
            ? _c("refs-person-phone", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.COMPANY_ADDRESS
            ? _c("refs-company-address", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.GROUP
            ? _c("refs-group", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.CONTRACT
            ? _c("refs-contract", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.FLEET
            ? _c("refs-fleet", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm.refType === _vm.ResourceEnum.PARTNER_CLIENT_BANKING
            ? _c("refs-partner-banking", {
                ref: "refDetail",
                attrs: {
                  item: _vm.loadedItem,
                  small: _vm.small,
                  confirmable: _vm.confirmable
                },
                on: {
                  openDetail: _vm.emitOpenDetail
                }
              })
            : _vm._e()
        ],
        1
      )
    : _c("latest-entries-card-empty")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }