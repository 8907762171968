import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IAdminTemplate, AdminTemplate } from "@/models/admin-template.entity";

export const AdminTemplateDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IAdminTemplate> {
  getIdentifier(entity: IAdminTemplate): string {
    return entity.id;
  }

  mapEntity(entity: IAdminTemplate): IAdminTemplate {
    return new AdminTemplate(entity);
  }
})();
