















































































































































































































import { TemplateEditConfiguration } from "@/components/template/TemplateEditor.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { MessageContentFormatEnum } from "@/lib/enum/templateEnums/messageContentFormat.enum";
import { TemplateEditorEnum } from "@/lib/enum/templateEnums/tempalteEditor.enum";
import { getAutocompleteFilter } from "@/lib/utility/filter";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import { TemplateGoToHelper } from "@/lib/utility/template.go-to-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { IPartnerTemplate } from "@/models/partner-template.entity";
import { buildTemplate, ITemplate } from "@/models/template.entity";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { ConfigModule } from "@/store/modules/config";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import TemplateDetail from "./TemplateDetail.vue";
import TemplateEditorAddPlaceholder from "./TemplateEditorAddPlaceholder.vue";
import CustomFieldBoolean from "../report/CustomFieldBoolean.vue";

@Component({
  components: {
    MHeader,
    TemplateEditorAddPlaceholder,
    CustomFieldBoolean,
    LatestEntriesCardEmpty
  },
  filters: { getFlagEmojiByLanguage }
})
export default class TemplateDetailForm extends mixins(TemplateDetail, RulesMixin, PermissionMixin) {
  readonly getAutocompleteFilter = getAutocompleteFilter;

  panels = [0, 1];

  isLoading = false;

  search = "";

  valueCopy = this.copyTemplate();

  isFormValid = false;

  refreshKey = 0;

  get isPlain() {
    return (
      this.valueCopy.meta.contentFormat === MessageContentFormatEnum.PLAIN &&
      this.valueCopy.meta.editor === TemplateEditorEnum.DEFAULT
    );
  }

  set isPlain(isPlain: boolean) {
    if (isPlain) {
      this.valueCopy.meta.contentFormat = MessageContentFormatEnum.PLAIN;
      this.valueCopy.meta.editor = TemplateEditorEnum.DEFAULT;
    } else {
      this.valueCopy.meta.contentFormat = MessageContentFormatEnum.HTML;
      this.valueCopy.meta.editor = TemplateEditorEnum.TIPTAP;
    }
  }

  get store() {
    if (!(this.value as IPartnerTemplate).partnerId) return PartnerTemplateModule;
    return AdminTemplateModule;
  }

  get availableLanguages() {
    return ConfigModule.availableLanguages;
  }

  get editConfiguration() {
    return new TemplateEditConfiguration((this.value as IPartnerTemplate).partnerId, true);
  }

  get changesDetected() {
    return JSON.stringify(this.valueCopy) !== JSON.stringify(this.value);
  }

  get isDisabled() {
    if (!this.isFormValid || !this.value.content.body || !this.value.content.subject) {
      // dont allow to save an empty form
      return true;
    }

    return false;
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadCrumbs = TemplateGoToHelper.breadCrumbs;

    const selected = [];

    const partnerId = (this.value as IPartnerTemplate).partnerId;
    const templateId = this.value.id;

    selected.push(breadCrumbs.TemplateTable({ partnerId }));
    selected.push(breadCrumbs.TemplateDetail({ partnerId, templateId }));
    selected.push(breadCrumbs.TemplateDetailForm({ partnerId, templateId }));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push({
      text: $t("common.nouns.detail"),
      key: "detail",
      icon: "mdi-open-in-new",
      color: "",
      exec: this.value.goTo(this.$router).detail
    });

    if (
      !(this.value as IPartnerTemplate).isAdminTemplate &&
      this.can(this.ActionEnum.DELETE, this.ResourceEnum.TEMPLATE)
    ) {
      actions.push({
        text: $t("delete"),
        key: "title",
        icon: "mdi-trash-can",
        color: "red",
        exec: () => {
          this.isDeleteDialogActive = true;
        }
      });
    }

    return actions;
  }

  get availableCategories() {
    const entities: ITemplate[] = this.store.entities;

    const cats = entities.map(entity => entity.meta.categories).flat();

    return Array.from(new Set(cats));
  }

  beforeMount() {
    this.setAndCopyTemplate();
  }

  copyTemplate() {
    return buildTemplate(this.value);
  }

  setAndCopyTemplate() {
    this.valueCopy = this.copyTemplate();
    this.refreshKey++;
  }

  searchMatch(values: string[]) {
    if (!this.search) {
      return true;
    }

    return values.some(value => value.toLowerCase().includes(this.search.toLowerCase()));
  }

  async sync() {
    this.isLoading = true;
    try {
      await this.value.updatePartial(this.valueCopy.updateDto);
      this.$toast.success("👍");
      this.setAndCopyTemplate();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  abort() {
    this.setAndCopyTemplate();
  }
}
