var render = function() {
  var _vm$report$handover

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          class: _vm.outline ? "mt-3 pt-5" : "",
          attrs: {
            outlined: _vm.outline,
            flat: ""
          }
        },
        [
          _c("m-header", {
            attrs: {
              title: ""
                .concat(_vm.$t("common.nouns.handover"), " #")
                .concat(_vm.value.number),
              subtitle: _vm.createdByOn,
              breadCrumbs: _vm.breadCrumbs,
              chips: _vm.chips,
              actions: _vm.actions
            },
            on: {
              actionClicked: function actionClicked($event) {
                return $event.exec()
              },
              alertClicked: function alertClicked($event) {
                return $event.exec()
              }
            }
          }),
          _vm.value.handover.date
            ? _c("v-card-title", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("common.nouns.handover")) +
                    " " +
                    _vm._s(
                      _vm._f("simpleDoubleDigitDate")(_vm.value.handover.date)
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _c(
            "v-card-subtitle",
            [
              _vm.value.handover.description
                ? _c("template-editor", {
                    staticClass: "mx-n3",
                    attrs: {
                      height: "100%",
                      value: _vm.value.handover.description,
                      readOnly: true,
                      outlined: false
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.value.handover.parties.length
        ? _c(
            "v-card",
            {
              class: _vm.outline ? "mt-3" : "",
              attrs: {
                outlined: _vm.outline,
                flat: ""
              }
            },
            [
              _c("v-card-title", [
                _vm._v(" " + _vm._s(_vm.$t("common.nouns.parties")) + " ")
              ]),
              _c(
                "div",
                {
                  staticClass: "mt-n2",
                  staticStyle: {
                    display: "flex"
                  }
                },
                _vm._l(_vm.value.handover.parties, function(party, index) {
                  return _c(
                    "v-card",
                    {
                      key: "party" + index,
                      staticStyle: {
                        width: "30%"
                      },
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-subtitle", [
                        party.companyType
                          ? _c("b", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "enums.HandoverCompanyTypeEnum.".concat(
                                        party.companyType
                                      )
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        party.companyName
                          ? _c("div", [
                              _vm._v(" " + _vm._s(party.companyName) + " ")
                            ])
                          : _vm._e(),
                        party.email
                          ? _c("div", [_vm._v(" " + _vm._s(party.email) + " ")])
                          : _vm._e(),
                        party.phone
                          ? _c("div", [_vm._v(" " + _vm._s(party.phone) + " ")])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.value.handover.vehicle && _vm.value.handover.vehicle.blueprints
        ? _c(
            "v-card",
            {
              class: _vm.outline ? "mt-3" : "",
              attrs: {
                outlined: _vm.outline,
                flat: ""
              }
            },
            [
              _c(
                "v-card-title",
                [
                  _vm._v(" " + _vm._s(_vm.$t("common.nouns.damages")) + " "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        "x-small": "",
                        elevation: 0
                      },
                      on: {
                        click: _vm.downlodXlsx
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("common.nouns.download")) +
                          " (XLSX) "
                      )
                    ]
                  ),
                  _c("file-download", {
                    ref: "zipper",
                    attrs: {
                      icon: false,
                      downloadFiles: _vm.downloadableFiles,
                      filePrefix: _vm.prefix,
                      text: String(
                        _vm.$t("common.nouns.download") +
                          " (".concat(_vm.$t("common.nouns.images"), ")")
                      )
                    }
                  })
                ],
                1
              ),
              _vm._l(_vm.value.handover.vehicle.blueprints, function(
                blueprint,
                key
              ) {
                return _c(
                  "div",
                  {
                    key: "blueprint" + key
                  },
                  [
                    _vm.getReportsForBlueprint(blueprint.id).length
                      ? _c(
                          "div",
                          [
                            _c("v-card-subtitle", [
                              _vm._v(" " + _vm._s(blueprint.name) + " ")
                            ]),
                            _c("m-image-marker", {
                              staticClass: "mx-2",
                              attrs: {
                                deactivated: true,
                                markerSize: "large",
                                src: blueprint.image.url,
                                markers: _vm
                                  .getReportsForBlueprint(blueprint.id)
                                  .filter(function(r) {
                                    var _r$position,
                                      _r$position$coordinat,
                                      _r$position2,
                                      _r$position2$coordina

                                    return (
                                      ((_r$position = r.position) === null ||
                                      _r$position === void 0
                                        ? void 0
                                        : (_r$position$coordinat =
                                            _r$position.coordinates) === null ||
                                          _r$position$coordinat === void 0
                                        ? void 0
                                        : _r$position$coordinat.x) &&
                                      ((_r$position2 = r.position) === null ||
                                      _r$position2 === void 0
                                        ? void 0
                                        : (_r$position2$coordina =
                                            _r$position2.coordinates) ===
                                            null ||
                                          _r$position2$coordina === void 0
                                        ? void 0
                                        : _r$position2$coordina.y)
                                    )
                                  })
                                  .map(function(r) {
                                    var _r$handover

                                    return {
                                      left: r.position.coordinates.x,
                                      top: r.position.coordinates.y,
                                      text: "#".concat(
                                        (_r$handover = r.handover) === null ||
                                          _r$handover === void 0
                                          ? void 0
                                          : _r$handover.number
                                      )
                                    }
                                  })
                              },
                              on: {
                                markerClicked: _vm.onMarkerClicked,
                                mouseoverMarker: _vm.onMouseoverMarker,
                                mouseleaveMarker: _vm.onMouseleaveMarker
                              }
                            }),
                            _c("table-wrapper", {
                              attrs: {
                                allItems: _vm
                                  .getReportsForBlueprint(blueprint.id)
                                  .map(function(d) {
                                    return _vm.mapReportToDamageInfo(d)
                                  }),
                                headers: _vm.headers,
                                hideTableHeader: true,
                                hideTableFooter: false,
                                outlined: !_vm.outline,
                                sortBy: "number",
                                sortDesc: false,
                                hideSearch: true,
                                itemsPerPage: _vm.getReportsForBlueprint(
                                  blueprint.id
                                ).length
                              },
                              on: {
                                "click:row": function clickRow($event) {
                                  return _vm.openReportSideCard($event)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.number",
                                    fn: function fn(_ref) {
                                      var item = _ref.item
                                      return [
                                        _vm._v(" #" + _vm._s(item.number) + " ")
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.title",
                                    fn: function fn(_ref2) {
                                      var item = _ref2.item
                                      return [
                                        _vm._v(" " + _vm._s(item.title) + " "),
                                        _c("br"),
                                        _vm._v(" " + _vm._s(item.message) + " ")
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              }),
              _vm.reportsWithoutBlueprint.length
                ? _c(
                    "div",
                    [
                      _c("v-card-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.CreateHandoverFindingsDialog.steps.success.findingWithoutBlueprint"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("table-wrapper", {
                        attrs: {
                          allItems: _vm.reportsWithoutBlueprint.map(function(
                            d
                          ) {
                            return _vm.mapReportToDamageInfo(d)
                          }),
                          headers: _vm.headers,
                          hideTableHeader: true,
                          hideTableFooter: false,
                          outlined: !_vm.outline,
                          sortBy: "number",
                          sortDesc: false,
                          hideSearch: true,
                          itemsPerPage: _vm.reportsWithoutBlueprint.length
                        },
                        on: {
                          "click:row": function clickRow($event) {
                            return _vm.openReportSideCard($event)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.number",
                              fn: function fn(_ref3) {
                                var item = _ref3.item
                                return [
                                  _vm._v(" #" + _vm._s(item.number) + " ")
                                ]
                              }
                            },
                            {
                              key: "item.title",
                              fn: function fn(_ref4) {
                                var item = _ref4.item
                                return [
                                  _vm._v(" " + _vm._s(item.title) + " "),
                                  _c("br"),
                                  _vm._v(" " + _vm._s(item.message) + " ")
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1519638866
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.value.handover &&
      _vm.value.handover.agreements &&
      _vm.value.handover.agreements.length
        ? _c(
            "v-card",
            {
              class: _vm.outline ? "mt-3" : "",
              attrs: {
                outlined: _vm.outline,
                flat: ""
              }
            },
            _vm._l(_vm.value.handover.agreements, function(agreement) {
              return _c(
                "v-list-item",
                {
                  key: agreement.timestamp.created
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("simpleDoubleDigitDate")(
                                agreement.timestamp.created
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("v-list-item-subtitle", [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "white-space": "pre-line"
                            }
                          },
                          [_vm._v(" " + _vm._s(agreement.description) + " ")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.value.signers && _vm.value.signers.length
        ? _c(
            "v-card",
            {
              class: _vm.outline ? "mt-3" : "",
              attrs: {
                outlined: _vm.outline,
                flat: ""
              }
            },
            [
              _c("v-card-title", [
                _vm._v(" " + _vm._s(_vm.$t("objects.snapshot.signers")) + " ")
              ]),
              _c(
                "div",
                {
                  staticClass: "mt-n2",
                  staticStyle: {
                    display: "flex"
                  }
                },
                _vm._l(_vm.value.signers, function(signer, index) {
                  return _c(
                    "v-card",
                    {
                      key: "signer" + index,
                      staticStyle: {
                        width: "33%"
                      },
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-subtitle", [
                        signer.image && signer.image.url
                          ? _c(
                              "div",
                              [
                                signer.image.url
                                  ? _c("v-img", {
                                      staticClass: "image-content",
                                      attrs: {
                                        src: signer.image.url,
                                        "max-width": "100%"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "placeholder",
                                            fn: function fn() {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center justify-center fill-height",
                                                    staticStyle: {
                                                      "min-height": "30px"
                                                    }
                                                  },
                                                  [
                                                    _c("v-progress-circular", {
                                                      attrs: {
                                                        color: "grey-lighten-4",
                                                        indeterminate: ""
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  : _c("latest-entries-card-empty")
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", [
                          _c("b", [
                            _vm._v(
                              _vm._s(signer.firstName) +
                                " " +
                                _vm._s(signer.lastName)
                            )
                          ])
                        ]),
                        _c("div", [
                          _vm._v(
                            _vm._s(signer.location) +
                              ", " +
                              _vm._s(
                                _vm._f("simpleDoubleDigitDate")(
                                  signer.timestamp.created
                                )
                              )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("create-handover-snapshot-dialog", {
        ref: "createHandoverSnapshotDialog",
        attrs: {
          value: _vm.value
        },
        on: {
          signed: _vm.loadDocumentImages
        }
      }),
      _vm.report
        ? _c(
            "side-card",
            {
              attrs: {
                absolute: true,
                width: "650px"
              },
              on: {
                close: _vm.closeReportSideCard
              }
            },
            [
              _c("m-header", {
                attrs: {
                  title: "#"
                    .concat(
                      (_vm$report$handover = _vm.report.handover) === null ||
                        _vm$report$handover === void 0
                        ? void 0
                        : _vm$report$handover.number,
                      " "
                    )
                    .concat(_vm.report.title),
                  subtitle: _vm.report.message
                }
              }),
              _c("m-detail-table", {
                attrs: {
                  title: _vm.$t("about"),
                  i18nPath: "objects.report",
                  item: _vm.report,
                  config: _vm.detailTableConfig
                }
              }),
              _c("vue-picture-swipe", {
                staticClass: "mx-2 mt-4 mb-2",
                attrs: {
                  items: _vm.getReportImages(_vm.report),
                  options: {
                    shareEl: false,
                    rotationOn: true
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("share-dialog", {
        ref: "shareDialog",
        attrs: {
          refs: {
            refId: _vm.value.id,
            refType: _vm.ResourceEnum.SNAPSHOT
          },
          partnerId: _vm.value.partnerId
        }
      }),
      _c("create-handover-snapshot-dialog", {
        ref: "createHandoverSnapshotDialog",
        attrs: {
          value: _vm.value
        },
        on: {
          signed: _vm.loadDocumentImages
        }
      }),
      _c("debug", [_vm._v("value " + _vm._s(_vm.value))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }