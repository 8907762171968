































































































import Debug from "@/components/utility/Debug.vue";
import { TemplateTokenConfig } from "@/lib/configuration/template-token.configuration";
import { TokenCategoriesEnum } from "@/lib/enum/templateEnums/tokenCategories.enum";
import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { dottedSubString } from "@/lib/utility/string-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivPartnerTemplateViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TableWrapper, { ITableWrapperHeader, IControlElements } from "@/components/utility/TableWrapper.vue";
import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import { $t } from "@/lib/utility/t";
import { MessageContentFormatEnum } from "@/lib/enum/templateEnums/messageContentFormat.enum";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import Tooltip from "@/components/utility/tooltip.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { ITemplate } from "@/models/template.entity";
import TemplateCreateDialog from "./TemplateCreateDialog.vue";
import TemplateSideCard from "./TemplateSideCard.vue";

@Component({
  components: {
    PaginatedTable,
    TableWrapper,
    ConfirmActionDialog,
    FilterCardPagination,
    TemplateSideCard,
    TemplateCreateDialog,
    Tooltip,
    Debug
  },
  filters: { getFlagEmojiByLanguage }
})
export default class TemplateTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly BackendResourceEnum = BackendResourceEnum;

  @Prop()
  partnerId?: string;

  @Prop()
  context!: ITemplateContext;

  @Prop({ default: () => [] })
  filters!: IPageFilterElement[];

  @Prop()
  controlElements?: IControlElements[];

  @Prop({ default: true })
  isOpenSideCard?: boolean;

  @Prop()
  itemsPerPage?: number;

  selectedItem: ITemplate | null = null;

  selectedItemForDelete: ITemplate | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get isMultipleLanguages() {
    if (!this.store.filteredAndSorted?.length) {
      return false;
    }

    return this.store.filteredAndSorted.some(
      (item: any) => item.meta.language !== this.store.filteredAndSorted[0].meta.language
    );
  }

  get store() {
    if (this.partnerId) {
      return PartnerTemplateModule;
    } else {
      return AdminTemplateModule;
    }
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    if (!this.partnerId) {
      headers.push({ text: $t("objects.template.isPublic"), value: "isPublic", width: "100px", align: "center" });
    }

    headers.push({ text: $t("language"), value: "meta.language", width: "100px", align: "center" });
    headers.push({ text: $t("components.template.dialog.selection.tempalteTitle"), value: "meta.title" });
    headers.push({
      text: $t("components.template.dialog.selection.categories"),
      value: "meta.categories",
      sortable: false
    });

    if (this.context) {
      headers.push({
        text: $t("components.template.dialog.selection.context"),
        value: "context",
        sortable: false
      });
    }

    if (this.partnerId) {
      headers.push({
        text: $t("components.template.dialog.selection.isAdminTemplate"),
        value: "isAdminTemplate",
        align: "center",
        width: "30px",
        sortable: false
      });
    }

    headers.push({
      text: $t("objects.timestamp.created"),
      value: "timestamp.created",
      type: "date",
      width: "40px",
      align: "end"
    });

    return headers;
  }

  get _controlElements(): IControlElements[] {
    if (this.controlElements) {
      return this.controlElements;
    }

    const controlElements: IControlElements[] = [];

    return controlElements;
  }

  get predefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { name: string; filter: IPageFilterElement[] }[] = [];

    for (const key of Object.values(MessageContentFormatEnum)) {
      predefinedFilter.push({
        name: $t(`enums.MessageContentFormatEnum.${key}`),
        filter: [
          new PageFilterElement({
            key: "meta.contentFormat",
            operation: PageFilterOperationEnum.EQUAL,
            value: key
          })
        ]
      });
    }

    if (this.partnerId) {
      predefinedFilter.push({
        name: $t("objects.template.isAdminTemplate"),
        filter: [
          new PageFilterElement({
            key: "isAdminTemplate",
            operation: PageFilterOperationEnum.EQUAL,
            value: true as any
          })
        ]
      });
      predefinedFilter.push({
        name: $t("objects.template.isPartnerTemplate"),
        filter: [
          new PageFilterElement({
            key: "isAdminTemplate",
            operation: PageFilterOperationEnum.EQUAL,
            value: false as any
          })
        ]
      });
    }

    return predefinedFilter;
  }

  beforeMount() {
    const filters: IPageFilterElement[] = [];
    if (!this.filters?.length) {
      if (this.partnerId) {
        filters.push(
          new PageFilterElement({
            key: "isAdminTemplate",
            operation: PageFilterOperationEnum.EQUAL,
            value: false as any
          })
        );
      }
    } else {
      filters.push(...this.filters);
    }
    this.store.setFilters(filters);
  }

  getDescription(item: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    const text = item.meta.description || item.content.subject || item.content.body || "";

    return dottedSubString(text, 200);
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  setSelectedItem(item: ITemplate | null) {
    this.selectedItem = null;
    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("click:row", item);
    });
  }

  getTemplateContext(template: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    const available: string[] = [];
    const missing: string[] = [];
    const tokens = template.content?.tokens;

    if (!tokens) {
      return { available, missing };
    }

    const tokenCategories = tokens
      .map(token => token.split(".")[0])
      .filter((cat: string) => TemplateTokenConfig.objectCategories.includes(cat as TokenCategoriesEnum));

    tokenCategories.forEach(category => {
      if (!this.context?.[category]) {
        missing.push(category);
      } else {
        available.push(category);
      }
    });

    return { available: [...new Set(available)], missing: [...new Set(missing)] };
  }
}
