var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _c(
        "v-btn",
        {
          class: _vm.$vuetify.breakpoint.mdAndDown
            ? "mr-n1 ml-n1"
            : "pr-0 pl-0",
          attrs: {
            color: _vm.color,
            text: "",
            small: "",
            icon: _vm.$vuetify.breakpoint.mdAndDown
          },
          on: {
            click: function click($event) {
              _vm.isDialogActive = true
            }
          }
        },
        [
          !_vm.$vuetify.breakpoint.mdAndDown
            ? _c(
                "small",
                {
                  staticClass: "mr-1"
                },
                [_vm._v("template")]
              )
            : _vm._e(),
          !_vm.$vuetify.breakpoint.mdAndDown
            ? _c("v-icon", [_vm._v(" mdi-email-multiple-outline ")])
            : _c(
                "tooltip",
                {
                  attrs: {
                    text: "Template"
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        small: ""
                      }
                    },
                    [_vm._v(" mdi-email-multiple-outline ")]
                  )
                ],
                1
              )
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            fullscreen: true,
            isDialogActive: _vm.isDialogActive,
            title: _vm.$t("components.template.dialog.selection.title"),
            hideRight: true,
            supressKeyboardActions: true,
            selectedItems: _vm.selectedItems
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:selectedItems": function updateSelectedItems($event) {
              _vm.selectedItems = $event
            },
            "update:selected-items": function updateSelectedItems($event) {
              _vm.selectedItems = $event
            },
            leftClick: function leftClick($event) {
              _vm.isDialogActive = false
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                height: "calc(100vh - 190px)",
                overflow: "auto"
              }
            },
            [
              _c("template-table", {
                attrs: {
                  partnerId: _vm.partnerId,
                  context: _vm.context,
                  controlElements: [],
                  filters: _vm.filters,
                  isOpenSideCard: false
                },
                on: {
                  "click:row": function clickRow($event) {
                    return _vm.$emit("select", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "bodyActions",
                    fn: function fn() {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              elevation: "0",
                              small: "",
                              color: "primary"
                            },
                            on: {
                              click: _vm.goToTemplates
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("nav.PARTNER_TEMPLATE_TABLE.title")
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }