






import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IGeocode } from "@/models/User/IAddress";
import { ConfigModule } from "@/store/modules/config";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { VSkeletonLoader } from "vuetify/lib";

const PartnerMap = () => ({
  component: import(/* webpackPrefetch: true  */ /* webpackChunkName: "map" */ "@/components/form/PartnerMap.vue"),
  loading: VSkeletonLoader
});

@Component({
  components: {
    PartnerMap
  },
  filters: {}
})
export default class GeoCoordinateMap extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  value!: IGeocode;

  @Prop()
  color!: string;

  @Prop({ default: 265 })
  height!: string;

  key = Math.random();

  get _color() {
    if (this.color) {
      return this.color;
    }

    return ConfigModule.color.primary;
  }

  mounted() {
    setTimeout(() => {
      this.key = Math.random();
    }, 1000);
  }
}
