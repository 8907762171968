
































import { Component, Prop } from "vue-property-decorator";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import Tooltip from "./tooltip.vue";
import { IShortUser, ShortUser } from "@/models/short-user.entity";

@Component({
  components: {
    Tooltip
  },
  filters: {}
})
export default class AssigneePreview extends PermissionMixin {
  @Prop({ default: false })
  value!: string[];

  @Prop()
  amount?: number;

  @Prop()
  partnerId!: string;

  get assignees(): IShortUser[] {
    return this.value.map(assigneeId => new ShortUser(PartnerUserModule.maps.id.get(assigneeId)[0]));
  }

  get firstAssignees() {
    if (this.amount) {
      return this.assignees.slice(0, this.amount);
    }

    return this.assignees;
  }

  get remainingAssignees() {
    if (this.amount) {
      return this.assignees.slice(this.amount);
    }

    return [];
  }

  getAssigneeName(assignee: IShortUser) {
    return `${assignee?.firstName} ${assignee?.lastName}`;
  }

  getAssigneeInititals(assignee: IShortUser) {
    return `${assignee?.firstName[0]}${assignee?.lastName[0]}`;
  }
}
