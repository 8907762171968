














































































import GoToReferenceButton from "@/components/partner/GoToReferenceButton.vue";
import Card from "@/components/utility/Card.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { ISignDocument } from "@/models/sign-document.entity";
import { SignDocumentTokenCoordinatesGen } from "@/services/sign/v1/data-contracts";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import debounce from "debounce";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { IDocumentPageMeta } from "./DocumentDetailEditorMixin.vue";
import SignDocumentForm from "./DocumentForm.vue";
import DocumentTemplateDetailDeleteDialog from "./DocumentTemplateDetailDeleteDialog.vue";
import DocumentTemplateDetailDownload from "./DocumentTemplateDetailDownload.vue";
import DocumentTemplateDetailInformation from "./DocumentTemplateDetailInformation.vue";
import DocumentTemplateDetailPageList from "./DocumentTemplateDetailPageList.vue";
import DocumentTemplateDetailPageListMobile from "./DocumentTemplateDetailPageListMobile.vue";
import DocumentTemplateDetailSave from "./DocumentTemplateDetailSave.vue";
import DocumentTemplateDetailSignature from "./DocumentTemplateDetailSignature.vue";
import DocumentTemplateDetailTokenList, {
  SignatureQRTokenConfig,
  SignatureTokenConfigType,
  TextFieldConfig
} from "./DocumentTemplateDetailTokenList.vue";
import ImagePositionSelector from "./ImagePositionSelector.vue";

export enum DocumentDetailSideBarElements {
  CLOSED = "closed",
  EDIT = "edit",
  DETAIL = "detail",
  DOWNLOAD = "download",
  SIGNATURE = "signature",
  ACTIVITY = "activity",
  COST = "cost",
  LINK = "link"
}
@Component({
  components: {
    TheLayoutPortal,
    Card,
    ImagePositionSelector,
    SignDocumentForm,
    GoToReferenceButton,
    DocumentTemplateDetailPageList,
    DocumentTemplateDetailPageListMobile,
    DocumentTemplateDetailInformation,
    DocumentTemplateDetailDeleteDialog,
    DocumentTemplateDetailDownload,
    DocumentTemplateDetailTokenList,
    DocumentTemplateDetailSave,
    DocumentTemplateDetailSignature
  },
  filters: { simpleDoubleDigitDate }
})
export default class DocumentDetailCardEditor extends Vue {
  @Ref("selector")
  selector!: ImagePositionSelector;

  @Prop({ default: false })
  isSignature!: boolean;

  @Prop({ default: false })
  isSigning!: boolean;

  @Prop({ default: false })
  isLoading!: boolean;

  @Prop({ default: false })
  isLoadingSave!: boolean;

  @Prop({ default: false })
  isLoadingDelete!: boolean;

  @Prop()
  sideBarElement!: DocumentDetailSideBarElements;

  @Prop()
  currentPageNumber!: number;

  @Prop()
  pages!: IDocumentPageMeta[];

  @Prop({ default: false })
  fullwidth!: boolean;

  @Prop({ default: false })
  editValues!: boolean;

  @Prop({ default: true })
  outlined!: boolean;

  @Prop()
  signDocument?: ISignDocument;

  @Prop({ default: false })
  supressKeyboardActions!: boolean;

  @Prop({ default: false })
  hideTokenFields!: boolean;

  debounceOnResize = debounce(this.onResize, 100, false);

  onResize() {
    this.selector.key++;
  }

  get currentDocumentToken() {
    return DocumentTemplateModule.documentTokens[this.currentPageNumber];
  }

  get currentPage() {
    return this.pages[this.currentPageNumber];
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get DocumentDetailSideBarElements() {
    return DocumentDetailSideBarElements;
  }

  getMobilePageHeight(pageCount: number) {
    if (!pageCount) {
      return "0px";
    }
    return "100px";
  }

  getBodyHeight(pageCount: number) {
    if (this.isMobile && !this.isSigning) {
      return `calc(100vh - 116px - 15px - ${this.getMobilePageHeight(pageCount)})`;
    } else if (this.isSigning) {
      return "calc(100vh - 156px)";
    }
  }

  showSelect() {
    this.selector.showSelect();
  }

  hideSelect() {
    this.selector.hideSelect();
  }

  getTokenPosition() {
    const { sx, sy, swidth, sheight } = this.selector.getDrawPosition().pos;

    return {
      page: this.currentPageNumber,
      documentToken: {
        coordinates: {
          x: sx * this.currentPage.scaleX,
          y: sy * this.currentPage.scaleY,
          w: swidth * this.currentPage.scaleX,
          h: sheight * this.currentPage.scaleY,
          fontSize: 12
        },
        token: ""
      }
    };
  }

  getTokenListPosition(coordinates: SignDocumentTokenCoordinatesGen, token: string) {
    return DocumentTemplateModule.documentTokens[this.currentPageNumber].findIndex(t => {
      return (
        t.coordinates.x === coordinates.x &&
        t.coordinates.y === coordinates.y &&
        t.coordinates.w === coordinates.w &&
        t.coordinates.h === coordinates.h &&
        t.coordinates.fontSize === coordinates.fontSize &&
        t.token === token
      );
    });
  }

  addToken() {
    const tokenPosition = this.getTokenPosition();
    DocumentTemplateModule.addDocumentToken(tokenPosition);

    const token = tokenPosition.documentToken;
    const position = this.getTokenListPosition(token.coordinates, token.token);

    this.hideSelect();

    return { token, position };
  }

  addSignature() {
    const tokenPosition = this.getTokenPosition();
    DocumentTemplateModule.addDocumentToken(tokenPosition);

    const token = tokenPosition.documentToken;

    const position = this.getTokenListPosition(token.coordinates, token.token);

    const signatureToken: SignatureTokenConfigType = SignatureQRTokenConfig;

    const x = token.coordinates.x + token.coordinates.w / 2 - signatureToken.w / 2;
    const y = token.coordinates.y + token.coordinates.h / 2 - signatureToken.h / 2;
    token.coordinates.x = x;
    token.coordinates.y = y;
    token.coordinates.h = signatureToken.h;
    token.coordinates.w = signatureToken.w;
    token.token = signatureToken.token;

    this.hideSelect();

    return { token, position };
  }

  addTextField() {
    const tokenPosition = this.getTokenPosition();

    DocumentTemplateModule.addDocumentToken(tokenPosition);

    const token = tokenPosition.documentToken;
    token.token = TextFieldConfig.token;

    const position = this.getTokenListPosition(token.coordinates, token.token);

    this.hideSelect();

    return { token, position };
  }

  @Watch("currentPageNumber")
  close() {
    // DocumentTemplateModule.setHighlightedToken(-1);
    this.selector.hideSelect();
  }

  closeSideBar() {
    this.$emit("closeSideBar");
  }

  setCurrentPage(currentPageNumber: number) {
    this.$emit("setCurrentPage", currentPageNumber);
  }

  async save() {
    this.$emit("save");
  }

  async onDelete() {
    this.$emit("onDelete");
  }

  previousPage() {
    if (this.currentPageNumber === 0) {
      this.setCurrentPage(this.pages.length - 1);
      DocumentTemplateModule.setCurrentPage(this.currentPageNumber);

      return;
    }
    this.setCurrentPage(this.currentPageNumber - 1);
    DocumentTemplateModule.setCurrentPage(this.currentPageNumber);
  }

  nextPage() {
    if (this.currentPageNumber === this.pages.length - 1) {
      this.setCurrentPage(0);
      DocumentTemplateModule.setCurrentPage(this.currentPageNumber);

      return;
    }
    this.setCurrentPage(this.currentPageNumber + 1);
    DocumentTemplateModule.setCurrentPage(this.currentPageNumber);
  }
}
