var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function callback($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-tab",
                {
                  attrs: {
                    disabled:
                      !_vm.isProfileFormDisabled || !_vm.isBankingFormDisabled,
                    href: "#".concat(_vm.ProfileTabsEnum.PROFILE)
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "pr-2"
                    },
                    [_vm._v("mdi-account-outline")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.profile.ProfileCard.contactSubtitle")
                      ) +
                      " "
                  )
                ],
                1
              ),
              _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.THG) &&
              _vm.isBankingAvailable
                ? _c(
                    "v-tab",
                    {
                      attrs: {
                        disabled:
                          !_vm.isProfileFormDisabled ||
                          !_vm.isBankingFormDisabled,
                        href: "#".concat(_vm.ProfileTabsEnum.BANKING)
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pr-2"
                        },
                        [_vm._v("mdi-bank-outline")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.profile.ProfileCard.bankingSubtilte"
                            )
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab",
                {
                  attrs: {
                    disabled:
                      !_vm.isProfileFormDisabled || !_vm.isBankingFormDisabled,
                    href: "#".concat(_vm.ProfileTabsEnum.NOTIFICATION)
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "pr-2"
                    },
                    [_vm._v("mdi-bell-outline")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.profile.ProfileCard.notification")
                      ) +
                      " "
                  )
                ],
                1
              ),
              _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER)
                ? _c(
                    "v-tab",
                    {
                      attrs: {
                        disabled:
                          !_vm.isProfileFormDisabled ||
                          !_vm.isBankingFormDisabled,
                        href: "#".concat(_vm.ProfileTabsEnum.PERMISSION)
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pr-2"
                        },
                        [_vm._v("mdi-lock-outline")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.profile.ProfileCard.permissions")
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "div",
                    [
                      _vm.tab === _vm.ProfileTabsEnum.PROFILE &&
                      _vm.isProfileFormDisabled
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mt-5 mr-5",
                              attrs: {
                                color: "primary",
                                "data-test-profile-edit": ""
                              },
                              on: {
                                click: _vm.startProfileEdit
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.profile.EditProfileDialog.edit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.tab === _vm.ProfileTabsEnum.BANKING &&
                      _vm.isBankingFormDisabled
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mt-5 mr-5",
                              attrs: {
                                color: "primary",
                                "data-test-profile-edit": ""
                              },
                              on: {
                                click: _vm.startBankingEdit
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.profile.EditProfileDialog.edit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.tab === _vm.ProfileTabsEnum.NOTIFICATION &&
                      _vm.isNotificationFormDisabled
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mt-5 mr-5",
                              attrs: {
                                color: "primary",
                                "data-test-profile-edit": ""
                              },
                              on: {
                                click: _vm.startNotificationEdit
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.profile.EditProfileDialog.edit"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function callback($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            {
              attrs: {
                value: _vm.ProfileTabsEnum.PROFILE
              }
            },
            [
              _c("profile-form", {
                ref: "profileForm",
                attrs: {
                  disabled: _vm.isProfileFormDisabled,
                  userToUpdate: _vm.user,
                  isLoading: _vm.isUpdatingUser
                },
                on: {
                  "update:disabled": function updateDisabled($event) {
                    _vm.isProfileFormDisabled = $event
                  },
                  save: _vm.updateUser
                }
              })
            ],
            1
          ),
          _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.THG) &&
          _vm.isBankingAvailable
            ? _c(
                "v-tab-item",
                {
                  attrs: {
                    value: _vm.ProfileTabsEnum.BANKING
                  }
                },
                [
                  _c("profile-banking-form", {
                    attrs: {
                      disabled: _vm.isBankingFormDisabled,
                      isLoading: _vm.isLoadingBanking,
                      banking: _vm.banking
                    },
                    on: {
                      "update:disabled": function updateDisabled($event) {
                        _vm.isBankingFormDisabled = $event
                      },
                      save: _vm.updateBanking
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-tab-item",
            {
              attrs: {
                value: _vm.ProfileTabsEnum.NOTIFICATION
              }
            },
            [
              _c("profile-notification-form", {
                attrs: {
                  disabled: _vm.isNotificationFormDisabled,
                  isSystemEmail: _vm.isSystemEmail,
                  isMarketingOptIn: _vm.user.isMarketingOptIn,
                  isAssigneeEmail: _vm.user.notificationConfig.assigned.isMail,
                  partnerNotifications: _vm.partnerNotifications,
                  partnerNotificationsEnabled:
                    _vm.isPartnerNotificationsEnabled,
                  isLoading: _vm.isUpdatingUser
                },
                on: {
                  "update:disabled": function updateDisabled($event) {
                    _vm.isNotificationFormDisabled = $event
                  },
                  "update:isMarketingOptIn": function updateIsMarketingOptIn(
                    $event
                  ) {
                    return _vm.$set(_vm.user, "isMarketingOptIn", $event)
                  },
                  "update:is-marketing-opt-in": function updateIsMarketingOptIn(
                    $event
                  ) {
                    return _vm.$set(_vm.user, "isMarketingOptIn", $event)
                  },
                  "update:isAssigneeEmail": function updateIsAssigneeEmail(
                    $event
                  ) {
                    return _vm.$set(
                      _vm.user.notificationConfig.assigned,
                      "isMail",
                      $event
                    )
                  },
                  "update:is-assignee-email": function updateIsAssigneeEmail(
                    $event
                  ) {
                    return _vm.$set(
                      _vm.user.notificationConfig.assigned,
                      "isMail",
                      $event
                    )
                  },
                  "update:partnerNotifications": function updatePartnerNotifications(
                    $event
                  ) {
                    _vm.partnerNotifications = $event
                  },
                  "update:partner-notifications": function updatePartnerNotifications(
                    $event
                  ) {
                    _vm.partnerNotifications = $event
                  },
                  save: _vm.updateNotification
                }
              })
            ],
            1
          ),
          _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER)
            ? _c(
                "v-tab-item",
                {
                  attrs: {
                    value: _vm.ProfileTabsEnum.PERMISSION
                  }
                },
                [_c("login-card-authorization-overview")],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }