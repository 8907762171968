


















































































































































































































































































































































import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";
import { calculateDayDifference, simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import FeatureMixin from "@/mixins/FeatureMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActivityLog } from "@/models/activity-log.entity";
import { AttachmentRequest, IAttachmentRequest } from "@/models/attachment-request.entity";
import { CreateSharedContentDto } from "@/models/create-shared-content.dto";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IReport } from "@/models/report.entity";
import { ISharedContent } from "@/models/shared-content.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { SignRequest } from "@/models/sign-request.entity";
import { MrfiktivCreateActivityLogDtoGen, MrfiktivDocumentViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { AttachmentRequestModule } from "@/store/modules/attachment-request.store";
import { DocumentModule } from "@/store/modules/document.store";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { SharedContentModule } from "@/store/modules/shared-content.store";
import { SignRequestModule } from "@/store/modules/sign-request.store";
import { SignModule } from "@/store/modules/sign.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import AEicon from "../utility/AEicon.vue";
import TableWrapper, { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import AttachmentRequestSideCard from "./AttachmentRequestSideCard.vue";
import PartnerReportTimeLineCardScrollMixin from "./PartnerReportTimeLineCardScrollMixin.vue";
import ReportActivityBoxShareComponentScreenOrder from "./ReportActivityBoxShareComponentScreenOrder.vue";
import SharedContentSideCard from "./SharedContentSideCard.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";

export enum ReportShareSteps {
  DATE = "date",
  IS_EDIT = "isEdit",
  APPENDIXES = "appendixes",
  EDIT_STEPS = "editSteps",
  REQUEST_SUCCESS = "requestSuccess",
  SHARING_SUCCESS = "sharingSuccess",
  OVERVIEW = "overview"
}

@Component({
  components: {
    TableWrapper,
    AEicon,
    SharedContentSideCard,
    AttachmentRequestSideCard,
    ReportActivityBoxShareComponentScreenOrder,
    draggable
  }
})
export default class ReportActivityBoxShareComponent extends mixins(
  PartnerReportTimeLineCardScrollMixin,
  DarkModeHighlightMixin,
  FeatureMixin,
  PermissionMixin
) {
  @Prop({ default: false })
  hideButton!: boolean;

  @Prop({})
  report!: IReport;

  @Prop({ default: true })
  actionButton!: boolean;

  @Watch("step")
  emitStep() {
    this.$emit("setStep", this.step);
    this.scrollDown();
  }

  @Watch("isLoading")
  emitIsLoading() {
    this.$emit("setLoading", this.isLoading);
  }

  isExpiringLink = true;

  readonly STEPS = ReportShareSteps;

  selectedAttachments: MrfiktivDocumentViewModelGen[] = [];

  documents: ISignDocument[] = [];

  step = ReportShareSteps.OVERVIEW;

  isLoadingDocuments = false;

  isLoading = false;
  isAttachmentSharingLink = false;

  sharedContent: IAttachmentRequest | ISharedContent | null = null;

  sharedContents: (ISharedContent | IAttachmentRequest)[] = [];
  // attachmentRequests: IAttachmentRequest[] = [];

  newScreen: ReportScreenEnum | null = null;

  sharedName = `${this.$t("objects.attachmentRequest.title").toString()}  ${this.report.title}`;

  get requiredRule() {
    return [requiredRule()];
  }

  get sharedSorted() {
    return this.sharedContents.sort(
      (a: IAttachmentRequest | ISharedContent, b: IAttachmentRequest | ISharedContent) => {
        if (!a.validBy) return 1;
        if (!b.validBy) return -1;
        return a.validBy > b.validBy ? 1 : -1;
      }
    );
  }

  get sharedIds() {
    const sharedIds: string[] = [];

    for (const log of ActivityLogModule.filteredAndSorted) {
      if (
        [ActivityTypeEnum.SHARE, ActivityTypeEnum.SHARE_REPORT_INITIAL].includes(log.activity as ActivityTypeEnum) &&
        log.target?.length
      ) {
        sharedIds.push(log.target[0].refId);
      }
    }

    return sharedIds;
  }

  get requestIds() {
    const requestIds: string[] = [];

    for (const log of ActivityLogModule.filteredAndSorted) {
      if ([ActivityTypeEnum.REQUEST_ATTACHMENT].includes(log.activity as ActivityTypeEnum) && log.target?.length) {
        requestIds.push(log.target[0].refId);
      }
    }

    return requestIds;
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }

  async loadExistingSharedContents() {
    SharedContentModule.setFilters([
      new PageFilterElement({
        key: "refs.refId",
        operation: "$eq",
        value: this.report._id
      }),
      new PageFilterElement({
        key: "refs.refType",
        operation: "$eq",
        value: BackendResourceEnum.REPORT
      })
    ]);
    SharedContentModule.fetchFirstPage({ partnerId: this.partnerId });

    SharedContentModule.filtered.forEach(sharedContent => {
      this.sharedContents.push(sharedContent);
    });
  }

  async loadExistingRequestIds() {
    AttachmentRequestModule.setFilters([
      new PageFilterElement({
        key: "refs.refId",
        operation: "$eq",
        value: this.report.id
      }),
      new PageFilterElement({
        key: "refs.refType",
        operation: "$eq",
        value: BackendResourceEnum.REPORT
      })
    ]);
    AttachmentRequestModule.fetchFirstPage({ partnerId: this.partnerId });

    AttachmentRequestModule.filtered.forEach(sharedContent => {
      this.sharedContents.push(sharedContent);
    });
  }

  async mounted() {
    this.isLoading = true;
    try {
      await this.loadExistingSharedContents();
      await this.loadExistingRequestIds();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [
      { text: String(this.$t("sign.DocumentTable.type")), align: "start", value: "isTemplate", width: 150 },
      { text: String(this.$t("sign.SignDocumentForm.title")), value: "title" },
      { text: String(this.$t("sign.SignDocumentForm.name")), value: "name" },
      { text: String(this.$t("sign.SignDocumentForm.folder")), value: "folder" },
      {
        text: "",
        value: "timestamp.created",
        type: "date",
        align: "end",
        width: "100px"
      }
    ];

    if (!this.hideButton) {
      headers.push({ text: "", value: "onClick" });
    }

    return headers;
  }

  get minimumExpirationDate() {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    return date.toISOString().substr(0, 10);
  }

  get daysUntilExpirations() {
    return calculateDayDifference(new Date(this.validBy));
  }

  get choices() {
    return [
      {
        icon: "mdi-file-eye-outline",
        title: this.$t("timeLine.ReportActivityBoxShareComponent.shareReadonly"),
        subtitle: this.$t("timeLine.ReportActivityBoxShareComponent.readonlySubtitle"),
        onClick: () => {
          this.step = ReportShareSteps.APPENDIXES;
        }
      },
      {
        icon: "mdi-file-document-edit-outline",
        title: this.$t("timeLine.ReportActivityBoxShareComponent.shareEditable"),
        subtitle: this.$t("timeLine.ReportActivityBoxShareComponent.editableSubtitle"),
        onClick: () => {
          this.step = ReportShareSteps.EDIT_STEPS;
        }
      }
    ];
  }

  get stepActions() {
    switch (this.step) {
      case ReportShareSteps.OVERVIEW: {
        return {
          isPrevious: false,
          onPrevious: undefined,
          nextLabel: this.$t("create"),
          isNext: true,
          onNext: () => (this.step = ReportShareSteps.DATE)
        };
      }
      case ReportShareSteps.DATE: {
        return {
          isPrevious: true,
          onPrevious: () => (this.step = ReportShareSteps.OVERVIEW),
          isNext: true,
          onNext: () => (this.step = ReportShareSteps.IS_EDIT)
        };
      }
      case ReportShareSteps.IS_EDIT: {
        return {
          isPrevious: true,
          onPrevious: () => (this.step = ReportShareSteps.DATE),
          isNext: false,
          onNext: undefined
        };
      }
      case ReportShareSteps.APPENDIXES: {
        return {
          isPrevious: true,
          onPrevious: () => (this.step = ReportShareSteps.IS_EDIT),
          isNext: true,
          onNext: async () => {
            await this.shareReadOnly();
            this.step = ReportShareSteps.SHARING_SUCCESS;
          }
        };
      }
      case ReportShareSteps.EDIT_STEPS: {
        return {
          isPrevious: true,
          onPrevious: () => (this.step = ReportShareSteps.IS_EDIT),
          isNext: true,
          onNext: async () => {
            await this.shareEdit();
            this.step = ReportShareSteps.REQUEST_SUCCESS;
          }
        };
      }
      case ReportShareSteps.SHARING_SUCCESS: {
        return {
          previousLabel: this.$t("timeLine.ReportActivityBoxShareComponent.continue"),
          isPrevious: true,
          onPrevious: this.reset,
          nextLabel: this.$t("timeLine.ReportActivityBoxShareComponent.copy"),
          isNext: true,
          onNext: () => this.sharedContent?.copyLinkToClipboard()
        };
      }
      case ReportShareSteps.REQUEST_SUCCESS: {
        return {
          previousLabel: this.$t("timeLine.ReportActivityBoxShareComponent.continue"),
          isPrevious: true,
          onPrevious: this.reset,
          nextLabel: this.$t("timeLine.ReportActivityBoxShareComponent.copy"),
          isNext: true,
          onNext: () => this.sharedContent?.copyLinkToClipboard()
        };
      }
      default: {
        return {
          previousLabel: "",
          isPrevious: false,
          onPrevious: undefined,
          nextLabel: "",
          isNext: false,
          onNext: undefined
        };
      }
    }
  }

  scrollDown() {
    if (this.hideButton) return;

    this.debounceScrollToBottom();
  }

  reset() {
    this.step = ReportShareSteps.DATE;
    this.validBy = this.defaultValidBy;
    this.selectedAttachments.splice(0);
  }

  removeNonSelectableScreens(screens: ReportScreenEnum[]) {
    const unselectableScreens = [
      ReportScreenEnum.closing,
      ReportScreenEnum.closingalt,
      ReportScreenEnum.closingsimple,
      ReportScreenEnum.closingaltauth,
      ReportScreenEnum.welcome,
      ReportScreenEnum.welcomealt,
      ReportScreenEnum.welcomelogin,
      ReportScreenEnum.REPAREO_HOME,
      ReportScreenEnum.REPAREO_CLOSING
    ];

    return screens.filter(screen => !unselectableScreens.includes(screen));
  }

  screenSelection = [...this.selectableScreens];

  get selectableScreens() {
    return this.removeNonSelectableScreens(
      (PartnerModule.partner.settings?.reportSettings.screenOrder ?? []) as ReportScreenEnum[]
    );
  }

  get unselectedSelectableScreens() {
    return this.selectableScreens.filter(screen => !this.screenSelection.includes(screen));
  }

  get defaultPartnerUrl() {
    return PartnerModule.partner.settings?.defaultUrl;
  }

  addNewScreen() {
    if (!this.newScreen) return;

    this.screenSelection.push(this.newScreen);
    this.newScreen = null;
  }

  removeScreen(index: number) {
    this.screenSelection.splice(index, 1);
  }

  moveUp(indexBefore: number) {
    const moved = this.screenSelection.splice(indexBefore, 1)[0];
    const indexAfter = indexBefore - 1;
    this.screenSelection.splice(indexAfter, 0, moved);
  }

  moveDown(indexBefore: number) {
    const moved = this.screenSelection.splice(indexBefore, 1)[0];
    const indexAfter = indexBefore + 1;
    this.screenSelection.splice(indexAfter, 0, moved);
  }

  get screens() {
    return this.removeNonSelectableScreens(
      (PartnerModule.partner.settings?.reportSettings.screenOrder ??
        Object.values(ReportScreenEnum)) as ReportScreenEnum[]
    );
  }

  //default set to 14 days
  readonly defaultValidBy = new Date(Date.now() + 12096e5).toISOString().substring(0, 10);

  validBy = this.defaultValidBy;

  get partnerId() {
    return PartnerModule.partner._id;
  }

  simpleDate(date: string) {
    return simpleDate(date);
  }

  async shareReadOnly() {
    this.isLoading = true;
    this.sharedContent = null;

    try {
      const createSharedContentDto = new CreateSharedContentDto();
      if (this.isExpiringLink) {
        createSharedContentDto.validBy = new Date(this.validBy).toISOString();
      } else {
        createSharedContentDto.validBy = undefined;
      }
      createSharedContentDto.refs = [
        {
          refType: BackendResourceEnum.REPORT,
          refId: this.report._id
        }
      ];
      this.selectedAttachments.forEach(selectedAttachment => {
        createSharedContentDto.refs.push({
          refId: selectedAttachment.id,
          refType: BackendResourceEnum.DOCUMENT
        });
      });

      const newShared = await createSharedContentDto.create();
      if (newShared) {
        this.sharedContent = newShared;
        this.sharedContents.push(newShared);
      } else {
        this.sharedContent = null;
      }
    } catch (error) {
      this.$toast.error("timeLine.ReportActivityBoxShareComponent.failedLinkCreation");
      this.$log.error(error);
    }

    if (this.sharedContent) {
      try {
        const data: MrfiktivCreateActivityLogDtoGen = {
          source: {
            refType: ResourceEnum.REPORT,
            refId: this.report._id
          },
          target: [{ refType: ResourceEnum.SHARED_CONTENT, refId: this.sharedContent.id }],
          actionType: ActionEnum.CREATE,
          activity: ActivityTypeEnum.SHARE
        };

        const activity = await new ActivityLog({
          partnerId: this.report.partnerId,
          ...data
        }).create();

        this.$emit("save", activity);
      } catch (error) {
        this.$toast.error(this.$t("components.partner.PartnerReportInitializeCard.failedActivityCreation"));
        this.$log.error(error);
      }
    }

    this.isLoading = false;
  }

  async shareEdit() {
    this.isLoading = true;
    this.sharedContent = null;

    try {
      const attachmentRequest = await new AttachmentRequest({
        partnerId: this.partnerId,
        validBy: this.isExpiringLink ? new Date(this.validBy).toISOString() : undefined,
        title: this.sharedName,
        refs: [
          {
            refType: BackendResourceEnum.REPORT,
            refId: this.report._id
          }
        ],
        screenOrder: this.screenSelection
      }).create();

      this.sharedContent = attachmentRequest;
      this.sharedContents.push(attachmentRequest);
    } catch (error) {
      this.$toast.error("timeLine.ReportActivityBoxShareComponent.failedLinkCreation");
      this.$log.error(error);
    }

    if (this.sharedContent) {
      try {
        const data: MrfiktivCreateActivityLogDtoGen = {
          source: {
            refType: ResourceEnum.REPORT,
            refId: this.report._id
          },
          target: [{ refType: ResourceEnum.ATTACHMENT_REQUEST, refId: this.sharedContent.id }],
          actionType: ActionEnum.CREATE,
          activity: ActivityTypeEnum.REQUEST_ATTACHMENT
        };
        const activity = await new ActivityLog({
          partnerId: this.report.partnerId,
          ...data
        }).create();

        this.$emit("save", activity);
      } catch (error) {
        this.$toast.error(this.$t("components.partner.PartnerReportInitializeCard.failedActivityCreation"));
        this.$log.error(error);
      }
    }

    this.isLoading = false;
  }

  scrollToDocument(documentId: string) {
    const reference = document.getElementById(documentId);
    reference?.scrollIntoView({ behavior: "smooth" });
  }

  setDocument(document?: ISignDocument) {
    if (!document) {
      return;
    }
    const index = this.documents.findIndex(d => d.id === document.id);
    if (index > -1) {
      this.documents.splice(index, 1, document);
    } else {
      this.documents.push(document);
    }
  }

  private async getDocumentForId(documentId?: string) {
    const partnerId = this.partnerId;

    if (!documentId) {
      return;
    }
    const fromList = DocumentModule.maps.id.get(documentId)[0];
    if (fromList) {
      this.setDocument(fromList);
      return;
    }

    const document = await new SignDocument({ partnerId, id: documentId }).fetch();

    return document;
  }

  private async getDocumentIdFromSignId(requestId: string) {
    const partnerId = this.partnerId;

    const signRequest =
      SignRequestModule.maps.id.get(requestId)[0] ?? (await new SignRequest({ partnerId, id: requestId }).fetch());

    if (signRequest.status !== DigitalSignatureRequestStatusEnum.SIGNED) {
      if (!signRequest.documents.length) {
        return;
      }

      return signRequest.documents[0];
    }

    const signatures = await SignModule.findAll({ partnerId, requestId });

    if (signatures.length) {
      const signId = signatures[0].id;
      const signature = await SignModule.findOne({ partnerId, requestId, signId });
      if (!signature.documents?.length) {
        return;
      }

      return signature.documents[0].id;
    }
  }

  @Watch("step")
  async loadDocuments() {
    if (this.step !== ReportShareSteps.IS_EDIT) return;

    this.isLoadingDocuments = true;

    const setDocumentsAsync: Promise<void>[] = [];
    for (const log of ActivityLogModule.filteredAndSorted) {
      if (!log?.target?.length) {
        continue;
      }

      const refId = log?.target[0].refId;
      if (!refId) {
        continue;
      }

      this.$log.debug(refId, log);

      switch (log.activity) {
        case ActivityTypeEnum.CREATE_DOCUMENT: {
          const documentId = refId;
          const promise = this.getDocumentForId(documentId)
            .then(this.setDocument)
            .catch(this.$log.error);

          setDocumentsAsync.push(promise);
          break;
        }

        case ActivityTypeEnum.REQUEST_SIGNATURE: {
          const signId = refId;
          const promise = this.getDocumentIdFromSignId(signId)
            .then(this.getDocumentForId)
            .then(this.setDocument)
            .catch(this.$log.error);

          setDocumentsAsync.push(promise);
          break;
        }
      }
    }

    await Promise.all(setDocumentsAsync);
    this.isLoadingDocuments = false;
  }
}
