import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { Cost, ICost } from "@/models/cost.entity";
import { MrfiktivCostControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { CostDataAccessLayer } from "./access-layers/cost.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { CostPageDataProvider } from "./page-data-provider/cost.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "cost",
  store
})
export class CostPaginationStore extends PaginatedBaseStore<ICost, MrfiktivCostControllerGetParamsGen> {
  protected _data = CostDataAccessLayer;
  protected _pageProvider = CostPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Cost.filterables;
}

export const CostModule = getModule(CostPaginationStore);
