





































import Debug from "@/components/utility/Debug.vue";
import MDetailTable, { IMDetailTableConfig } from "@/components/utility/mmmint/MDetailTable.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { handleError } from "@/lib/utility/handleError";
import { Report, IReport } from "@/models/report.entity";
import { DaWebhookModule } from "@/store/modules/da-webhook.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { dossierTableConfig } from "../portal/PartnerDaTableView.vue";

@Component({
  components: {
    MDetailTable,
    Tooltip,
    Debug
  }
})
export default class ReportDocumentSignRequestStepperDaWebhook extends mixins(ReportDocumentSignRequestStepperMixin)
  implements IStepper {
  @Prop()
  nextScreen?: SignRequestStepperPageEnum;

  page = SignRequestStepperPageEnum.DA_WEBHOOK;

  search = "";

  report: IReport | null = null;

  previous() {
    this.currentStep = SignRequestStepperPageEnum.CHOICE;
  }

  mounted() {
    this.isValid = false;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  async findWebhook() {
    if (!this.search) {
      return;
    }

    try {
      this.isLoading = true;
      const partnerId = this.partnerId;

      this.$log.debug("search", this.search);

      const webhook = await DaWebhookModule.findByNumberplate({ partnerId, licensePlate: this.search, isOpen: true });

      this.report = Report.createFromDaWebhook(webhook, this.partnerId);

      this.$log.debug("search", webhook);

      this.isValid = true;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  get webhook() {
    return DaWebhookModule.dossier;
  }

  get daDetailTable(): IMDetailTableConfig[] {
    return dossierTableConfig;
  }

  async next() {
    this.isLoading = true;

    if (!this.report || !this.webhook) {
      this.$toast.error("Kein Webhook ausgewählt");

      return;
    }

    this.$emit("setReport", this.report);
    this.$emit("setDaServiceEvent", { serviceEventId: `${this.webhook.data?.dossier.id}` });
    if (this.webhook.ksrIds && this.webhook.ksrIds.length > 0) {
      this.$emit("setKsrJob", { jobId: Number(this.webhook.ksrIds[0]) });
    }

    DaWebhookModule.clearDossier();

    this.currentStep = SignRequestStepperPageEnum.DOCUMENT_TYPE_CHOICE;
  }
}
