var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        margin: 0,
        flat: "",
        elevation: 0,
        outlined: _vm.outlined,
        subtitle: _vm.showSubtitle
          ? _vm.$t("common.nouns.vehicleReference")
          : "",
        hideTitle: true
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "pt-2 pr-2 pl-2"
        },
        [
          _vm.loadingVehicle
            ? _c("v-progress-linear", {
                attrs: {
                  indeterminate: ""
                }
              })
            : _vm._e(),
          _c("vehicle-api-selection", {
            ref: "vehicle-api-selection",
            staticClass: "mx-2 mt-2 mb-n2",
            attrs: {
              clearable: true
            },
            on: {
              change: _vm.onChangeVehicleSelection
            }
          }),
          _vm.showCopyAction && _vm.reportDto.vehicleId
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-n4",
                  attrs: {
                    small: "",
                    text: ""
                  },
                  on: {
                    click: _vm.startConfirmReplacementDialog
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "timeLine.VehicleTimeLineElement.copyVehicleData"
                        )
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isReplacementDialogActive
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isReplacementDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isReplacementDialogActive = $event
            },
            leftClick: function leftClick($event) {
              _vm.isReplacementDialogActive = false
            },
            close: function close($event) {
              _vm.isReplacementDialogActive = false
            },
            rightClick: _vm.replaceVehicleData
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("timeLine.VehicleTimeLineElement.overwriteWarning")
              ) +
              " "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }