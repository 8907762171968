





























































import TemplateEditor from "@/components/template/TemplateEditor.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { MessageContentFormatEnum } from "@/lib/enum/templateEnums/messageContentFormat.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { IPartnerTemplate } from "@/models/partner-template.entity";
import { ITemplate } from "@/models/template.entity";
import { MrfiktivPartnerTemplateViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import MHeader, { IAction, IAlert } from "../utility/mmmint/MHeader.vue";
import TemplateCreateDialog from "./TemplateCreateDialog.vue";
import TemplateDetailLanguageDialog from "./TemplateDetailLanguageDialog.vue";
import TemplateEditorSubject from "./TemplateEditorSubject.vue";
import { TemplateGoToHelper } from "@/lib/utility/template.go-to-helper";
import Debug from "../utility/Debug.vue";

@Component({
  components: {
    MHeader,
    LatestEntriesCardEmpty,
    Card,
    ConfirmActionDialog,
    TemplateEditorSubject,
    TemplateCreateDialog,
    TemplateDetailLanguageDialog,
    TemplateEditor,
    Debug
  },
  filters: { getFlagEmojiByLanguage }
})
export default class TemplateDetail extends mixins(DarkModeHighlightMixin, PermissionMixin, RulesMixin) {
  readonly getFlagEmojiByLanguage = getFlagEmojiByLanguage;
  readonly MessageContentFormatEnum = MessageContentFormatEnum;

  @Prop()
  value!: ITemplate;

  @Prop({ default: true })
  displayToDetailView!: boolean;

  @Prop({ default: false })
  hideBreadCrumbs!: boolean;

  isDeleteDialogActive = false;

  isLoadingDelete = false;

  get alerts(): IAlert[] {
    const alerts: IAlert[] = [];

    if ((this.value as MrfiktivPartnerTemplateViewModelGen)?.isAdminTemplate) {
      alerts.push({
        text: $t("components.template.detail.delete.systemMail"),
        type: "info"
      });
    }

    return alerts;
  }

  get chips() {
    const chips = [];

    chips.push({
      text: this.value ? simpleDoubleDigitDate(this.value.timestamp.created) : "",
      color: "info"
    });

    if (!(this.value as IPartnerTemplate).partnerId) {
      if ((this.value as IPartnerTemplate).isPublic) {
        chips.push({
          text: $t("template.public"),
          color: "primary"
        });
      } else {
        chips.push({
          text: $t("template.private"),
          color: "primary"
        });
      }
    }

    for (const cat of this.value?.meta.categories ?? []) {
      chips.push({
        text: cat,
        color: "primary"
      });
    }

    if (this.value?.meta?.contentFormat === MessageContentFormatEnum.HTML) {
      chips.push({
        text: $t("enums.MessageContentFormatEnum.html"),
        color: "primary"
      });
    } else if (this.value?.meta?.contentFormat === MessageContentFormatEnum.PLAIN) {
      chips.push({
        text: $t("enums.MessageContentFormatEnum.plain"),
        color: "primary"
      });
    }

    return chips;
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadCrumbs = TemplateGoToHelper.breadCrumbs;

    const selected = [];

    const partnerId = (this.value as IPartnerTemplate).partnerId;
    const templateId = this.value.id;

    selected.push(breadCrumbs.TemplateTable({ partnerId }));
    selected.push(breadCrumbs.TemplateDetail({ partnerId, templateId }));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.displayToDetailView) {
      actions.push({
        key: "toDetail",
        text: $t("components.template.detail.toDetail"),
        exec: this.value.goTo(this.$router).detail
      });
    }

    actions.push({
      key: "toDetail",
      text: $t("common.verbs.edit"),
      exec: this.value.goTo(this.$router).form
    });

    if (this.can(this.ActionEnum.UPDATE, this.ResourceEnum.TEMPLATE)) {
      actions.push({
        key: "createForNewLanguage",
        text: $t("components.template.detail.language.title"),
        exec: () => (this.$refs.languageOptions as TemplateDetailLanguageDialog)?.open()
      });
    }

    if (
      this.value &&
      !(this.value as IPartnerTemplate).isAdminTemplate &&
      this.can(this.ActionEnum.DELETE, this.ResourceEnum.TEMPLATE)
    ) {
      actions.push({
        key: "bodyUpdate",
        text: $t("delete"),
        exec: () => {
          this.isDeleteDialogActive = true;
        },
        color: "error"
      });
    }

    return actions;
  }

  async deleteTemplate() {
    this.isLoadingDelete = true;
    try {
      await this.value.delete();
      this.value.goTo(this.$router).table();
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteDialogActive = false;
      this.isLoadingDelete = false;
      this.$emit("onDelete");
    }
  }
}
