
































































import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { detailedDate } from "@/lib/utility/date-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { TimeStampEntity } from "@/models/timestampEntity";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import TemplateCard from "../template/TemplateCard.vue";
import TemplateDialog from "../template/TemplateDialog.vue";
import TemplateEditor from "../template/TemplateEditor.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { IPartnerMessage, MessageFolderEnum } from "@/models/partner-message.entity";
import MHeader, { IAction, IBreadcrumb } from "../utility/mmmint/MHeader.vue";
import { handleError } from "@/lib/utility/handleError";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { $t } from "@/lib/utility/t";
import { avatar } from "@/lib/utility/mail-helper";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    MHeader,
    TemplateEditor,
    TimelineCard,
    TemplateCard,
    TemplateDialog,
    LatestEntriesCardEmpty,
    ConfirmActionDialog
  }
})
export default class PartnerMessageDetail extends PermissionMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  value?: IPartnerMessage;

  isMounting = false;

  isArchiveDialogOpen = false;

  isArchiveLoading = false;

  get actions(): IAction[] {
    const actions: IAction[] = [];
    actions.push({
      text: $t("common.verbs.reply"),
      key: "Reply",
      icon: "mdi-reply-outline",
      exec: () => (this.$refs.templateDialog as TemplateDialog)?.show()
    });

    if (this.value?.url) {
      actions.push({
        text: $t("common.verbs.download"),
        key: "Download",
        icon: "mdi-download",
        exec: () => this.value?.download()
      });
    }

    if (this.value?.folder !== MessageFolderEnum.ARCHIVE) {
      actions.push({
        text: $t("common.verbs.archive"),
        key: "Archive",
        icon: "mdi-inbox",
        exec: () => (this.isArchiveDialogOpen = true)
      });
    }

    return actions;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];
    const isDetailView = this.$route.params.messageId;

    breadCrumbs.push({
      text: $t("common.nouns.inboxes"),
      to: { name: "PartnerMessageInboxList" },
      exact: true
    });

    const inbox = PartnerModule.partner.settings?.inboxes?.find(inbox => inbox.identifier === this.value?.to);
    if (inbox && isDetailView) {
      breadCrumbs.push({
        text: inbox.name,
        to: {
          name: "PartnerMessageInbox",
          params: { partnerId: this.value?.partnerId ?? "", inboxId: this.value?.to ?? "" },
          query: { folder: this.value?.folder.toString() ?? "" }
        },
        exact: true
      });
    }

    breadCrumbs.push({
      text: $t("common.nouns.detail"),
      to: { name: "PartnerMessageDetail" },
      exact: true,
      disabled: true
    });

    return breadCrumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    if (this.value?.folder) {
      chips.push({
        text: $t("MessageFolderEnum." + this.value.folder),
        key: "MessageFolderEnum",
        color: "info"
      });
    }

    return chips;
  }

  /**
   * E.g. "user xy from mrfiktiv"
   */
  get fromMail() {
    let fromMail = PartnerModule.partner.companyName;
    let fromUser = "";

    if (this.value?.folder === MessageFolderEnum.OUTBOX) {
      return this.value?.to;
    }

    if (this.value?.from) {
      fromMail = this.value.from;
    }

    if (this.userNameForId) {
      fromUser = this.$t("components.PartnerMessageDetail.toAlt", { mail: this.userNameForId }).toString();
    }

    return fromUser + fromMail;
  }

  get date() {
    if ((this.value?.timestamp as TimeStampEntity).created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";

      return detailedDate((this.value?.timestamp as TimeStampEntity).created, locale);
    }
    return "";
  }

  get partner() {
    return PartnerModule.partner;
  }

  get from() {
    return this.partner;
  }

  get newMessageTo() {
    if (this.value?.from) {
      return [this.value.from];
    }

    return this.to;
  }

  get to(): string[] {
    if (!this.value?.to) {
      return [];
    }
    return [this.value?.to];
  }

  get context(): ITemplateContext {
    return { partner: this.partner };
  }

  get userNameForId() {
    if (this.value?.userId === undefined) {
      return "";
    }

    const user = PartnerUserModule.maps.id.get(this.value.userId)[0];
    if (!user) {
      return "";
    }

    return user.firstName + " " + user.lastName;
  }

  getAvatar(mail: string) {
    return avatar(mail);
  }

  async archive() {
    try {
      if (!this.value) {
        return;
      }
      this.isArchiveLoading = true;
      await this.value.archive();
    } catch (e) {
      handleError(e);
    } finally {
      this.isArchiveDialogOpen = false;
      this.isArchiveLoading = false;
    }
  }

  abortArchive() {
    this.isArchiveDialogOpen = false;
  }
}
