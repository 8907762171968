






import { Component } from "vue-property-decorator";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import TemplateDetail from "@/components/template/TemplateDetail.vue";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import LayoutSimple from "@/layouts/LayoutSimple.vue";

@Component({
  components: { TemplateDetail, LayoutSimple }
})
export default class TemplateListDetailView extends PartnerFallbackMixin {
  loading = true;
  partnerId = "";
  key = "";

  async mounted() {
    this.partnerId = this.$route.params.partnerId;
    this.key = this.$route.params.key;
    this.loading = true;
    try {
      if (this.partnerId) {
        await PartnerTemplateModule.getTemplateByKey({ partnerId: this.partnerId, key: this.key });
      } else {
        await AdminTemplateModule.getTemplateByKey(this.key);
      }
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.loading = false;
    }
  }
}
