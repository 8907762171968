
































































































































import { TemplateTokenConfig } from "@/lib/configuration/template-token.configuration";
import { MessageContentFormatEnum } from "@/lib/enum/templateEnums/messageContentFormat.enum";
import { MessageMedium } from "@/lib/enum/templateEnums/messageMedium.enum";
import { ITemplateContext, ITemplateExtendedContext } from "@/lib/interfaces/template/templateContext.interface";
import { getNestedObjectValues, setNestedObjectValues } from "@/lib/objectPath-helper";
import { emailRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { renderTemplate } from "@/lib/utility/renderTemplate";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivCreateMessageDtoGen,
  MrfiktivPartnerTemplateViewModelGen,
  MrfiktivSettingPartnerInternalViewModelGen,
  MrfiktivSettingViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ThgAdminTemplateViewModelGen, ThgPartnerTemplateViewModelGen } from "@/services/thg/v1/data-contracts";
import { SettingModule } from "@/store/modules/setting.store";
import { debounce } from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ComboBox from "../utility/ComboBox.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import Tooltip from "../utility/tooltip.vue";
import TemplateEditor from "./TemplateEditor.vue";
import TemplateEditorActionDivider from "./TemplateEditorActionDivider.vue";
import TemplateSelectionDialog from "./TemplateSelectionDialog.vue";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { PartnerMessage } from "@/models/partner-message.entity";
import { IReference } from "@/models/reference.entity";

@Component({
  components: {
    Card,
    ComboBox,
    Debug,
    ConfirmActionDialog,
    TemplateEditor,
    TemplateSelectionDialog,
    TemplateEditorActionDivider,
    Tooltip
  }
})
export default class TemplateCard extends mixins(PermissionMixin) {
  readonly MessageContentFormatEnum = MessageContentFormatEnum;

  @Prop()
  partnerId?: string;

  @Prop()
  from!: PartnerEntity[];

  @Prop()
  context!: ITemplateContext;

  @Prop()
  loadContext?: () => Promise<ITemplateContext>;

  @Prop()
  to!: string[];

  @Prop({ default: false })
  isCustomSenderSelection?: boolean;

  @Prop()
  isEditorMobile?: boolean;

  @Prop()
  refs!: IReference[];

  get isMobile() {
    if (this.isEditorMobile) {
      return true;
    }
    if (this.$vuetify.breakpoint.mdAndDown) {
      return true;
    }
    return false;
  }

  template: ThgAdminTemplateViewModelGen | ThgPartnerTemplateViewModelGen = {
    key: "",
    content: {
      subject: "",
      body: "",
      tokens: []
    },
    meta: {
      description: "",
      categories: [],
      editor: "tiptap",
      contentFormat: "html",
      language: LanguageCodeEnum.DE,
      title: ""
    },
    timestamp: { created: new Date().toString(), lastModified: new Date().toString() },
    isAdminTemplate: false
  };

  customTokenDialog = false;

  selectedReceivers: string[] = this.to;
  selectedSender = this.from?.length > 0 ? this.from[0].companyName : "";

  renderedBody = "";
  renderedSubject = "";

  loading = false;

  isValid = false;
  isReceiverSelectionValid = false;
  isSenderSelectionValid = false;

  problems: string[] = [];

  extendedContext: ITemplateExtendedContext = {};

  /**
   * this key is incremented whenever the template is regenerated.
   * it forces the template editor to rerender to be responsive to changes
   */
  k = 0;

  /**
   * How long to wait for changes before search input is updated
   */
  filterDebounceTimeout = 300;

  get isTokenWithoutValue() {
    for (const token of this.template.content.tokens) {
      if (!this.getValue(token)) {
        return true;
      }
    }
    return false;
  }

  get hasTokens() {
    return this.template.content.tokens.length;
  }

  get templateEditorOffset() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return 190;
    }
    return 150;
  }

  get receiverMailRules() {
    return [emailRule(" ")];
  }

  get textRules(): any {
    return [requiredRule(" ")];
  }

  get allFormsValid() {
    return this.isValid && this.isReceiverSelectionValid;
  }

  get senderNames() {
    return this.from?.map(s => s.companyName);
  }

  get selectedSenderId() {
    const sender = this.from?.find(s => s.companyName === this.selectedSender);

    return sender?._id || "";
  }

  get settingTokens() {
    return this.template.content.tokens.filter((c: string) => c.split(".")[0] === TemplateTokenConfig.settingCategory);
  }

  get customTokens() {
    return this.template.content.tokens.filter((c: string) => c.split(".")[0] === TemplateTokenConfig.customCategory);
  }

  async mounted() {
    this.loading = true;

    // for some reason this.template was undefined when mounting
    this.template = {
      key: "",
      content: {
        subject: "",
        body: "",
        tokens: []
      },
      meta: {
        description: "",
        categories: [],
        editor: "tiptap",
        contentFormat: "html",
        language: LanguageCodeEnum.DE,
        title: ""
      },
      timestamp: { created: new Date().toString(), lastModified: new Date().toString() },
      isAdminTemplate: false
    };

    await this.setContext();
    this.generateTemplate();

    this.loading = false;
  }

  @Watch("isValid")
  @Watch("renderedBody")
  isValidWatcher() {
    this.$emit("isValid", this.isValid && this.renderedBody.length > 0);
  }

  getLabel(tokenPath: string) {
    const translationPrefix = "components.template.editor.placeholder.path";
    const translation = this.$t(`${translationPrefix}.${tokenPath}`).toString();
    if (translation.startsWith(translationPrefix)) {
      return tokenPath;
    }
    return translation;
  }

  getValue(tokenPath: string) {
    return getNestedObjectValues(this.extendedContext, tokenPath);
  }

  setValue(tokenPath: string, value: string) {
    return setNestedObjectValues(this.extendedContext, tokenPath, value);
  }

  /**
   * Sets the template to loading
   *
   * @param loading
   */
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  changeReceiverSelection(selectedReceivers: string[]) {
    this.selectedReceivers = selectedReceivers;
  }

  changeIsReceiverSelectionValid(isValid: boolean) {
    this.isReceiverSelectionValid = isValid;
  }

  changeSenderSelection(selectedSender: string) {
    this.selectedSender = selectedSender ? selectedSender : "";
  }

  changeIsSenderSelectionValid(isValid: boolean) {
    this.isSenderSelectionValid = isValid;
  }

  debounceInput = debounce(
    (v: any, customTokenKey: string) => this.handleCustomContextChange(v, customTokenKey),
    this.filterDebounceTimeout,
    false
  );

  async setContext() {
    const context = (await this.getContext()) || {};
    this.extendedContext = { ...context, custom: {} };
  }

  async getContext() {
    const context = this.context;
    if (context) {
      return context;
    }

    const loadContext = this.loadContext;
    if (loadContext) {
      try {
        const context = await loadContext();

        return context;
      } catch (e) {
        this.$log.error(e);
        this.$toast.error((e as any).message);
      }
    }
    this.$log.error("either context or load context must be given");
  }

  /**
   * Add a key value to the extended context manually from outside
   * @param key
   * @param value
   */
  extendCustomExtendedContext(key: string, value: string) {
    // this.extendedContext = { ...this.extendedContext, custom: { ...this.extendedContext.custom, [key]: value } };
    this.debounceInput(value, key);
  }

  async buildExtendedContext() {
    this.$log.debug("EXTENDED CONTEXT CHANGED");
    this.addCustomToContext();
    this.$log.debug("ADDED CUSTOM TOKENS");
    await this.addSettingsToContext();
    this.$log.debug("ADDED SETTINGS TO CONTEXT");
  }

  async loadSetting(key: string) {
    let setting: MrfiktivSettingViewModelGen | MrfiktivSettingPartnerInternalViewModelGen | undefined;

    if (this.partnerId) {
      setting = await SettingModule.getSettingByKey({ key: key, partnerId: this.partnerId });

      if (!setting) {
        this.problems.push(`Setting ${key} not found`);
      }
      if (setting?.isEncrypted) {
        this.problems.push(`Setting ${key} encrypted`);
      }
    } else {
      try {
        setting = await SettingModule.getAdminSettingByKey(key);
        if (setting?.isEncrypted) {
          this.problems.push(`Setting ${key} encrypted`);
        }
      } catch (e) {
        this.problems.push(`Setting ${key} not found`);
      }
    }

    return setting?.value;
  }

  handleCustomContextChange(v: any, customTokenKey: string) {
    this.setValue(customTokenKey, v);

    this.generateTemplate();
  }

  addCustomToContext() {
    const settingContext: Record<string, string> = {};

    this.customTokens.forEach(t => {
      const key = t.split(".")[1];
      settingContext[key] = `${key}`;
    });

    this.extendedContext.custom = settingContext;
  }

  async addSettingsToContext() {
    const settingsAsync: Promise<{ key: string; value: string | undefined }>[] = this.settingTokens
      .map(st => st.split(".")[1])
      .map(async key => {
        return {
          key: key,
          value: await this.loadSetting(key)
        };
      });

    const settings = await Promise.all(settingsAsync);

    const settingContext: Record<string, string> = {};
    settings.forEach(s => (settingContext[s.key] = s.value || ""));

    this.extendedContext.setting = settingContext;
  }

  generateTemplate() {
    const input = this.extendedContext;

    this.renderedSubject = renderTemplate(this.template.content.subject, input);
    this.renderedBody = renderTemplate(this.template.content.body, input);

    this.k++;
  }

  setExtendedContext(extendedContext: ITemplateExtendedContext) {
    this.extendedContext = extendedContext;
    this.generateTemplate();
  }

  async setTemplate(template: MrfiktivPartnerTemplateViewModelGen | MrfiktivAdminTemplateViewModelGen) {
    this.loading = true;
    this.template = template;
    await this.buildExtendedContext();
    this.generateTemplate();
    this.loading = false;
  }

  assertMailInformation() {
    const errors: string[] = [];
    const missingErrors: string[] = [];
    if (!this.selectedSenderId) {
      missingErrors.push(this.$t("components.template.detail.errors.sender").toString());
    }
    if (!this.selectedReceivers?.length) {
      missingErrors.push(this.$t("components.template.detail.errors.receiver").toString());
    }
    if (!this.renderedSubject) {
      missingErrors.push(this.$t("components.template.detail.errors.subject").toString());
    }
    if (!this.renderedBody) {
      missingErrors.push(this.$t("components.template.detail.errors.body").toString());
    }
    if (missingErrors.length) {
      errors.push(missingErrors.join(", ") + " " + this.$t("components.template.detail.errors.missing").toString());
    }

    if (this.selectedReceivers.length)
      for (const receiver of this.selectedReceivers) {
        const rule = emailRule()(receiver);
        if (rule && rule !== true) {
          errors.push(rule);
        }
      }

    if (errors.length) {
      throw new Error(errors.join(", "));
    }
  }

  async send() {
    try {
      this.assertMailInformation();
      this.loading = true;
      const mrfiktivCreateMessageDtoGen: MrfiktivCreateMessageDtoGen[] = [];

      for (const receiver of this.selectedReceivers) {
        mrfiktivCreateMessageDtoGen.push({
          to: receiver,
          content: {
            subject: this.renderedSubject,
            body: this.renderedBody
          },
          meta: {
            contentFormat: MessageContentFormatEnum.HTML,
            medium: MessageMedium.EMAIL
          }
        });
      }

      const promises = [];
      for (const dto of mrfiktivCreateMessageDtoGen) {
        promises.push(new PartnerMessage({ partnerId: this.selectedSenderId, ...dto, refs: this.refs ?? [] }).create());
      }
      const messages = await Promise.all(promises);

      this.$toast.success(this.$t("components.template.dialog.preview.sentConfirmation"));

      this.template.content.subject = "";
      this.template.content.body = "";
      this.generateTemplate();

      this.$emit("sent", messages);

      return messages;
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.loading = false;
    }
  }
}
