var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mt-4",
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-card-text",
        {
          staticClass: "px-0"
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.thg.thgQuickCheckout.thgDialogImageConfirm.subtitle"
                )
              ) +
              " "
          )
        ]
      ),
      _vm.frontImage
        ? _c("image-dialog", {
            staticClass: "px-0",
            attrs: {
              src: _vm.frontImage,
              title: "Vorderseite"
            }
          })
        : _vm._e(),
      _vm.backImage
        ? _c("image-dialog", {
            staticClass: "px-0",
            attrs: {
              src: _vm.backImage,
              title: "Rückseite"
            }
          })
        : _vm._e(),
      _c(
        "v-radio-group",
        {
          model: {
            value: _vm.isRegistrationValid,
            callback: function callback($$v) {
              _vm.isRegistrationValid = $$v
            },
            expression: "isRegistrationValid"
          }
        },
        [
          _c("v-radio", {
            attrs: {
              label: _vm.$t(
                "components.thg.thgQuickCheckout.thgDialogImageConfirm.label.yes"
              ),
              value: true
            }
          }),
          _c("v-radio", {
            attrs: {
              label: _vm.$t(
                "components.thg.thgQuickCheckout.thgDialogImageConfirm.label.no"
              ),
              value: false
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }