var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-select", {
    attrs: {
      rules: _vm.requiredRule,
      items: _vm.values,
      label: _vm.customField.label + (_vm.required ? " *" : ""),
      hint: _vm.customField.hint,
      disabled: _vm.disabled,
      loading: _vm.loading,
      placeholder: _vm.customField.placeholder,
      clearable: !_vm.required,
      "persistent-hint": "",
      outlined: ""
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function fn(_ref) {
          var item = _ref.item
          return [
            _c("custom-field-value-chip", {
              attrs: {
                value: item
              }
            })
          ]
        }
      },
      {
        key: "item",
        fn: function fn(_ref2) {
          var item = _ref2.item
          return [
            _c(
              "v-list-item-content",
              [
                _c("v-list-item-title", [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex"
                      }
                    },
                    [
                      _c("v-sheet", {
                        staticClass: "mr-1 mb-n1",
                        attrs: {
                          rounded: "pill",
                          color: "".concat(item.color, " lighten-3"),
                          height: "18",
                          width: "18"
                        }
                      }),
                      _vm._v(" " + _vm._s(item.value) + " ")
                    ],
                    1
                  )
                ]),
                _c("v-list-item-subtitle", [
                  _vm._v(_vm._s(item.description) + " ")
                ])
              ],
              1
            )
          ]
        }
      }
    ]),
    model: {
      value: _vm.input,
      callback: function callback($$v) {
        _vm.input = $$v
      },
      expression: "input"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }