import { PartnerTemplateRouteNames } from "@/lib/utility/partner-template.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import PartnerTemplateDetailView from "@/views/template/PartnerTemplateDetailView.vue";
import PartnerTemplateDetailFormView from "@/views/template/PartnerTemplateDetailFormView.vue";
import PartnerTemplateTableView from "@/views/template/PartnerTemplateTableView.vue";

/**
 * Routes for templates and tickets
 */
export const PartnerTemplateRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/template/",
    component: PartnerTemplateTableView,
    name: PartnerTemplateRouteNames.PARTNER_TEMPLATE_TABLE,
    permission: {
      resource: ResourceEnum.TEMPLATE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-format-float-left",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 45
    }
  },
  {
    path: "/partner/:partnerId/template/:templateId",
    component: PartnerTemplateDetailView,
    name: PartnerTemplateRouteNames.PARTNER_TEMPLATE_DETAIL,
    permission: {
      resource: ResourceEnum.TEMPLATE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/template/:templateId/form",
    component: PartnerTemplateDetailFormView,
    name: PartnerTemplateRouteNames.PARTNER_TEMPLATE_DETAIL_FORM,
    permission: {
      resource: ResourceEnum.TEMPLATE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
