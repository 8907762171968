var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list-item",
    [
      _c(
        "v-list-item-content",
        [
          _c(
            "v-list-item-title",
            {
              class: {
                "text-decoration-line-through": _vm.item.isArchived
              }
            },
            [
              _vm.hasOpenDetailListener()
                ? _c(
                    "a",
                    {
                      on: {
                        click: function click($event) {
                          $event.stopPropagation()
                          return _vm.emitOpenDetail.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.firstName) +
                          " " +
                          _vm._s(_vm.item.lastName) +
                          " "
                      )
                    ]
                  )
                : _c("div", [
                    _vm._v(
                      _vm._s(_vm.item.firstName) +
                        " " +
                        _vm._s(_vm.item.lastName)
                    )
                  ])
            ]
          ),
          _c("v-list-item-subtitle", [
            _vm._v(" " + _vm._s(_vm.item.userName) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }