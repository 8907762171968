





























import { IFormableClass, MDetailFormConfig } from "@/lib/formable";
import { getNestedObjectValues, propertiesToArray, setNestedObjectValues } from "@/lib/objectPath-helper";
import { UpdateDto } from "@/lib/utility/data/update-dto.interface";
import { handleError } from "@/lib/utility/handleError";
import { ICustomField } from "@/models/custom-field.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import MDetailForm, { IClearableCategory } from "./mmmint/MDetailForm.vue";
import SideCard from "./SideCard.vue";

@Component({
  name: "UpdateForm",
  components: {
    SideCard,
    MDetailForm
  },
  filters: {}
})
export default class UpdateForm<T> extends Vue {
  /**
   * Initial value
   */
  @Prop()
  value!: T;

  /**
   * Class to update the item
   */
  @Prop()
  formable!: { new (value: T): UpdateDto<T> } & IFormableClass;

  /**
   * Key that triggers the loadCustomConfig function
   */
  @Prop()
  customConfigKey?: string;

  /**
   * Function to load custom config, e.g. if groupId changes
   */
  @Prop()
  loadCustomConfig?: (value: string) => Promise<ICustomField[]>;

  /**
   * Path to the custom category name
   */
  @Prop()
  customCategoryNamePath?: string;

  @Prop({ default: true })
  containButtons!: boolean;

  @Prop()
  partnerId!: string;

  model = new this.formable(this.value);

  isLoading = false;

  get clearables() {
    const clearableLeaves: Omit<IClearableCategory, "category">[] = [];
    const clearableCategories: IClearableCategory[] = [];
    for (const clearable of this.formable.clearables) {
      // if there is a formable with an exact match, its a clearable leaf as opposed to a whole clearble category
      const isLeaf = this.formables.find(formable => formable.key === clearable);
      if (isLeaf) {
        clearableLeaves.push({ key: isLeaf.key });
      } else {
        const categories = this.formables.filter(formable => formable.key.startsWith(clearable)).map(c => c.category);

        // check if entire category is clearabale. Are there any formables in the category that are not clearable?
        for (const category of categories) {
          const formablesInCategory = this.formables.filter(formable => formable.category === category);

          const formablesInCategoryThatAreNotCleraeble = formablesInCategory.filter(
            formable => !formable.key.startsWith(clearable)
          );
          if (formablesInCategoryThatAreNotCleraeble.length === 0) {
            if (!clearableCategories.find(c => c.category === category)) {
              clearableCategories.push({ key: clearable, category });
            }
          } else {
            this.$log.error(
              `clearable category ${category} has non-clearable formables ${formablesInCategoryThatAreNotCleraeble}. This category will not be cleareble untiul all non-clerable formables are removed`
            );
          }
        }
      }
    }

    return { clearableCategories, clearableLeaves };
  }

  get formables() {
    return this.formable.formables;
  }

  get config() {
    return this.formable.formables;
  }

  async mounted() {
    await this.abortChanges();
  }

  /**
   * Syncs the changes from the form to the value
   * and show the update confirmation dialog.
   */
  async syncChanges() {
    const config = new MDetailFormConfig(this.config);
    const configKeys = config.getConfigKeys();

    for (const key of configKeys) {
      setNestedObjectValues(this.model, key, config.getConfigValueByKey(key));
    }

    const clearables = this.clearables;
    // if a cleareble leaf has an empty value ("", null, undefined), the value will be set to null
    for (const clearableLeaf of clearables.clearableLeaves) {
      const value = getNestedObjectValues(this.model, clearableLeaf.key);
      if (!value) {
        setNestedObjectValues(this.model, clearableLeaf.key, null);
      }
    }

    // if a cleareble category, only has empty values ("", null, undefined), the category will be set to null
    for (const clearCategory of clearables.clearableCategories) {
      const category = clearCategory.category;
      const formablesInCategory = this.formables.filter(formable => formable.category === category);
      const valuesInCategory = formablesInCategory.map(formable => getNestedObjectValues(this.model, formable.key));
      if (valuesInCategory.every(value => value === "" || value === null || value === undefined)) {
        setNestedObjectValues(this.model, clearCategory.key, null);
      }
    }

    // for array values that are clearable, check if the array is empty and set it to null if it is
    const arrayConfigs = this.config.filter(c => c.isArray && c.type);
    for (const arrayConfig of arrayConfigs) {
      const arrayObjects = getNestedObjectValues(this.model, arrayConfig.key) as object[];
      const arrayClearables = arrayConfig.props.form.clearables as string[];
      // for each element in the array, check if the clearables indicate that something can be cleared and clear it if it is empty ("", null, undefined)
      for (const object of arrayObjects) {
        const paths = propertiesToArray(object);
        for (const path of paths) {
          const value = getNestedObjectValues(object, path);
          if (!value && arrayClearables.includes(path)) {
            setNestedObjectValues(object, path, null);
          }
        }
      }
    }

    this.isLoading = true;
    await this.model.update().catch(handleError);
    this.$toast.success("👍");
    this.isLoading = false;

    for (const key of configKeys) {
      config.configValueByKey = { key: key, value: getNestedObjectValues(this.model, key) };
    }

    this.$emit("input", this.model);
  }

  async abortChanges() {
    this.isLoading = true;
    const config = new MDetailFormConfig(this.config);
    const configKeys = config.getConfigKeys();

    for (const key of configKeys) {
      config.configValueByKey = { key: key, value: getNestedObjectValues(this.model, key) };
    }
    this.$nextTick(() => {
      this.isLoading = false;
    });
  }
}
