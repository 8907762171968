var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card-actions",
        [
          _vm.isExistingJobsLoading
            ? _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "components.partner.PartnerReportDetail.SendToKsrDialog.jobSearching",
                      {
                        search: _vm.searchText
                      }
                    )
                  )
                }
              })
            : !_vm.existingJobs.length
            ? _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "components.partner.PartnerReportDetail.SendToKsrDialog.jobSearchingNotFound",
                      {
                        search: _vm.searchText
                      }
                    )
                  )
                }
              })
            : _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "components.partner.PartnerReportDetail.SendToKsrDialog.jobSearchingFound",
                      {
                        search: _vm.searchText
                      }
                    )
                  )
                }
              }),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                "x-small": "",
                dense: "",
                outlined: "",
                color: "info"
              },
              on: {
                click: _vm.toggleSearchEdit
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.partner.PartnerReportDetail.SendToKsrDialog.editSearch"
                    )
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _vm.isExistingJobsLoading
        ? _c("v-progress-linear", {
            attrs: {
              indeterminate: ""
            }
          })
        : _vm._e(),
      _vm.isSearchEditActive
        ? _c(
            "div",
            [
              _c(
                "v-card-actions",
                {
                  staticClass: "mb-n7 mt-n6"
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        row: ""
                      },
                      model: {
                        value: _vm.searchOption,
                        callback: function callback($$v) {
                          _vm.searchOption = $$v
                        },
                        expression: "searchOption"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.numberplate"
                          ),
                          value: _vm.SearchOptions.NUMBERPLATE
                        }
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.jobId"
                          ),
                          value: _vm.SearchOptions.JOB_ID
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "tooltip",
                {
                  attrs: {
                    text: _vm.$t(
                      "components.partner.PartnerReportDetail.SendToKsrDialog.searchNumberPlateOrJobHint"
                    )
                  }
                },
                [
                  _c(
                    "v-form",
                    {
                      on: {
                        submit: function submit($event) {
                          $event.preventDefault()
                          return _vm.loadJobs.apply(null, arguments)
                        },
                        keydown: function keydown($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.loadJobs.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          type:
                            _vm.searchOption === _vm.SearchOptions.JOB_ID
                              ? "number"
                              : "text",
                          label: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.search"
                          ),
                          hint: _vm.$t("confirmSearchWithEnter"),
                          loading: _vm.isExistingJobsLoading,
                          "append-icon": "mdi-magnify",
                          "persistent-hint": ""
                        },
                        on: {
                          "click:append": _vm.loadJobs
                        },
                        model: {
                          value: _vm.search,
                          callback: function callback($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.existingJobs.length
        ? _c(
            "div",
            [
              _vm._l(_vm.existingJobs, function(item) {
                return _c(
                  "card",
                  {
                    key: item.jobId,
                    staticClass: "mt-1 mb-1",
                    attrs: {
                      hideTitle: true,
                      margin: 0
                    }
                  },
                  [
                    _c(
                      "tooltip",
                      {
                        attrs: {
                          text: _vm.$t(
                            "components.partner.PartnerReportDetail.SendToKsrDialog.exportExistingTooltip",
                            {
                              jobId: item.jobId
                            }
                          )
                        }
                      },
                      [
                        _c(
                          "v-list-item",
                          {
                            attrs: {
                              "three-line": ""
                            },
                            on: {
                              click: function click($event) {
                                return _vm.confirm(item)
                              }
                            }
                          },
                          [
                            _c(
                              "tooltip",
                              {
                                attrs: {
                                  top: "",
                                  text: _vm.$t(
                                    "components.partner.PartnerReportDetail.SendToKsrDialog.titleExisting"
                                  )
                                }
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c("v-icon", [
                                      _vm._v(" mdi-file-replace-outline ")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                item
                                  ? _c(
                                      "partner-report-detail-send-to-ksr-dialog-ksr-detail",
                                      {
                                        staticClass: "ml-n2 pl-2",
                                        attrs: {
                                          job: item
                                        }
                                      }
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  !_vm.isExistingJobsLoading && _vm.isChangeAmountButtonVisible
                    ? _c(
                        "v-btn",
                        {
                          staticStyle: {
                            width: "30%"
                          },
                          attrs: {
                            elevation: 0,
                            "x-small": ""
                          },
                          on: {
                            click: _vm.profillMore
                          }
                        },
                        [
                          _c("small", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.partner.PartnerReportDetail.SendToKsrDialog.loadMore"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }