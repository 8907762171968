
















import { Component, Prop, Vue } from "vue-property-decorator";
import { IAction } from "./MHeader.vue";

@Component({})
export default class MActionList extends Vue {
  @Prop()
  actions!: IAction[];

  @Prop({ default: false })
  divider!: boolean;

  get activeActions() {
    return this.actions;
  }

  textStyle(action: IAction) {
    if (action.color) {
      return `text-transform: uppercase; color: ${action.color}`;
    } else {
      return "text-transform: uppercase;";
    }
  }

  actionClicked(action: IAction | undefined) {
    if (!action) {
      return;
    }
    if (action.exec) {
      action.exec();
      return;
    }
    this.$emit("actionClicked", action);
  }
}
