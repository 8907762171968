









































































































































































































import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { ITicket } from "@/models/ticket.entity";
import { IVehicle } from "@/models/vehicle.entity";
import {
  MrfiktivProjectCustomViewFieldDtoGen,
  MrfiktivReportViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import ContextMenu from "./ContextMenu.vue";
import RefsAttachmentRequest from "./RefsAttachmentRequest.vue";
import RefsCompany from "./RefsCompany.vue";
import RefsCompanyGroup from "./RefsCompanyGroup.vue";
import RefsCost from "./RefsCost.vue";
import RefsCostGroup from "./RefsCostGroup.vue";
import RefsDocument from "./RefsDocument.vue";
import RefsEvent from "./RefsEvent.vue";
import RefsHandover from "./RefsHandover.vue";
import RefsMessage from "./RefsMessage.vue";
import RefsReport from "./RefsReport.vue";
import RefsSignRequest from "./RefsSignRequest.vue";
import RefsSnapshot from "./RefsSnapshot.vue";
import RefsTicket from "./RefsTicket.vue";
import RefsVehicle from "./RefsVehicle.vue";
import RefsPerson from "./RefsPerson.vue";
import RefsPersonGroup from "./RefsPersonGroup.vue";
import RefsPersonPhone from "./RefsPersonPhone.vue";
import RefsPersonEmail from "./RefsPersonEmail.vue";
import RefsCompanyAddress from "./RefsCompanyAddress.vue";
import RefsGroup from "./RefsGroup.vue";
import RefsContract from "./RefsContract.vue";
import RefsFleet from "./RefsFleet.vue";
import RefsPartnerBanking from "./RefsPartnerBanking.vue";

export interface IRefBase<T> {
  /**
   * The ref to be displayed.
   */
  item: T;

  /**
   * Displays the ref with less details.
   */
  small: boolean;

  /**
   * Indicates wether the ref should be displayed as confirmable.
   */
  confirmable: boolean;
}

export interface IRefDetail<T> extends IRefBase<T> {
  /**
   * Navigates to the detail page of the ref.
   */
  goToDetail: (newTab?: boolean) => void;
}

type ItemTypes = MrfiktivReportViewModelGen | IVehicle | IEventUIDto | ITicket;

@Component({
  components: {
    ContextMenu,
    RefsReport,
    RefsVehicle,
    RefsEvent,
    RefsTicket,
    RefsDocument,
    RefsCost,
    RefsSignRequest,
    RefsAttachmentRequest,
    RefsCostGroup,
    RefsSnapshot,
    RefsHandover,
    RefsMessage,
    RefsCompany,
    RefsCompanyGroup,
    RefsPerson,
    RefsPersonGroup,
    RefsPersonPhone,
    RefsPersonEmail,
    RefsCompanyAddress,
    RefsGroup,
    RefsContract,
    RefsFleet,
    RefsPartnerBanking,
    LatestEntriesCardEmpty
  }
})
export default class RefsList extends Vue implements IRefDetail<ItemTypes> {
  @Prop()
  item!: ItemTypes;

  @Prop()
  itemPromise?: () => Promise<ItemTypes>;

  @Prop()
  refType!: ResourceEnum;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  @Prop()
  customConfig!: MrfiktivProjectCustomViewFieldDtoGen[];

  loading = true;

  loadedItem: ItemTypes | null = null;

  readonly ResourceEnum = ResourceEnum;

  @Ref("refDetail")
  refDetail!: IRefDetail<ItemTypes>;

  emitOpenDetail(v: any) {
    this.$emit("openDetail", v);
  }

  goToDetail() {
    this.refDetail?.goToDetail();
  }

  async mounted() {
    try {
      this.loading = true;

      if (this.item) {
        this.loadedItem = this.item;
      } else if (this.itemPromise) {
        const loadedItem = await this.itemPromise();
        this.loadedItem = loadedItem;
      }
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loading = false;
    }
  }
}
