import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { PartnerTemplate, IPartnerTemplate } from "@/models/partner-template.entity";

export const PartnerTemplateDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IPartnerTemplate> {
  getIdentifier(entity: IPartnerTemplate): string {
    return entity.id;
  }

  mapEntity(entity: IPartnerTemplate): IPartnerTemplate {
    return new PartnerTemplate(entity);
  }
})();
