



























































import { Component, Prop } from "vue-property-decorator";
import AssigneeUpdateMixin from "../project/AssigneeUpdateMixin.vue";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";

@Component({
  components: {},
  filters: {}
})
export default class SelectAssignees extends mixins(PermissionMixin, AssigneeUpdateMixin) {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: () => [] })
  value!: string[];

  @Prop()
  documentId!: string;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: true })
  dense!: boolean;

  @Prop({ default: "max-width: 240px; overflow-x: auto;" })
  css!: boolean;

  @Prop()
  partnerId!: string;

  get users() {
    return PartnerUserModule.entities;
  }

  get assignees() {
    return this.value;
  }

  set assignees(value: string[]) {
    const oldAssignees = this.assignees;
    const newAssignees = value;

    this.debounceChangeAssignees(newAssignees, oldAssignees, this.documentId);

    this.value.splice(0, this.value.length, ...value);
  }

  /**
   * Method to handle assignee changes. Needs to be implemented in the component.
   * @param added All added assignees
   * @param removed All removed assignees
   * @param all All current assignees
   * @param documentId The document id of the current document
   */
  changeAssignees(added: string[], removed: string[], all: string[]) {
    this.$emit("removed", removed);
    this.$emit("added", added);
    this.$emit("input", all);
  }
}
