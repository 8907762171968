import { getNestedObjectValues } from "../objectPath-helper";

export function findArrayDifferences<A extends object, B extends object>(
  oldFields: A[],
  keyA: string,
  newFields: B[],
  keyB: string
) {
  const fieldsThatAreInNewFieldsButNotInOldFields: B[] = [];
  const fieldsThatAreInOldFieldsButNotInNewFields: A[] = [];

  for (const oldField of oldFields) {
    if (!newFields.find(newField => getNestedObjectValues(oldField, keyA) === getNestedObjectValues(newField, keyB))) {
      fieldsThatAreInOldFieldsButNotInNewFields.push(oldField);
    }
  }

  for (const newField of newFields) {
    if (!oldFields.find(oldField => getNestedObjectValues(oldField, keyA) === getNestedObjectValues(newField, keyB))) {
      fieldsThatAreInNewFieldsButNotInOldFields.push(newField);
    }
  }

  return {
    addedFields: fieldsThatAreInNewFieldsButNotInOldFields,
    removedFields: fieldsThatAreInOldFieldsButNotInNewFields
  };
}
