var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      staticClass: "mt-8",
      model: {
        value: _vm.valid,
        callback: function callback($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _vm.isReportPortalContext
        ? _c(
            "v-list-item",
            {
              attrs: {
                "three-line": ""
              }
            },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-card-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("components.profile.notifications.assignee")
                        ) +
                        " "
                    )
                  ]),
                  _c("v-card-subtitle", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.profile.notifications.assigneeExplanation"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "v-list-subtitle",
                    [
                      _c("v-checkbox", {
                        staticClass: "mx-4 my-n2",
                        attrs: {
                          disabled: _vm.disabled,
                          label: _vm.$t(
                            "components.profile.notifications.email"
                          )
                        },
                        model: {
                          value: _vm.assigneeEmail,
                          callback: function callback($$v) {
                            _vm.assigneeEmail = $$v
                          },
                          expression: "assigneeEmail"
                        }
                      }),
                      _c(
                        "tooltip",
                        {
                          attrs: {
                            text: _vm.$t("comingSoon")
                          }
                        },
                        [
                          _c("v-checkbox", {
                            staticClass: "mx-4 my-n2",
                            attrs: {
                              disabled: "",
                              label: _vm.$t(
                                "components.profile.notifications.push"
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-row",
                [
                  _c("v-checkbox", {
                    staticClass: "mx-4 my-n2",
                    attrs: {
                      disabled: "",
                      label: _vm.$t(
                        "components.profile.ProfileCard.notificationTypes.system"
                      )
                    },
                    model: {
                      value: _vm.isSystemEmail,
                      callback: function callback($$v) {
                        _vm.isSystemEmail = $$v
                      },
                      expression: "isSystemEmail"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-checkbox", {
                    staticClass: "mx-4 my-n2",
                    attrs: {
                      disabled: _vm.disabled,
                      label: _vm.$t(
                        "components.profile.ProfileCard.notificationTypes.news"
                      )
                    },
                    model: {
                      value: _vm.marketingOptIn,
                      callback: function callback($$v) {
                        _vm.marketingOptIn = $$v
                      },
                      expression: "marketingOptIn"
                    }
                  })
                ],
                1
              ),
              _vm.partnerNotificationsEnabled
                ? _c(
                    "v-row",
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.partnerCompanyName))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.partnerNotificationsEnabled
                ? _c(
                    "v-row",
                    [
                      _c("v-checkbox", {
                        staticClass: "mx-4 my-n2",
                        attrs: {
                          disabled: _vm.disabled,
                          label: _vm.$t(
                            "components.profile.ProfileCard.notificationTypes.news"
                          )
                        },
                        model: {
                          value: _vm.partnerNotificationsLocal,
                          callback: function callback($$v) {
                            _vm.partnerNotificationsLocal = $$v
                          },
                          expression: "partnerNotificationsLocal"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
      !_vm.isDisabled
        ? _c("profile-confirmation-row", {
            attrs: {
              isLoading: _vm.isLoading,
              valid: _vm.valid
            },
            on: {
              save: _vm.save,
              abort: _vm.abort
            }
          })
        : _vm._e(),
      _vm.isDisabled
        ? _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    color: "primary",
                    "data-test-profile-edit": ""
                  },
                  on: {
                    click: function click($event) {
                      _vm.isDisabled = !_vm.isDisabled
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.profile.EditProfileDialog.edit")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }