
















import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { DocumentModule } from "@/store/modules/document.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import DocumentTable from "./DocumentTable.vue";
import SignRequestTable from "./SignRequestTable.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    TheLayoutPortal,
    MHeader,
    DocumentTable,
    SignRequestTable
  }
})
export default class DocumentTableView extends PartnerFallbackMixin {
  loading = false;

  get actions(): IAction[] {
    const actions: IAction[] = [];

    return actions;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];
    return breadCrumbs;
  }

  get chips() {
    const chips: IAction[] = [];

    chips.push({
      text: `${DocumentModule.totalItems} ${$t("sign.DigitalSignatureHomeView.showAllDocuments")}`,
      key: "numberOfDocuments"
    });

    return chips;
  }

  get partner() {
    return PartnerModule.partner;
  }

  async mounted() {
    try {
      this.loading = true;
      await this.trySetByRouteOrDefault();
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }
}
