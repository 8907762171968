var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      style: "height: ".concat(_vm.height, "; overflow: hidden")
    },
    [
      !_vm.isMobile && !_vm.isLoading
        ? _c(
            "div",
            {
              style: "height: ".concat(
                _vm.height,
                "; overflow-y: auto; position: absolute; width: 400px; right: 10px;"
              )
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "pb-2"
                },
                [
                  _c(
                    "v-col",
                    [
                      _c("document-template-detail-token-list-options", {
                        attrs: {
                          document: _vm.signDocument,
                          showSave: false,
                          showPlaceholderOption: false,
                          getDetailCardEditor: function getDetailCardEditor() {
                            return _vm.detailCardEditor
                          }
                        },
                        on: {
                          save: _vm.resetHighlighted
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.isMobile && _vm.isSelectedItem
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "document-template-detail-token-list-keyboard-instructions",
                            {
                              staticClass: "mb-4"
                            }
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSelectedItem
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("document-template-detail-token-form", {
                            attrs: {
                              page: _vm.page
                            },
                            on: {
                              save: function save($event) {
                                return _vm.$emit("save")
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isSelectedItem
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("document-template-detail-token-list", {
                            attrs: {
                              currentPageNumber: _vm.currentPageNumber,
                              page: _vm.page,
                              isEditValue: true,
                              height: "calc(100vh - 232px)"
                            },
                            on: {
                              setCurrentPage: _vm.setCurrentPage
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? _c("document-detail-card-editor", {
            ref: "detailCardEditor",
            staticStyle: {
              width: "calc(100%)"
            },
            attrs: {
              outlined: false,
              editValues: true,
              isLoading: _vm.isLoading,
              sideBarElement: _vm.sideBarElement,
              currentPageNumber: _vm.currentPageNumber,
              pages: _vm.pages
            },
            on: {
              setCurrentPage: _vm.setCurrentPage,
              save: _vm.resetHighlighted
            }
          })
        : _c("v-skeleton-loader", {
            staticStyle: {
              width: "calc(100%)"
            },
            attrs: {
              type: "image@3"
            }
          }),
      _vm.isMobile
        ? _c("document-template-detail-token-list-options-mobile", {
            staticStyle: {
              "z-index": "5",
              position: "relative",
              bottom: "80px"
            },
            attrs: {
              document: _vm.signDocument,
              showPlaceholderOption: false,
              getDetailCardEditor: function getDetailCardEditor() {
                return _vm.detailCardEditor
              }
            },
            on: {
              save: _vm.resetHighlighted
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }