

























































import { RefTypeMap } from "@/store/modules/refs.store";
import { Vehicle } from "@/models/vehicle.entity";
import { Report } from "@/models/report.entity";
import { Ticket } from "@/models/ticket.entity";
import { SignDocument } from "@/models/sign-document.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { EventModule } from "@/store/modules/event.store";
import { IRefSuggestion, RefSelectType } from "@/store/modules/fleet-aggregation.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";
import RefsList, { IRefDetail } from "./RefsList.vue";
import Tooltip from "./tooltip.vue";
import { Snapshot } from "@/models/snapshot.entity";
import { SignRequest } from "@/models/sign-request.entity";
import { AttachmentRequest } from "@/models/attachment-request.entity";
import { PartnerMessage } from "@/models/partner-message.entity";
import { Cost } from "@/models/cost.entity";
import { Company } from "@/models/company.entity";
import { CompanyGroup } from "@/models/company-group.entity";
import { Person } from "@/models/person.entity";
import { PersonGroup } from "@/models/person-group.entity";
import { Contract } from "@/models/contract.entity";
import { Group } from "@/models/group.entity";
import { Fleet } from "@/models/fleet.entity";

@Component({
  components: { RefsList, draggable, Tooltip },
  filters: {}
})
export default class RefsSelected extends Vue {
  @Prop()
  private value!: MrfiktivReferenceGen[];

  @Prop({ default: () => [] })
  suggested!: IRefSuggestion[];

  @Prop()
  partnerId!: string;

  @Prop({ default: true })
  draggable!: boolean;

  @Prop({ default: true })
  removable!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: true })
  outlined!: boolean;

  get selected(): MrfiktivReferenceGen[] {
    return this.value;
  }
  set selected(v: MrfiktivReferenceGen[]) {
    this.$emit("input", v);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  readonly RefTypeMap = RefTypeMap;

  onChange() {
    this.$emit("input", this.value);
  }

  getRefTypeName(refType: ResourceEnum) {
    if (refType === ResourceEnum.EVENT) {
      return this.$t("common.nouns.appointments");
    }
    return this.$t(`BackendResourceEnum.${refType}`);
  }

  getClickTranslation(refType: ResourceEnum) {
    return this.$t(`common.BackendResourceEnum.click.${refType}`);
  }

  getClickTooltipTranslation(refType: ResourceEnum) {
    return this.$t(`common.BackendResourceEnum.click.tooltip.${refType}`);
  }

  async resolveReference(item: MrfiktivReferenceGen) {
    let found = this.suggested.find((e: IRefSuggestion) => e.ref.id === item.refId)?.ref;
    if (found) return found;

    const module = this.RefTypeMap.get(item.refType as BackendResourceEnum)?.module;
    found = module?.maps?.id?.get(item.refId)?.[0];

    // new paginated base store returns list of items from maps
    if (Array.isArray(found)) {
      found = found[0];
    }

    if (found) return found;

    found = this.RefTypeMap.get(item.refType as ResourceEnum)?.module.filteredAndSorted.find(
      (e: RefSelectType) => e.id === item.refId
    );
    if (found) return found;

    // if the reference is not found in the local store, try to get it from the backend
    switch (item.refType as ResourceEnum) {
      case ResourceEnum.EVENT:
        found = await EventModule.getOne({ partnerId: this.partnerId, eventId: item.refId });
        break;
      case ResourceEnum.VEHICLE:
        found = await new Vehicle({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.REPORT:
        found = await new Report({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.TICKET:
        found = await new Ticket({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.DOCUMENT:
        found = await new SignDocument({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.SNAPSHOT:
        found = await new Snapshot({ id: item.refId, handoverId: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.SIGN:
        found = await new SignRequest({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.ATTACHMENT_REQUEST:
        found = await new AttachmentRequest({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.MESSAGE:
        found = await new PartnerMessage({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.COST:
        found = await new Cost({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.COMPANY:
        found = await new Company({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.COMPANY_GROUP:
        found = await new CompanyGroup({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.PERSON:
        found = await new Person({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.PERSON_GROUP:
        found = await new PersonGroup({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.CONTRACT:
        found = await new Contract({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.GROUP:
        found = await new Group({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
      case ResourceEnum.FLEET:
        found = await new Fleet({ id: item.refId, partnerId: this.partnerId }).fetch();
        break;
    }

    if (found) return found;

    return found;
  }

  removeFromSuggested(item: MrfiktivReferenceGen) {
    const index = this.selected.findIndex(i => i.refId === item.refId && item.refType === i.refType);
    this.selected.splice(index, 1);
    this.$forceUpdate();
    this.$emit("removed", item);
  }

  goToDetail(ref: MrfiktivReferenceGen) {
    if (this.$listeners.openDetail) {
      this.$emit("openDetail", ref);
      return undefined;
    }

    const referenceList = this.$refs["reference" + ref.refId];
    if (!referenceList) {
      return;
    }

    const reference = referenceList[0] as IRefDetail<any>;
    if (!reference?.goToDetail) {
      return;
    }

    reference.goToDetail();
  }
}
