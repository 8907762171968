var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticStyle: {
        height: "100%"
      }
    },
    [
      _c("preselect-template-card", {
        key: _vm.urlWithQuery,
        ref: "templateCard",
        staticStyle: {
          height: "calc(100% - 50px)"
        },
        attrs: {
          templateKey: "request_signature_mail",
          partnerId: _vm.partnerId,
          from: [_vm.partner],
          context: _vm.context,
          to: [_vm.signRequest.recipient.email]
        },
        on: {
          sent: _vm.save
        }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6"
              }
            },
            [
              _c("v-select", {
                staticClass: "mb-n7 mt-n1",
                attrs: {
                  items: _vm.baseReportUrls,
                  "item-text": "type",
                  "item-value": "id",
                  outlined: "",
                  dense: "",
                  "return-object": "",
                  "single-line": ""
                },
                on: {
                  change: _vm.createUrlWithQuery
                },
                model: {
                  value: _vm.baseReportUrl,
                  callback: function callback($$v) {
                    _vm.baseReportUrl = $$v
                  },
                  expression: "baseReportUrl"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6"
              }
            },
            [
              _c("v-text-field", {
                ref: "link",
                staticClass: "mb-n7 mt-n1",
                attrs: {
                  dense: "",
                  outlined: "",
                  value: _vm.urlWithQuery,
                  "append-icon": "mdi-content-copy",
                  "persistent-hint": "",
                  "data-test-copy-link": ""
                },
                on: {
                  "click:append": _vm.copyLinkToClipboard
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }