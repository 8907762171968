





































































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { IControlElements } from "@/components/utility/TableWrapper.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { ISignDocument } from "@/models/sign-document.entity";
import { ISignRequest } from "@/models/sign-request.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { DocumentModule } from "@/store/modules/document.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import SignDocumentCreateDialog from "./DocumentCreateDialog.vue";
import DragMixin from "./DragMixin.vue";
import ReportDocumentSignRequestDialog from "./ReportDocumentSignRequestDialog.vue";
import SignRequestCreateDialog from "./SignRequestCreateDialog.vue";

@Component({
  components: {
    Tooltip,
    TheLayoutPortal,
    SignDocumentCreateDialog,
    SignRequestCreateDialog,
    ConfirmActionDialog,
    ReportDocumentSignRequestDialog,
    PaginatedTable
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class DocumentTable extends mixins(DragMixin) {
  @Ref("signRequestDialog")
  signRequestDialog!: ReportDocumentSignRequestDialog;

  @Ref("signDocumentCreateDialog")
  signDocumentCreateDialog!: SignDocumentCreateDialog;

  @Prop({ default: false })
  load!: boolean;

  @Prop({ default: false })
  isTemplate!: boolean;

  readonly store = DocumentModule;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  isLoadingAll = false;
  isLoading = false;

  acceptImage = false;

  /**
   * Opening delete confirmation
   */
  isDeleteDialogActive = false;
  isDeleteDialogLoading = false;

  search = "";

  itemsPerPage = 25;

  snack = false;
  snackColor = "";
  snackText = "";

  expanded = [];
  selected: ISignDocument[] = [];

  createSignRequest = false;

  headers = [
    { text: this.$t("sign.DocumentTable.created"), align: "start", width: "120px", value: "timestamp.created" },
    { text: this.$t("sign.DocumentTable.type"), align: "start", value: "isTemplate", width: 80 },
    { text: this.$t("sign.DocumentTable.internalTitle"), align: "start", value: "title", width: 350 },
    { text: this.$t("sign.DocumentTable.name"), align: "start", value: "name", width: 350 },
    { text: this.$t("sign.DocumentTable.modified"), align: "start", value: "timestamp.lastModified" },
    { text: "", align: "end", value: "controls", width: 120, sortable: false }
  ];

  onDrop(file: File) {
    this.signDocumentCreateDialog.isDialogActive = true;
    this.signDocumentCreateDialog.$nextTick(() => {
      this.signDocumentCreateDialog.signDocumentForm.createDocumentDto.file = file;
      this.signDocumentCreateDialog.signDocumentForm.onChange(file);
    });
  }

  @Watch("isTemplate")
  setIsTemplateFilter(value: boolean) {
    DocumentModule.setFilters([
      new PageFilterElement({
        key: "isTemplateFilter",
        operation: PageFilterOperationEnum.EQUAL,
        value: value.toString()
      })
    ]);
  }

  get partner() {
    return PartnerModule.partner;
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        text: this.$t("sign.DocumentTable.open").toString(),
        action: this.open
      },
      {
        icon: "mdi-delete-outline",
        text: this.$t("sign.DocumentTable.delete").toString(),
        action: (item: ISignDocument) => {
          this.selected = [item];
          this.isDeleteDialogActive = true;
        }
      },
      {
        icon: "mdi-file-sign",
        text: this.$t("sign.DocumentTable.sign").toString(),
        action: async ({ partnerId, id }: ISignDocument) => {
          await this.signRequestDialog.initializeForSignDocument(partnerId, id);
        },
        disabled: !UserModule.abilities.can(ActionEnum.READ, ResourceEnum.SIGN, this.partner.id)
      }
    ];
  }

  goToSignRequest(document: ISignDocument) {
    this.selected = [document];
    this.createSignRequest = true;
  }

  async open(request: ISignDocument) {
    await new GoToHelper(this.$router).goToDocumentDetail(request.id, request.partnerId);
  }

  async deleteDocument() {
    this.isDeleteDialogLoading = true;

    const request = this.selected[0];

    if (!request) {
      this.$toast.error("No Document selected");
    }

    await request.delete();

    this.isDeleteDialogLoading = false;
    this.isDeleteDialogActive = false;
  }

  async signRequestSaved(signRequest: ISignRequest) {
    this.createSignRequest = false;

    if (!signRequest) {
      return;
    }

    await this.$router.push({
      name: "SignRequestDetailView",
      params: { partnerId: signRequest.partnerId, signRequestId: signRequest.id }
    });
  }

  async save() {
    await DocumentModule.fetchFirstPage({ partnerId: this.partner.id });
  }
}
